import { authFetch } from "@shared/services/auth/authFetch";
import SettingsApiModel from "@shared/apimodels/SettingsApiModel";
import BuildingApiModel from "@shared/apimodels/BuildingApiModel";
import { QuotaProfileApiModel } from "@shared/apimodels/QuotaProfileApiModel.js";
import { AnalyticsVisualConfigApiModel } from "@shared/apimodels/AnalyticsVisualConfigApiModel.js";
import { LocationProfileApiModel } from "@shared/apimodels/LocationProfileApiModel.js";
import { AdminExceptionApiModel } from "@shared/apimodels/AdminExceptionApiModel.js";
import { MeetingsSettingsApiModel } from "@shared/apimodels/MeetingsSettingsApiModel.js";
import { UserAttributesModel } from "@admin/apimodels/UserAttributesModel";
import UserPreferencesApiModel from "@shared/apimodels/UserPreferencesApiModel";
import { MeetingRoomApiModel } from "@shared/apimodels/MeetingRoomApiModel";
import { AnalyticsPageConfigApiModel } from "@shared/apimodels/AnalyticsPageConfigApiModel";
import ParkingLotApiModel from "@admin/apimodels/ParkingLotApiModel";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { getDateKey } from "@shared/utils/dateHelpers";
import { WorkdayApiModel } from "@shared/apimodels/WorkdayApiModel";
import { ExtrasApiModel } from "@shared/apimodels/Extras";

const meetingsBaseUrl =
  process.env.MAPIQ_API_BASE_URL_MEET || process.env.MAPIQ_API_BASE_URL;

const gatewayBaseUrl =
  process.env.MAPIQ_API_BASE_URL_GATEWAY || process.env.MAPIQ_API_BASE_URL;

const smsBaseUrl =
  process.env.MAPIQ_API_BASE_URL_SMS || process.env.MAPIQ_API_BASE_URL;

const usersBaseUrl =
  process.env.MAPIQ_API_BASE_URL_USERS || process.env.MAPIQ_API_BASE_URL;

const post = (url, body, headers = {}, baseUrl) =>
  authFetch(
    url,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    },
    baseUrl
  );

const put = (url, body, headers = {}, baseUrl) =>
  authFetch(
    url,
    {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    },
    baseUrl
  );

const patch = (url, body, headers = {}, baseUrl) =>
  authFetch(
    url,
    {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    },
    baseUrl
  );

const del = (url, headers = {}, baseUrl) =>
  authFetch(url, { method: "DELETE", headers }, baseUrl);

export const AdminApi = {
  // SHIFT QUOTA PROFILES
  getShiftQuotaProfiles: () =>
    authFetch("/shifts/accessprofiles").then(ps =>
      ps.map(QuotaProfileApiModel.fromDto)
    ),
  saveShiftQuotaProfile: profile => {
    if (profile.id) return put(`/shifts/accessprofiles/${profile.id}`, profile);

    return post(`/shifts/accessprofiles`, profile);
  },
  deleteShiftQuotaProfile: id => del(`/shifts/accessprofiles/${id}`),
  importUsersToShiftQuotaProfile: (profileId, users) =>
    authFetch(`/shifts/AccessProfiles/${profileId}/import/users`, {
      method: "POST",
      body: users,
      headers: {
        "Content-Type": "text/csv"
      }
    }),
  exportUsersFromShiftQuotaProfile: profileId =>
    authFetch(`/shifts/accessprofiles/${profileId}/export/users`),

  // PARKING QUOTA PROFILES
  getUsersCountPerParkingQuotaProfile: () =>
    authFetch("/parking/admin/QuotaProfiles/UserCounts"),
  getParkingQuotaProfiles: () =>
    authFetch("/parking/QuotaProfiles").then(parkingProfiles =>
      parkingProfiles.map(QuotaProfileApiModel.fromDto)
    ),
  saveParkingQuotaProfile: profile => {
    if (profile.id)
      return put(`/parking/admin/QuotaProfiles/${profile.id}`, profile);

    return post("/parking/admin/QuotaProfiles", profile);
  },
  deleteParkingQuotaProfile: id => del(`/parking/admin/QuotaProfiles/${id}`),
  importUsersToParkingQuotaProfile: (profileId, users) =>
    authFetch(`/parking/admin/QuotaProfiles/${profileId}/importUsers`, {
      method: "POST",
      body: users,
      headers: {
        "Content-Type": "text/csv"
      }
    }),
  exportUsersFromParkingQuotaProfile: profileId =>
    authFetch(`/parking/admin/quotaProfiles/${profileId}/exportUsers`),

  //SHIFT LOCATION PROFILES
  getShiftLocationProfiles: () => {
    return authFetch("/shifts/locationprofiles").then(ps =>
      ps.map(LocationProfileApiModel.fromDto)
    );
  },
  saveShiftLocationProfile: profile => {
    if (profile.id === null) {
      return post(`/shifts/locationprofiles`, profile);
    }

    return put(`/shifts/locationprofiles/${profile.id}`, profile);
  },
  deleteShiftLocationProfile: profileId => {
    return del(`/shifts/locationprofiles/${profileId}`);
  },
  importUsersToShiftLocationProfile: (profileId, users) =>
    authFetch(`/shifts/LocationProfiles/${profileId}/import/users`, {
      method: "POST",
      body: users,
      headers: {
        "Content-Type": "text/csv"
      }
    }),
  exportUsersFromShiftLocationProfile: profileId =>
    authFetch(`/shifts/locationprofiles/${profileId}/export/users`),

  //PARKING LOCATION PROFILES
  getParkingLots: () =>
    authFetch("/parking/admin/parkingLot", {
      headers: { "x-api-version": "2.0" }
    }).then(parkingLots => parkingLots.map(p => new ParkingLotApiModel(p))),
  getParkingLocationProfiles: () =>
    authFetch("/parking/locationProfiles").then(parkingProfiles =>
      parkingProfiles.map(LocationProfileApiModel.fromDto)
    ),
  saveParkingLocationProfile: profile => {
    if (profile.id)
      return put(`/parking/admin/locationProfiles/${profile.id}`, profile);

    return post("/parking/admin/locationProfiles", profile);
  },
  deleteParkingLocationProfile: profileId => {
    return del(`/parking/admin/locationprofiles/${profileId}`);
  },
  importUsersToParkingLocationProfile: (profileId, users) =>
    authFetch(`/parking/admin/locationprofiles/${profileId}/importedUsers`, {
      method: "POST",
      body: users,
      headers: {
        "Content-Type": "text/csv"
      }
    }),
  exportUsersFromParkingLocationProfile: profileId =>
    authFetch(`/parking/admin/locationprofiles/${profileId}/users`),
  getUsersCountPerParkingLocationProfile: () =>
    authFetch("/parking/admin/locationprofiles/usercounts"),

  //WORKDAYS
  getUserWorkdays: (startDate, endDate, userId) =>
    authFetch(
      `/users/${userId}/workdays?startDate=${getDateKey(
        startDate
      )}&endDate=${getDateKey(endDate)}`
    ).then(workdays => workdays.map(w => new WorkdayApiModel(w))),
  createWorkdayOnBehalf: params =>
    post("/admin/workdays", params, { "x-api-version": "2.0" }).then(
      apiObj => new WorkdayApiModel(apiObj)
    ),
  updateWorkdayOnBehalf: (params, workdayId) =>
    put(`/admin/workdays/${workdayId}`, params, {
      "x-api-version": "2.0"
    }).then(apiObj => new WorkdayApiModel(apiObj)),

  // WORKSPACES
  getWorkspaces: (
    date,
    endDate,
    userId //With 3 arguments it gets workspaces in a range for a user (dates can be the same if necessary)
  ) =>
    //With 2 arguments it gets workspaces in a range for all users
    //With 1 argument it gets workspaces on a single day for all users
    (userId
      ? authFetch(
          `/workspace-reservations?startDate=${date}&endDate=${endDate}&userId=${userId}`,
          { headers: { "x-api-version": "2.0" } }
        )
      : endDate
      ? authFetch(
          `/workspace-reservations?startDate=${date}&endDate=${endDate}`,
          { headers: { "x-api-version": "2.0" } }
        )
      : authFetch(`/workspace-reservations?startDate=${date}&endDate=${date}`, {
          headers: { "x-api-version": "2.0" }
        })
    ).then(workspaces =>
      workspaces.filter(w => w.status === ReservationStatus.Confirmed)
    ),

  deleteWorkspaces: reservationIds =>
    authFetch("/workspace-reservations", {
      method: "DELETE",
      body: JSON.stringify({ reservationIds }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),

  updateWorkspace: ({ nodeId, workspaceId, userId, date }) => {
    if (!workspaceId)
      return post("/workspace-reservations", {
        nodeId,
        userId,
        startDate: date,
        endDate: date
      });
    return patch(`/workspace-reservations/${workspaceId}`, {
      nodeId
    });
  },
  getWorkdays: (startDate, endDate) =>
    authFetch(`/admin/workdays?startDate=${startDate}&endDate=${endDate}`, {
      headers: { "x-api-version": "2.0" }
    }),

  deleteWorkdays: workdayIds =>
    authFetch("/admin/workdays", {
      method: "DELETE",
      body: JSON.stringify({ workdayIds }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),

  getCheckInStatuses: (startDate, endDate) => {
    return authFetch(
      `/localization/admin/checkin/events?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { "x-api-version": "2.0" }
      }
    );
  },
  getDeskSuggestions: ({ areaId, userId, date }) =>
    authFetch(
      `/shifts/admin/desksuggestions?areaId=${areaId}&userId=${userId}&date=${date}`
    ),
  //PARKING RESERVATIONS
  getParkingReservations: (startDate, endDate) =>
    endDate
      ? authFetch(
          `/parking/admin/reservations?startDate=${startDate}&endDate=${endDate}`,
          { headers: { "x-api-version": "2.0" } }
        )
      : authFetch(
          `/parking/admin/reservations?startDate=${startDate}&endDate=${startDate}`,
          { headers: { "x-api-version": "2.0" } }
        ),
  deleteParkingReservations: ids =>
    authFetch("/parking/admin/reservations", {
      method: "DELETE",
      body: JSON.stringify({ reservationIds: ids }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),
  // USERS
  getUsers: (
    pageIndex,
    pageSize,
    getCount,
    sorter = { property: "email" },
    filterQuery
  ) =>
    filterQuery
      ? authFetch(
          `/shifts/users?filters=${filterQuery}&UseCount=${getCount}&sorts=${sorter.property}&page=${pageIndex}&pageSize=${pageSize}`
        )
      : authFetch(
          `/shifts/users?UseCount=${getCount}&sorts=${sorter.property}&page=${pageIndex}&pageSize=${pageSize}`
        ),
  getUsersTotalCount: () =>
    authFetch("/shifts/users?UseCount=true&page=1&pageSize=1"),
  //                                      v--- might want to include ${user.id} here
  getUser: id => authFetch(`/shifts/users/${id}`),
  saveUser: user => {
    [
      "accessProfileId",
      "locationProfileId",
      "parkingQuotaProfileId",
      "parkingLocationProfileId"
    ].forEach(el => {
      if (user[el] === "AUTO") user[el] = null;
    });
    return put("/shifts/users", user);
  },

  postUser: user => post("/shifts/users", user),
  getUsersCountPerShiftLocationProfile: () =>
    authFetch("/shifts/locationprofiles/usercounts"),
  getProfileCount: () => authFetch("/shifts/accessprofiles/usercounts"),
  getUserEmail: email => authFetch(`/shifts/users/validate?email=${email}`),
  getUsersByEmail: (email, usersCount) =>
    authFetch(
      `/shifts/emails?searchValue=${email}&retrieveAmount=${usersCount}`
    ),
  //GUESTS
  postGuest: guest => post("/shifts/guests", guest),
  getGuests: (pageIndex, pageSize, getCount, sorter, filterQuery) =>
    filterQuery
      ? authFetch(
          `/shifts/guests?filters=${filterQuery}&UseCount=${getCount}&sorts=${sorter.property}&page=${pageIndex}&pageSize=${pageSize}`
        )
      : authFetch(
          `/shifts/guests?UseCount=${getCount}&sorts=${sorter.property}&page=${pageIndex}&pageSize=${pageSize}`
        ),
  getGuestsTotalCount: () =>
    authFetch("/shifts/guests?UseCount=true&page=1&pageSize=1"),
  editGuest: ({ userId, phoneNumber, companyName, firstName, lastName }) =>
    put("/shifts/guests", {
      userId,
      phoneNumber,
      companyName,
      firstName,
      lastName
    }),
  getGuest: id => authFetch(`/shifts/guests/${id}`),
  deleteGuest: id => del(`/shifts/guests/${id}`),
  getCompanies: () => authFetch("/shifts/guests/companynames"),
  getUserDomain: email =>
    authFetch(`/shifts/emails/EmailDomainExists?email=${email}`),
  // BUILDINGS
  // GET
  getBuildings: () =>
    authFetch(`/shifts/buildings`, {
      headers: { "x-api-version": "2.0" }
    }).then(obj => obj.map(b => new BuildingApiModel(b))),
  // CREATE
  postBuilding: building =>
    post(
      `/shifts/buildings`,
      {
        ianaTimezone: "Europe/Amsterdam",
        ...building
      },
      { "x-api-version": "2.0" }
    ),

  // DELETE
  deleteBuilding: buildingId =>
    del(`/shifts/Buildings/${buildingId}`, {
      "x-api-version": "2.0"
    }),

  // UPDATE
  saveBuilding: building =>
    put(`/shifts/buildings/${building.id}`, building, {
      "x-api-version": "2.0"
    }),

  updateBuildingBookingPolicy: buildings =>
    put(
      "/buildings/allow-workday-without-workspace",
      {
        allowWorkdayWithoutWorkspacePerBuildingId: buildings
      },
      {
        "x-api-version": "2.0"
      }
    ).then(apiBuildings => apiBuildings.map(b => new BuildingApiModel(b))),
  // PARKING
  getParkingReservationsCount: nodeId =>
    authFetch(`/parking/admin/${nodeId}/reservations/count`),

  // SETTINGS
  saveSettings: settings =>
    put("/shifts/settings", settings).then(
      apiObj => new SettingsApiModel(apiObj)
    ),

  getLastPublicCheckInActivity: () =>
    authFetch("/localization/admin/checkin/lastPublicApiActivity"),

  //extras
  getExtras: () =>
    authFetch("/admin/extrasLinks").then(extras =>
      extras.map(extra => new ExtrasApiModel(extra))
    ),

  postExtras: extras =>
    post("/admin/extrasLinks", {
      extras
    }).then(extras => extras.map(e => new ExtrasApiModel(e))),

  updateExtras: extras =>
    put("/admin/extrasLinks", { extras }).then(extras =>
      extras.map(e => new ExtrasApiModel(e))
    ),

  deleteExtras: extrasIds =>
    authFetch("/admin/extrasLinks", {
      method: "DELETE",
      body: JSON.stringify({ extrasIds }),
      headers: {
        "Content-Type": "application/json"
      }
    }),

  // Home tab

  getWorkspaceCounts: (start, end) =>
    authFetch(
      `/reservation-counts?from=${getDateKey(start)}&to=${getDateKey(end)}`,
      { headers: { "x-api-version": "2.0" } }
    ),
  //POWER BI
  getPowerBIDashboard: () => {
    return authFetch("/shifts/PowerBI/token");
  },

  getPowerBIVisualsConfig: () => {
    return authFetch("/shifts/PowerBI/visuals").then(vs =>
      vs.map(AnalyticsVisualConfigApiModel)
    );
  },
  getPowerBIPagesConfig: () => {
    return authFetch("/shifts/PowerBI/pages").then(vs =>
      vs.map(AnalyticsPageConfigApiModel)
    );
  },

  deleteShiftsUser: userId => del(`/shifts/users/${userId}`),
  deleteUser: directoryObjectId =>
    del(
      `/admin/users/?directoryObjectId=${directoryObjectId}`,
      {},
      usersBaseUrl
    ),
  getUserStats: () =>
    authFetch("/shifts/UserAttribute/UserAttributes ").then(userStats =>
      userStats.map(UserAttributesModel.fromApiObj)
    ),
  //EXCEPTIONS
  /**
   * Get exceptionals.
   * @param {string} endDateAfter string in format of 'yyyy-MM-dd'
   * @returns {Promise<AdminExceptionApiModel[]>} AdminExceptionApiModels.
   */
  getExceptions: endDateAfter => {
    const queryString = endDateAfter ? `?endDateAfter=${endDateAfter}` : "";
    return authFetch(`/shifts/admin/openingdayexceptions${queryString}`).then(
      ex =>
        // return Promise.resolve(exceptions).then(ex =>
        ex.map(AdminExceptionApiModel.fromApiObj)
    );
  },
  postException: ex => {
    return post("/shifts/admin/openingdayexceptions", ex).then(
      AdminExceptionApiModel.fromApiObj
    );
  },
  deleteException: id => {
    return del(`/shifts/admin/openingdayexceptions/${id}`);
  },
  putException: ex => {
    return put(`/shifts/admin/openingdayexceptions/${ex.id}`, ex).then(
      AdminExceptionApiModel.fromApiObj
    );
  },

  postExceptionPreflight: ex => {
    return post(`/shifts/admin/openingdayexceptions/precalculation`, ex).then(
      apiObj => apiObj
    );
  },

  // Enable or disable the meetings feature for users
  putMeetingSettings: settings => {
    return put("/meetings/Settings", settings, {}, meetingsBaseUrl).then(
      MeetingsSettingsApiModel.fromApiObj
    );
  },

  getAllPreferences: () => {
    return authFetch("/me/preferences", {}, gatewayBaseUrl).then(
      obj => new UserPreferencesApiModel(obj)
    );
  },

  setUserDismissedCheckInApiInactivityWarning: () => {
    return patch("/shifts/me/preferences", {
      adminCheckInApiInactivityWarningDismissed: true
    });
  },

  /**
   * Returns meeting rooms.
   * @returns {Promise<MeetingRoomApiModel[]>}
   */
  getAvailableMeetingRooms: roomsCacheKey => {
    return authFetch(
      `/meetings/me/rooms?cloudSuite=${roomsCacheKey}`,
      {},
      meetingsBaseUrl
    ).then(rooms => rooms.map(MeetingRoomApiModel.fromApiObj));
  },
  putSubscription: featureFlagIds =>
    authFetch(
      `/me/subscription`,
      {
        method: "PUT",
        body: JSON.stringify({ featureFlagIds }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      smsBaseUrl
    )
};
