import template from "./WorkDayTile.template.html";
import "./WorkDayTile.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import ko from "@shared/knockout/extended";
import { ParkingService } from "../Parking/Service/ParkingService";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import {
  getWorkspaceIcon,
  getWorkspaceIconFromWorkspace,
  WorkspaceAvailability
} from "@app/utils/workspaceHelpers";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { CheckInStatusSourceType } from "@shared/services/CheckInStatus";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { isManualCheckInFeatureEnabled } from "@app/utils/checkIn";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";
import { format } from "date-fns";
import { ExtrasTypes } from "@admin/components/SettingsViewer/views/ExtrasView/helpers/ExtrasTypes";

const WorkDayTileVM = ({
  workspaceBooked,
  workspaceAction,
  workspaceActionButton,
  workspace,
  day,
  workspaceAccessProfile,
  user,
  workday = ko.observable(null),
  workdayBooked = ko.observable(false),
  buildingId = null
}) => {
  const parkingFeatureEnabled = getFeatures().has(FeatureNames.PARKING);
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const urlNavigation = UrlNavigationSingleton();
  const parkingService = ParkingService(day, user);
  const checkInService = CheckInServiceSingleton();
  const checkInBuildingName = checkInService.buildingName;
  const checkInSourceType = checkInService.sourceType;
  const loadingParkings = parkingService.loading;
  const loadingWorkday = ko.pureComputed(() =>
    WorkdayReservationServiceSingleton().loading()
  );
  const parkingData = parkingService.parkingData;
  const parkingBuildingSource = ko
    .pureComputed(() =>
      hasWorkdayFeature
        ? workday()?.status === WorkdayStatus.OfficeDay
          ? workday()
          : null
        : workspace
    )
    .extend({ deferred: true });

  const parkingListener = ko.computed(() =>
    parkingService.getParkingData(ko.unwrap(parkingBuildingSource()))
  );

  const disableParkingAction = ko.pureComputed(() =>
    parkingData()?.disableParkingAction()
  );

  const hasGroupBookingLoadingError = ko.observable(null);
  const retryGroupBookingsObs = ko.observable(null);

  const location = LocationStoreSingleton().get(
    workspace()?.workAreaId ?? workspace()?.floorId ?? workspace()?.buildingId
  );
  return {
    hasWorkdayFeature,
    loadingWorkday,
    workspaceBooked,
    workdayBooked,
    extrasType: ExtrasTypes.WORKDAY,
    isBooked: ko.pureComputed(() => workdayBooked() || workspaceBooked()),
    officeLabel: ko.pureComputed(() =>
      workdayBooked() || workspaceBooked()
        ? workspace()?.officeName || workday()?.nodeName
        : "Not working from office"
    ),
    locationIcon: ko.pureComputed(() =>
      workdayBooked() || workspaceBooked() ? "workspaceOffice" : "workspaceHome"
    ),
    deskBookingIcon: ko.pureComputed(() =>
      workspaceBooked()
        ? location
          ? getWorkspaceIcon(location)
          : getWorkspaceIconFromWorkspace(workspace())
        : "workspaceDesk"
    ),
    locationInfo: ko.pureComputed(() => {
      if (!workspaceBooked()) return null;
      return (
        workspace().workAreaName ??
        workspace().floorName ??
        WorkspaceAvailability.ANY_DESK
      );
    }),
    showDeskInfo: ko.pureComputed(() => ko.unwrap(workspace)?.floorId),
    deskInfo: ko.pureComputed(() => {
      if (!workspaceBooked()) return null;
      const anyDesk = WorkspaceAvailability.ANY_DESK.toLowerCase();
      return workspace().workAreaName
        ? `${workspace().floorName}, ${workspace().deskName ?? anyDesk}`
        : WorkspaceAvailability.ANY_DESK;
    }),

    workspaceBookingsLeft: ko.pureComputed(() =>
      workspaceActionButton.bookingsLeft()
    ),
    parkingData,
    workspaceBooked,
    workspaceBookingPartOfGroup: ko.pureComputed(
      () => !!workspace()?.groupReservationId
    ),
    workspace,
    workspaceAction,
    disableButton: ko.pureComputed(
      () => workspaceActionButton.disableShiftButton() || loadingWorkday()
    ),
    disableParkingAction,
    // We won't show parking button in case that registrationDays for that location profile in none
    // And in case that shift daysPerWeek is zero
    showParkingButton:
      parkingFeatureEnabled &&
      parkingService.profilesAllowParking &&
      !!workspaceAccessProfile.registrationDays.length &&
      !!workspaceAccessProfile.daysPerWeek,

    parkingBookingsLeft: ko.pureComputed(() => parkingData()?.bookingsLeft()),
    parkingLabel: ko.pureComputed(() => parkingData()?.parkingLabel()),
    parkingLotName: ko.pureComputed(() => parkingData()?.parkingLotName()),
    parkingZoneName: ko.pureComputed(() => parkingData()?.parkingZoneName()),
    loadingParkings,
    isFarAhead: workspaceActionButton.isFarAhead,
    workspaceLabel: workspaceActionButton.shiftLabel,
    disableWorkspaceButton: ko.pureComputed(
      () => workspaceActionButton.disableShiftButton() || loadingWorkday()
    ),
    disableParkingButton: ko.pureComputed(
      () => disableParkingAction() || loadingWorkday()
    ),
    parkingFeatureEnabled,
    parkingReservation: ko.pureComputed(() =>
      parkingData()?.parkingReservation()
    ),
    parkingAction: () => {
      if (disableParkingAction() || loadingWorkday()) return;

      urlNavigation.navigate(
        "",
        null,
        hasWorkdayFeature ? [format(day, "yyyy-MM-dd")] : []
      );
      parkingService.openParkingReservation();
    },

    hasGroupInvitationsFeature: getFeatures().has(
      FeatureNames.GROUP_RESERVATIONS
    ),
    showUpdateBuildingToCheckinInBuildingButton: ko.pureComputed(() => {
      const checkInIsEnabled =
        hasWorkdayFeature &&
        (isManualCheckInFeatureEnabled() ||
          checkInSourceType() === CheckInStatusSourceType.Automatic);

      return (
        checkInService.isCheckedInDifferentOffice(day, ko.unwrap(buildingId)) &&
        checkInIsEnabled &&
        !!checkInBuildingName()
      );
    }),
    openWorkspaceWizard: () => {
      if (loadingWorkday()) return;
      workspaceAction();
    },
    onUpdate: () =>
      Mediator().publish(Channels.OpenWorkDayWizard, {
        day
      }),
    openWorkdayWizard: () => {
      if (!hasWorkdayFeature || loadingWorkday()) return;

      Mediator().publish(Channels.OpenWorkdayWizard, {
        day
      });
    },
    user,
    day,
    hasGroupBookingLoadingError,
    retryGroupBookingsObs,
    retryLoadGroupBookings: () =>
      retryGroupBookingsObs(!retryGroupBookingsObs()),
    dispose: () => {
      parkingListener.dispose();
    }
  };
};

export const WorkDayTile = KOComponent(
  "work-day-tile",
  WorkDayTileVM,
  template
);
