import "./TimePicker.scss";
import template from "./TimePicker.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import {
  isToday,
  set,
  addHours,
  addMinutes,
  getHours,
  getMinutes,
  isAfter,
  isEqual
} from "date-fns";
import { createInviteesGroups } from "@app/utils/createInviteesGroups";
import { AvailabilityStatus } from "@app/apimodels/AvailabilityStatus";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { LocationType } from "@app/apimodels/LocationType";

const TimePickerVM = ({ timePickerParams }) => {
  const {
    startDateTime,
    endDateTime,
    date,
    day,
    mediator = Mediator(),
    userOfficeDay,
    selectedAttendees,
    discardTimePicker,
    confirmTimePicker,
    availableAttendeesCount,
    onSiteAttendeesCount,
    selectedSuggestion,
    getInviteeAvailability,
    isInvalidTimeRange
  } = timePickerParams;

  const manualDefaultStartDateTime = isToday(date)
    ? set(addHours(new Date(), 1), { minutes: 0, seconds: 0, milliseconds: 0 })
    : set(date, { hours: 10, minutes: 0, seconds: 0, milliseconds: 0 });
  const manualDefaultEndDateTime = ko.pureComputed(() =>
    addMinutes(manualDefaultStartDateTime, 30)
  );

  const manualStartDate = ko.observable(manualDefaultStartDateTime);
  const manualStartTime = ko.observable(manualDefaultStartDateTime);
  const manualEndDate = ko.observable(manualDefaultEndDateTime());
  const manualEndTime = ko.observable(manualDefaultEndDateTime());

  if (startDateTime() && endDateTime()) {
    manualStartDate(startDateTime());
    manualStartTime(startDateTime());
    manualEndDate(endDateTime());
    manualEndTime(endDateTime());
  }

  const manualStartDateTime = ko.pureComputed(() =>
    manualStartDate() && manualStartTime()
      ? set(manualStartDate(), {
          hours: getHours(manualStartTime()),
          minutes: getMinutes(manualStartTime())
        })
      : null
  );

  const manualEndDateTime = ko.pureComputed(() =>
    manualEndDate() && manualEndTime()
      ? set(manualEndDate(), {
          hours: getHours(manualEndTime()),
          minutes: getMinutes(manualEndTime())
        })
      : null
  );

  const loadingAvailability = ko.observable(false);
  const attendeesWithAvailability = ko.observableArray(null);
  const requestAvailabilityForManualSelection = (
    attendees,
    meetingStartDateTime,
    meetingEndDateTime
  ) => {
    loadingAvailability(true);
    getInviteeAvailability(
      attendees(),
      meetingStartDateTime(),
      meetingEndDateTime(),
      isInvalidTimeRange,
      date,
      day
    )
      .then(attendeesWithAvailability)
      .finally(() => {
        loadingAvailability(false);
      });
  };

  const MAX_AVATARS = 5;

  const avatars = ko.pureComputed(() =>
    attendeesWithAvailability().length > MAX_AVATARS
      ? attendeesWithAvailability()
          .slice(0, MAX_AVATARS - 1)
          .concat({
            label: `+${attendeesWithAvailability().length - MAX_AVATARS + 1}`,
            imageHash: null,
            email: null,
            checkInStatus: ko.observable(null),
            availability: null
          })
      : attendeesWithAvailability()
  );

  const manualAvailabilityAutoUpdater = ko
    .computed(() => {
      requestAvailabilityForManualSelection(
        selectedAttendees,
        manualStartDateTime,
        manualEndDateTime
      );
    })
    .extend({ deferred: true });

  const inviteesGroups = ko.pureComputed(() =>
    createInviteesGroups(attendeesWithAvailability(), userOfficeDay)
  );

  const onInviteesClick = () => {
    mediator.publish(Channels.ToggleAttendeesList, {
      groups: inviteesGroups(),
      title: "Availability"
    });
  };

  const isValidInput = ko.pureComputed(() => {
    const start = manualStartDateTime();
    const end = manualEndDateTime();
    return (
      start &&
      end &&
      start instanceof Date &&
      end instanceof Date &&
      !isAfter(start, end)
    );
  });

  const hasDateTimeChanges = ko.pureComputed(
    () =>
      !isEqual(startDateTime(), manualStartDateTime()) ||
      !isEqual(endDateTime(), manualEndDateTime())
  );

  const back = discardTimePicker;
  const confirm = () => {
    if (!isValidInput()) {
      mediator.publish(Channels.OpenStatusMessage, {
        type: "failure",
        reason: "Please input a valid date and time"
      });
      return;
    }

    if (!hasDateTimeChanges()) {
      confirmTimePicker();
      return;
    }

    //important to first null the suggestion, that in turn will null the times,
    //then overwrite the times
    selectedSuggestion(null);
    startDateTime(manualStartDateTime());
    endDateTime(manualEndDateTime());
    availableAttendeesCount(
      attendeesWithAvailability().filter(
        a => a.availability === AvailabilityStatus.Available
      ).length
    );
    onSiteAttendeesCount(
      attendeesWithAvailability().filter(
        a => a.locationType === LocationType.Local
      ).length
    );
    confirmTimePicker();
  };

  return {
    manualStartDate,
    manualEndDate,
    manualStartTime,
    manualEndTime,
    loadingAvailability,
    dispose: () => {
      manualAvailabilityAutoUpdater.dispose();
    },
    avatars,
    onInviteesClick,
    back,
    confirm
  };
};

export const TimePicker = KOComponent("time-picker", TimePickerVM, template);
