import { Channels } from "@shared/Channels.js";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator.js";
import { CheckInStatus } from "@shared/services/CheckInStatus.js";
import { Singleton } from "@shared/utils/Singleton.js";
import { AppApi } from "./AppApi.js";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel.js";
import { ErrorType } from "@shared/utils/ErrorType.js";
import { LocationStoreSingleton } from "@shared/services/LocationStore.js";
import { isToday } from "date-fns";
/*
  This service makes it easy to get the current user's check-in status, check-in and check-out.
*/
const CheckInService = () => {
  const loading = ko.observable(false);
  const hasError = ko.observable(false);
  const checkInApiModel = ko.observable(null);
  const checkInEnabled = isCheckInFeatureEnabled();

  const checkInStatus = ko.pureComputed(() => checkInApiModel()?.status);
  const hasCheckInStatus = checkInStatus.maybeMap(
    status => status !== CheckInStatus.NotCheckedInYet,
    false
  );
  const sourceType = ko.pureComputed(() => checkInApiModel()?.sourceType);
  const buildingId = ko.pureComputed(() => checkInApiModel()?.buildingId);
  const getCheckInStatus = () => {
    loading(true);
    return AppApi.getCheckInStatus()
      .then(api => {
        hasError(false);
        checkInApiModel(api);
      })
      .catch(() => {
        showLoadingError();
        hasError(true);
      })
      .finally(() => {
        loading(false);
      });
  };

  const postCheckInStatus = (buildingId, status) => {
    if (checkInEnabled) {
      if (!buildingId) throw "Building ID required";
      loading(true);
      return AppApi.postCheckInStatus(buildingId, status)
        .then(api => {
          hasError(false);
          checkInApiModel(api);
        })
        .catch(error => {
          showUpdateError(error);
          hasError(true);
        })
        .finally(() => {
          loading(false);
        });
    }
    return Promise.resolve();
  };

  if (checkInEnabled) getCheckInStatus();

  const isCheckedInDifferentOffice = (date, shiftBuildingId) =>
    isToday(date) &&
    hasCheckInStatus() &&
    checkInStatus() !== CheckInStatus.NotAtTheOffice &&
    shiftBuildingId !== buildingId();

  return {
    loading,
    hasError,
    sourceType,
    buildingId,
    status: checkInStatus,
    hasCheckInStatus,
    buildingName: ko.pureComputed(
      () => LocationStoreSingleton().getBuildingForId(buildingId())?.name
    ),

    getCheckInStatus,
    isCheckedInDifferentOffice,
    checkedInDifferentOfficeId: (date, shiftBuildingId) =>
      isCheckedInDifferentOffice(date, shiftBuildingId) ? buildingId() : null,

    checkOut: buildingId => {
      if (checkInStatus() && checkInStatus() !== CheckInStatus.NotAtTheOffice) {
        return postCheckInStatus(buildingId, CheckInStatus.NotAtTheOffice);
      }
      return Promise.resolve();
    },
    checkIn: buildingId => {
      return postCheckInStatus(buildingId, CheckInStatus.AtTheOffice);
    }
  };
};

export const CheckInServiceSingleton = Singleton(CheckInService);

const showLoadingError = () =>
  Mediator().publish(Channels.OpenStatusMessage, {
    type: "failure",
    reason: "We couldn't load your check-in status. Please try again later."
  });

const showUpdateError = error => {
  const errorModel = Server400ErrorModel(error);
  let reason =
    "We couldn't update your check-in status. Please try again later.";
  let title = null;
  if (
    error.status === 400 &&
    errorModel.firstErrorType === ErrorType.ConsecutiveCheckInStatusChange
  ) {
    reason = errorModel.firstError;
    title = "Status couldn’t be updated";
  }
  Mediator().publish(Channels.OpenStatusMessage, {
    type: "failure",
    title: title,
    reason: reason
  });
};
