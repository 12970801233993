import "./ConnectionsTab.scss";
import template from "./ConnectionsTab.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import { WorkdayColleagueTodayVM } from "@app/viewmodels/ColleagueTodayVM";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { groupBy } from "@shared/utils/groupBy";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { EmptyStates } from "../EmptyState/EmptyState";
import { ConnectedColleaguesServiceSingleton } from "@app/services/ConnectedColleaguesService";

const ConnectionsTabVM = ({ day }) => {
  const connectedColleaguesStore = ConnectedColleaguesStoreSingleton();
  const loadingColleagues = ConnectedColleaguesServiceSingleton().loading;

  const connectedColleagues = ko.pureComputed(() =>
    connectedColleaguesStore.allWithWorkdayOnDate(day)
  );

  const colleaguesWithWorkday = connectedColleagues.mapArray(c =>
    WorkdayColleagueTodayVM(c, day)
  );

  const colleaguesGroups = ko.pureComputed(() => {
    const byBuilding = groupBy(c => c.buildingName, colleaguesWithWorkday());

    return Object.entries(byBuilding)
      .map(([buildingName, connections]) => ({
        building: buildingName,
        connections
      }))
      .sort((group1, group2) => {
        return stringCompareAsc(group1.building, group2.building);
      });
  });

  const showNoConnectionsView = ko.computed(
    () => colleaguesWithWorkday().length === 0
  );

  const showNoColleaguesTodayView = ko.pureComputed(
    () =>
      colleaguesWithWorkday().length > 0 && colleaguesWithWorkday().length === 0
  );

  return {
    loadingColleagues,
    colleaguesGroups,

    showEmptyState: ko.pureComputed(
      () =>
        (showNoConnectionsView() || showNoColleaguesTodayView()) &&
        !loadingColleagues()
    ),

    emptyStateParams: Object.assign(EmptyStates.CONNECTIONS_TAB, {
      showFirstExplainer: showNoColleaguesTodayView,
      showSecondExplainer: showNoConnectionsView,
      onClick: () => UrlNavigationSingleton().navigate("", "connections")
    })
  };
};

export const ConnectionsTab = KOComponent(
  "connections-tab",
  ConnectionsTabVM,
  template
);
