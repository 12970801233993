import { format, isSameDay, startOfDay } from "date-fns";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { LocationType } from "@app/apimodels/LocationType";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { createInviteesGroups } from "@app/utils/createInviteesGroups";
import { OfficeDayVM } from "./OfficeDayVM";

export const SuggestionVM = (
  day,
  selectedSuggestion,
  apiModel,
  id,
  userAsAttendee,
  mediator = Mediator()
) => {
  const dateLabel = format(apiModel.timeSlotStart, "EEEE d MMMM");

  const userOfficeDay = OfficeDayVM(apiModel.timeSlotStart);

  const attendees = apiModel.attendeeAvailabilities
    .concat([userAsAttendee])
    .map(a => ({
      ...a,
      label: a.locationType === LocationType.Unknown ? null : a.initials,
      isOrganizer: false,
      checkInStatus: ko.pureComputed(() =>
        isSameDay(day, startOfDay(new Date()))
          ? CheckInStatusStoreSingleton().getByDirectoryObjectId(a.id)
          : null
      ),
      responseStatus: a.availability
    }));

  const availableAttendeesCount = attendees.filter(
    a => a.availability === "Available"
  ).length;
  const attendeesCount = attendees.length;
  const attendeesAvailabilityLabel = `${availableAttendeesCount}/${attendeesCount} available`;

  const localAttendeesCount = attendees.filter(
    a => a.locationType === LocationType.Local
  ).length;
  const attendeesLocationLabel = `${localAttendeesCount}/${attendeesCount} on site`;

  const startTime = format(apiModel.timeSlotStart, "HH:mm");
  const endTime = format(apiModel.timeSlotEnd, "HH:mm");

  const allAvailable =
    apiModel.availableAttendeesPercentage === 100 ||
    apiModel.attendeeAvailabilities.length === 0;

  const attendeeGroups = createInviteesGroups(attendees, userOfficeDay);

  const select = (self, e) => {
    e.stopPropagation();
    selectedSuggestion(self);
    e.target.scrollIntoView({ behavior: "smooth", inline: "center" });
  };

  const onClick = (self, e) => {
    mediator.publish(Channels.ToggleAttendeesList, {
      groups: attendeeGroups,
      title: "Availability"
    });
  };

  return {
    dateLabel,
    attendeesAvailabilityLabel,
    startTime,
    endTime,
    id,
    allAvailable,
    timeSlotStart: apiModel.timeSlotStart,
    timeSlotEnd: apiModel.timeSlotEnd,
    attendeesLocationLabel,
    onClick,
    select,
    userOfficeDay,
    availableAttendeesCount,
    localAttendeesCount
  };
};
