import { setSubtract, setSum } from "@shared/utils/setHelpers.js";
import { Api } from "./Api.js";

export const FeatureNames = {
  HYBRID_MEETINGS_CALENDAR_VIEW: "HYBRID_MEETINGS_CALENDAR_VIEW",
  CHECK_IN: "CHECK_IN",
  PROFILE_PICTURES: "PROFILE_PICTURES",
  BOOK_MEETINGS: "fe_meeting-booking_temp_2022-03-23",
  DEMO_IMPORT_BUILDING: "bldg_admin-import-building_temp_2022-05-09",
  PARKING: "PARKING",
  MAP_RESTYLE_NO_SHADOWS: "MAP_RESTYLE_NO_SHADOWS",
  MAP_RESTYLE_SHADOWS: "MAP_RESTYLE_SHADOWS",
  ENRICHED_MEETING_DETAILS: "fe_meeting-description_temp_2022-06-13",
  USER_RETENTION: "user-retention_temp_2022-06-17",
  BOOKING_GOOGLE_INTEGRATION: "fe_meeting-booking-google_temp_2022-07-11",
  BUILDING_SELECTOR_SEARCH: "fe_building-selector-search_temp_2022-07-15",
  OCCUPANCY_SENSOR: "OCCUPANCY_SENSOR",
  MANUAL_SELECTION: "fe_manual-time-selection_temp_2022-11-08",
  MANUAL_ROOM_SELECTION: "fe_manual-room-selection_temp_2022-08-09",
  CONNECTION_SUGGESTIONS: "fe_release_connection-suggestions_temp_2022-11-23",
  MEETING_HOURS: "meeting_hours_release_temp_2022-11-25",
  GROUP_RESERVATIONS: "RELEASE_2023-01-06_GROUP_WORKSPACE_RESERVATIONS",
  GROUP_DESK_RESERVATIONS: "RELEASE_2023-05-03_GROUP_DESK_RESERVATIONS",
  ADD_ROOM_TO_MEETING: "fe_add-room-existing-meeting_temp_2022-11-07",
  WORK_DAY: "RELEASE_2023-02-03_WORKDAY",
  PURPOSE_DRIVEN_ANALYTICS: "RELEASE_2023-03-02_PURPOSEDRIVENANALYTICS",
  HERE_NOW: "RELEASE_2023-09-02_HERE_NOW",
  CISCO_RELEASE: "RELEASE_2023-02-03_CISCO",
  CISCO: "CISCO",
  HERE_NOW_V2: "RELEASE_2023-02-13_HERE_NOW_V2",
  EXTRAS: "EXTRAS",
  HISTORIC_BOOKING_OVERVIEW: "RELEASE_2023-03-17_HISTORIC_BOOKING_OVERVIEW",
  LICENSE_PLATE: "RELEASE_2023-04-05_LICENSE_PLATE"
};

export const ToggleFeatureNames = {
  MANUAL_CHECK_IN: "MANUAL_CHECK_IN"
};

export const Status = {
  NotInitialized: "NotInitialized",
  Loading: "Loading",
  Loaded: "Loaded",
  Failed: "Failed"
};

let enabledFeatures = [];
let status = Status.NotInitialized;

export const getFeatures = () => {
  switch (status) {
    case Status.NotInitialized:
      throw "Features not initialized";
    case Status.Loading:
      throw "Features loading";
    case Status.Failed:
    case Status.Loaded:
      // When features fail to load, assume everything's turned off.
      // If you want different behavior, chain a `.catch` to the promise
      // returned by loadFeatures
      return new Set(enabledFeatures);
    default:
      throw "Unknown status";
  }
};

export const loadFeatures = () => {
  switch (status) {
    case Status.Loaded:
    case Status.Failed:
    case Status.Loading:
      throw "You can only load features once per session";

    case Status.NotInitialized:
      status = Status.Loading;

      return Api.getFeatures()
        .then(features => {
          enabledFeatures = overrideFlagsViaUrl(features, document.location);
          status = Status.Loaded;
          return getFeatures();
        })
        .catch(e => {
          status = Status.Failed;
          throw e;
        });

    default:
      throw "Unknown status";
  }
};

export const overrideFlagsViaUrl = (features, loc) => {
  const params = new URL(loc).searchParams;
  const urlDisable = new Set();
  const urlEnable = new Set();

  Object.entries(FeatureNames).forEach(([key, flag]) => {
    if (params.has(key)) {
      const v = params.get(key);
      if (v === "1") urlEnable.add(flag);
      else if (v === "0") urlDisable.add(flag);
      else console.warn(`Cannot override ${flag}. Pass either 1 or 0.`);
    }
  });

  return [...setSubtract(setSum(new Set(features), urlEnable), urlDisable)];
};

export const toggleSpecificFeature = feature => {
  const activeToggleFeatures = Object.values(
    ToggleFeatureNames
  ).filter(active => getFeatures().has(active));

  return getFeatures().has(feature)
    ? activeToggleFeatures.filter(activeFeature => activeFeature !== feature)
    : [...activeToggleFeatures, feature];
};
