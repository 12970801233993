import "./DayOverview.scss";
import template from "./DayOverview.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import {
  format,
  startOfDay,
  isSameDay,
  differenceInHours,
  isBefore,
  parseISO
} from "date-fns";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { MeetingVM } from "@app/viewmodels/MeetingVM";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { getAppFeatures } from "@app/services/AppFeatures";
import { Mediator } from "@shared/mediator";
import { locationLabels } from "@shared/utils/locationLabels";
import { ConnectedColleagueVM } from "@app/viewmodels/ConnectedColleagueVM";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import { ColleagueTodayVM } from "@app/viewmodels/ColleagueTodayVM";
import { groupBy } from "@shared/utils/groupBy";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { MeetingDetails } from "../MeetingDetails/MeetingDetails";
import { UserApp } from "@app/UserApp";
import { shiftActionButtonVM } from "@app/utils/shiftActionButton";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { MeetingCreation } from "../MeetingCreation/MeetingCreation";
import { Channels } from "@shared/Channels";
import { AttendeeResponseStatus } from "@shared/components/HybridMeetings/AttendeeResponseStatus/AttendeeResponseStatus";
import { WorkDayTile } from "../WorkDayTile/WorkDayTile";
import { BookedWorkspaceSummary } from "@shared/components/Workspace/BookedWorkspaceSummary/BookedWorkspaceSummary";
import { PendingWorkspaceSummary } from "../PendingWorkspaceSummary/PendingWorkspaceSummary";
import { WorkspaceConnectionsAndOrMembers } from "../WorkspaceConnectionsAndOrMembers/WorkspaceConnectionsAndOrMembers";
import { GroupInvitations } from "../WorkDayTile/GroupInvitation/GroupInvitations";
import { GroupInvitation } from "../WorkDayTile/GroupInvitation/GroupInvitation";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers.js";

export const DayOverviewVM = ({
  settings,
  defaultOffice,
  path,
  showSidePanelCloseButton,
  endOfCalendar,
  user
}) => {
  showSidePanelCloseButton(true);
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  const bookingFeatureEnabled = getFeatures().has(FeatureNames.BOOK_MEETINGS); //temporary feature toggle
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const urlNavigation = UrlNavigationSingleton();
  const workspaceService = WorkspaceServiceSingleton();

  const workspaceParams = ko.observable(null);
  const pendingWorkspaceParams = ko.observable(null);
  const profile = UserApp.resolveUser().accessProfile;

  const dateStr = hasWorkDayFeature ? getFormatedActiveDateFromUrl() : path[0];
  const urlPath = path[1];

  const day = parseISO(dateStr);
  const weekDay = format(day, "iiii"); // Monday
  const date = format(day, "d MMMM");
  const now = startOfDay(new Date());
  const inPast = isBefore(day, now);

  const shift = ko
    .pureComputed(() => workspaceService.getConfirmedForDate(day))
    .map(getFirstElement)
    .maybeMap(WorkspaceVM);

  const booked = shift.map(Boolean);

  const navigateToDayPage = () => {
    urlNavigation.navigate("", null, [format(day, "yyyy-MM-dd")]);
  };

  const backToDayOverview = () => {
    if (hasWorkDayFeature) {
      navigateToDayPage();
    } else {
      urlNavigation.navigate("", "overview", [format(day, "yyyy-MM-dd")]);
    }
  };

  const setPendingWorkspaceParams = () => {
    const _gwrId = path.at(-1);

    if (!_gwrId) {
      backToDayOverview();
      return;
    }

    pendingWorkspaceParams({
      groupWorkspaceId: _gwrId,
      currentWorkspace: shift,
      date: day,
      selectedLocationId: ko.observable(null),
      user
    });
  };

  let selectedMeetingId = null;
  let meetingCreation = null;

  if (urlPath === "plan") {
    if (bookingFeatureEnabled) meetingCreation = true;
  } else if (urlPath === "workspace" && shift()) {
    workspaceParams({
      date: day,
      workspace: shift,
      selectedLocationId: ko.observable(null),
      user,
      settings,

      editWorkspace: () => {
        if (hasWorkDayFeature) {
          navigateToDayPage();
        } else {
          urlNavigation.navigate(null, null, []);
        }

        shiftActionButton.editShift();
      },

      deleteWorkspace: (showSuccesMessage = true) =>
        workspaceService
          .deleteWorkspace(shift(), day, showSuccesMessage)
          .finally(() => {
            if (hasWorkDayFeature) navigateToDayPage();
          })
    });
  } else if (urlPath === "workspace-invite") {
    setPendingWorkspaceParams();
  } else {
    if (urlPath === "workspace") {
      urlNavigation.navigate("", "overview", [format(day, "yyyy-MM-dd")]);
    }
    selectedMeetingId = urlPath;
  }

  //BOOK A MEETING
  const openMeetingCreation = () => {
    urlNavigation.navigate(null, "overview", [
      format(day, "yyyy-MM-dd"),
      "plan"
    ]);
  };

  //CALENDAR
  const meetingStore = MeetingStoreSingleton();
  const dayMeetings = ko.pureComputed(() => {
    return meetingStore
      .onDate(day)
      .map(MeetingVM(day))
      .sort((m1, m2) => {
        if (isBefore(m1.startDate, m2.startDate)) return -1;
        if (isBefore(m2.startDate, m1.startDate)) return 1;
        const m1Length = differenceInHours(m1.endDate, m1.startDate);
        const m2Length = differenceInHours(m2.endDate, m2.startDate);
        return m2Length - m1Length;
      });
  });

  const meetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const {
    enabledForUsers,
    userHasOptedIn,
    isGoogleIntegration
  } = getAppFeatures().has(meetingsFeature)
    ? getAppFeatures().get(meetingsFeature)
    : { enabledForUsers: false, userHasOptedIn: false };

  const hideCreateButtonForGoogleIntegration =
    isGoogleIntegration &&
    !getFeatures().has(FeatureNames.BOOKING_GOOGLE_INTEGRATION);

  const showCalendarTab = enabledForUsers;
  const connectCalendarParams = {
    title: "What's on the agenda for today?",
    subtitle: "Connect your calendar and see your upcoming meetings."
  };

  const openWorkspaceInfo = () => {
    urlNavigation.navigate("", "overview", [
      format(day, "yyyy-MM-dd"),
      "workspace"
    ]);
  };

  const shiftActionButton = shiftActionButtonVM(
    day,
    settings,
    profile,
    now,
    shift,
    inPast
  );

  const locationId = ko.pureComputed(() =>
    shift() ? shift().workAreaId || shift().floorId || shift().buildingId : null
  );
  const locationInfo = ko.pureComputed(() =>
    locationLabels(locationId(), shift()?.deskName)
  );

  const register = () => {
    urlNavigation.navigate("", null);
    shiftActionButton.bookShift();
  };

  const edit = () => {
    openWorkspaceInfo();
  };

  const shiftAction = () => {
    if (shiftActionButton.disableShiftButton()) return;
    if (booked()) edit();
    else register();
  };

  //CONNECTIONS
  const allColleagues = ConnectedColleaguesStoreSingleton().all.mapArray(
    ConnectedColleagueVM
  );

  const colleaguesWithShift = allColleagues
    .filter(c => c.shifts.some(s => isSameDay(s.day, day)))
    .mapArray(c => ColleagueTodayVM(c, day, shift));

  const colleaguesGroups = ko.pureComputed(() => {
    const byBuilding = groupBy(c => c.buildingName, colleaguesWithShift());
    return Object.entries(byBuilding)
      .map(([buildingName, connections]) => ({
        building: buildingName,
        connections
      }))
      .sort((group1, group2) => {
        if (group1.building === shift()?.officeName) return -1;
        if (group2.building === shift()?.officeName) return 1;
        if (group1.building === defaultOffice().name) return -1;
        if (group2.building === defaultOffice().name) return 1;
        return stringCompareAsc(group1.building, group2.building);
      });
  });

  const tabs = [
    {
      id: "connections",
      title: "Connections",
      isActive: ko.observable(false),
      colleaguesGroups,
      showNoColleaguesTodayView: ko.pureComputed(
        () => allColleagues().length > 0 && colleaguesWithShift().length === 0
      ),
      showNoConnectionsView: ko.pureComputed(
        () => allColleagues().length === 0
      ),
      openConnectionsPage: () => {
        urlNavigation.navigate("", "connections");
      }
    }
  ];

  if (showCalendarTab) {
    const showErrorView = ko.pureComputed(() => meetingStore.dateError(day));

    tabs.unshift({
      id: "calendar",
      title: "Calendar",
      meetings: dayMeetings,
      isActive: ko.observable(false),
      showNoConsentView: !userHasOptedIn,
      showNoMeetingsView: ko.pureComputed(
        () =>
          userHasOptedIn &&
          !showErrorView() &&
          meetingStore.dateHydrated(day) &&
          dayMeetings().length === 0
      ),
      loading: ko.pureComputed(
        () =>
          userHasOptedIn && !showErrorView() && !meetingStore.dateHydrated(day)
      ),
      showErrorView,
      showCreateButton:
        bookingFeatureEnabled &&
        userHasOptedIn &&
        !hideCreateButtonForGoogleIntegration,
      openMeetingCreation,
      connectCalendarParams
    });
  }

  tabs[0].isActive(true);

  const toggleTab = data => {
    tabs.forEach(t =>
      t.id === data.id ? t.isActive(true) : t.isActive(false)
    );
  };

  const attendeesListParams = ko.observable(false);
  const roomDetailsParams = ko.observable(null);

  const setAttendeesList = params => {
    attendeesListParams(params);
  };

  const setRoomDetails = params => {
    roomDetailsParams(params);
  };

  Mediator().subscribe(Channels.ToggleAttendeesList, setAttendeesList);
  Mediator().subscribe(Channels.ToggleRoomDetails, setRoomDetails);

  const showMeetingDetails = ko.pureComputed(
    () => selectedMeetingId && !attendeesListParams() && !roomDetailsParams()
  );

  const showMeetingsList = ko.pureComputed(
    () =>
      !meetingCreation &&
      !selectedMeetingId &&
      !attendeesListParams() &&
      !roomDetailsParams() &&
      !workspaceParams() &&
      !pendingWorkspaceParams()
  );

  return {
    checkInFeatureEnabled,
    user,
    profile,
    shift,
    weekDay,
    date,
    day,
    toggleTab,
    tabs,
    calendarTab: showCalendarTab ? tabs[0] : null,
    connectionsTab: showCalendarTab ? tabs[1] : tabs[0],
    shiftAction,
    shiftActionButton,
    booked,
    inPast,
    isFarAhead: shiftActionButton.isFarAhead,
    locationLine1:
      locationInfo.map(([l1, l2]) => l1)() || shift()?.officeName || null,
    locationLine2:
      locationInfo.map(([l1, l2]) => l2)() || shift()?.locationLabel || null,
    shiftLabel: shiftActionButton.shiftLabel,
    disableShiftButton: shiftActionButton.disableShiftButton,

    selectedMeetingId,
    selectedDate: day,
    showMeetingCreation: meetingCreation,
    showMeetingDetails,
    showMeetingsList,
    showSidePanelCloseButton,
    endOfCalendar,
    hasWorkDayFeature,
    attendeesListParams,
    roomDetailsParams,

    workspaceParams,
    pendingWorkspaceParams,
    dispose: () => {
      Mediator().unsubscribe(Channels.ToggleAttendeesList, setAttendeesList);
      Mediator().unsubscribe(Channels.ToggleRoomDetails, setRoomDetails);
    }
  };
};

const dayOverviewComponent = KOComponent(
  "day-overview",
  DayOverviewVM,
  template
);
export const DayOverview = {
  ...dayOverviewComponent,
  register: ko => {
    MeetingDetails.register(ko);
    dayOverviewComponent.register(ko);
    MeetingCreation.register(ko);
    AttendeeResponseStatus.register(ko);
    WorkDayTile.register(ko);
    BookedWorkspaceSummary.register(ko);
    PendingWorkspaceSummary.register(ko);
    WorkspaceConnectionsAndOrMembers.register(ko);
    GroupInvitations.register(ko);
    GroupInvitation.register(ko);
  }
};
