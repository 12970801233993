import { differenceInDays } from "date-fns";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { pl } from "@shared/utils/pluralize";

export const WorkspaceVM = ({
  areaName,
  floorName,
  deskName,
  buildingName,
  areaId,
  members,
  ...rest
}) => {
  //same logic on two places -> refactor later
  const areaOrFloorName = areaName ?? floorName;
  const locationLabel =
    deskName && areaName ? `${areaName}, ${deskName}` : areaOrFloorName;

  const fullLocationLabel = [floorName, areaName, deskName]
    .filter(str => str && str.length > 0)
    .join(", ");

  const fullLabel = [buildingName, locationLabel]
    .filter(str => str && str.length > 0)
    .join(", ");

  const _members = members.map(({ user, ...memberRest }) => {
    const daysUntilExpiry = differenceInDays(
      memberRest.expiryDateTime,
      new Date()
    );
    const expiryLabel =
      memberRest.status !== ReservationStatus.InvitationPending
        ? null
        : daysUntilExpiry > 0
        ? `${daysUntilExpiry} ${pl("day", daysUntilExpiry)} to respond`
        : "Final day to respond";

    return { ...memberRest, user: UserVM(user), expiryLabel };
  });

  // eslint-disable-next-line no-unused-vars
  const { isOwner, ...ownerRest } = _members.find(({ isOwner }) => isOwner);

  return {
    officeName: buildingName,
    floorName,
    workAreaName: areaName,
    workAreaId: areaId,
    locationLabel,
    fullLocationLabel,
    deepestBookedLeafNameWithoutDesk: areaOrFloorName ?? buildingName,
    buildingAndFloorName: areaName
      ? `${buildingName}, ${floorName}`
      : floorName
      ? buildingName
      : null,
    fullLabel,
    deskName,
    members: _members,
    owner: ownerRest,
    ...rest
  };
};

export const UserVM = user => {
  return {
    ...user,
    displayName:
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : null
  };
};
