import { getAppFeatures } from "@app/services/AppFeatures.js";
import {
  enable,
  giveConsent,
  disable,
  handleError
} from "@app/utils/meetConsent";
import ko from "@shared/knockout/extended";

import { FeatureNames } from "@shared/services/Features.js";
import { MeetApi } from "@shared/services/MeetApi.js";

export const MeetSection = user => {
  const loading = ko.observable(false);

  const consentStatus = ko.observable(null);
  const consentIssue = consentStatus.maybeMap(
    status => status.isValidRefreshToken === false,
    false
  );

  const isEnabled = getAppFeatures().get(
    FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW
  )?.userHasOptedIn;

  if (isEnabled) {
    loading(true);
    MeetApi.getConsentStatus()
      .then(consentStatus)
      .catch(e => {
        // The status call is not vital, if it fails we can assume stuff works.
        // If not, we'll notice soon enough in other calls to the meeting service.
      })
      .finally(() => {
        loading(false);
      });
  }

  return {
    isEnabled,
    loading,
    giveConsent: () => {
      loading(true);
      // Success will redirect, so no need to clear loading state
      giveConsent().catch(e => {
        // Can be an issue with the consent URL or the user preference
        handleError(e);
        loading(false);
      });
    },
    enable: () => {
      loading(true);
      enable()
        // Success will redirect, so no need to clear loading state
        .catch(e => {
          // Can be an issue with the consent URL or the user preference
          handleError(e);
        })
        .finally(e => {
          //always clear the loading state, in case the user interrupts the auth flow (so no redirect)
          //timeout to avoid the loading state going off already before auth page is open
          setTimeout(() => loading(false), 1000);
        });
    },
    disable: () => {
      loading(true);
      disable()
        // Success will redirect, so no need to clear loading state
        .catch(e => {
          handleError(e);
          loading(false);
        });
    },
    consentIssue,
    test: () => {
      MeetApi.getEvents();
    }
  };
};
