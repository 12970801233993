import { parseISO } from "date-fns";

export class WorkspaceApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.startDate = parseISO(obj.startDate);
    this.endDate = parseISO(obj.endDate);
    this.userId = obj.userId;
    this.nodeId = obj.nodeId;

    this.day = parseISO(obj.startDate); //for now, when we introduce the concept of multiple shifts in a day it may have to change (because of the time (UTC, midnight?)?)

    this.buildingId = obj.buildingId;
    this.buildingName = obj.buildingName;

    this.floorId = obj.floorId;
    this.floorHasMap = obj.floorHasMap;
    this.floorNumber = obj.floorNumber;
    this.floorName = obj.floorName;

    this.areaId = obj.areaId;
    this.areaName = obj.areaName;

    this.deskId = obj.deskId ?? null;
    this.deskName = obj.deskName ?? null;

    this.status = obj.status;
    this.groupReservationId = obj.groupReservationId;

    this.members = obj.members?.map(member => new WorkspaceMemberDTO(member));

    this.createdBy = obj.createdBy
      ? new WorkspaceCreatorDto(obj.createdBy)
      : null;

    this.lastModifiedBy = obj.lastModifiedBy
      ? new WorkspaceCreatorDto(obj.lastModifiedBy)
      : null;

    const createdByAdmin =
      obj.createdBy !== null && obj.createdBy.userId !== obj.userId;
    const modifiedByAdmin =
      obj.lastModifiedBy !== null && obj.lastModifiedBy.userId !== obj.userId;

    this.bookedByAdmin =
      (createdByAdmin || modifiedByAdmin) && !obj.groupReservationId;
  }
  static toApiModel(obj) {
    return {
      nodeId: obj.nodeId,
      //we are sending the same dates for now
      startDate: obj.date,
      endDate: obj.date
    };
  }
}

class WorkspaceMemberDTO {
  constructor(obj) {
    this.userId = obj.userId;
    this.isOwner = obj.isOwner;
    this.reservationId = obj.reservationId;
    this.user = obj.user;
    this.nodeId = obj.nodeId;
    this.location = obj.location;
    this.status = obj.status;
    this.expiryDateTime = parseISO(obj.expiryDateTime);
  }
}

class WorkspaceCreatorDto {
  constructor(obj) {
    this.userId = obj.userId;
    this.directoryObjectId = obj.directoryObjectId;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.fullName = obj.fullName;
    this.displayName = obj.displayName;
    this.email = obj.email;
    this.date = parseISO(obj.createdOrLastModifiedDate);
  }
}
