import "./ProfilePictureUpload.scss";
import bodyTemplate from "./ProfilePictureUpload.body.template.html";
import ko from "@shared/knockout/extended";
import { PopupManagerVM } from "../../PopupManager/PopupManagerVM";
import { dropZone } from "@shared/bindings/dropZone";
import { BreakpointVM } from "@app/viewmodels/ResponsiveDesign/BreakpointVM";

const Views = {
  ProfilePhoto: "ProfilePhoto",
  EditProfilePhoto: "EditProfilePhoto"
};

export const ProfilePictureUploadVM = ({
  user,
  profilePicture,
  api,
  imageHash
}) => {
  const largePictureError = ko.observable(false);
  const formatPictureError = ko.observable(false);
  const showAvatar = ko.observable(true);
  const uploadedPicture = ko.observable(null).extend({ deferred: true });
  const imageIsCropped = ko.observable(false);
  const zoomValue = ko.observable(0);
  const loading = ko.observable(false);
  const error = ko.observable(false);
  const CropperLib = ko.observable(null);
  const edit = ko.observable(false);
  const modifyZoomValue = 0.05;

  const activeView = ko.pureComputed(() =>
    edit() ? Views.EditProfilePhoto : Views.ProfilePhoto
  );

  import(/* webpackChunkName: "Cropper" */ "cropperjs")
    .then(cropper => CropperLib(cropper.default))
    .catch(() => error(true));

  const validatePicture = picture => {
    const allowedExtensions = ["jpg", "png", "jpeg"];
    // 2MB FILE SIZE
    const sizeLimit = 2000000;
    const { name, size } = picture;
    const fileExtension = name
      .split(".")
      .pop()
      .toLowerCase();

    // set everthing back to zero
    [
      largePictureError,
      formatPictureError,
      showAvatar,
      imageIsCropped,
      error
    ].forEach(obs => obs(false));
    zoomValue(0);

    if (!allowedExtensions.includes(fileExtension)) {
      formatPictureError(true);
    } else if (size > sizeLimit) {
      largePictureError(true);
    } else {
      const reader = new FileReader();
      reader.onload = function() {
        uploadedPicture(reader.result);
      };
      reader.readAsDataURL(picture);
    }
  };

  let cropper;
  const autoDisable = loading.subscribe(isLoading => {
    if (cropper) {
      isLoading ? cropper.disable() : cropper.enable();
    }
  });
  const uploadedPictureListener = uploadedPicture.subscribe(imageUrl => {
    if (imageUrl) {
      const imgElement = document.querySelector(
        ".ProfilePictureUpload-imgContainer [data-cropper-target]"
      );

      if (imgElement.src && cropper) cropper.destroy();

      const Cropper = ko.unwrap(CropperLib);
      imgElement.onload = () => {
        cropper = new Cropper(imgElement, {
          viewMode: 3,
          dragMode: "move",
          aspectRatio: 1 / 1,
          guides: false,
          cropBoxMovable: false,
          center: false,
          responsive: false,
          cropBoxResizable: false,
          zoomOnWheel: false,
          ready: () => {
            const containerSize = cropper.getContainerData();
            const data = {
              width: containerSize.width,
              height: containerSize.height,
              top: (containerSize.height - containerSize.width) / 2
            };
            const leftOffSet = (containerSize.width - containerSize.height) / 2;

            cropper.setCropBoxData(data);
            // setCropBoxData dont work for left offset
            if (containerSize.width > containerSize.height) {
              document.querySelector(
                ".ProfilePictureUpload-imgContainer .cropper-crop-box"
              ).style.transform = `translateX(${leftOffSet}px)`;
            }
          }
        });
      };
      imgElement.onerror = () => {
        formatPictureError(true);
      };

      imgElement.src = imageUrl;
    }
  });
  const validationError = ko.pureComputed(() => {
    if (!showAvatar()) {
      uploadedPicture(null);
      if (formatPictureError()) {
        return "Sorry, you can only use an image of PNG or JPEG file type";
      }
      if (largePictureError()) {
        return "Sorry, your image is too big. A maximum size of 2 MB is allowed.";
      }
      if (error()) {
        return "Something went wrong, please try again.";
      }
    }
  });

  const fileUpload = (_data, e) => {
    const file = e.target.files[0];
    if (file) {
      edit(true);
      validatePicture(file);
    }
  };

  const cropImage = () => {
    imageIsCropped(true);
  };

  const decreaseZoomValue = () => {
    const value = Number(zoomValue());
    const newValue = value - modifyZoomValue;
    if (newValue <= 0) zoomValue(0);
    else zoomValue(newValue);
  };

  const increaseZoomValue = () => {
    const value = Number(zoomValue());
    const newValue = value + modifyZoomValue;
    if (newValue >= 1) zoomValue(1);
    else zoomValue(newValue);
  };
  const oldZoomValue = ko.observable(0);
  const zoomListener = zoomValue.subscribe(value => {
    const difference = value - oldZoomValue();
    cropper.zoom(difference);
    setProgressBar(value);
    oldZoomValue(value);
  });

  const { isBreakpointMatched } = BreakpointVM(
    "only screen and (max-width: 500px)"
  );

  if (isBreakpointMatched()) {
    document.querySelector(".ModalWrapper").style.zIndex = "15";
    document.querySelector(".Card-scrollbody").style.marginBottom = "0";
  }

  const cropListener = imageIsCropped.subscribe(cropped => {
    if (cropped) {
      cropper
        .getCroppedCanvas({
          fillColor: "#fff",
          width: 512,
          height: 512
        })
        .toBlob(blob => uploadPicture(blob), "image/jpeg", 0.7);
    }
  });

  const uploadPicture = blob => {
    const formData = new FormData();
    formData.append("UserImage", blob);

    loading(true);
    let promise = imageHash()
      ? api.updateProfilePicture(formData)
      : api.createProfilePicture(formData);

    promise
      .then(({ imageHash }) => imageHash)
      .then(getProfilePicture)
      .catch(() => error(true))
      .finally(() => {
        loading(false);
        uploadedPicture(null);
        if (error()) showAvatar(false);
      });
  };

  const getProfilePicture = newImageHash => {
    imageHash(newImageHash);
    edit(false);
    error() ? showAvatar(false) : showAvatar(true);
  };

  const deleteProfilePicture = () => {
    loading(true);
    api
      .deleteProfilePicture()
      .then(() => imageHash(null))
      .catch(() => error(true))
      .finally(() => {
        loading(false);
        if (error()) showAvatar(false);
      });
  };
  const setProgressBar = zoomValue => {
    const input = document.querySelector(
      ".ProfilePictureUpload-zoomControl input"
    );

    function setBackgroundSize(input, zoomValue) {
      input.style.setProperty(
        "--background-size",
        `${getBackgroundSize(input, zoomValue)}%`
      );
    }

    setBackgroundSize(input, zoomValue);

    function getBackgroundSize(input, zoomValue) {
      const min = input.min || 0;
      const max = input.max || 100;
      const value = zoomValue;
      const size = ((value - min) / (max - min)) * 100;
      return size;
    }
  };

  return {
    initials: user.initials,
    email: user.email,
    imageHash,
    fileUpload,
    profilePicture,
    formatPictureError,
    largePictureError,
    showAvatar,
    zoomValue,
    increaseZoomValue,
    decreaseZoomValue,
    validationError,
    uploadedPicture,
    cropImage,
    imageIsCropped,
    loading,
    error,
    deleteProfilePicture,
    activeView,

    handleDrop: (data, event) => {
      const files = (event.dataTransfer || event.currentTarget).files;
      fileUpload(null, {
        target: {
          files
        }
      });
    },

    dispose: () => {
      autoDisable.dispose();
      cropListener.dispose();
      zoomListener.dispose();
      uploadedPictureListener.dispose();
      cropper ? cropper.destroy() : () => {};
    }
  };
};
export const ProfilePictureUpload = {
  register: ko => {
    ko.components.register("profilePicture-upload-body", {
      template: bodyTemplate
    });
    ko.bindingHandlers.dropZone = dropZone;
  },

  bodyComponentName: "profilePicture-upload-body"
};

const { getTitle, disableClosing } = PopupManagerVM.Keys;

Object.assign(ProfilePictureUploadVM, {
  [getTitle]: vm => {
    switch (vm.activeView()) {
      case Views.ProfilePhoto:
        return "Your profile photo";
      case Views.EditProfilePhoto:
        return "Edit your profile photo";
    }
  },
  [disableClosing]: vm => (vm.loading() ? true : false)
});
