import "./NewFeatureAnnouncement.scss";
import template from "./NewFeatureAnnouncement.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";

const NewFeatureAnnouncementVM = ({
  newFeatureAnnouncementParams: {
    showNewFeatureAnnouncement,
    text,
    title,
    navigateAction,
    updateAction,
    image,
    buttonText
  }
}) => {
  const onClose = () => {
    updateAction();
    showNewFeatureAnnouncement(false);
  };

  const onClick = () => {
    updateAction();
    showNewFeatureAnnouncement(false);
    navigateAction();
  };

  return {
    onClose,
    onClick,
    text,
    title,
    buttonText,
    imgUrl: image
  };
};

export const NewFeatureAnnouncement = KOComponent(
  "new-feature-announcement",
  NewFeatureAnnouncementVM,
  template
);
