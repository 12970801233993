import template from "./CalendarTab.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { MeetingVM } from "@app/viewmodels/MeetingVM";
import { isBefore, differenceInHours } from "date-fns";
import { getAppFeatures } from "@app/services/AppFeatures";
import { FeatureNames } from "@shared/services/Features";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { enable, handleError } from "@app/utils/meetConsent";
import { format } from "date-fns";
import "./CalendarTab.scss";
import { EmptyStates } from "../EmptyState/EmptyState";

const CalendarTabVM = ({ day }) => {
  const meetingStore = MeetingStoreSingleton();

  const meetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const { userHasOptedIn } = getAppFeatures().has(meetingsFeature)
    ? getAppFeatures().get(meetingsFeature)
    : { userHasOptedIn: false };

  const showNoConsentView = !userHasOptedIn;
  const showErrorView = ko.pureComputed(() => meetingStore.dateError(day));

  const meetings = ko.pureComputed(() => {
    return meetingStore
      .onDate(day)
      .map(MeetingVM(day))
      .sort((m1, m2) => {
        if (isBefore(m1.startDate, m2.startDate)) return -1;
        if (isBefore(m2.startDate, m1.startDate)) return 1;
        const m1Length = differenceInHours(m1.endDate, m1.startDate);
        const m2Length = differenceInHours(m2.endDate, m2.startDate);
        return m2Length - m1Length;
      });
  });

  return {
    meetings,
    showNoConsentView,
    showNoMeetingsView: ko.pureComputed(
      () =>
        userHasOptedIn &&
        !showErrorView() &&
        meetingStore.dateHydrated(day) &&
        meetings().length === 0
    ),
    loading: ko.pureComputed(
      () =>
        userHasOptedIn && !showErrorView() && !meetingStore.dateHydrated(day)
    ),
    showErrorView,
    userHasOptedIn,

    openMeetingCreation: () => {
      UrlNavigationSingleton().navigate(null, "overview", [
        format(day, "yyyy-MM-dd"),
        "plan"
      ]);
    },
    emptyStateParams: Object.assign(EmptyStates.CALENDAR_TAB, {
      showSecondExplainer: showNoConsentView,
      onClick: () => enable().catch(e => handleError(e))
    })
  };
};

export const CalendarTab = KOComponent("calendar-tab", CalendarTabVM, template);
