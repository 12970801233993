import "./AttendeesPicker.scss";
import template from "./AttendeesPicker.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { InviteeVM } from "@app/viewmodels/InviteeVM";
import { isEmailValid } from "@shared/utils/emailHelpers";

const AttendeesPickerVM = ({
  selectedAttendees,
  toggleAttendeesPicker,
  day,
  maxSelectedAttendees = Number.POSITIVE_INFINITY,
  allowOutsideOrganization = true,
  searchApiMethod,
  showHeader = true,
  emptyText = null,
  onConfirm = ko.observable(null),
  isDirtyObservable = ko.observable(null)
}) => {
  if (typeof searchApiMethod !== "function") {
    throw new Error("Please provide searchApiMethod");
  }

  const goBack = () => {
    toggleAttendeesPicker();
  };

  const temporaryAttendees = ko.observableArray(
    Array.from(selectedAttendees())
  );

  const isDirty = ko.computed(() => {
    const isDirty = !areAttendeesTheSame(
      selectedAttendees(),
      temporaryAttendees()
    );
    isDirtyObservable(isDirty);
    return isDirty;
  });

  const confirm = () => {
    //prevent reload of timeslots / rooms if there's no changes
    if (isDirty()) selectedAttendees(temporaryAttendees());
    toggleAttendeesPicker();
  };

  isDirtyObservable(isDirty());

  const confirmSub = onConfirm.subscribe(() => confirm());

  //loading state for search results
  const loading = ko.observable(false);

  const hasServerError = ko.observable(null);

  const searchInput = ko.observable("");

  const searchResults = ko.observableArray([]);

  const inputField = document.querySelector("input");
  inputField.addEventListener("input", function(e) {
    this.style.width = "auto";
    this.style.width = this.value.length + "ch";
  });

  const clearAfterSelection = () => {
    searchInput("");
    //replacing manually to skip over the throttling
    searchResults([]);
  };

  const isAlreadySelected = email =>
    temporaryAttendees().some(
      a => a?.email.toLowerCase() === email.toLowerCase()
    );

  const isInvalidEmailAddress = ko.observable(false);

  const reachedMaxSelectedAttendees = ko.pureComputed(
    () => temporaryAttendees().length >= maxSelectedAttendees
  );

  const maxSelectedInviteesWarningText = `You can invite a maximum of ${maxSelectedAttendees} people.`;

  const PickerStateTexts = {
    LOADING: null,
    SERVER_ERROR: {
      description:
        "Something went wrong. We can’t show any results. Please try again later."
    },
    OUTSIDE_ORGANIZATION: {
      description: "This person seems to be outside your company"
    },
    INVALID_EMAIL: {
      title: "Invalid email address",
      description: "You have added an incorrect email address."
    },
    NO_RESULTS: {
      description: "No users were found. Try a new search or an email address."
    },
    MAX_ATTENDEES: { description: maxSelectedInviteesWarningText }
  };

  const errorStateText = ko
    .pureComputed(() => {
      if (reachedMaxSelectedAttendees()) return PickerStateTexts.MAX_ATTENDEES;
      if (loading()) return PickerStateTexts.LOADING;
      if (hasServerError()) return PickerStateTexts.SERVER_ERROR;
      if (!searchResults().length && searchQuery().length) {
        if (allowOutsideOrganization) {
          if (isEmailValid(searchQuery())) {
            return PickerStateTexts.OUTSIDE_ORGANIZATION;
          } else if (isInvalidEmailAddress()) {
            return PickerStateTexts.INVALID_EMAIL;
          }
        }
        return PickerStateTexts.NO_RESULTS;
      }

      return null;
    })
    .extend({ rateLimit: { timeout: 100, method: "notifyWhenChangesStop" } });

  const addToAttendees = () => {
    if (searchResults().length === 1) {
      temporaryAttendees.push(searchResults()[0]);
    } else if (!searchResults().length) {
      temporaryAttendees.push(
        InviteeVM({
          email: searchQuery(),
          color: "#ebebeb"
        })
      );
    }
    clearAfterSelection();
  };

  const removeAttendee = attendee => temporaryAttendees.remove(attendee);

  inputField.addEventListener("keydown", event => {
    const lastAttendee = temporaryAttendees()[temporaryAttendees().at(-1)];
    if (event.key !== "Backspace" && lastAttendee?.selectedForDeletion())
      lastAttendee.selectedForDeletion(false);
    if (event.key !== "Enter" && isInvalidEmailAddress())
      isInvalidEmailAddress(false);
    if (event.key === "Enter" && searchQuery().length) {
      if (
        isEmailValid(searchQuery()) &&
        allowOutsideOrganization &&
        !isAlreadySelected(searchQuery())
      )
        addToAttendees();
      if (!isEmailValid(searchQuery())) isInvalidEmailAddress(true);
    }
    if (event.key === "Backspace" && !searchInput().length && lastAttendee) {
      if (lastAttendee.selectedForDeletion()) {
        temporaryAttendees.pop();
      } else {
        lastAttendee.selectedForDeletion(true);
      }
    }
  });

  const select = (data, event) => {
    temporaryAttendees.push(data);
    clearAfterSelection();
    inputField.focus();
  };

  const searchQuery = searchInput
    .map(str => {
      const trimmedString = str.trim();
      if (trimmedString.length < 2) {
        return "";
      }

      return trimmedString;
    })
    .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

  const searchSub = searchQuery.subscribe(query => {
    if (query) {
      loading(true);

      const splitRegex = /[,|;|\|]\s{0,}/;
      const queryStrings = query.split(splitRegex).filter(Boolean);

      if (queryStrings.length > 1) {
        Promise.allSettled(queryStrings.map(searchApiMethod))
          .then(results => {
            const searchResultValues = results
              .map(({ value }) => value)
              .flat()
              .filter(invitee => invitee && !isAlreadySelected(invitee.email));

            // used to prevent duplicates
            const searchResultMap = new Map(
              searchResultValues.map(searchResultValue => [
                searchResultValue.id,
                searchResultValue
              ])
            );

            return Array.from(searchResultMap.values());
          })
          .then(values => {
            return values.map(apiObj => InviteeVM(apiObj, day));
          })
          .then(invitees => {
            temporaryAttendees.push(
              ...invitees.splice(0, maxSelectedAttendees)
            );

            clearAfterSelection();
            inputField.focus();
            hasServerError(false);
            loading(false);
          })
          .catch(e => {
            console.error(e);
            hasServerError(true);
            loading(false);
          });
      } else {
        searchApiMethod(query)
          .then(people => {
            if (query === searchQuery())
              searchResults(
                people
                  .filter(p => !isAlreadySelected(p.email))
                  .map(apiObj => InviteeVM(apiObj, day))
              );
            hasServerError(false);
            loading(false);
          })
          .catch(e => {
            console.error(e);
            hasServerError(true);
            loading(false);
            searchResults([]);
          });
      }
    } else searchResults([]);
  });

  const showEmptyText = ko.pureComputed(
    () =>
      emptyText &&
      !loading() &&
      !errorStateText() &&
      !searchResults().length &&
      !temporaryAttendees().length
  );

  return {
    goBack,
    confirm,
    searchInput,
    temporaryAttendees,
    searchResults,
    select,
    reachedMaxSelectedAttendees,
    errorStateText,
    loading,
    showHeader,
    showEmptyText,
    emptyText,
    removeAttendee,
    dispose: () => {
      searchSub.dispose();
      confirmSub.dispose();
    }
  };
};

const areAttendeesTheSame = (attendees1, attendees2) => {
  const emails1 = attendees1.map(a => a.email);
  const emails2 = attendees2.map(a => a.email);
  if (
    emails1.some(e => !emails2.includes(e)) ||
    emails2.some(e => !emails1.includes(e))
  )
    return false;
  return true;
};

export const AttendeesPicker = KOComponent(
  "attendees-picker",
  AttendeesPickerVM,
  template
);
