import "./WelcomePage.scss";
import template from "./WelcomePage.template.html";

import { Channels } from "@shared/Channels";
import { AppApi } from "../../services/AppApi";

import { KOComponent } from "@shared/knockout/KOComponent";
import { Mediator } from "@shared/mediator";

const WelcomePageVM = ({ settings, user = {}, mediator = Mediator() }) => {
  const goNext = () => {
    // Fire and forget; it's not the end of the world if this call fails
    AppApi.setUserIsNewToFalse().catch(e => {
      /* Do nothing */
    });
    mediator.publish(Channels.CloseWelcomePage, "");
  };

  const welcomeMessageLines = settings.map(s => {
    const str = s.officeInstructions;
    if (!str) return "";

    return str.split("\n");
  });

  return {
    welcomeMessageLines,
    user,
    goNext,
    settings
  };
};

export const WelcomePage = KOComponent("welcome-page", WelcomePageVM, template);
