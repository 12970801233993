import ko from "@shared/knockout/extended";

const init = (dropArea, va) => {
  const onFileDrop = va();
  const highlighted = ko.observable(false);

  // Prevent default drop behavior
  ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false);
    document.body.addEventListener(eventName, preventDefaults, false);
  });

  // Handle drop-hover state
  ["dragenter", "dragover"].forEach(eventName => {
    dropArea.addEventListener(eventName, () => highlighted(true), false);
  });

  ["dragleave", "drop"].forEach(eventName => {
    dropArea.addEventListener(eventName, () => highlighted(false), false);
  });

  // Handle drop
  dropArea.addEventListener(
    "drop",
    e => {
      onFileDrop(null, e);
    },
    false
  );

  // TODO: remove event listeners?
  ko.applyBindingsToNode(dropArea, { css: { highlighted } });
};

export const dropZone = { init };

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

// Helper that takes an image file and an observable/callback
// It loads the image and writes it data src, file name and size to
// the observable when done
export function processImageFile(target, file) {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    const dataUrl = reader.result;

    const img = new Image();
    img.addEventListener("load", () => {
      // At some point we should actually upload the image and share a web src here
      target({
        name: file.name,
        src: dataUrl,
        width: img.width,
        height: img.height
      });
    });

    img.src = dataUrl;
  });

  reader.readAsDataURL(file);
}

export const tryGetImageFile = event => {
  const dt = event.dataTransfer || event.currentTarget;
  const [firstFile] = dt.files;

  if (firstFile.type !== "image/jpeg" && firstFile.type !== "image/png") {
    alert("unsupported file type");
    return;
  }

  return firstFile;
};
