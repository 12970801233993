import { format, getISOWeek } from "date-fns";

export const isDateInstance = str => {
  if (!str) return false;
  const dateObj = new Date(str);

  return dateObj instanceof Date && !isNaN(dateObj);
};

export const getDateKey = date => format(date, "yyyy-MM-dd");

export const getDateKeyForFeature = feature =>
  getDateKey(feature.day || feature.date || feature.startDate);

export const getWeekKeyForFeature = feature =>
  getISOWeek(feature.day || feature.date);
