import { ReservationStatus } from "@shared/apimodels/ReservationStatus";

const getReservationStatusOrder = status =>
  ({
    [ReservationStatus.Confirmed]: 2,
    [ReservationStatus.InvitationPending]: 1
  }[status] || 0);

export const sortMembers = members => {
  return members
    .sort((a, b) => {
      const displayNameA = a.user.displayName || a.user.email;
      const displayNameB = b.user.displayName || b.user.email;

      return displayNameA.localeCompare(displayNameB);
    })
    .sort((a, b) => Number(b.isOwner) - Number(a.isOwner))
    .sort(
      (a, b) =>
        getReservationStatusOrder(b.status) -
        getReservationStatusOrder(a.status)
    );
};
