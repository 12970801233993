import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./CheckInNoShiftTile.template.html";
import "../CheckInTile.scss";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { CheckInServiceSingleton } from "@app/services/CheckInService.js";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import startOfDay from "date-fns/startOfDay";
import {
  isCheckInFeatureEnabled,
  isManualCheckInFeatureEnabled
} from "@app/utils/checkIn";
import { determineStatusToSend } from "../helpers";

const CheckInNoShiftTileVM = ({
  dayVM,
  visible,
  settings,
  mediator = Mediator()
}) => {
  const checkInService = CheckInServiceSingleton();
  const checkInEnabled = isCheckInFeatureEnabled();
  const manualCheckInEnabled = isManualCheckInFeatureEnabled();
  const loading = checkInService.loading;

  const openBookingFlow = () => {
    mediator.publish(Channels.ToggleConfirmationWindow, {
      options: {
        title: "Book a shift?",
        subtitle: "Reserve a space to work in the office today.",
        primaryButtonText: "Book",
        onPrimaryButtonClick: () => {
          mediator.publish(Channels.OpenWorkspaceWizard, {
            date: startOfDay(new Date()),
            onBookingMade: () => {
              checkInService.checkIn(dayVM.buildingId());
            }
          });
          return Promise.resolve();
        },
        primaryButtonClass: "MpqButton--app"
      }
    });
  };

  const checkInStatusSelectorParams = {
    bookingPolicy: settings()?.registrationPolicy,
    dayVM,
    openBookingFlow,
    output: newCheckInStatus => {
      if (newCheckInStatus === CheckInStatus.NotAtTheOffice)
        checkInService.checkOut(checkInService.buildingId());
    }
  };
  return {
    dayVM,
    visible,
    checkInEnabled,
    manualCheckInEnabled,
    loading,
    hasCheckInStatus: checkInService.hasCheckInStatus,
    checkInStatusSelectorParams,
    openBookingFlow,

    onTileClick: () => {
      determineStatusToSend(dayVM, false);

      dayVM.shiftAction();
    }
  };
};

export const CheckInNoShiftTile = KOComponent(
  "check-in-no-shift-tile",
  CheckInNoShiftTileVM,
  template
);
