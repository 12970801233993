import "./ConfirmationWindow.scss";
import template from "./ConfirmationWindow.template.html";

import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";

const ConfirmationWindowVM = ({
  options: {
    title,
    iconName = null,
    iconSize = 80,
    subtitle,
    primaryButtonText,
    secondaryButtonText = "Cancel",
    primaryButtonClass = "MpqButton--red",
    secondaryButtonClass = null,
    onPrimaryButtonClick,
    onSecondaryButtonClick = () => {},
    smallerSubText = false,
    buttonInColumnOrder = false,
    mediator = Mediator()
  }
}) => {
  const loading = ko.observable(false);
  const closeConfirmationWindow = () =>
    mediator.publish(Channels.ToggleConfirmationWindow, null);

  const onConfirm = () => {
    loading(true);
    onPrimaryButtonClick().finally(() => {
      loading(false);
      closeConfirmationWindow();
    });
  };

  return {
    title,
    subtitle,
    iconName,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonClass,
    secondaryButtonClass,
    loading,
    smallerSubText,
    buttonInColumnOrder,
    iconSize,
    closeConfirmationWindow,
    onConfirm,
    onCancel: () => {
      onSecondaryButtonClick();
      closeConfirmationWindow();
    }
  };
};

export const ConfirmationWindow = KOComponent(
  "confirmation-window",
  ConfirmationWindowVM,
  template
);
