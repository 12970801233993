import { availabilityParkingData } from "@shared/viewmodels/AvailabilityParkingData";
import ko from "@shared/knockout/extended";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames } from "@app/tracking/EventNames";

export const ParkingZoneVM = (zone, date, selectedParkingZone) => {
  const id = zone.id;
  const availabilityData = availabilityParkingData(date, zone);
  const isSelected = ko.pureComputed(() => id === selectedParkingZone()?.id);
  const isDisabled = ko.pureComputed(
    () => availabilityData.full() || availabilityData.closed()
  );

  const select = self => {
    if (isSelected() || isDisabled()) return;
    publishAIEvent(EventNames.ParkingLocationPickerSelectZone);
    selectedParkingZone(self);
  };
  return {
    id,
    name: zone.name,
    full: availabilityData.full,
    closed: availabilityData.closed,
    availability: availabilityData.availability,
    availabilityLabel: availabilityData.availabilityLabel,
    colorClass: availabilityData.colorClass,
    percentage: availabilityData.percentage,

    select,
    isSelected,
    isDisabled
  };
};
