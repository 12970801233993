import { parseISO } from "date-fns";
export class WorkdayApiModel {
  constructor(apiObj) {
    this.nodeId = apiObj.nodeId;
    this.nodeName = apiObj.nodeName;
    this.id = apiObj.id;
    this.status = apiObj.status;
    this.userId = apiObj.userId;
    this.startDate = parseISO(apiObj.startDate);
    this.endDate = parseISO(apiObj.endDate);
  }
}
