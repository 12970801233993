import ko from "@shared/knockout/extended";
import { indexBy } from "./indexBy.js";

const id = x => x.id;

export const Store = (getId = id) => {
  const cache = ko.observable({});
  const hydrated = ko.observable(false);
  const hasLoadingError = ko.observable(null);

  const validateHydrated = () => {
    if (!hydrated()) {
      throw "You can only mutated a hydrated store. Use update([item]) instead.";
    }
  };

  return {
    /**
     * Replace all items in the store with new items
     * @param {object[]} xs
     */
    update(xs) {
      cache(indexBy(getId, xs));
      hasLoadingError(false);
      hydrated(true);
    },

    /**
     * Delete a single item from the store
     * @param {string|number} id
     */
    deleteId(id) {
      validateHydrated();

      const _cache = cache();

      if (id in _cache) {
        delete _cache[id];
        cache.valueHasMutated();
      }
    },

    /**
     * Add or overwrite a single item
     * @param {object} x
     */
    add(x) {
      validateHydrated();

      const _cache = cache();
      const k = getId(x);

      if (_cache[k] !== x) {
        _cache[k] = x;
        cache.valueHasMutated();
      }
    },

    /**
     * Clear the store. Equal to `update([])`
     */
    clear() {
      const _cache = cache();
      if (Object.keys(_cache).length) {
        cache({});
      }
    },

    /**
     * Get an item by id. Returns `null` if not in store
     * @param {string|number} id
     * @returns {any}
     */
    getId(id) {
      return cache()[id] ?? null;
    },

    /**
     * Deferred array of all items in the cache
     */
    all: cache.map(Object.values).extend({ deferred: true }),
    /**
     * Read only cache. Can be used to extend the store
     */
    byId: ko.pureComputed(cache),

    /**
     * @returns {ko.PureComputed<boolean>} whether the store has been updated once.
     */
    hydrated: ko.pureComputed(hydrated),

    /**
     * @returns {ko.Observable<boolean?>} whether the store failed to load, null indicates indicisive.
     */
    hasLoadingError
  };
};
