import chair from "./svgs/chair.template.html";
import sadChairs from "./svgs/sadChairs.template.html";
import benchmark from "./svgs/benchmark.template.html";
import chevronDown from "./svgs/chevron-down.template.html";
import buildings from "./svgs/buildings.template.html";
import floor from "./svgs/floor.template.html";
import waffle from "./svgs/waffle.template.html";
import edit from "./svgs/edit.template.html";
import add from "./svgs/add.template.html";
import addNew from "./svgs/addNew.template.html";
import trashbin from "./svgs/trashbin.template.html";
import trashbinNew from "./svgs/trashbin-new.template.html";
import settings from "./svgs/settings.template.html";
import users from "./svgs/users.template.html";
import booking from "./svgs/booking.template.html";
import chevronLeft from "./svgs/chevron-left.template.html";
import chevronRight from "./svgs/chevron-right.template.html";
import chevronThickRight from "./svgs/chevron-thick-right.template.html";
import arrowRight from "./svgs/arrow-right.template.html";
import search from "./svgs/search.template.html";
import calendar from "./svgs/calendar.template.html";
import refresh from "./svgs/refresh.template.html";
import download from "./svgs/download.template.html";
import downloadNew from "./svgs/download-new.template.html";
import chevronUp from "./svgs/chevron-up.template.html";
import assets from "./svgs/assets.template.html";
import card from "./svgs/card.template.html";
import doorLocked from "./svgs/door-locked.template.html";
import cog from "./svgs/cog.template.html";
import close from "./svgs/close.template.html";
import thickerClose from "./svgs/thickerClose.template.html";
import upload from "./svgs/upload.template.html";
import menu from "./svgs/menu.template.html";
import mail from "./svgs/mail.template.html";
import locket from "./svgs/locket.template.html";
import connections from "./svgs/connections.template.html";
import searchUser from "./svgs/searchUser.template.html";
import home from "./svgs/home.template.html";
import note from "./svgs/note.template.html";
import puzzle from "./svgs/puzzle.template.html";

import pickerBuilding from "./svgs/pickerBuilding.template.html";
import pickerFloor from "./svgs/pickerFloor.template.html";
import pickerHotDeskingArea from "./svgs/pickerHotDeskingArea.template.html";
import pickerHotellingArea from "./svgs/pickerHotellingArea.template.html";
import pickerMeetingRoomTemplate from "./svgs/pickerMeetingRoom.template.html";
import vector from "./svgs/vector.template.html";
import closeSign from "./svgs/close-sign.template.html";
import topologyDesk from "./svgs/topologyDesk.template.html";
import topologyFloor from "./svgs/topologyFloor.template.html";
import dottedElipse from "./svgs/dotted-elipse.template.html";
import chained from "./svgs/chained.template.html";
import unchained from "./svgs/unchained.template.html";
import importer from "./svgs/importer.template.html";

//parking icons: car - app & admin, carDarker - app, parkingCar - admin, parkingCarWhite-app
import car from "./svgs/car.template.html";
import carDarker from "./svgs/carDarker.template.html";
import parkingCarWhite from "./svgs/parkingCarWhite.template.html";
import parkingIntroduction from "./svgs/parking-introduction.template.html";
import hybridMeetingConsent from "./svgs/hybrid-meetings.template.html";
import parkingCar from "./svgs/parkingCar.template.html";

import broadcast from "./svgs/broadcast.template.html";
import pointgrab from "./svgs/pointgrab.template.html";
import cisco from "./svgs/cisco.template.html";

import calendarBooked from "./svgs/calendarBooked.template.html";
import calendarUpdated from "./svgs/calendarUpdated.template.html";
import calendarDeleted from "./svgs/calendarDeleted.template.html";

import success from "./svgs/success.template.html";
import failure from "./svgs/failure.template.html";

import building from "./svgs/eventIcons/building.template.html";
import pencil from "./svgs/eventIcons/pencil.template.html";
import remote from "./svgs/eventIcons/remote.template.html";
import star from "./svgs/eventIcons/star.template.html";
import unknownLocation from "./svgs/eventIcons/unknownLocation.template.html";
import bwpencil from "./svgs/eventIcons/bwpencil.template.html";
import message from "./svgs/eventIcons/message.template.html";
import clock from "./svgs/eventIcons/clock.template.html";
import clockCounterClockwise from "./svgs/clock-counter-clockwise.template.html";
import eventUsers from "./svgs/eventIcons/users.template.html";
import location from "./svgs/eventIcons/location.template.html";
import camera from "./svgs/eventIcons/camera.template.html";
import circledInfo from "./svgs/eventIcons/circledInfo.template.html";
import createEvent from "./svgs/eventIcons/createEvent.template.html";

import dots from "./svgs/designsystem/Dots.template.html";
import newDownload from "./svgs/designsystem/Download.template.html";
import trash from "./svgs/designsystem/Trash.template.html";
import desk from "./svgs/desk.template.html";

import mpqLogoLarge from "./svgs/mpqLogoLarge.template.html";
import mpqLogoSmall from "./svgs/mpqLogoSmall.template.html";
import mpqCheckboxChecked from "./svgs/mpqCheckboxChecked.template.html";
import mpqCheckboxUnchecked from "./svgs/mpqCheckboxUnchecked.template.html";

import coffeeCups from "./svgs/coffeeCups.template.html";
import workspaceOffice from "./svgs/workspaceOffice.template.html";
import workspaceFloor from "./svgs/workspaceFloor.template.html";
import workspaceArea from "./svgs/workspaceArea.template.html";
import workspaceDesk from "./svgs/workspaceDesk.template.html";
import workspaceHome from "./svgs/workspaceHome.template.html";
import workspaceTransparentDesk from "./svgs/workspaceTransparentDesk.template.html";
import unknownWorkdayStatus from "./svgs/unknownWorkdayStatus.template.html";
import remoteWorkdayStatus from "./svgs/remoteWorkdayStatus.template.html";
import notWorkingWorkdayStatus from "./svgs/notWorkingStatus.template.html";
import locketWorkspace from "./svgs/locketWorkspace.template.html";

import dayViewToggle from "./svgs/dayViewToggle.template.html";
import calendarViewToggle from "./svgs/calendarViewToggle.template.html";

import teamVideoMeeting from "./svgs/teamVideoMeeting.template.html";

import notiNotifyBookingOnBehalf from "./svgs/notifyBookingOnBehalf.template.html";
import notiNotifyUpdateOnBehalf from "./svgs/notifyUpdateOnBehalf.template.html";
import notiNotifyDeleteOnBehalf from "./svgs/notifyDelete.template.html";
const notiNotifyDeleteOnLocationClose = notiNotifyDeleteOnBehalf;
import notiNotifyInAppReservationInvitationCreated from "./svgs/notifyReservationInvitationCreated.template.html";
import notiNotifyGroupInvitationCanceled from "./svgs/notiNotifyGroupInvitationCanceled.template.html";
import notiNotifyReservationInvitationUpdated from "./svgs/notifyReservationInvitationUpdated.template.html";

import rsvpConfirmed from "./svgs/rsvpConfirmed.template.html";
import rsvpDeclined from "./svgs/rsvpDeclined.template.html";
import rsvpPending from "./svgs/rsvpPending.template.html";

//extras
import addExtras from "./svgs/Extras/add-extras.template.html";
import externalLink from "./svgs/Extras/external-link.template.html";

//workday extras
import bookCoworkingSpace from "./svgs/Extras/WorkdayExtras/book-coworking-space.template.html";
import bookLocker from "./svgs/Extras/WorkdayExtras/book-locker.template.html";
import bookLunch from "./svgs/Extras/WorkdayExtras/book-lunch.template.html";
import requestMedicalService from "./svgs/Extras/WorkdayExtras/request-medical-service.template.html";
import registerVisitor from "./svgs/Extras/WorkdayExtras/register-visitor.template.html";

//event extras
//same icon, different extras
const inviteVisitors = registerVisitor;
import reportProblem from "./svgs/Extras/EventExtras/report-problem.template.html";
import requestCatering from "./svgs/Extras/EventExtras/request-catering.template.html";
import requestEquipment from "./svgs/Extras/EventExtras/request-equipment.template.html";
import requestSeatingArrangement from "./svgs/Extras/EventExtras/request-seating-arrangement.template.html";

//general extras
import controlBlinds from "./svgs/Extras/GeneralExtras/control-blinds.template.html";
import manageEmergencyContact from "./svgs/Extras/GeneralExtras/manage-emergency-contact.template.html";
import manageLicensePlate from "./svgs/Extras/GeneralExtras/manage-license-plate.template.html";
import requestSupport from "./svgs/Extras/GeneralExtras/request-support.template.html";

import photoCamera from "./svgs/photo-camera.template.html";
import boldBroadcast from "./svgs/boldBroadcast.template.html";
import boldCalendar from "./svgs/boldCalendar.template.html";
import calendarToday from "./svgs/calendar-today-overview-toggle.template.html";

export const Icons = {
  chair,
  sadChairs,
  benchmark,
  chevronDown,
  buildings,
  floor,
  waffle,
  edit,
  add,
  addNew,
  trashbin,
  trashbinNew,
  settings,
  users,
  booking,
  chevronLeft,
  chevronRight,
  chevronThickRight,
  arrowRight,
  search,
  calendar,
  refresh,
  download,
  downloadNew,
  chevronUp,
  assets,
  card,
  doorLocked,
  cog,
  close,
  thickerClose,
  upload,
  menu,
  mail,
  locket,
  connections,
  searchUser,
  home,
  note,
  puzzle,

  pickerBuilding,
  pickerFloor,
  pickerHotDeskingArea,
  pickerHotellingArea,
  desk,
  vector,
  closeSign,
  importer,

  //parking icons: car - app, carDarker - admin
  car,
  carDarker,
  parkingIntroduction,
  hybridMeetingConsent,
  broadcast,
  parkingCar,
  parkingCarWhite,

  broadcast,
  pointgrab,
  cisco,

  building,
  pencil,
  remote,
  star,
  unknownLocation,
  pickerMeetingRoomTemplate,
  topologyDesk,
  topologyFloor,
  dottedElipse,
  chained,
  unchained,
  bwpencil,
  message,
  clock,
  clockCounterClockwise,
  location,
  eventUsers,
  camera,
  photoCamera,
  circledInfo,
  createEvent,
  calendarBooked,
  calendarUpdated,
  calendarDeleted,

  success,
  failure,

  dots,
  newDownload,
  trash,

  mpqLogoLarge,
  mpqLogoSmall,
  mpqCheckboxChecked,
  mpqCheckboxUnchecked,

  coffeeCups,

  workspaceArea,
  workspaceDesk,
  workspaceFloor,
  workspaceOffice,
  workspaceHome,
  workspaceTransparentDesk,
  unknownWorkdayStatus,
  remoteWorkdayStatus,
  notWorkingWorkdayStatus,
  locketWorkspace,

  dayViewToggle,
  calendarViewToggle,

  teamVideoMeeting,

  // Icons for notifications should be prefixed with noti
  // See NotificationVM
  notiNotifyBookingOnBehalf,
  notiNotifyUpdateOnBehalf,
  notiNotifyDeleteOnBehalf,
  notiNotifyDeleteOnLocationClose,
  notiNotifyInAppReservationInvitationCreated,
  notiNotifyGroupInvitationCanceled,
  notiNotifyReservationInvitationUpdated,

  rsvpConfirmed,
  rsvpDeclined,
  rsvpPending,

  //extras
  addExtras,
  externalLink,

  //workday extras
  bookCoworkingSpace,
  bookLocker,
  bookLunch,
  requestMedicalService,
  registerVisitor,

  //event extras
  inviteVisitors,
  reportProblem,
  requestCatering,
  requestEquipment,
  requestSeatingArrangement,

  //general extras
  controlBlinds,
  manageEmergencyContact,
  manageLicensePlate,
  requestSupport,
  boldBroadcast,
  boldCalendar,
  calendarToday
};
