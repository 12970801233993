import { Singleton } from "@shared/utils/Singleton";
import { AppApi } from "./AppApi";
import { NotificationPreferencesVM } from "@app/viewmodels/NotificationPreferencesVM";
import { NotificationPreferenceVM } from "@app/viewmodels/NotificationPreferenceVM";

const NotificationPreferencesService = () => {
  const getPreferences = () =>
    AppApi.getNotificationPreferences().then(NotificationPreferencesVM);

  const updatePreference = preferences =>
    AppApi.updateNotificationPreferences(preferences).then(
      NotificationPreferenceVM
    );

  return {
    getPreferences,
    updatePreference
  };
};

export const NotificationPreferencesServiceSingleton = Singleton(
  NotificationPreferencesService
);
