export class NotificationApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.type = obj.type;
    this.messageTemplate = obj.messageTemplate;
    this.messageTitle = obj.messageTitle;
    this.message = obj.message;
    this.messageValues = obj.messageValues;
    this.isRead = obj.isRead;
    this.creationDateTime = obj.creationDateTime;
  }

  static fromApiObj(obj) {
    return new NotificationApiModel(obj);
  }
}
