import "./DefaultOfficeView.scss";
import template from "./DefaultOfficeView.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";

const DefaultOfficeViewVM = ({
  isVisible,
  panelIsOpened,
  buildings,
  activeBuilding,
  changeDefaultBuilding,
  saving
}) => {
  const pendingActiveBuilding = ko.observable(null);

  const buildingTiles = buildings.mapArray(building => ({
    name: building.name,
    isActive: ko.pureComputed(() => {
      return (
        activeBuilding()?.id === building.id ||
        pendingActiveBuilding()?.id === building.id
      );
    }),
    isDisabled: saving,
    onClick: () => {
      pendingActiveBuilding(building);
      changeDefaultBuilding(building);
    }
  }));

  return {
    isVisible,
    panelIsOpened,
    buildings: buildingTiles,
    activeBuilding,
    changeDefaultBuilding,
    saving
  };
};

export const DefaultOfficeView = KOComponent(
  "default-office-view",
  DefaultOfficeViewVM,
  template
);
