import { Singleton } from "@shared/utils/Singleton";
import ko from "@shared/knockout/extended";
import { AppApi } from "./AppApi";
import { startOfWeek } from "date-fns";
import { ConnectedColleaguesStoreSingleton } from "./ConnectedColleaguesStore";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { ErrorType } from "@shared/utils/ErrorType";

const ConnectedColleaguesService = () => {
  const connectedColleaguesStore = ConnectedColleaguesStoreSingleton();
  const loading = ko.observable(false);
  const fetching = ko.observable(null);

  const getConnectedColleagues = endDate => {
    const startDate = startOfWeek(new Date(), {
      weekStartsOn: 1
    });
    loading(true);
    return AppApi.getConnectedColleagues(startDate, endDate)
      .then(ConnectedColleaguesStoreSingleton().update)
      .catch(() =>
        Mediator().publish(
          Channels.OpenConnectionErrorWindow,
          ErrorType.LoadingFailed
        )
      )
      .finally(() => loading(false));
  };

  // Can be called multiple times for the same endDate. Tracking the 'fetching' promise to minimise the number of calls.
  // if its not fetching and forceRefresh is true or has no results in cache, then start fetching
  // if it is currently fetching then return that promise.
  // else return cache
  const getConnectedColleaguesOrCached = (endDate, forceRefresh = false) => {
    const startDate = startOfWeek(new Date(), {
      weekStartsOn: 1
    });

    if (
      !fetching() &&
      (forceRefresh || !connectedColleaguesStore.all()?.length)
    ) {
      loading(true);

      const connectedColleaguesPromise = AppApi.getConnectedColleagues(
        startDate,
        endDate
      )
        .then(connectedColleagues => {
          connectedColleaguesStore.addMultiple(connectedColleagues);
          return connectedColleaguesStore.all();
        })
        .catch(() =>
          Mediator().publish(
            Channels.OpenConnectionErrorWindow,
            ErrorType.LoadingFailed
          )
        )
        .finally(() => {
          loading(false);
          fetching(null);
        });

      fetching(connectedColleaguesPromise);
    }

    if (fetching()) {
      return fetching();
    }

    return new Promise(res => res(connectedColleaguesStore.all()));
  };

  return {
    loading,
    getConnectedColleagues,
    getConnectedColleaguesOrCached
  };
};

export const ConnectedColleaguesServiceSingleton = Singleton(
  ConnectedColleaguesService
);
