export const ConnectionStatus = {
  Accepted: "Accepted",
  Ignored: "Ignored",
  Pending: "Pending"
};

export const ConnectionDirection = {
  Outgoing: "Outgoing",
  Incoming: "Incoming"
};
