import { differenceInDays, parseISO } from "date-fns";
import { pl } from "@shared/utils/pluralize";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
export class GroupReservationApiModel {
  constructor(apiObj) {
    this.id = apiObj.id;
    this.startDate = apiObj.startDate;
    this.endDate = apiObj.endDate;
    this.owner = new InviteeReservationApiModel(apiObj.owner);
    this.invitees = apiObj.invitees.map(i => new InviteeReservationApiModel(i));
  }
}

export class InviteeReservationApiModel {
  constructor(apiObj) {
    this.reservationId = apiObj.reservationId;
    this.userId = apiObj.userId;
    this.nodeId = apiObj.nodeId;
    this.status = apiObj.status;
    this.isOwner = apiObj.isOwner ?? false;
  }
}

export class ExtendedGroupReservationApiModel {
  constructor(apiObj) {
    this.id = apiObj.id;
    this.startDate = apiObj.startDate;
    this.endDate = apiObj.endDate;
    this.owner = new ExtendedInviteeReservationApiModel(apiObj.owner);
    this.invitees = apiObj.invitees.map(
      i => new ExtendedInviteeReservationApiModel(i)
    );
  }
}

export class ExtendedInviteeReservationApiModel {
  constructor(apiObj) {
    this.reservationId = apiObj.reservationId;
    this.userId = apiObj.userId;
    this.nodeId = apiObj.nodeId;
    this.status = apiObj.status;
    this.expiryDateTime = parseISO(apiObj.expiryDateTime);
    const daysUntilExpiry = differenceInDays(this.expiryDateTime, new Date());
    this.expiryLabel =
      apiObj.status === ReservationStatus.Confirmed
        ? null
        : daysUntilExpiry > 0
        ? `${daysUntilExpiry} ${pl("day", daysUntilExpiry)} to respond`
        : "Final day to respond";
    this.user = new ReservationUserApiModel(apiObj.user);
    this.location = new ReservationLocationApiModel(apiObj.location);
  }
}

export class ReservationUserApiModel {
  constructor(apiObj) {
    this.id = apiObj.id;
    this.email = apiObj.email;
    this.directoryObjectId = apiObj.directoryObjectId;
    this.imageHash = apiObj.imageHash;
    this.initials = apiObj.initials;
    this.firstName = apiObj.firstName;
    this.lastName = apiObj.lastName;
    this.displayName =
      apiObj.firstName && apiObj.lastName
        ? `${apiObj.firstName} ${apiObj.lastName}`
        : null;
  }
}

export class ReservationLocationApiModel {
  constructor(apiObj) {
    this.buildingId = apiObj.buildingId;
    this.buildingDisplayName = apiObj.buildingDisplayName;
    this.floorId = apiObj.floorId;
    this.floorDisplayName = apiObj.floorDisplayName;
    this.areaId = apiObj.areaId;
    this.areaDisplayName = apiObj.areaDisplayName;
    this.deskId = apiObj.deskId;
    this.deskDisplayName = apiObj.deskDisplayName;
  }
}
