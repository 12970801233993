import template from "./WorkspaceConnectionsAndOrMembers.template.html";
import "./WorkspaceConnectionsAndOrMembers.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { ConnectedColleaguesServiceSingleton } from "@app/services/ConnectedColleaguesService";
import { ConnectedShiftsHelper } from "@shared/utils/ConnectedShiftsHelper.js";
import { isSameDay, startOfDay } from "date-fns";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import {
  getEndOfCalendar,
  getWorkdayEndOfCalendar
} from "@app/utils/getEndOfCalendar";
import { sortMembers } from "@app/utils/sortMembers";
import { FeatureNames, getFeatures } from "@shared/services/Features";

const getReservationStatusIcon = status =>
  ({
    [ReservationStatus.Confirmed]: "rsvpConfirmed",
    [ReservationStatus.InvitationPending]: "rsvpPending"
  }[status] || "rsvpDeclined");

const getCheckInStatus = (date, userId) =>
  isSameDay(date, startOfDay(new Date()))
    ? CheckInStatusStoreSingleton().getById(userId)
    : null;

export const WorkspaceConnectionsAndOrMembersVM = ({
  date,
  workspace,
  currentUser
}) => {
  const loading = ko.observable(true);
  const connectedColleaguesService = ConnectedColleaguesServiceSingleton();

  const isGWR = ko.observable(!!workspace().groupReservationId);
  const members = ko.observableArray([]);
  const connectionsInLocation = ko.observableArray([]);

  const endOfCalendar = getFeatures().has(FeatureNames.WORK_DAY)
    ? getWorkdayEndOfCalendar()
    : getEndOfCalendar(currentUser);

  const currentUserIsOrganizer = workspace().owner.userId === currentUser.id;

  const getMembers = () => {
    return sortMembers(workspace().members).map(member =>
      MemberVM({ date, currentUser, currentUserIsOrganizer, ...member })
    );
  };

  const getConnections = () =>
    connectedColleaguesService
      .getConnectedColleaguesOrCached(endOfCalendar())
      .then(() => {
        const conHelper = ConnectedShiftsHelper();

        return conHelper.visitsOnDate(date).map(officeVisits => ({
          ...officeVisits,
          person: {
            ...officeVisits.person,
            checkInStatus: ko.pureComputed(() =>
              getCheckInStatus(date, officeVisits.person.id)
            )
          }
        }));
      })
      .then(visitsOnDay => {
        const { workAreaId, floorId, buildingId } = workspace() || {};
        const locationId = workAreaId ?? floorId ?? buildingId;

        return ConnectedShiftsHelper.inGroup(locationId, visitsOnDay);
      });

  Promise.all([getMembers(), getConnections()])
    .then(([_members, _connections]) => {
      members(_members);

      // filter connections that are already a member when it is a GWR
      connectionsInLocation(
        _connections.filter(connection => {
          return (
            !isGWR() ||
            (isGWR() &&
              !_members.find(member => member.id === connection.person.id))
          );
        })
      );
    })
    .finally(() => {
      loading(false);
    });

  return {
    loading,
    workspace,
    members,
    connectionsInLocation,
    isGWR
  };
};

const MemberVM = ({
  date,
  currentUser,
  expiryLabel,
  user,
  status,
  isOwner = false,
  currentUserIsOrganizer,
  location
}) => {
  const userIsCurrentUser = currentUser.id === user.id;

  const generateSubtitle = () => {
    if (status === ReservationStatus.Confirmed && location.deskName) {
      return location.deskName;
    }

    if (currentUserIsOrganizer || userIsCurrentUser) {
      return expiryLabel;
    }
  };

  return {
    ...user,
    subtitle: generateSubtitle(),
    isOwner,
    displayName: userIsCurrentUser ? "You" : user.displayName || user.email,
    iconName: getReservationStatusIcon(status),
    checkInStatus: ko.pureComputed(() => getCheckInStatus(date, user.id))
  };
};

export const WorkspaceConnectionsAndOrMembers = KOComponent(
  "workspace-connections-and-or-members",
  WorkspaceConnectionsAndOrMembersVM,
  template
);
