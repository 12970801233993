import "./SearchBar.scss";
import template from "./SearchBar.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";

const SearchBarVM = params => {
  const placeholderText = params.placeholderText || "Search name or email";
  const id = params.id || "SearchBar";
  const hasFocus = params.hasFocus;
  if (params.options) params = params.options;
  const query = params.query;
  return {
    query,
    hasFocus,
    id,
    placeholderText
  };
};

export const SearchBar = KOComponent("search-bar", SearchBarVM, template);
