import template from "./BookedWorkspaceSummary.template.html";
import "./BookedWorkspaceSummary.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { startOfDay, format } from "date-fns";
import ko from "@shared/knockout/extended";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { isAfter } from "date-fns/esm";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { EventNames, ResultTypes } from "@admin/tracking/EventNames";
import { WorkspaceBookedByTitle } from "../WorkspaceCreator/WorkspaceCreator";
import { showSuccessMessage } from "@app/utils/FeedbackMessage";
import { getGroupDeskIds } from "@app/utils/getGroupDeskIds";
import { DeskSection } from "@shared/viewmodels/DeskSection.js";

export const BookedWorkspaceSummaryVM = ({
  date,
  workspace,
  selectedLocationId,
  user,
  editWorkspace,
  deleteWorkspace,
  settings = ko.observable(null),
  workspaceBookedByTitle = WorkspaceBookedByTitle.USER_APP,
  //admin
  selectedUserId = null,
  emailHref = null
}) => {
  const urlNavigation = UrlNavigationSingleton();
  const areaViewMode = ko.observable(false);
  const loading = ko.observable(false);
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);

  const isAdmin = !!selectedUserId;

  const locationId =
    workspace().workAreaId || workspace().floorId || workspace().buildingId;

  const isGroupBooking = ko.pureComputed(
    () => !!workspace()?.groupReservationId || false
  );

  const headerTitle = ko.pureComputed(() =>
    isGroupBooking() ? "Group booking" : "Desk booking"
  );

  const onBack = () => {
    if (areaViewMode()) {
      areaViewMode(false);
      return;
    }

    urlNavigation.navigate(
      "",
      "overview",
      hasWorkDayFeature ? ["workspace"] : [format(date, "yyyy-MM-dd")]
    );
  };

  const triggerConfirmDeleteWindow = () => {
    Mediator().publish(Channels.ToggleConfirmationWindow, {
      options: {
        title: "Delete group booking?",
        subtitle:
          "This will delete your group booking and those of your invitees.",
        smallerSubText: false,
        buttonInColumnOrder: true,
        primaryButtonText: "Delete",
        primaryButtonClass: "MpqButton--secondaryGray",
        onPrimaryButtonClick: () =>
          deleteBooking({ showDefaultSuccesMessage: false }).then(() =>
            showSuccessMessage(
              { successfulAction: "Group booking cancelled" },
              null,
              "All desk bookings have been deleted."
            )
          )
      }
    });
  };

  const deleteBooking = async ({ showDefaultSuccesMessage }) => {
    loading(true);

    await deleteWorkspace(showDefaultSuccesMessage).finally(() => {
      loading(false);
      if (!hasWorkDayFeature)
        urlNavigation.navigate("", "overview", [format(date, "yyyy-MM-dd")]);
    });
  };

  const onDelete = async () => {
    if (
      isGroupBooking() &&
      !isAdmin &&
      user?.id === workspace().owner?.userId
    ) {
      triggerConfirmDeleteWindow();
    } else {
      deleteBooking({ showDefaultSuccesMessage: true });
    }
  };

  const deskSection = ko.pureComputed(() => {
    if (!ko.unwrap(workspace)) return null;

    return DeskSection({
      locationId,
      date,
      workspace: ko.unwrap(workspace),
      selectedDeskIds: getGroupDeskIds(ko.unwrap(workspace)),
      isGroupBooking,
      selectedUserId,
      disableDeskChoosing: true,
      groupSize: ko.pureComputed(
        () => ko.unwrap(workspace)?.members?.length || 1
      )
    });
  });

  return {
    areaViewMode,
    locationId,
    loading,
    onBack,
    workspace,
    selectedLocationId,
    selectedBuildingId: workspace().buildingId,
    enlargedMap: areaViewMode,
    date,
    user,
    headerTitle,
    isAdmin,
    isGroupBooking,

    canEdit: workspace.maybeMap(({ owner }) => {
      return owner?.userId === user?.id;
    }, false), // Temporary disable editing for beta GWR release

    canDelete: settings.maybeMap(
      ({ registrationPolicy }) =>
        isAfter(date, startOfDay(new Date())) ||
        registrationPolicy === "SameDay",
      // Assume it's allowed if somehow we don't have settings or used from the admin app. Server will do a final check
      true
    ),

    editWorkspace,
    onDelete,
    backButtonIsVisible: ko.pureComputed(
      () => !hasWorkDayFeature || (hasWorkDayFeature && areaViewMode())
    ),
    workspaceNodeSummaryParams: {
      date,
      locationId,
      workspace: workspace()
    },
    workspaceCreatorParams: {
      workspace: workspace(),
      workspaceBookedByTitle
    },
    //admin
    emailHref,
    trackEmail: () => {
      Mediator().publish(Channels.TrackAIEvent, [
        EventNames.ClickShiftEmailSingle,
        ResultTypes.Undefined
      ]);
      return true;
    },
    deskPickerParams: {
      date,
      workspace: workspace(),
      deskSection,
      locationId,
      selectedUserId,
      selectedDeskIds: selectedUserId
        ? workspace()?.deskId
          ? [workspace().deskId]
          : []
        : getGroupDeskIds(workspace),
      isGroupBooking,

      disableDeskChoosing: true,
      areaViewMode,
      showMapIcon: true
    }
    ///////
  };
};

export const BookedWorkspaceSummary = KOComponent(
  "booked-workspace-summary",
  BookedWorkspaceSummaryVM,
  template
);
