import "./MpqIcon.scss";
import template from "./MpqIcon.template.html";

import ko from "../../knockout/extended";

import { Icons } from "./IconIndex";

import { KOComponent } from "../../knockout/KOComponent";
import { DesignSystemIcons } from "./DesignSystemIconIndex.js";

/*
Component for monocolor SVGs icons that takes as parameters: 
- name (required)
- size (optional; default is defined in the HTML of each icon)
- color (optional; default is $darkGrey)
- disabled (optional; default is false; true means color is $mediumGrey)
*/
const IconComponent = KOComponent(
  "mpq-icon",
  params => {
    const iconSource = params.ds ? DesignSystemIcons : Icons;
    return {
      svgString: ko.pureComputed(() => iconSource[ko.unwrap(params.name)]),
      disabled: "disabled" in params ? params.disabled : false,
      customSize: "size" in params ? params.size : null,
      colorClass:
        "color" in params
          ? ko.pureComputed(() => `MpqIcon--${ko.unwrap(params.color)}`)
          : ""
    };
  },
  template
);

export const MpqIcon = Object.assign({}, IconComponent, {
  register: ko => {
    ko.bindingHandlers.updateNestedSvgSize = {
      update: (el, va, allBindings) => {
        const sizeUpdater = ko.computed(() => {
          // Create a subscription on the HTML binding which
          // injects new SVG when the icon name changes
          ko.unwrap(allBindings.get("html"));

          const svg = el.querySelector("svg");
          const size = ko.unwrap(va());

          if (svg && size) {
            svg.setAttribute("width", size);
            svg.setAttribute("height", size);
          }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
          sizeUpdater.dispose();
        });
      }
    };
    IconComponent.register(ko);
  }
});
