import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";

const PreferencesStore = () => {
  const cache = ko.observable({});

  const hydrated = ko.observable(false);

  /**
   * Set new cache
   * @param {Object} preferences
   */
  const update = preferencesApiModel => {
    cache(preferencesApiModel);
    hydrated(true);
  };

  const Setter = (namespace, setting, value = false) => () => {
    const current = cache();

    current[namespace][setting] = value;

    cache.valueHasMutated();
  };

  const Getter = (namespace, setting) => () => {
    const current = cache();

    return current[namespace][setting];
  };

  return {
    hydrated,
    update,

    getHasNotDismissedMeetingOnboardingPrompt: Getter(
      "meetings",
      "hasNotDismissedMeetingOnboardingPrompt"
    ),
    setHasNotDismissedMeetingOnboardingPrompt: Setter(
      "meetings",
      "hasNotDismissedMeetingOnboardingPrompt"
    ),

    getHasNotDismissedCheckInApiInactivityWarning: Getter(
      "checkIn",
      "hasNotDismissedCheckInApiInactivityWarning"
    ),
    setHasNotDismissedCheckInApiInactivityWarning: Setter(
      "checkIn",
      "hasNotDismissedCheckInApiInactivityWarning"
    ),
    getUserDismissedParkingSuggestionPopUp: Getter(
      "parking",
      "userDismissedParkingSuggestionPopUp"
    ),
    setUserDismissedParkingSuggestionPopUp: Setter(
      "parking",
      "userDismissedParkingSuggestionPopUp",
      true
    ),
    getUserDismissedParkingIntroduction: Getter(
      "parking",
      "userDismissedParkingIntroduction"
    ),
    setUserDismissedParkingIntroduction: Setter(
      "parking",
      "userDismissedParkingIntroduction",
      true
    )
  };
};

export const PreferencesStoreSingleton = Singleton(PreferencesStore);
