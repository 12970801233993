export const ParkingReservationVM = ({
  id,
  userId,
  nodeId,
  date,
  parkingLotId,
  parkingLotName,
  zoneId,
  zoneName,
  createdBy,
  lastModifiedBy
}) => {
  const locationLabel = [parkingLotName, zoneName]
    .filter(str => str && str.length > 0)
    .join(", ");

  return {
    id,
    userId,
    nodeId,
    date,
    parkingLotId,
    parkingLotName,
    zoneId,
    zoneName,
    createdBy,
    lastModifiedBy,
    locationLabel
  };
};
