import { ExceptionStoreSingleton } from "@shared/services/ExceptionsStore.js";
import { Singleton } from "@shared/utils/Singleton";
import { format } from "date-fns";
import ko from "../knockout/extended";
import { BuildingNodeTypes } from "../../app/utils/BuildingNodesLS";

const BuildingType = Symbol();
const FloorType = Symbol();
const WorkAreaType = Symbol();

const toCacheObject = apiBuildings => {
  // Create a flat list of [ id, model ]
  // Enrich the models to expose some info about their type
  const allLocations = apiBuildings.flatMap(b => [
    [b.id, Tag(b, BuildingType, b)],
    // Note: API allows buildings to also have direct areas, but we ignore those (for now?)
    ...b.floors.flatMap(f => [
      [f.id, Tag(f, FloorType, b, f)],
      ...f.workAreas.map(a => [a.id, Tag(a, WorkAreaType, b, f, a)])
    ])
  ]);

  return Object.fromEntries(allLocations);
};

/**
 * Stores locations and keeps track of their relations
 * @param {string} preferredBuildingId
 */
//keep the export because this is used in unit tests
export const LocationStore = preferredBuildingId => {
  const cache = ko.observable({});
  const allIds = cache.map(Object.keys);

  const hydrated = ko.observable(false);

  const update = apiBuildings => {
    cache(toCacheObject(apiBuildings));
    hydrated(true);
  };

  const add = apiBuilding => {
    const _cache = cache();
    Object.assign(_cache, toCacheObject([apiBuilding]));

    cache.valueHasMutated();
  };

  const remove = apiBuilding => {
    const _cache = cache();
    const partialCache = toCacheObject([apiBuilding]);

    Object.keys(partialCache).forEach(k => {
      delete _cache[k];
    });

    cache.valueHasMutated();
  };

  const locationOfType = type => () =>
    Object.values(cache()).filter(hasTag(type));

  /**
   * Get all buildings in the store. Empty array if none present
   */
  const getBuildings = locationOfType(BuildingType);

  /**
   * Get a location for a given id, null if it does not exist
   * @param {string} id
   * @returns {object}
   */
  const get = id => cache()[id] || null;

  /**
   * Calculates the total capacity of all buildings
   */
  const totalCapacity = () =>
    getBuildings()
      .map(b => b.capacity)
      .reduce(sum, 0);

  const exStore = ExceptionStoreSingleton();

  const getBuildingForId = locationId => {
    const el = get(locationId);
    return (el && el[BuildingType]) || null;
  };
  return {
    get,

    idBelongsToBookableNode: id => {
      const n = get(id);

      if (!n) return false;

      // Use the merged types to assess the type of this node
      switch (n[T]) {
        case BuildingType:
          return n.floors.length === 0;
        case FloorType:
          return n.workAreas.length === 0;
        case WorkAreaType:
          return true;
        default:
          throw "Unknown node type";
      }
    },

    getPreferredBuilding: () => {
      const allBuildings = getBuildings();
      if (allBuildings.length === 1) return allBuildings[0];

      const officeId =
        ko.unwrap(preferredBuildingId) ||
        localStorage.getItem(BuildingNodeTypes.LastChosenOffice);

      if (!officeId) return null;

      return cache()[officeId] || null;
    },
    getBuildings,
    getLocations: getBuildings,
    getOpenBuildings: date => {
      const day = format(date, "eeee");
      const openOnDay = getBuildings().filter(b => b.openingDays.includes(day));
      return openOnDay.filter(b => !exStore.nodeIsClosed(exStore, date, b));
    },
    getFloors: locationOfType(FloorType),
    getWorkAreas: locationOfType(WorkAreaType),
    getBuildingForId,
    getFloorForId: locationId => {
      const el = get(locationId);
      return (el && el[FloorType]) || null;
    },
    getWorkAreaForId: locationId => {
      const el = get(locationId);
      return (el && el[WorkAreaType]) || null;
    },
    getFlattedNodesFromBuilding: locationId =>
      getBuildingForId(locationId)?.floors.flatMap(floor => {
        const areas = floor.areas;
        if (!areas.length) return floor;
        return areas;
      }) ?? [],
    totalCapacity,
    update,
    add,
    remove,
    allIds,
    allAvailable: date => {
      const all = allIds().map(id => get(id));
      return all.filter(
        place =>
          place.openingDays &&
          place.openingDays.includes(format(date, "eeee")) &&
          !exStore.nodeIsClosed(exStore, date, place)
      );
    },
    hydrated
  };
};

export const LocationStoreSingleton = Singleton(LocationStore);
const T = Symbol();
const Tag = (obj, tag, b = null, f = null, a = null) => ({
  [T]: tag,
  [BuildingType]: b,
  [FloorType]: f,
  [WorkAreaType]: a,
  ...obj
});
const hasTag = tag => obj => obj[T] === tag;
const sum = (a, b) => a + b;
