import "./WorkDayCalendar.scss";
import template from "./WorkDayCalendar.template.html";
import dayTemplate from "./Day.template.html";
import {
  startOfWeek,
  eachDayOfInterval,
  getDay,
  isAfter,
  getISOWeek,
  isToday
} from "date-fns";
import { groupBy } from "@shared/utils/groupBy";
import { WorkDayWeekVM } from "./viewmodels/WorkDayWeekVM";
import { KOComponent } from "@shared/knockout/KOComponent";
import { FeatureNames } from "@shared/services/Features";
import ko from "@shared/knockout/extended";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { getAppFeatures } from "@app/services/AppFeatures";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { WorkDayStatusIcon } from "./components/WorkDayStatusIcon/WorkDayStatusIcon";
import { getWorkdayEndOfCalendar } from "@app/utils/getEndOfCalendar";

const WorkDayCalendarVM = ({
  profile,
  now,
  stores,
  selectedColleagues,
  selectionCount,
  mobileCalendarViewIsActive
}) => {
  const workspaceService = WorkspaceServiceSingleton();
  const { settings } = stores;
  //0 is Sunday
  const lastVisibleWeekDay = ko.pureComputed(() => {
    const futureShifts = workspaceService
      .all()
      .filter(s => isAfter(s.day, now) || isToday(s.day));

    return Math.max(
      ...futureShifts.map(s => {
        const dayKey = getDay(s.day);
        if (dayKey === 0) return 7;
        return dayKey;
      })
    );
  });

  // The calendar always starts at Monday
  const startOfCalendar = startOfWeek(now, { weekStartsOn: 1 });
  const endOfCalendar = getWorkdayEndOfCalendar();
  // Unfold all days in the range
  const calendarDays = eachDayOfInterval({
    start: startOfCalendar,
    end: endOfCalendar()
  });
  //hybrid meetings
  //TODO: remove old property once other PR is merged
  const meetingsFeature =
    FeatureNames.HYBRID_MEETINGS_OFFICE365 ||
    FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;

  const {
    enabledForUsers,
    userHasOptedIn,
    tokenAvailable
  } = getAppFeatures().has(meetingsFeature)
    ? getAppFeatures().get(meetingsFeature)
    : { enabledForUsers: false, userHasOptedIn: false };

  const showMeetings = enabledForUsers && userHasOptedIn && tokenAvailable;

  if (enabledForUsers && userHasOptedIn && !tokenAvailable) {
    // Manually put the store in to error state if we already know
    // our requests will fail due to a lack of token
    MeetingStoreSingleton().completelyBroken(true);
  }

  // Group the days by their week numbers
  const weekGroups = groupBy(day => getISOWeek(day), calendarDays);
  const weeks = Object.entries(weekGroups)
    //sort by first day in each week, to avoid end-of-the-year issues
    .sort((a, b) => a[1][0] - b[1][0])
    .map(
      WorkDayWeekVM(
        profile,
        now,
        stores,
        selectedColleagues,
        lastVisibleWeekDay,
        showMeetings,
        mobileCalendarViewIsActive
      )
    );

  // Get today's shift
  const todayDayVM = weeks.flatMap(w => w.days).find(d => d.isToday);

  return {
    weeks,
    settings,
    hasSelections: selectedColleagues.map(hasLength),
    showConnectionFilter: () =>
      UrlNavigationSingleton().navigate(null, "filter"),
    selectionCount,

    todayDayVM
  };
};

const MainCalendarView = KOComponent(
  "work-day-calendar",
  WorkDayCalendarVM,
  template
);
export const WorkDayCalendar = {
  register: ko => {
    MainCalendarView.register(ko);
    WorkDayStatusIcon.register(ko);

    ko.components.register("work-day", {
      template: dayTemplate
    });
  }
};

const hasLength = xs => xs.length > 0;
