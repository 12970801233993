import template from "./Filters.template.html";
import "./Filters.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { isStringEqual } from "@shared/utils/OfficeDay/helpers";
import { byPropAsc } from "@shared/utils/sort";
import { numberCompareAsc } from "@shared/utils/sortHelper";
import { notEmpty } from "@shared/utils/capacityHelper";
import { NodeAvailabilityVM } from "@app/viewmodels/WorkSpace/NodeVM";
import footerTemplate from "./activeFilter.footer.template.html";
import bodyTemplate from "./activeFilter.body.template.html";

const FiltersVM = ({
  goBack,
  date,
  openActiveFilterView,
  selectedFloor,
  allFloors
}) => {
  const deselectPreviousSelection = clickedOptionName => {
    if (
      !selectedOption() ||
      isStringEqual(selectedOption().name, clickedOptionName)
    )
      return;
    else selectedOption().selected(false);
  };
  const uniqueOptions = Array.from(
    new Set(
      allFloors
        .sort(byPropAsc("number", numberCompareAsc))
        .map(f => ({
          ...NodeAvailabilityVM(date, f),
          name: f.name.trim()
        }))
        .filter(notEmpty)
    )
  ).map(FilterOptionVM(deselectPreviousSelection, selectedFloor));

  const selectedOption = ko.pureComputed(
    () => uniqueOptions.find(v => v.selected()) ?? null
  );

  const enableApplyButton = ko.pureComputed(() => {
    if (selectedFloor() && !selectedOption()) return true;
    if (selectedOption())
      if (!isStringEqual(selectedOption().name, selectedFloor())) return true;

    return false;
  });

  return {
    uniqueOptions,
    buttonText: ko.pureComputed(() => selectedFloor() ?? "Floors"),
    selectedFloor,
    enableClearButton: selectedOption,
    onClear: () => {
      if (!selectedOption()) return;
      selectedFloor(null);
      uniqueOptions.forEach(v => v.selected(false));
      goBack();
    },

    enableApplyButton,
    onApply: () => {
      if (!enableApplyButton()) return;
      selectedFloor(selectedOption()?.name);
      goBack();
    },
    onClick: self => openActiveFilterView(self)
  };
};

const FilterOptionVM = (
  deselectPreviousSelection,
  selectedFloor
) => filterOption => {
  const name = filterOption.name;
  const selected = ko.observable(name === selectedFloor());

  return {
    selected,
    name,
    availability: filterOption.availability,
    onClick: () => {
      deselectPreviousSelection(name);
      selected(!selected());
    }
  };
};
const FiltersComponent = KOComponent("filters", FiltersVM, template);

export const Filters = Object.assign({}, FiltersComponent, {
  register: ko => {
    FiltersComponent.register(ko);

    ko.components.register("active-filter-footer", {
      template: footerTemplate
    });

    ko.components.register("active-filter-body", {
      template: bodyTemplate
    });
  }
});
