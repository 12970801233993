import bodyTemplate from "./ParkingReservation.template.html";
import footerTemplate from "./ParkingReservation.footer.template.html";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { isAfter, startOfDay } from "date-fns";
import { getDateKey } from "@shared/utils/dateHelpers";
import { PopupManagerVM } from "@app/components/PopupManager/PopupManagerVM";
import ko from "@shared/knockout/extended";
import {
  showFailureMessage,
  ActionTypes,
  ActionObjs,
  showSuccessMessage
} from "@app/utils/FeedbackMessage";
import { ParkingLotStoreSingleton } from "@app/services/ParkingLotStore";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { ParkingService } from "../Service/ParkingService";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { FeatureNames, getFeatures } from "@shared/services/Features";

export const ParkingReservationVM = ({
  date,
  settings,
  user,
  mediator = Mediator()
}) => {
  const loading = ko.observable(false);
  const selectedParkingLocationId = ko.observable(null);
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const parkingService = ParkingService(date, user);
  const parkingLotStore = ParkingLotStoreSingleton();
  const parkingReservation = ko.observable(
    parkingReservationsStore.getForDate(date)[0] || null
  );
  const afterToday = isAfter(date, startOfDay(new Date()));

  const closeWizard = () =>
    mediator.publish(Channels.CloseParkingReservationWindow);

  const updateAvailability = () => {
    if (!getFeatures().has(FeatureNames.WORK_DAY)) return;

    parkingService.getParkingAvailabilty(date);
  };
  const onParkingConfirm = () => {
    loading(true);
    if (parkingReservation()) {
      publishAIEvent(EventNames.EditParkingReservation);
      parkingService
        .updateParkingReservation({
          currentReservationId: parkingReservation().id,
          newParkingLocationId: selectedParkingLocationId()
        })
        .then(apiObj => {
          parkingReservation(apiObj);
          updateAvailability();
          showSuccessMessage(ActionTypes.Updated, ActionObjs.Parking);
        })
        .catch(error =>
          showFailureMessage(
            ActionTypes.Updated,
            ActionObjs.Parking,
            error,
            date
          )
        )
        .finally(() => {
          closeWizard();
          loading(false);
        });
    } else {
      publishAIEvent(EventNames.ReserveParkingSpot);
      parkingService
        .postParkingReservation({
          date: getDateKey(date),
          nodeId: selectedParkingLocationId()
        })
        .then(apiObj => {
          parkingReservation(apiObj);
          updateAvailability();
          showSuccessMessage(
            ActionTypes.Booked,
            ActionObjs.Parking,
            "You’re all set for your workday"
          );
        })
        .catch(error =>
          showFailureMessage(
            ActionTypes.Booked,
            ActionObjs.Parking,
            error,
            date
          )
        )
        .finally(() => {
          closeWizard();
          loading(false);
        });
    }
  };

  return {
    loading,
    date,
    parkingLotStore,
    parkingReservationsStore,
    parkingReservation,
    selectedParkingLocationId,
    onParkingConfirm,
    disableParkingConfirmButton: ko.pureComputed(() => {
      const parkingReservationId = parkingReservation()?.nodeId;

      return selectedParkingLocationId()
        ? parkingReservationId
          ? selectedParkingLocationId() === parkingReservationId
          : false
        : true;
    }),
    disableParkingDeleteButton: ko.pureComputed(() =>
      !!parkingReservationsStore.getForDate(date).length
        ? !settings.maybeMap(
            ({ registrationPolicy }) =>
              afterToday || registrationPolicy === "SameDay",
            true
          )
        : true
    ),
    parkingConfirmButtonText: ko.pureComputed(() =>
      !!parkingReservationsStore.getForDate(date).length
        ? "Save"
        : "Confirm booking"
    ),
    onParkingDelete: () => {
      publishAIEvent(EventNames.StartDeleteParkingReservation);
      Mediator().publish(Channels.ToggleConfirmationWindow, {
        options: {
          title: "Delete booking?",
          iconName: "car",
          subtitle: "Your parking spot will become available to others.",
          primaryButtonText: "Delete",
          onPrimaryButtonClick: () => {
            loading(true);
            publishAIEvent(EventNames.DeleteParkingReservation);
            return parkingService
              .deleteParkingReservation(
                parkingReservationsStore.getForDate(date)[0].id
              )
              .then(() => {
                parkingReservation(null);
                updateAvailability();
                showSuccessMessage(ActionTypes.Deleted, ActionObjs.Parking);
              })
              .catch(error =>
                showFailureMessage(
                  ActionTypes.Deleted,
                  ActionObjs.Parking,
                  error,
                  date
                )
              )
              .finally(() => {
                closeWizard();
                loading(false);
              });
          }
        }
      });
    }
  };
};
const { getTitle } = PopupManagerVM.Keys;

Object.assign(ParkingReservationVM, {
  [getTitle]: vm =>
    vm.parkingReservation() ? "Your parking spot" : "Book a parking spot"
});

export const ParkingReservation = {
  register: ko => {
    ko.components.register("parking-reservation-body", {
      template: bodyTemplate
    });
    ko.components.register("parking-reservation-footer", {
      template: footerTemplate
    });
  }
};
