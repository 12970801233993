import "./CalendarView.scss";
import "./CalendarTile.scss";
import template from "./CalendarView.template.html";
import dayTemplate from "./Day.template.html";
import {
  startOfWeek,
  eachDayOfInterval,
  getDay,
  isAfter,
  getISOWeek,
  isToday
} from "date-fns";
import { groupBy } from "@shared/utils/groupBy";
import { WeekVM } from "./viewmodels/WeekVM";
import { KOComponent } from "@shared/knockout/KOComponent";
import { ConnectedColleagueVM } from "@app/viewmodels/ConnectedColleagueVM";
import { FeatureNames } from "@shared/services/Features";
import ko from "@shared/knockout/extended";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { getAppFeatures } from "@app/services/AppFeatures";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { IntroductionTile } from "./tiles/IntroductionTile/IntroductionTile.js";
import { RestoreMeetTile } from "./tiles/RestoreMeetTile/RestoreMeetTile.js";
import { toasterBinding } from "./toasterBindings.js";
import { CheckInHasShiftTile } from "./tiles/CheckInTile/CheckInHasShiftTile/CheckInHasShiftTile";
import { CheckInNoShiftTile } from "./tiles/CheckInTile/CheckInNoShiftTile/CheckInNoShiftTile";
import { OfficesClosedTile } from "./tiles/OfficesClosedTile/OfficesClosedTile";
import { enable, handleError } from "@app/utils/meetConsent";
import { format } from "date-fns";
import { MeetApi } from "@shared/services/MeetApi";
import { ParkingService } from "../Parking/Service/ParkingService";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import { ConnectedColleaguesServiceSingleton } from "@app/services/ConnectedColleaguesService";
import { ConnectionsWorkingHere } from "@shared/components/ConnectionsWorkingHere/ConnectionsWorkingHere";

const CalendarViewVM = ({
  profile,
  now,
  user,
  stores,
  selectedColleagues,
  selectionCount,
  endOfCalendar
}) => {
  const workspaceService = WorkspaceServiceSingleton();
  const { settings } = stores;
  const parkingService = ParkingService(now, user);
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const connectedColleaguesStore = ConnectedColleaguesStoreSingleton();
  //0 is Sunday
  const lastVisibleWeekDay = ko.pureComputed(() => {
    const futureShifts = workspaceService
      .all()
      .filter(s => isAfter(s.day, now) || isToday(s.day));

    return Math.max(
      ...futureShifts.map(s => {
        const dayKey = getDay(s.day);
        if (dayKey === 0) return 7;
        return dayKey;
      })
    );
  });

  const allColleagues = connectedColleaguesStore.all.mapArray(
    ConnectedColleagueVM
  );

  // The calendar always starts at Monday
  const startOfCalendar = startOfWeek(now, { weekStartsOn: 1 });

  // Unfold all days in the range
  const calendarDays = eachDayOfInterval({
    start: startOfCalendar,
    end: endOfCalendar()
  });

  //hybrid meetings
  //TODO: remove old property once other PR is merged
  const meetingsFeature =
    FeatureNames.HYBRID_MEETINGS_OFFICE365 ||
    FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;

  const {
    enabledForUsers,
    userHasOptedIn,
    tokenAvailable,
    hideConsentCTA,
    hideConsentErrorCTA
  } = getAppFeatures().has(meetingsFeature)
    ? getAppFeatures().get(meetingsFeature)
    : { enabledForUsers: false, userHasOptedIn: false };

  const showMeetings = enabledForUsers && userHasOptedIn && tokenAvailable;

  if (enabledForUsers && userHasOptedIn && !tokenAvailable) {
    // Manually put the store in to error state if we already know
    // our requests will fail due to a lack of token
    MeetingStoreSingleton().completelyBroken(true);
  }

  // Group the days by their week numbers
  const weekGroups = groupBy(day => getISOWeek(day), calendarDays);
  const weeks = Object.entries(weekGroups)
    //sort by first day in each week, to avoid end-of-the-year issues
    .sort((a, b) => a[1][0] - b[1][0])
    .map(
      WeekVM(
        profile,
        now,
        stores,
        allColleagues,
        selectedColleagues,
        lastVisibleWeekDay,
        showMeetings
      )
    );

  // Get today's shift
  const todayDayVM = weeks.flatMap(w => w.days).find(d => d.isToday);

  const showTodayTile = ko.pureComputed(todayDayVM.booked);
  const showNoShiftTile = ko.pureComputed(
    () => !todayDayVM.booked() && !todayDayVM.isClosed()
  );

  const showOfficesClosedTile = ko.pureComputed(
    () => todayDayVM.isClosed() && !todayDayVM.booked()
  );
  const showConsentCTA = ko.observable(
    enabledForUsers && !userHasOptedIn && !hideConsentCTA
  );
  const showConsentErrorCTA = ko.observable(
    enabledForUsers && userHasOptedIn && !tokenAvailable && !hideConsentErrorCTA
  );

  const firstAllowedDayForParking = ko.pureComputed(() => {
    const thisWeek = weeks[0].days;
    return showMeetings
      ? thisWeek.filter(
          d => !d.inPast && !parkingReservationsStore.getForDate(d.day).length
        )[0]?.day
      : thisWeek.filter(
          d =>
            !d.inPast &&
            (d.weekDayAllowed || d.booked()) &&
            !parkingReservationsStore.getForDate(d.day).length
        )[0]?.day;
  });

  const showParkingIntroductionTile = ko
    .computed({
      read: () =>
        parkingReservationsStore.hydrated() &&
        !showConsentErrorCTA() &&
        !showConsentCTA() &&
        parkingService.userCanSeeIntroductionBanner() &&
        firstAllowedDayForParking(),

      write: val => val
    })
    .extend({ deferred: true });

  return {
    weeks,
    settings,
    hasConnectionFilter: allColleagues.map(hasLength),
    hasSelections: selectedColleagues.map(hasLength),
    showConnectionFilter: () =>
      UrlNavigationSingleton().navigate(null, "filter"),
    loadingColleagues: ConnectedColleaguesServiceSingleton().loading,
    selectionCount,

    todayDayVM,
    showTodayTile,
    showNoShiftTile,
    showOfficesClosedTile,
    showConsentCTA,
    showParkingIntroductionTile,
    meetingConsentTileParams: {
      title: "Sync your calendar",
      subTitle: "See your upcoming meetings, who’s joining, and from where.",
      iconName: "hybridMeetingConsent",
      buttonText: "Connect",
      showLoading: true,
      onPrimaryButtonClick: () => enable(),
      handleError: err => handleError(err),
      onClose: () =>
        MeetApi.setUserPreference({
          userClosedConsentTile: true
        })
    },
    parkingIntroductionTileParams: {
      title: "Parking is now available",
      subTitle: "Tap on a day to book a parking spot for your office days.",
      iconName: "parkingIntroduction",
      buttonText: "Go there",
      showLoading: false,
      onPrimaryButtonClick: () => {
        UrlNavigationSingleton().navigate("", "overview", [
          format(firstAllowedDayForParking(), "yyyy-MM-dd")
        ]);
        return parkingService.dismissParkingIntroduction();
      },
      handleError: () => {},
      onClose: () => parkingService.dismissParkingIntroduction()
    },
    showConsentErrorCTA,
    dispose: () => {
      showParkingIntroductionTile.dispose();
    }
  };
};

const MainCalendarView = KOComponent("calendar-view", CalendarViewVM, template);
export const CalendarView = {
  register: ko => {
    MainCalendarView.register(ko);
    CheckInHasShiftTile.register(ko);
    CheckInNoShiftTile.register(ko);
    OfficesClosedTile.register(ko);
    IntroductionTile.register(ko);
    RestoreMeetTile.register(ko);
    ConnectionsWorkingHere.register(ko);

    ko.bindingHandlers.toaster = toasterBinding;

    ko.components.register("cv-day", {
      template: dayTemplate
    });
  }
};

const hasLength = xs => xs.length > 0;
