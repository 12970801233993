import { getISOWeek } from "date-fns";
import { WorkDayVM } from "./WorkDayVM";

export const WorkDayWeekVM = (
  profile,
  now,
  stores,
  selectedColleagues,
  lastVisibleWeekDay,
  showMeetings,
  mobileCalendarViewIsActive
) => ([weekKey, weekDays]) => {
  // Label
  const weekNr = Number(weekKey);
  const currentWeekNr = getISOWeek(now);
  const heading =
    weekNr === currentWeekNr
      ? "This week"
      : weekNr === currentWeekNr + 1
      ? "Next week"
      : `Week ${weekNr}`;

  // Create viewmodels for each day
  const days = weekDays.map(
    WorkDayVM(
      profile,
      now,
      stores,
      selectedColleagues,
      lastVisibleWeekDay,
      showMeetings,
      mobileCalendarViewIsActive
    )
  );

  return {
    heading,
    days
  };
};
