import template from "./PageContent.template.html";
import { Mediator } from "@shared/mediator";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { urlNavigationChannel } from "@app/utils/URLNavigation.js";
import "./PageContent.scss";
const PageContentVM = () => {
  const heading = ko.observable("");
  const body = ko.observable(null);
  const id = ko.observable(null);

  const active = ({
    config: {
      path: panelId,
      heading: newHeading,
      content: {
        bodyComponentName,
        // Flavor 1:
        // This works for components that construct their own VM from a params object
        params = {},
        // Flavor 2:
        // This works for components that share a VM that is bound via a with binding
        VMConstructor = cfg => cfg
      }
    },
    path
  }) => {
    heading(newHeading);
    id(panelId);

    const componentParams = VMConstructor(params);

    body({
      name: bodyComponentName,
      params: { ...componentParams, path }
    });
  };

  Mediator().subscribe(urlNavigationChannel, ({ page, path }) => {
    if (page.path !== id()) {
      active({ config: page, path });
    }
  });

  return {
    heading,
    active,
    body
  };
};
export const PageContent = KOComponent("page-content", PageContentVM, template);
