export class ExtrasApiModel {
  constructor(apiObj) {
    this.id = apiObj.id;
    //I wouldn't say that this is the name that we can show on the UI since it is an enum value (PascalCase)
    //On the FE we map to the corresponding name that is displayed on the UI
    this.name = apiObj.name ?? "";
    this.type = apiObj.type;
    this.description = apiObj.description ?? "";
    this.url = apiObj.url ?? "";
  }

  static emptyApiModel(type) {
    return new ExtrasApiModel({ type });
  }

  static toApiModel(vmObj) {
    return new ExtrasApiModel(vmObj);
  }

  static equal(vmObj, apiObj) {
    return (
      vmObj &&
      apiObj &&
      vmObj.id === apiObj.id &&
      vmObj.name === apiObj.name &&
      vmObj.url === apiObj.url &&
      vmObj.description === apiObj.description
    );
  }
}
