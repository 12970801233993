import { parseNotification } from "@app/utils/formatNotifications.js";
import ko from "@shared/knockout/extended";
import { formatDistanceToNow } from "date-fns";

export function NotificationVM(notification) {
  // Formatting function
  const formatDate = ko.pureComputed(() =>
    formatDistanceToNow(new Date(notification.creationDateTime), {
      addSuffix: true
    })
  );

  // Format the date
  const dateFormatted = ko.observable(formatDate());

  // Formatting interval
  // eslint-disable-next-line no-unused-vars
  const formateDateInterval = setInterval(() => {
    dateFormatted(formatDate());
  }, 5000);

  const disabled = ko.observable(false);

  return {
    id: notification.id,
    type: notification.type,
    messageTemplate: notification.messageTemplate,
    messageTitle: notification.messageTitle,
    messageParts: parseNotification(notification),
    messageValues: notification.messageValues,
    iconName: `noti${notification.type}`,
    isRead: notification.isRead,
    date: dateFormatted,
    disabled,
    dispose: () => {
      clearInterval(formateDateInterval);
    }
  };
}
