import { pl } from "./pluralize";
import { intervalToDuration, set } from "date-fns";

export const formatDuration = (duration, showFullFormat = false) => {
  const { months = 0, days = 0, hours = 0, minutes = 0 } = duration ?? {};
  let durationText = "";

  if (months) {
    // currently only support full format
    durationText = durationText + `${months} ${pl("month", months)}`;
  }

  if (days) {
    if (months) {
      durationText = durationText + " ";
    }
    durationText =
      durationText + getFormattedTimeUnit("day", days, showFullFormat);
  }

  if (hours) {
    if (days || months) {
      durationText = durationText + " ";
    }
    durationText =
      durationText + getFormattedTimeUnit("hour", hours, showFullFormat);
  }

  if (minutes || !durationText) {
    if (hours || days || months) {
      durationText = durationText + " ";
    }
    durationText =
      durationText + getFormattedTimeUnit("minute", minutes, showFullFormat);
  }

  return durationText;
};

/**
 * Calculates duration between dates and provides formatted duration. Note: it will strip seconds and miliseconds.
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {boolean} showFullFormat
 * @returns formatted duration.
 */
export const intervalToFormattedDuration = (
  startDate,
  endDate,
  showFullFormat
) =>
  formatDuration(
    intervalToDuration({
      start: set(startDate, { seconds: 0, milliseconds: 0 }),
      end: set(endDate, { seconds: 0, milliseconds: 0 })
    }),
    showFullFormat
  );

export const roundDownDurationIfMoreThanDay = duration => {
  const { years, months, weeks, days } = duration;

  if (years) return { years };
  if (months) return { months };
  if (weeks) return { weeks };
  if (days) return { days };

  return duration;
};

const getFormattedTimeUnit = (timeUnit, value, showFullFormat) =>
  showFullFormat ? `${value} ${pl(timeUnit, value)}` : `${value}${timeUnit[0]}`;
