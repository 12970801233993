import template from "./RedirectPopup.template.html";
import { KOComponent } from "../../knockout/KOComponent";
import "./RedirectPopup.scss";

const RedirectPopupVM = ({ showRedirectPopup, isUserApp }) => {
  return { showRedirectPopup, isUserApp };
};

export const RedirectPopup = KOComponent(
  "redirect-popup",
  RedirectPopupVM,
  template
);
