import { setEqual } from "@shared/utils/setHelpers.js";
import { UserAssignmentRuleApiModel } from "./UserAssignmentRuleApiModel";

export class QuotaProfileApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.daysPerWeek = obj.daysPerWeek;
    this.daysAhead = obj.daysAhead ?? 0;
    this.registrationDays = obj.registrationDays
      ? obj.registrationDays
      : [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ];
    this.rule = obj.rule ? UserAssignmentRuleApiModel.fromDto(obj.rule) : null;
  }

  static equal(a, b) {
    return (
      a &&
      b &&
      a.id === b.id &&
      a.name === b.name &&
      a.daysPerWeek === b.daysPerWeek &&
      a.daysAhead === b.daysAhead &&
      setEqual(new Set(a.registrationDays), new Set(b.registrationDays)) &&
      (a.rule === b.rule || UserAssignmentRuleApiModel.equal(a.rule, b.rule))
    );
  }

  static fromDto(apiObj) {
    return new QuotaProfileApiModel(apiObj);
  }
}
