import { subMilliseconds } from "date-fns";

export const formatDateToNoUTC = (date, isAllDay = false) => {
  return !isAllDay ? date : isUTCDate(date) ? date.slice(0, -1) : date;
};

const isUTCDate = date => {
  if (date.endsWith("Z")) return true;
  return false;
};

export const returnNotOverlappingDate = date => subMilliseconds(date, 1);
