export const WorkdayStatus = {
  OfficeDay: "OfficeDay",
  NotWorking: "NotWorking",
  WorkingRemotely: "WorkingRemotely",
  Unknown: null
};

export const WorkdayStatusLabel = {
  [WorkdayStatus.NotWorking]: "Not working",
  [WorkdayStatus.WorkingRemotely]: "Working remotely",
  [WorkdayStatus.Unknown]: "Where will you be?"
};

export const WorkdayStatusIcon = {
  [WorkdayStatus.NotWorking]: "notWorkingWorkdayStatus",
  [WorkdayStatus.WorkingRemotely]: "remoteWorkdayStatus",
  [WorkdayStatus.OfficeDay]: "workspaceOffice",
  [WorkdayStatus.Unknown]: "unknownWorkdayStatus"
};

export const getWorkdayIcon = status => WorkdayStatusIcon[status];

export const getWorkdayTitle = status => WorkdayStatusLabel[status];
