import { initialsForName } from "../../shared/utils/avatar-helpers";
import { Query } from "@shared/utils/Query";

import ko from "@shared/knockout/extended";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";

export function ConnectedColleagueVM(colleague) {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  //TODO: remove fallback option once tested existensively the server property
  const initials = colleague.initials || initialsForName(colleague.name);
  const imageHash = colleague.imageHash;
  return {
    email: colleague.email,
    initials,
    id: colleague.id,
    name: colleague.name,
    shifts: colleague.shifts,
    imageHash,

    checkInStatus: ko.pureComputed(() =>
      checkInStatusStore.getById(colleague.id)
    ),
    [Query.getMatchTerms]: () => [initials, colleague.name]
  };
}
