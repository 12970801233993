import { areEmailsEqual } from "@shared/utils/emailHelpers";

export const MeetingAttendeeVM = (userEmail, userOfficeDay) => apiModel => {
  //if this is the current user, we determine location type and shift info
  //from their current shift, instead of the API data, so to guarantee automatic updates
  const isUser = areEmailsEqual(userEmail, apiModel.email);

  const locationType = !isUser
    ? apiModel.locationType
    : userOfficeDay.locationType;

  const shiftBuildingName = !isUser
    ? apiModel.shiftBuildingName
    : userOfficeDay.buildingName;

  const shiftBuildingId = !isUser
    ? apiModel.shiftBuildingId
    : userOfficeDay.buildingId;

  return Object.assign({}, apiModel, {
    locationType,
    shiftBuildingId,
    shiftBuildingName
  });
};
