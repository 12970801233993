import { ErrorType } from "@shared/utils/ErrorType";

const CustomMessages = {
  // We're gambling on this message meaning the input is too long. In that case, the server
  // has a very technical message we'd like to replace.
  [ErrorType.OfficeInstructions]:
    "Your office instructions may only contain 240 characters.",
  [ErrorType.CreateRegistrationsInThePast]:
    "We couldn't complete your request because this office is in a different time zone than yours.",
  [ErrorType.ConsecutiveCheckInStatusChange]:
    "You checked in or out too fast. Please try again in a few seconds.",
  [ErrorType.ParkingReservationLimitReached]:
    "Looks like someone else just booked the last spot. Please select a different one."
};

export const Server400ErrorModel = apiError => {
  const title = apiError.title;

  // The errors prop: { errorKey: [ errorMessage ] }
  const errorDescriptions = Object.entries(apiError.errors).map(
    ([errorKey, [firstMessage]]) => CustomMessages[errorKey] || firstMessage
  );

  return {
    title,
    errorDescriptions,
    firstError: errorDescriptions[0],
    firstErrorType: Object.keys(apiError.errors)[0]
  };
};

export const Server400MultiErrorModel = apiError => {
  const title = apiError.title;

  // The errors prop: { errorKey: [ errorMessage ] }
  const errorDescriptions = Object.entries(apiError.errors)
    .map(([_, messages]) => messages)
    .flatMap(m => m);

  return {
    title,
    errorDescriptions,
    firstError: errorDescriptions[0],
    firstErrorType: Object.keys(apiError.errors)[0]
  };
};

Server400ErrorModel.tryExtractErrorMessage = (error, defaultMessage = null) => {
  if (error.status === 400) {
    return Server400ErrorModel(error).firstError;
  }

  return defaultMessage;
};
