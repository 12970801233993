import ko from "@shared/knockout/extended";
import { sum } from "@shared/utils/sum";
import { getDateKey } from "@shared/utils/dateHelpers";
import { notNil } from "@shared/utils/typeHelper";
import { LocationStoreSingleton } from "./LocationStore";
import { Singleton } from "@shared/utils/Singleton";
/**
 * Stores registration counts for locations per day
 */
const AvailabilityStore = () => {
  const cache = ko.observable({});
  const locationStore = LocationStoreSingleton();
  /**
   * Adds a shift count sample to the store
   * @param {Date} date
   * @param {string} locationId
   * @param {number} availability
   */
  const add = (date, locationId, availability) => {
    const dateKey = getDateKey(date);
    const _cache = cache();

    if (!_cache[dateKey]) _cache[dateKey] = {};

    _cache[dateKey][locationId] = availability;

    cache.valueHasMutated();
  };

  /**
   * Replaces the whole cache
   * @param {object} newCache Object of { 'yyyy-MM-dd': { 'guid': int } }
   */
  const update = newCache => cache(newCache);

  /**
   * Get the number of booked shifts on a given day for a given location
   * @param {Date} date
   * @param {string} locationId
   */
  const get = (date, locationId) => {
    const dateKey = getDateKey(date);
    const _cache = cache();

    // Check if this level appears in cache first
    const inCache = _cache[dateKey] && _cache[dateKey][locationId];
    if (typeof inCache !== "undefined") return inCache;

    // Fall back to using location tree to get all deepest level workareas
    // and count up their availabilities
    const loc = locationStore.get(locationId);

    if (!loc) {
      // Warning: we're retrieving availability for a location we don't know
      return null;
    }

    const children = loc.floors || loc.workAreas;

    if (children) {
      const childAvailabilities = children.map(child => get(date, child.id));

      if (childAvailabilities.length === 0) return null;

      const nextLevel = childAvailabilities.filter(notNil);
      return nextLevel.length > 0 ? nextLevel.reduce(sum, 0) : null;
    }

    // When retrieving a availability for a known workarea, that is not in the
    // availability store, you get here.
    return null;
  };

  /**
   * Check whether there is any availability data available for a given date
   * @param {Date} date
   * @returns {Boolean}
   */
  const hasDate = date => {
    const dateKey = getDateKey(date);

    return !!cache()[dateKey];
  };

  /**
   * Get the total shift count for all buildings on a given date
   * @param {Date} date
   * @returns {number}
   */
  const getAllBuildings = date => {
    const counts = locationStore
      .getBuildings()
      .map(b => get(date, b.id))
      .filter(notNil);

    return counts.length ? counts.reduce(sum, 0) : null;
  };

  /**
   * Get the shift count for the preferred building on a given date
   * @param {Date} date
   * @returns {Number}
   */
  const getPreferredBuilding = date => {
    const pref = locationStore.getPreferredBuilding();

    if (!pref) return null;

    return get(date, pref.id);
  };

  return {
    update,
    add,
    get,
    getState: ko.pureComputed(cache),
    getAllBuildings,
    getPreferredBuilding,
    hasDate
  };
};

export const AvailabilityStoreSingleton = Singleton(AvailabilityStore);
