import ko from "@shared/knockout/extended";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { ConnectionStatus } from "../components/popups/ConnectionsPage/ConnectionStatus";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel";
import { initialsForName } from "@shared/utils/avatar-helpers.js";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { publishAIEvent } from "@app/tracking/publishAIEvent";

export const ConnectionVM = (
  connection,
  api,
  connectionStore,
  connectedColleaguesStore,
  mediator = Mediator()
) => {
  const cleanedName = (connection.connectedUser.name || "").trim();
  const name = cleanedName || null;
  const email = connection.connectedUser.email;
  const id = connection.id;
  const userId = connection.userId;
  const status = connection.status;
  //TODO: remove fallback option once tested existensively the server property
  const initials = connection.initials || initialsForName(name || email);
  const imageHash = connection.imageHash;
  const checkInStatusStore = CheckInStatusStoreSingleton();

  const onDelete = () => {
    mediator.publish(Channels.OpenConnectionDeleteWindow, {
      initials,
      email,
      status,
      id,
      imageHash
    });
  };

  const updated = ko.observable(false);

  const onAccept = () => {
    updateConnection(id, ConnectionStatus.Accepted);
  };

  const onIgnore = () => {
    updateConnection(id, ConnectionStatus.Ignored);
  };

  const updateConnection = (connectionId, newStatus) => {
    // This lets the UI start a transition hiding the Pending request
    updated(true);

    const AIEvent =
      newStatus === ConnectionStatus.Accepted
        ? EventNames.AcceptConnectionRequest
        : EventNames.IgnoreConnectionRequest;
    api
      .updateConnection(connectionId, newStatus)
      .then(updatedConnection => {
        // After 500ms, we add the updated connection to the store, automatically
        // triggering a re-render of the UI (removing the hidden pending request)
        setTimeout(() => {
          connectionStore.add(updatedConnection);

          // Request updates to both connection endpoints so that:
          // 1. Our new connection's shifts are shown in calendar
          // 2. Any other updates from people accepting our stuff also get loaded
          if (newStatus === ConnectionStatus.Accepted) {
            mediator.publish(Channels.RequestConnectionsUpdate);
          }
        }, 500);

        publishAIEvent(AIEvent, null, ResultTypes.Success);
      })
      .catch(e => {
        updated(false);

        // Publish AI event first
        publishAIEvent(AIEvent, null, ResultTypes.Fail);

        // For 400 errors, we show the server message
        if (e.status === 400) {
          const error = Server400ErrorModel(e);

          // All other errors are handled with a status message
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: error.firstError
          });
        } else {
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: "Something went wrong. Please try again later."
          });
        }
      });
  };

  return {
    id,
    status,
    direction: connection.direction,
    name,
    email,
    userId,
    initials,
    onDelete,
    onAccept,
    onIgnore,
    updated,
    imageHash,
    checkInStatus: ko.pureComputed(() =>
      checkInStatusStore.getById(connection.connectedUser.id)
    )
  };
};
