import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import ko from "@shared/knockout/extended";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

export const OfficeVM = ({
  office,
  date,
  selectedOfficeId,
  userSelectedDifferentOffice,
  selectedLocationId,
  selectedDeskIds,
  goHome,
  selectedInvitees = ko.observableArray(null),
  usePendingOrConfirmedMemberCount,
  //admin app
  isDefaultOffice = () => false,
  isLocationRestricted = () => false
}) => {
  const id = office.id;
  const availabilityData = availabilityLocationData(
    date,
    office,
    selectedInvitees()?.length + 1,
    usePendingOrConfirmedMemberCount
  );

  const isSelected = ko.pureComputed(
    () =>
      id === LocationStoreSingleton().getBuildingForId(selectedOfficeId())?.id
  );
  const isDisabled = ko.pureComputed(
    () => availabilityData.full() || availabilityData.closed()
  );

  const select = self => {
    if (isDisabled()) return;
    selectedOfficeId(self.id);
    selectedLocationId(null);
    selectedDeskIds([]);
    userSelectedDifferentOffice(true);
    goHome();
  };
  return {
    id,
    name: office.name,
    capacity: office.capacity,
    full: availabilityData.full,
    closed: availabilityData.closed,
    availability: availabilityData.availability,
    availabilityLabel: availabilityData.availabilityLabel,
    groupBookingLabel: ko.pureComputed(() =>
      availabilityData.groupAvailabilityLabel()
    ),
    colorClass: availabilityData.colorClass,
    percentage: availabilityData.percentage,

    select,
    isSelected,
    isDisabled,

    showDefaultOfficeIcon: isDefaultOffice(id),
    showRestrictedLocationIcon: isLocationRestricted(id)
  };
};
