import ko from "@shared/knockout/extended";
import { stringCompare, sort } from "@shared/utils/sort";

import { ErrorType } from "../../shared/utils/ErrorType";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { Singleton } from "@shared/utils/Singleton.js";
import { isSameDay, startOfDay } from "date-fns";
import { stringToColor } from "@shared/utils/avatar-helpers";
import { ConnectionsStoreSingleton } from "./ConnectionStore";
import { CheckInStatusStoreSingleton } from "./CheckInStatusStore";
import { groupBy } from "@shared/utils/groupBy";

export const ConnectedColleaguesStore = (mediator = Mediator()) => {
  const cache = ko.observable({});
  const all = cache.map(Object.values).map(
    sort((c1, c2) => {
      // Name based first:
      if (c1.name && c2.name) return stringCompare(c1.name, c2.name);
      if (c1.name) return -1;
      if (c2.name) return 1;

      // If both don't have names, use email
      return stringCompare(c1.email, c2.email);
    })
  );
  /**
   * Set new cache
   * @param {Array} allColleagues
   */
  const update = allColleagues => {
    cache(Object.fromEntries(allColleagues.map(c => [c.id, c])));
    error(null);
  };

  /**
   * add multiple to cache
   * @param {Array} allColleagues
   */
  const addMultiple = allColleagues => {
    const _cache = cache();
    cache(
      Object.assign(
        _cache,
        Object.fromEntries(allColleagues.map(c => [c.id, c]))
      )
    );
  };

  const error = ko.observable(null);

  const registerError = e => {
    error(e);
    mediator.publish(
      Channels.OpenConnectionErrorWindow,
      ErrorType.LoadingFailed
    );
  };
  const allWithShiftOnDate = date =>
    all().filter(({ shifts }) =>
      shifts.some(({ day }) => isSameDay(day, date))
    );

  const allWithReservationOnDateInNode = (date, nodeId) => {
    const conStore = ConnectionsStoreSingleton();
    const checkInStatusStore = CheckInStatusStoreSingleton();
    const allReservationsOnDate = allWithShiftOnDate(
      date
    ).map(({ id, shifts }) => [id, shifts.find(s => isSameDay(s.day, date))]);

    const groupedReservationsByNode = groupBy(
      ([_userId, shift]) => shift.areaId || shift.floorId || shift.buildingId,
      allReservationsOnDate
    );

    return (
      groupedReservationsByNode[nodeId]?.map(([userId, _shift]) => {
        const user = conStore.getByUserId(userId);
        return {
          email: user.email,
          initials: user.initials,
          name: user.name,
          imageHash: user.imageHash,
          checkInStatus: ko.pureComputed(() =>
            isSameDay(date, startOfDay(new Date()))
              ? checkInStatusStore.getById(userId)
              : null
          )
        };
      }) ?? []
    );
  };

  return {
    update,
    all,
    addMultiple,
    allWithShiftOnDate,
    allWithReservationOnDateInNode,

    allWithOnlyShiftsOnDateInArea: (date, aId) => {
      return all().map(({ shifts, ...user }) => ({
        ...user,
        color: stringToColor(user.email),
        imageHash: user.imageHash,
        workspace:
          shifts.find(
            ({ day, areaId }) => areaId === aId && isSameDay(day, date)
          ) || null
      }));
    },
    allWithWorkdayOnDate: date =>
      all().filter(({ workdays }) =>
        workdays.some(({ startDate }) => isSameDay(startDate, date))
      ),
    registerError,
    error
  };
};

export const ConnectedColleaguesStoreSingleton = Singleton(
  ConnectedColleaguesStore
);
