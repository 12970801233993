import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { format, isSameDay } from "date-fns";
import { AppApi } from "./AppApi";
import { MeetingStoreSingleton } from "./MeetingStore";
import ko from "@shared/knockout/extended";

const organizerTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const MeetingService = (mediator = Mediator()) => {
  const handleError = e => {
    let reason = null;

    if (e && e.errors) {
      const [[firstErrorValue]] = Object.values(e.errors);
      reason = firstErrorValue;
    }

    mediator.publish(Channels.OpenStatusMessage, {
      type: "failure",
      reason
    });
  };

  const createMeeting = (
    selectedAttendees,
    startDateTime,
    endDateTime,
    title,
    description,
    selectedRooms,
    isOnlineMeeting,
    userEmail
  ) => {
    const attendees = selectedAttendees()
      .map(a => a.email)
      .concat([userEmail]);
    const start = ko.unwrap(startDateTime);
    const end = ko.unwrap(endDateTime);
    const rooms = ko.unwrap(selectedRooms);
    return AppApi.createMeeting({
      startDateTime: start,
      endDateTime: end,
      title: title(),
      message: description(),
      attendees,
      roomEmails: rooms ? rooms.map(r => r.email) : [],
      isOnlineMeeting: isOnlineMeeting(),
      isAllDay: false,
      organizerTimeZone
    })
      .then(meeting => {
        MeetingStoreSingleton().addSingle(meeting);
        const dateString = isSameDay(start, end)
          ? `${format(start, "d MMMM HH:mm")}-${format(end, "HH:mm")}`
          : `${format(start, "d MMMM HH:mm")}-${format(end, "d MMMM HH:mm")}`;
        mediator.publish(Channels.OpenStatusMessage, {
          title: "Booked!",
          type: "success",
          timeout: 3000,
          htmlReason: `<span>Your event for <b>${dateString}</b> has been created.</span>`
        });
      })
      .catch(e => {
        handleError(e);
        throw e;
      });
  };

  const getRooms = ({
    attendeesAmount,
    buildingId,
    startDateTime,
    endDateTime,
    userId = null,
    showAllRoomsWithScore = false
  }) => {
    if (showAllRoomsWithScore) {
      const requestObj = {
        internalAttendeeIds: [userId],
        totalAttendees: attendeesAmount,
        buildingId,
        startDateTime,
        endDateTime
      };
      return AppApi.getAllRooms(requestObj).catch(e => handleError(e));
    }
    return AppApi.getAvailableMeetingRooms(
      attendeesAmount,
      buildingId,
      startDateTime,
      endDateTime
    ).catch(e => handleError(e));
  };

  return {
    handleError,
    createMeeting,
    getRooms
  };
};
