import ko from "@shared/knockout/extended";
import { statusIsConfirmedOrPending } from "./statusIsConfirmedOrPending";

export const getGroupDeskIds = workspace => {
  return ko.pureComputed(() => {
    const _workspace = ko.unwrap(workspace);
    if (!_workspace?.deskId) return [];

    return _workspace?.members
      ?.filter(({ status }) => statusIsConfirmedOrPending(status))
      ?.map(({ location: { deskId } }) => deskId);
  });
};
