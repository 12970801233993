import parseISO from "date-fns/parseISO";
import { MeetingAttendeeApiModel } from "./MeetingAttendeeApiModel";

export class SuggestionApiModel {
  constructor(obj) {
    this.timeSlotStart = parseISO(obj.timeSlotStart);
    this.timeSlotEnd = parseISO(obj.timeSlotEnd);
    this.attendeeAvailabilities = obj.attendeeAvailabilities?.map(
      MeetingAttendeeApiModel.fromApiObj
    );
    this.availableAttendeesPercentage = obj.availableAttendeesPercentage;
  }
  static fromApiObj(obj) {
    return new SuggestionApiModel(obj);
  }
}
