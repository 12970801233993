import "./WorkspacePicker.scss";
import template from "./WorkspacePicker.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { NodeVM } from "@app/viewmodels/WorkSpace/NodeVM";

import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { Filters } from "./components/Filters/Filters";
import {
  groupByAvailability,
  isStringEqual
} from "@shared/utils/OfficeDay/helpers";

const WorkspacePickerVM = ({
  selectedOfficeId,
  date,
  selectedLocationId,
  openSummary,
  deskSection,
  lastUsedLocationId,
  openActiveFilterView,
  selectedFloor,
  deepestWorkspaceNodeId,
  userSelectedDifferentNode,
  selectedDeskIds,
  goBack,
  goHome,
  selectedInvitees = ko.observableArray([]),
  increasedNumberOfInvitees = ko.observable(false),
  userChooseLastSelectedWorkdayId = ko.observable(false),
  usePendingOrConfirmedMemberCount,

  //admin
  isLocationRestricted
}) => {
  const allFloors = LocationStoreSingleton().getBuildingForId(
    selectedOfficeId()
  ).floors;

  const groups = ko.pureComputed(() =>
    groupByAvailability(
      allFloors
        .filter(f => !selectedFloor() || isStringEqual(f.name, selectedFloor()))
        .flatMap(floor => {
          const areas = floor.areas;
          if (!areas.length) return floor;
          return areas;
        })
        .map(node =>
          NodeVM({
            date,
            openSummary,
            selectedLocationId,
            lastUsedLocationId,
            deskSection,
            deepestWorkspaceNodeId,
            userSelectedDifferentNode,
            selectedDeskIds,
            goHome,
            node,
            selectedInvitees,
            usePendingOrConfirmedMemberCount,
            increasedNumberOfInvitees,
            userChooseLastSelectedWorkdayId,
            isLocationRestricted
          })
        )
        .sort(
          (a, b) =>
            ko.unwrap(b.colleagues).length - ko.unwrap(a.colleagues).length
        )
    )
  );

  return {
    groups,
    openSummary,

    filterParams: {
      goBack,
      date,
      selectedFloor,
      allFloors,
      openActiveFilterView
    }
  };
};

const WorkspaceComponent = KOComponent(
  "workspace-picker",
  WorkspacePickerVM,
  template
);
export const WorkspacePicker = Object.assign({}, WorkspaceComponent, {
  register: ko => {
    WorkspaceComponent.register(ko);
    Filters.register(ko);
  }
});
