import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";

const LotType = Symbol();
const ZoneType = Symbol();

const toCacheObject = apiParkings => {
  // Create a flat list of [ id, model ]
  // Enrich the models to expose some info about their type
  const allLocations = apiParkings.flatMap(b => [
    [b.id, Tag(b, LotType, b)],
    ...b.parkingZones.flatMap(f => [[f.id, Tag(f, ZoneType, b, f)]])
  ]);

  return Object.fromEntries(allLocations);
};

const ParkingLotStore = () => {
  const cache = ko.observable({});
  const all = cache.map(Object.values);

  const update = parkingLots => {
    cache(toCacheObject(parkingLots));
    return parkingLots;
  };

  const add = parkingLot => {
    const _cache = cache();
    _cache[parkingLot.id] = parkingLot;

    cache.valueHasMutated();
  };

  const locationOfType = type => () =>
    Object.values(cache()).filter(hasTag(type));

  const getParkings = locationOfType(LotType);

  const get = id => cache()[id] || null;
  return {
    all,
    update,
    add,
    dataExists: ko.pureComputed(() => !!Object.keys(cache()).length),
    get,
    getParkings,
    getLocations: getParkings,
    getParkingLotForId: parkingId => {
      const el = get(parkingId);
      return (el && el[LotType]) || null;
    },
    getZoneForId: parkingId => {
      const el = get(parkingId);
      return (el && el[ZoneType]) || null;
    }
  };
};

export const ParkingLotStoreSingleton = Singleton(ParkingLotStore);

const T = Symbol();
const Tag = (obj, tag, b = null, f = null) => ({
  [T]: tag,
  [LotType]: b,
  [ZoneType]: f,
  ...obj
});
const hasTag = tag => obj => obj[T] === tag;
