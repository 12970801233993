import FloorApiModel from "@shared/apimodels/FloorApiModel.js";
import BuildingApiModel from "@shared/apimodels/BuildingApiModel.js";
import WorkAreaApiModel from "@shared/apimodels/WorkAreaApiModel.js";

export const getWorkspaceIcon = loc => {
  switch (loc.type) {
    case WorkAreaApiModel:
      return loc.hasBookableDesks ? "workspaceDesk" : "workspaceArea";
    case FloorApiModel:
      return "workspaceFloor";
    case BuildingApiModel:
      return "workspaceOffice";
    default:
      return null;
  }
};

// We will use this one in case the user have a workspace and the building is removed from the users location profile
export const getWorkspaceIconFromWorkspace = workspace => {
  if (workspace?.deskId) return "workspaceDesk";
  if (workspace?.workAreaId) return "workspaceArea";
  if (workspace?.floorId) return "workspaceFloor";
  if (workspace?.buildingId) return "workspaceOffice";
};

export const WorkspaceAvailability = {
  ANY_DESK: "Any available desk"
};
