import { capitalizeSentence } from "@shared/utils/text-helpers";

//we get this from the server
export const ExtrasTypes = {
  WORKDAY: "Workday",
  HYBRID_MEETINGS: "HybridMeetings",
  USER_PROFILE: "UserProfile"
};

export const ExtrasTypeContent = {
  [ExtrasTypes.WORKDAY]: {
    heading: "Workday extras",
    explanation:
      "This allows users to add extras to their workdays. These extras will be shown on the day page."
  },
  [ExtrasTypes.HYBRID_MEETINGS]: {
    heading: "Event extras",
    explanation:
      "This allows users to add extras to an event. These extras will be shown on an event detail page."
  },
  [ExtrasTypes.USER_PROFILE]: {
    heading: "General extras",
    explanation:
      "This allows users to add general extras that are not related to a specific day or event. These extras will be shown on their profile page."
  }
};

const WorkdayExtrasTypeOptions = [
  {
    displayName: "Book a coworking space",
    name: "BookCoworkingSpace"
  },
  { displayName: "Book a locker", name: "BookLocker" },
  { displayName: "Book lunch", name: "BookLunch" },
  {
    displayName: "Request medical service",
    name: "RequestMedicalService"
  },
  {
    displayName: "Register a visitor",
    name: "RegisterVisitor"
  }
];

const HybridMeetingsExtrasTypeOptions = [
  {
    displayName: "Invite visitors",
    name: "InviteVisitors"
  },
  {
    displayName: "Report a problem",
    name: "ReportProblem"
  },
  {
    displayName: "Request catering",
    name: "RequestCatering"
  },
  {
    displayName: "Request equipment",
    name: "RequestEquipment"
  },
  {
    displayName: "Request seating arrangement",
    name: "RequestSeatingArrangement"
  }
];

const UserProfileExtrasTypeOptions = [
  { displayName: "Control blinds", name: "ControlBlinds" },
  {
    displayName: "Manage emergency contact",
    name: "ManageEmergencyContact"
  },
  {
    displayName: "Manage license plate",
    name: "ManageLicensePlate"
  },
  {
    displayName: "Report a problem",
    name: "ReportProblem"
  },
  {
    displayName: "Request support",
    name: "RequestSupport"
  }
];

export const ExtrasTypeOptions = {
  [ExtrasTypes.WORKDAY]: WorkdayExtrasTypeOptions,
  [ExtrasTypes.HYBRID_MEETINGS]: HybridMeetingsExtrasTypeOptions,
  [ExtrasTypes.USER_PROFILE]: UserProfileExtrasTypeOptions
};

/**
 *
 * @param {string} name Based on the server name of the icon, a suitable name is found to display on the UI
 * @param {string} extrasType Depending on which type the extra belongs to, the function returns the appropriate name to display on the UI
 * @returns {string} The name of the extra to be displayed on the UI
 */
export const getExtraDisplayName = (name, extrasType) => {
  const extraOption = ExtrasTypeOptions[extrasType].find(
    opt => opt.name === name
  );
  //This should not happen. Only if the names are not aligned between FE and BE
  return extraOption?.displayName;
};

/**
 *
 * @param {string} name Extra name that corresponds to the name received from the server
 * @returns {string} Since the name from the server is in the `PascalCase` convention, we convert it to `camelCase` (since the icons in the `IconIndex.js` are defined according to the `camelCase` convention)
 */
export const getExtraIconName = name => capitalizeSentence(name, false);
