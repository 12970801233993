import "./BookRoom.scss";
import template from "./BookRoom.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { differenceInMinutes, format } from "date-fns";
import { BookRoomSummary } from "./BookRoomSummary/BookRoomSummary";
import { getAppFeatures } from "@app/services/AppFeatures";
import { FeatureNames } from "@shared/services/Features";
import {
  RoomScheduleState,
  calculateDynamicFreeTimeslot
} from "@app/utils/RoomStates";
import { intervalToFormattedDuration } from "@shared/utils/durationHelpers";

const BookRoomVM = ({
  bookRoomParams,
  showSidePanelCloseButton,
  mediator = Mediator()
}) => {
  //This picker needed to be bound to a route to be opened by various components in the app
  //(not all of them already in the side panel) but cannot be access by browser pop-state.
  //This redirects to main page if user tries to access from a bookmark / refresh.
  //It's ugly but it works (for now)
  const renderScreen = ko.observable(true);
  if (!ko.unwrap(bookRoomParams)) {
    mediator.publish(Channels.CloseBookRoom, () => {
      UrlNavigationSingleton().navigate(null, null);
    });
    renderScreen(false);
    return { renderScreen };
  }
  const { room, date, startTime, endTime } = bookRoomParams();
  showSidePanelCloseButton(true);

  const isHereAndNowV2 = getAppFeatures().has(FeatureNames.HERE_NOW_V2);

  const showBookRoomSummary = ko.observable(null);

  showBookRoomSummary.subscribe(newValue => {
    if (!newValue) {
      const scrollElement = document.getElementsByClassName("SidePanel-body");
      scrollElement[0].scrollTo(0, 0);
    }
  });

  const hybridMeetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const { userHasOptedIn } = getAppFeatures().has(hybridMeetingsFeature)
    ? getAppFeatures().get(hybridMeetingsFeature)
    : false;
  const isBookable = !!room.email && userHasOptedIn;

  const isFree =
    !isHereAndNowV2 ||
    room.scheduleAvailability === RoomScheduleState.Available;

  const requiredDuration = differenceInMinutes(endTime, startTime);

  const dynamicFreeTimeslot = calculateDynamicFreeTimeslot(
    room.scheduleInformation,
    room.state,
    startTime,
    requiredDuration
  );

  const actualStartTime = dynamicFreeTimeslot?.start ?? startTime;
  const actualEndTime = dynamicFreeTimeslot?.end ?? endTime;

  const isDurationShortened =
    differenceInMinutes(actualEndTime, actualStartTime) < requiredDuration;
  const actualDurationFormatted = intervalToFormattedDuration(
    actualStartTime,
    actualEndTime,
    true
  );

  const disableBookButton = isHereAndNowV2 && !isFree && !dynamicFreeTimeslot;

  const formatToUse = isHereAndNowV2 ? "HH:mm" : "eeee d MMMM HH:mm";

  const bookingInfo = `${format(actualStartTime, formatToUse)} - ${format(
    actualEndTime,
    "HH:mm"
  )}`;
  const bookingInfoDuration = isHereAndNowV2
    ? ` (${actualDurationFormatted})`
    : "";

  const onClick = () => {
    if (disableBookButton) return;
    showBookRoomSummary(true);
  };

  const bookRoomSummaryParams = {
    showSidePanelCloseButton,
    showBookRoomSummary,
    bookingInfo,
    room,
    startTime: actualStartTime,
    endTime: actualEndTime,
    date
  };

  return {
    room,
    date,
    bookingInfo,
    bookingInfoDuration,
    renderScreen,
    bookRoomSummaryParams,
    onClick,
    showBookRoomSummary,
    isBookable,
    isFree,
    disableBookButton,
    isDurationShortened
  };
};

const bookRoomComponent = KOComponent("book-room", BookRoomVM, template);

export const BookRoom = {
  ...bookRoomComponent,
  register: ko => {
    bookRoomComponent.register(ko);
    BookRoomSummary.register(ko);
  }
};
