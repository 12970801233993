export class InviteeSearchApiModel {
  constructor(apiObj) {
    const cleanedName = (apiObj.name || "").trim();
    this.imageHash = apiObj.imageHash;
    this.id = apiObj.id;
    this.directoryObjectId = apiObj.directoryObjectId;
    this.name = cleanedName || null;
    this.email = apiObj.email;
    this.initials = apiObj.initials;
  }
}
