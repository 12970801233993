import bodyTemplate from "./ExtrasWizard.template.html";
import "./ExtrasWizard.scss";
import { PopupManagerVM } from "@app/components/PopupManager/PopupManagerVM";

export const ExtrasWizardVM = ({ title, extraVMs }) => {
  return { extraVMs, title };
};

const { getTitle, hasCloseButton } = PopupManagerVM.Keys;

Object.assign(ExtrasWizardVM, {
  [getTitle]: vm => vm.title,
  [hasCloseButton]: () => true
});

export const ExtrasWizard = {
  register: ko => {
    ko.components.register("extras-wizard-body", {
      template: bodyTemplate
    });
  }
};
