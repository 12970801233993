export class MeetingRoomApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.displayName = obj.displayName;
    this.email = obj.email;
    this.capacity = obj.capacity;
    this.activities = obj.activities;
    this.equipment = obj.equipment;
    this.buildingName = obj.buildingName;
    this.floorName = obj.floorName;
    this.buildingId = obj.buildingId;
    this.imageHash = obj.roomPictureHash;
    this.floorId = obj.floorId;
  }

  static fromApiObj(obj) {
    return new MeetingRoomApiModel(obj);
  }
}
