import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import template from "./OfficesClosedTile.template.html";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";

const OfficesClosedTileVM = ({ dayVM, visible }) => {
  const checkInService = CheckInServiceSingleton();
  const loading = checkInService.loading;
  const checkInEnabled = isCheckInFeatureEnabled();
  const tileText = ko.pureComputed(() =>
    dayVM.isSingleBuilding() ? "The office is closed" : "All offices are closed"
  );
  const checkInStatusSelectorParams = {
    dayVM,
    output: newCheckInStatus => {
      if (newCheckInStatus === CheckInStatus.NotAtTheOffice)
        checkInService.checkOut(checkInService.buildingId());
    }
  };
  return {
    visible,
    tileText,
    checkInEnabled,
    loading,
    checkInStatusSelectorParams,
    hasCheckInStatus: checkInService.hasCheckInStatus
  };
};

export const OfficesClosedTile = KOComponent(
  "offices-closed-tile",
  OfficesClosedTileVM,
  template
);
