import template from "./CheckInTile.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import {
  CheckInStatus,
  CheckInStatusLabel
} from "@shared/services/CheckInStatus";
import "./CheckInTile.scss";
import { isManualCheckInFeatureEnabled } from "@app/utils/checkIn";
const CheckInTileVM = ({ booked, buildingId }) => {
  const checkInService = CheckInServiceSingleton();
  const checkInStatus = checkInService.status;
  const checkInBuildingId = checkInService.buildingId;
  const loading = checkInService.loading;
  const checkInBuildingName = checkInService.buildingName;

  const userHasCheckedInDifferentBuilding = ko.pureComputed(() =>
    checkInService.isCheckedInDifferentOffice(new Date(), buildingId())
  );

  const statusToShow = ko.pureComputed(() => {
    if (userHasCheckedInDifferentBuilding())
      return `At ${checkInBuildingName() ?? "unknown office"}`;

    // Remove this line after relase
    if (checkInStatus() === CheckInStatus.NotCheckedInYet)
      return "Not checked in";
    return CheckInStatusLabel[checkInStatus()];
  });

  const showBlackButton = ko.pureComputed(
    () => booked() && !checkInService.hasCheckInStatus()
  );
  return {
    userHasCheckedInDifferentBuilding,
    booked,
    checkInStatus: ko.pureComputed(() =>
      userHasCheckedInDifferentBuilding()
        ? CheckInStatus.AtAnotherOffice
        : checkInStatus()
    ),
    statusToShow,
    showBlackButton,
    loading,
    disableButton: ko.pureComputed(() => {
      if (!isManualCheckInFeatureEnabled()) return true;

      return (
        (!booked() && checkInStatus() === CheckInStatus.NotAtTheOffice) ||
        loading()
      );
    }),
    buttonText: ko.pureComputed(() => {
      if (
        checkInStatus() === CheckInStatus.NotCheckedInYet ||
        checkInStatus() === CheckInStatus.NotAtTheOffice
      )
        return buttonText.CheckIn;
      return buttonText.CheckOut;
    }),
    onClick: () => {
      if (!isManualCheckInFeatureEnabled()) return;

      if (checkInStatus() !== CheckInStatus.AtTheOffice) {
        checkInService.checkIn(buildingId());
      } else checkInService.checkOut(checkInBuildingId());
    }
  };
};

export const CheckInTile = KOComponent(
  "check-in-tile",
  CheckInTileVM,
  template
);

const buttonText = {
  CheckIn: "Check in",
  CheckOut: "Check out"
};
