import { ExceptionStoreSingleton } from "@shared/services/ExceptionsStore.js";
import { sum } from "@shared/utils/sum";
import ko from "@shared/knockout/extended";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { AvailabilityDataVM } from "./AvailabilityDataVM";
import { AvailabilityStoreSingleton } from "@shared/services/AvailabilityStore";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { startOfDay } from "date-fns";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";

//Or should we prompt the use to refresh after a while?)
export const availabilityLocationData = (
  day,
  place,
  newMemberCount = 1,
  usePendingOrConfirmedMemberCount = false
) => {
  // For the workday we already have support hours and the current code for availability support start of the day, so this is fixed until we start working with multiple workday project
  const formatedDay = startOfDay(day);
  const exceptionStore = ExceptionStoreSingleton();
  const locationStore = LocationStoreSingleton();
  const currentBuilding = locationStore.getBuildingForId(place.id);

  const workspaceService = WorkspaceServiceSingleton();

  const groupDeskReservationsEnabled = getFeatures().has(
    FeatureNames.GROUP_DESK_RESERVATIONS
  );

  const _pendingOrConfirmedMemberCount =
    (usePendingOrConfirmedMemberCount &&
      workspaceService
        .getConfirmedForDate(formatedDay)[0]
        ?.members.filter(
          member =>
            member.status === ReservationStatus.Confirmed ||
            member.status === ReservationStatus.InvitationPending
        ).length) ||
    0;

  const getChildren = (node, skipNode) => {
    const children = node.floors || node.workAreas || node.areas || [];

    return skipNode
      ? children.flatMap(c => getChildren(c, false))
      : [node, ...children.flatMap(c => getChildren(c, false))];
  };

  const getLeafNodes = node => {
    const children =
      node.floors?.flatMap(floor => {
        const areas = floor.areas;
        if (!areas.length) return floor;
        return areas;
      }) ||
      node.workAreas ||
      node.areas ||
      [];

    if (!children?.length) return [node];
    return children;
  };

  // Used to calculate to total capacity of unavailable nodes
  // when they're closed.
  const capacityUnavailableDueToClosedNodes = ko.pureComputed(() => {
    return getChildren(place, true)
      .map(c =>
        exceptionStore.nodeIsClosed(exceptionStore, formatedDay, c, true)
          ? c.capacity
          : 0
      )
      .reduce(sum, 0);
  });

  const availabilityData = AvailabilityDataVM(
    place,
    currentBuilding,
    AvailabilityStoreSingleton(),
    formatedDay,
    exceptionStore,
    capacityUnavailableDueToClosedNodes,
    _pendingOrConfirmedMemberCount
  );

  const unavailableForGroupBookingDueToAvailability = ko.pureComputed(
    () =>
      newMemberCount > 1 &&
      getLeafNodes(place).every(
        node =>
          availabilityLocationData(
            formatedDay,
            node,
            newMemberCount,
            usePendingOrConfirmedMemberCount
          ).availability() < newMemberCount
      )
  );

  const unavailableForGroupBookingDueToDeskBookingOnly = ko.pureComputed(
    () =>
      !groupDeskReservationsEnabled &&
      newMemberCount > 1 &&
      getLeafNodes(place).every(n => n.hasBookableDesks || n.capacity === 0)
  );

  const groupAvailabilityLabel = ko.pureComputed(() =>
    unavailableForGroupBookingDueToDeskBookingOnly()
      ? "No group booking"
      : unavailableForGroupBookingDueToAvailability()
      ? "Not enough available desks"
      : ""
  );

  return {
    ...availabilityData,
    capacityUnavailableDueToClosedNodes,
    unavailableForGroupBookingDueToAvailability,
    unavailableForGroupBookingDueToDeskBookingOnly,
    groupAvailabilityLabel
  };
};
