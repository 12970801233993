import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./NowPage.template.html";
import "./NowPage.scss";
import ko from "@shared/knockout/extended";

import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { getAppFeatures } from "@app/services/AppFeatures";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { CheckInServiceSingleton } from "@app/services/CheckInService.js";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { set } from "date-fns";
import { OfficeDayVM } from "@app/viewmodels/OfficeDayVM";
import { MeetApi } from "@shared/services/MeetApi";
import { enable, handleError } from "@app/utils/meetConsent";
import { toasterBinding } from "../CalendarView/toasterBindings";

export const NowPageVM = () => {
  const now = set(new Date(), { seconds: 0, milliseconds: 0 });

  const userOfficeDay = ko.pureComputed(() => OfficeDayVM(now));

  const locationStore = LocationStoreSingleton();
  const checkInService = CheckInServiceSingleton();
  const isCheckInFeatureEnabled = getAppFeatures().has(FeatureNames.CHECK_IN);
  const buildings = locationStore.getBuildings();

  const buildingId =
    isCheckInFeatureEnabled &&
    checkInService.status() === CheckInStatus.AtTheOffice &&
    buildings.some(b => b.id === checkInService.buildingId())
      ? checkInService.buildingId()
      : userOfficeDay() &&
        buildings.some(b => b.id === userOfficeDay().buildingId)
      ? userOfficeDay().buildingId
      : locationStore.getPreferredBuilding()?.id ?? buildings[0].id;
  const building = locationStore.get(buildingId);

  const meetingRoomParams = {
    hasManualRoomSelection: getFeatures().has(
      FeatureNames.MANUAL_ROOM_SELECTION
    )
  };

  const selectedFloor = ko.observable(null);
  const selectedRange = ko.observable(null);
  const selectedBuilding = ko.observable(building.id);
  const startTime = new Date();
  const durations = [
    {
      label: "15 minutes",
      value: 15
    },
    {
      label: "30 minutes",
      value: 30
    },
    {
      label: "45 minutes",
      value: 45
    },
    {
      label: "1 hour",
      value: 60
    },
    {
      label: "1 hour 30 minutes",
      value: 90
    },
    {
      label: "2 hours",
      value: 120
    }
  ];

  const roomsListParams = {
    selectedBuilding,
    selectedFloor,
    selectedRange,
    filterOutSelectedRooms: () => true,
    startTime,
    meetingRoomParams,
    durations,
    hasDoubleAction: false,
    isHereNowPage: true
  };

  const hybridMeetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const { enabledForUsers, userHasOptedIn } = getAppFeatures().has(
    hybridMeetingsFeature
  )
    ? getAppFeatures().get(hybridMeetingsFeature)
    : { enabledForUsers: false, userHasOptedIn: false };
  const hideConsentCTA = sessionStorage.getItem("hideConsentCTA") === "true";
  const hasOccupancy =
    getAppFeatures().has(FeatureNames.OCCUPANCY_SENSOR) &&
    getAppFeatures().has(FeatureNames.HERE_NOW_V2);
  const showConnectCalendarPrompt = enabledForUsers && !userHasOptedIn;
  const showRooms = userHasOptedIn || hasOccupancy;
  const showMainCalendarPrompt = showConnectCalendarPrompt && !hasOccupancy;
  const showTileCalendarPrompt = ko.observable(
    showConnectCalendarPrompt && hasOccupancy && !hideConsentCTA
  );

  const connectCalendarParams = {
    title: "Do you want to find an available room?",
    subtitle:
      "Connect your calendar and see which rooms are available in your office"
  };

  const meetingConsentTileParams = {
    title: "Sync your calendar.",
    subTitle:
      "See all available rooms and easily book a space that fits your needs.",
    iconName: "hybridMeetingConsent",
    buttonText: "Connect",
    showLoading: true,
    onPrimaryButtonClick: () => enable(),
    handleError: err => handleError(err),
    onClose: () => {
      MeetApi.setUserPreference({
        userClosedConsentTile: true
      });
      sessionStorage.setItem("hideConsentCTA", "true");
    }
  };

  return {
    title: building.name,
    roomsListParams,
    showMainCalendarPrompt,
    showRooms,
    connectCalendarParams,
    meetingConsentTileParams,
    showTileCalendarPrompt
  };
};

const nowPageComponent = KOComponent("now-page", NowPageVM, template);

export const NowPage = {
  ...nowPageComponent,
  register: ko => {
    nowPageComponent.register(ko);
    ko.bindingHandlers.toaster = toasterBinding;
  }
};
