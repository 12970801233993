import ko from "@shared/knockout/extended";
import { ParkingLotVM } from "@app/viewmodels/Parking/ParkingLotVM";
import { ParkingReservationVM } from "@app/viewmodels/Parking/ParkingReservationVM";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { ParkingLotStoreSingleton } from "@app/services/ParkingLotStore";
import { getISOWeek, addDays, differenceInDays, startOfDay } from "date-fns";
import { pl } from "@shared/utils/pluralize";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { FeatureNames, getFeatures } from "@shared/services/Features";

export const ParkingLotDataVM = (
  shift,
  date,
  parkingQuotaProfile,
  workspaceQuotaProfile
) => {
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const parkingLotStore = ParkingLotStoreSingleton();

  const parkingLotsExist = parkingLotStore.dataExists;
  const parkingReservation = ko
    .pureComputed(() => parkingReservationsStore.getForDate(date))
    .map(getFirstElement)
    .maybeMap(ParkingReservationVM);

  const parkingLots = ko
    .pureComputed(() => parkingLotStore.getParkings())
    .mapArray(p => ParkingLotVM(p, date));

  const parkingLotsAreEmpty = ko.pureComputed(() =>
    parkingLots().every(parking => parking.capacity === 0)
  );

  const allParkingLotsClosed = ko.pureComputed(() =>
    parkingLots().every(parking => parking.closed())
  );
  const allParkingSpotsUnavailable = ko.pureComputed(() =>
    parkingLots().every(
      parking => (parking.full() || parking.closed()) && !allParkingLotsClosed()
    )
  );

  const weekNr = getISOWeek(date);
  const parkingReservationsThisWeek = ko.pureComputed(
    () => parkingReservationsStore.getForWeekNr(weekNr).length
  );

  const max = parkingQuotaProfile.daysPerWeek;
  const maxReached = parkingReservationsThisWeek.map(count => count >= max);

  const isTooFarAhead = ko.pureComputed(() => {
    if (!hasWorkdayFeature) return false;
    const lastBookableDay = addDays(
      startOfDay(new Date()),
      workspaceQuotaProfile.daysAhead
    );
    const dayDiff = differenceInDays(lastBookableDay, date);
    return dayDiff < 0;
  });

  const parkingLabel = ko.pureComputed(() => {
    if (shift) {
      if (isTooFarAhead()) return "You can’t book this far ahead";

      if (!parkingLotsExist()) return "Parking is not available here";

      if (parkingLotsExist() && allParkingSpotsUnavailable())
        return "No parking spots available";

      if (parkingLotsExist() && allParkingLotsClosed())
        return "All parking lots are closed";
    }
    if (maxReached()) return "Quota has been reached";

    if (
      !shift ||
      (!parkingReservation() &&
        parkingLotsExist() &&
        !allParkingLotsClosed() &&
        !allParkingSpotsUnavailable() &&
        !maxReached())
    )
      return "Book a parking spot";
  });

  const numberOfBookings = ko.pureComputed(
    () => max - parkingReservationsThisWeek()
  );
  const bookingsLeft = ko.pureComputed(() =>
    parkingLotsExist() &&
    shift &&
    !parkingReservation() &&
    !allParkingLotsClosed() &&
    !allParkingSpotsUnavailable() &&
    !isTooFarAhead() &&
    !maxReached()
      ? `${numberOfBookings()} ${pl(
          "booking",
          numberOfBookings()
        )} left this week`
      : null
  );

  const disableParkingAction = ko.pureComputed(
    () =>
      !parkingReservation() &&
      (!parkingLotsExist() ||
        !shift ||
        parkingLotsAreEmpty() ||
        allParkingLotsClosed() ||
        maxReached() ||
        allParkingSpotsUnavailable() ||
        isTooFarAhead())
  );

  return {
    bookingsLeft,
    userCanBook:
      parkingLotsExist() &&
      !parkingLotsAreEmpty() &&
      !allParkingLotsClosed() &&
      !allParkingSpotsUnavailable() &&
      !maxReached() &&
      !isTooFarAhead(),
    parkingReservation,
    disableParkingAction,
    parkingLabel,

    parkingLotName: ko.pureComputed(
      () => parkingReservation()?.parkingLotName ?? null
    ),
    parkingZoneName: ko.pureComputed(
      () => parkingReservation()?.zoneName ?? null
    )
  };
};
