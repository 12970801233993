import template from "./HomeViewer.template.html";
import "./HomeViewer.scss";
export const HomeViewerVM = ({
  showWelcomePage,
  showCalendarView,
  showBuildingPicker,
  showNoBuildingsView,
  showNewFeatureAnnouncementView,
  calendarView,
  user,
  settings,
  preferredLocationId,
  workingHours,
  isNew,
  newFeatureAnnouncementParams
}) => {
  return {
    showWelcomePage,
    showCalendarView,
    showBuildingPicker,
    showNoBuildingsView,
    showNewFeatureAnnouncementView,
    calendarView,
    user,
    settings,
    preferredLocationId,
    workingHours,
    isNew,
    newFeatureAnnouncementParams
  };
};
export const HomeViewer = {
  register: ko => {
    ko.components.register("homeViewer-body", {
      template: template
    });
  },

  bodyComponentName: "homeViewer-body"
};
