import ko from "@shared/knockout/extended";
import { format } from "date-fns";

export const AvailabilityDataVM = (
  place,
  currentPlace,
  availabilityStore,
  day,
  exceptionStore = null,
  capacityUnavailableDueToClosedNodes = 0,
  pendingOrConfirmedMemberCount = 0
) => {
  const alwaysClosedOnThisDay = !currentPlace.openingDays.includes(
    format(day, "eeee")
  );
  const closedDueToException = ko.pureComputed(() =>
    exceptionStore
      ? exceptionStore.nodeIsClosed(exceptionStore, day, place)
      : false
  );

  const closed = ko.pureComputed(
    () => alwaysClosedOnThisDay || closedDueToException()
  );

  const numberOfReservedPlaces = Math.min(
    Math.max(
      0,
      availabilityStore.get(day, place.id) - pendingOrConfirmedMemberCount
    ),
    place.capacity
  );

  const availability = ko.pureComputed(() => {
    return (
      place.capacity -
      ko.unwrap(capacityUnavailableDueToClosedNodes) -
      numberOfReservedPlaces
    );
  });

  const percentageBooked = ko.pureComputed(
    () =>
      (numberOfReservedPlaces +
        ko.unwrap(capacityUnavailableDueToClosedNodes)) /
      place.capacity
  );
  const percentageAvailable = ko.pureComputed(() => 1 - percentageBooked());

  const availabilityLabel = closed.map(isClosed =>
    isClosed
      ? "Office is closed"
      : availability() > 0
      ? `${availability()}/${place.capacity}`
      : "Full"
  );

  const full = ko.pureComputed(() => availability() === 0);

  const perc = ko.pureComputed(() =>
    Math.max(0, Math.min(1, percentageAvailable()))
  );

  const colorClass = ko.pureComputed(() => {
    if (isNaN(perc())) return "crowded";
    if (percentageAvailable() > 0.7) return "quiet";
    else if (percentageAvailable() > 0.3) return "regular";
    else return "crowded";
  });

  const percentage = ko.pureComputed(() => (isNaN(perc()) ? 1 : perc()));
  return {
    availability,
    availabilityLabel,
    percentage,
    full,
    closed,
    colorClass
  };
};
