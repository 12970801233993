import template from "./WorkDayOverview.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { format, startOfDay, isBefore, isToday } from "date-fns";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { UserApp } from "@app/UserApp";
import { shiftActionButtonVM } from "@app/utils/shiftActionButton";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { WorkDayOverviewTabs } from "./WorkDayOverviewTabs/WorkDayOverviewTabs";
import { CheckInTile } from "@app/components/CheckInTile/CheckInTile";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { CheckInStatusSourceType } from "@shared/services/CheckInStatus";
import {
  isCheckInFeatureEnabled,
  isManualCheckInFeatureEnabled
} from "@app/utils/checkIn";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { WorkdayStatusTile } from "./WorkDayStatusTile/WorkDayStatusTile";
import { WorkDayInviteTile } from "./WorkDayInviteTile/WorkDayInviteTile";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import "./WorkDayOverview.scss";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";

const WorkDayOverviewVM = ({ settings, user, day }) => {
  const workspaceService = WorkspaceServiceSingleton();
  const urlNavigation = UrlNavigationSingleton();
  const workdayStore = WorkdayReservationsStoreSingleton();
  const profile = UserApp.resolveUser().accessProfile;
  const now = startOfDay(new Date());

  const date = format(day, "EEEE d MMMM");
  const inPast = isBefore(day, now);

  const workspace = ko
    .pureComputed(() => workspaceService.getConfirmedForDate(day))
    .map(getFirstElement)
    .maybeMap(WorkspaceVM);

  const workday = ko
    .pureComputed(() => workdayStore.getForDate(day))
    .map(getFirstElement);

  const workspaceActionButton = ko.pureComputed(() =>
    shiftActionButtonVM(
      day,
      settings,
      profile,
      now,
      workspace,
      inPast,
      workday()?.nodeId,
      true
    )
  );
  const workdayBooked = ko.pureComputed(
    () => workday() && workday().status === WorkdayStatus.OfficeDay
  );
  const workspaceBooked = workspace.map(Boolean);

  const workspaceAction = () => {
    if (workspaceActionButton()?.disableShiftButton()) return;
    if (workspaceBooked())
      urlNavigation.navigate("", "overview", [
        format(day, "yyyy-MM-dd"),
        "workspace"
      ]);
    else workspaceActionButton()?.bookShift();
  };

  const hasGroupInvitationsFeature = getFeatures().has(
    FeatureNames.GROUP_RESERVATIONS
  );

  const groupInvitationsParams = {
    user,
    day,
    currentWorkspace: workspace
  };

  return {
    loadingWorkdayReservation: WorkdayReservationServiceSingleton().loading,
    workdayBooked,
    workspaceBooked,
    workday,
    workspace,
    booked: ko.pureComputed(() => workdayBooked() || workspaceBooked()),
    user,
    profile,
    workspace,
    day,
    date,
    workspaceAction,
    workspaceActionButton: workspaceActionButton(),
    inPast,
    buildingId: ko.pureComputed(
      () => workday()?.nodeId ?? workspace()?.buildingId ?? null
    ),
    showCheckInTile: ko.pureComputed(() => {
      const checkInService = CheckInServiceSingleton();
      const isAutomatic =
        checkInService.sourceType() === CheckInStatusSourceType.Automatic;

      return (
        getFeatures().has(FeatureNames.WORK_DAY) &&
        isToday(day) &&
        ((isManualCheckInFeatureEnabled() &&
          (workdayBooked() || checkInService.hasCheckInStatus())) ||
          (isCheckInFeatureEnabled() &&
            (checkInService.hasCheckInStatus() ||
              (isAutomatic && checkInService.hasCheckInStatus()))))
      );
    }),

    hasGroupInvitationsFeature,
    groupInvitationsParams
  };
};

const MainCalendarView = KOComponent(
  "work-day-overview",
  WorkDayOverviewVM,
  template
);
export const WorkDayOverview = {
  register: ko => {
    MainCalendarView.register(ko);
    CheckInTile.register(ko);
    WorkDayOverviewTabs.register(ko);
    WorkdayStatusTile.register(ko);
    WorkDayInviteTile.register(ko);
  }
};
