import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";

import {
  CheckInStatus,
  CheckInStatusLabel
} from "@shared/services/CheckInStatus";

export const determineStatusToSend = (dayVM, hasShift) => {
  const checkInService = CheckInServiceSingleton();
  const checkInStatus = checkInService.status();

  const userHasCheckedInDifferentBuilding =
    checkInStatus !== CheckInStatus.NotAtTheOffice &&
    dayVM.buildingId() !== checkInService.buildingId();

  const checkInStatusToSend =
    dayVM.booked() && userHasCheckedInDifferentBuilding
      ? CheckInStatusLabel[CheckInStatus.AtAnotherOffice]
      : CheckInStatusLabel[checkInStatus];

  publishAIEvent(EventNames.ClickTodayTile, {
    shift: hasShift,
    checkInStatusToSend
  });
};
