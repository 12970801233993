import { Singleton } from "@shared/utils/Singleton.js";
import { byPropAsc } from "@shared/utils/sort";
import { Store } from "@shared/utils/Store.js";

export const TimezoneStore = () => {
  const store = Store(tz => tz.timeZoneId);

  return {
    ...store,
    all: store.all.sort(byPropAsc("timeZoneId"))
  };
};

export const TimezoneStoreSingleton = Singleton(TimezoneStore);
