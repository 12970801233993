export class UserAttributesModel {
  constructor(apiObj) {
    this.userAttributeName = apiObj.userAttributeName;
    this.userAttributeDictionary = apiObj.userAttributeDictionary;
    this.dataExist = Object.keys(apiObj.userAttributeDictionary).length
      ? true
      : false;

    this.label =
      apiObj.userAttributeName === "HomeBase"
        ? "Office"
        : apiObj.userAttributeName === "JobTitle"
        ? "Job Title"
        : apiObj.userAttributeName === "BusinessUnit"
        ? "Business Unit"
        : apiObj.userAttributeName;
  }
  static fromApiObj(apiObj) {
    return new UserAttributesModel(apiObj);
  }
}
