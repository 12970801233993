export const CheckInStatus = {
  AtTheOffice: "AtTheOffice",
  NotAtTheOffice: "NotAtTheOffice",
  AtAnotherOffice: "AtAnotherOffice",
  NotCheckedInYet: "NotCheckedInYet"
};
export const CheckInStatusLabel = {
  [CheckInStatus.AtTheOffice]: "At the office",
  [CheckInStatus.NotAtTheOffice]: "Not at the office",
  [CheckInStatus.AtAnotherOffice]: "At another office",
  [CheckInStatus.NotCheckedInYet]: "Not checked in yet"
};

export const CheckInStatusSourceType = {
  Manual: "Manual",
  Automatic: "Automatic"
};
export const CheckInStatusSourceTypeLabel = {
  [CheckInStatusSourceType.Manual]: "Manually",
  [CheckInStatusSourceType.Automatic]: "Automatically"
};

const statusOrder = {
  [CheckInStatus.AtTheOffice]: 0
};

export const compareStatus = (a, b) => {
  const scoreA = statusOrder[a] ?? Infinity;
  const scoreB = statusOrder[b] ?? Infinity;

  return scoreA > scoreB ? 1 : scoreA < scoreB ? -1 : 0;
};
