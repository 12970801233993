import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { AppApi } from "./AppApi";
import { Singleton } from "@shared/utils/Singleton";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { WorkspaceServiceSingleton } from "./WorkspaceService";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import ko from "@shared/knockout/extended";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";
import { ParkingReservationStoreSingleton } from "./ParkingReservationStore";
import { formatDatesFromParams } from "@shared/utils/OfficeDay/dateHelper";

const WorkdayReservationService = () => {
  const workdayReservationStore = WorkdayReservationsStoreSingleton();
  const workspaceService = WorkspaceServiceSingleton();
  const loading = ko.observable(false);
  const api = AppApi;

  const getWorkdays = () => {
    if (!getFeatures().has(FeatureNames.WORK_DAY)) return Promise.resolve();

    return api
      .getWorkdays()
      .then(workdayReservationStore.update)
      .catch(e => {
        throw e;
      });
  };

  const getWorkdayForSingleDay = day => {
    if (!getFeatures().has(FeatureNames.WORK_DAY)) return Promise.resolve();

    return api
      .getWorkdayForSingleDay(day)
      .then(workday => {
        if (workday) workdayReservationStore.add(workday);
      })
      .catch(e => {
        throw e;
      });
  };

  const createWorkday = workday => {
    loading(true);
    return api
      .createWorkdayReservation(formatDatesFromParams(workday))
      .then(workdayReservationStore.add)
      .catch(e => {
        throw e;
      })
      .finally(() => loading(false));
  };

  const updateWorkday = (id, newWorkday, workspace, day) => {
    loading(true);
    return api
      .updateWorkdayReservation(id, newWorkday)
      .then(newWorkday => {
        const parkingReservationStore = ParkingReservationStoreSingleton();
        const parkingReservation = parkingReservationStore.getForDate(day)[0];

        workdayReservationStore.add(newWorkday);

        if (workspace) {
          workspaceService.remove(workspace.id);
        }

        if (
          newWorkday.status !== WorkdayStatus.OfficeDay &&
          parkingReservation
        ) {
          parkingReservationStore.remove(parkingReservation.id);
        }
      })
      .catch(e => {
        throw e;
      })
      .finally(() => loading(false));
  };

  const handleWorkdayReservation = (status, day, id = null) => {
    const mediator = Mediator();
    const workday = workdayReservationStore.getForDate(day)[0];
    const workspace = workspaceService.getConfirmedForDate(day)[0];

    const onClose = () => mediator.publish(Channels.CloseWorkdayWizard);

    const buildingName = workspace?.officeName || workday?.nodeName;

    const handleUpdate = () => {
      updateWorkday(
        workday.id,
        { status, nodeId: id ?? null },
        workspace,
        day
      ).catch(() => {
        mediator.publish(Channels.OpenStatusMessage, {
          type: "failure",
          reason: "Something went wrong. Please try again"
        });
      });
      onClose();
    };

    if (workday) {
      if (workday.status === WorkdayStatus.OfficeDay && workspace) {
        openDeleteBookingPopup(buildingName, onClose, handleUpdate);
      } else {
        handleUpdate();
      }
    } else {
      createWorkday({
        startDate: day,
        endDate: day,
        status,
        nodeId: id ?? null
      }).catch(() => {
        mediator.publish(Channels.OpenStatusMessage, {
          type: "failure",
          reason: "Something went wrong. Please try again"
        });
      });
      onClose();
    }
  };
  return {
    loading,
    getWorkdays,
    handleWorkdayReservation,
    getWorkdayForSingleDay
  };
};

export const WorkdayReservationServiceSingleton = Singleton(
  WorkdayReservationService
);

const openDeleteBookingPopup = (buildingName, onClose, handleUpdate) => {
  Mediator().publish(Channels.ToggleConfirmationWindow, {
    options: {
      title: "Delete your bookings?",
      subtitle: `If you're not going to ${buildingName} we will make your reservations available to others.`,
      primaryButtonText: "Delete",
      secondaryButtonText: "Cancel",
      primaryButtonClass: "MpqButton--red",
      secondaryButtonClass: "MpqButton--outlineBlack",
      smallerSubText: true,
      buttonInColumnOrder: true,
      onPrimaryButtonClick: () => {
        handleUpdate();
        return Promise.resolve();
      },
      onSecondaryButtonClick: onClose
    }
  });
};
