export const _Mediator = function() {
  const subscriptions = {};
  return {
    publish: (channel, data) => {
      const subscribers = subscriptions[channel];
      if (subscribers) {
        subscribers.forEach(callback => callback(data));
      }
    },
    subscribe: (channel, callback) => {
      if (subscriptions[channel]) {
        subscriptions[channel].push(callback);
      } else {
        subscriptions[channel] = [callback];
      }
    },
    unsubscribe: (channel, callback) => {
      if (!subscriptions[channel]) throw "Channel does not exist";
      if (!subscriptions[channel].includes(callback))
        throw "Channel does not include callback";

      subscriptions[channel] = subscriptions[channel].filter(
        cb => cb !== callback
      );
    }
  };
};

/** returns singleton instance of mediator */
export const Mediator = (function() {
  let instance = null;

  return () => {
    if (!instance) instance = _Mediator();

    return instance;
  };
})();
