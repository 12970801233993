import {
  ConnectionDirection,
  ConnectionStatus
} from "../components/popups/ConnectionsPage/ConnectionStatus";
import { initialsForName } from "../../shared/utils/avatar-helpers";
import ko from "@shared/knockout/extended";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";

export const SearchResultVM = (
  apiModel,
  connectionStore,
  api,
  mediator = Mediator()
) => {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const pairedConnection = ko.pureComputed(() =>
    connectionStore.getByUserId(apiModel.id)
  );

  const isConnected = pairedConnection.maybeMap(
    c => c.status === ConnectionStatus.Accepted,
    false
  );
  const isPendingOutgoing = pairedConnection.maybeMap(
    c =>
      c.status === ConnectionStatus.Pending &&
      c.direction === ConnectionDirection.Outgoing,
    false
  );
  const isPendingIncoming = pairedConnection.maybeMap(
    c =>
      c.status === ConnectionStatus.Pending &&
      c.direction === ConnectionDirection.Incoming,
    false
  );
  const isPotentialConnection = ko.pureComputed(
    () => !isConnected() && !isPendingOutgoing() && !isPendingIncoming()
  );

  const onAdd = () => {
    mediator.publish(Channels.OpenConnectionAddWindow, {
      name: apiModel.name,
      userId: apiModel.id
    });
  };

  const onAccept = () => {
    api
      .updateConnection(pairedConnection().id, ConnectionStatus.Accepted)
      .then(updatedConnection =>
        setTimeout(() => connectionStore.add(updatedConnection), 500)
      );
  };

  return {
    initials:
      apiModel.initials || initialsForName(apiModel.name || apiModel.email),
    name: apiModel.name,
    email: apiModel.email,
    imageHash: apiModel.imageHash,

    isConnected,
    isPendingOutgoing,
    isPendingIncoming,
    isPotentialConnection,
    isNew: apiModel.isNew,
    checkInStatus: ko.pureComputed(() =>
      isConnected() ? checkInStatusStore.getById(apiModel.id) : null
    ),
    onAdd,
    onAccept
  };
};
