import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const key = process.env.AI_INSTRUMENTATION_KEY;
let appInsights = null;

export const initializeAppInsights = subscriptionId => {
  // Create instance and track page view
  appInsights =
    key && key.length
      ? new ApplicationInsights({
          // https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#configuration
          config: {
            instrumentationKey: key,
            autoTrackPageVisitTime: true,
            accountId: subscriptionId
          }
        })
      : null;

  if (appInsights) {
    appInsights.loadAppInsights();

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#class-telemetrycontext
    appInsights.context.application.ver = process.env.APP_VERSION;

    // Inject the subscription id in our data. Will input "null" if
    // not available
    appInsights.addTelemetryInitializer(function(envelope) {
      envelope.data.subscriptionId = subscriptionId;
    });

    appInsights.trackPageView({
      properties: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      }
    });
  } else {
    console.warn(
      "This session is not tracked in Application Insights because no instrumentation key was provided."
    );
  }
};

//resultType property is present in almost all events. Extra properties are specific to single events
//TODO: include result in extra props, so it can be skipped while having other properties.
//Requires changes across the apps in every place we publish an event (TD)
export const publishEvent = (appName, eventName, result, extraProp) => {
  const resultType = result ? { resultType: result } : {};
  if (appInsights) {
    appInsights.trackEvent({
      name: `OfficeShifts_${eventName}`,
      properties: Object.assign(
        {
          product: "OfficeShifts",
          appType: appName
        },
        resultType,
        extraProp
      )
    });
  }
};

export const startTrackEvent = eventName => {
  if (appInsights) {
    appInsights.startTrackEvent(`OfficeShifts_${eventName}`);
  }
};

export const stopTrackEvent = (appName, eventName, result, extraProp) => {
  const resultType = result ? { resultType: result } : {};
  if (appInsights) {
    appInsights.stopTrackEvent(
      `OfficeShifts_${eventName}`,
      null,
      Object.assign(
        {
          product: "OfficeShifts",
          appType: appName
        },
        resultType,
        extraProp
      )
    );
  }
};

const trackPageView = props => {
  if (appInsights) {
    appInsights.trackPageView({
      properties: {
        ...props,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      }
    });
  }
};
//this is in use for adminAPP
export const trackTabView = tabName =>
  trackPageView({
    tab: tabName && tabName !== "#" ? tabName : "home"
  });

//this is in use for analyticsApp
export const trackSubtabView = subtabName =>
  trackPageView({
    subtab: subtabName
  });

// https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#tracktrace
export const traceWarning = (message, customProperties) => {
  if (appInsights) {
    appInsights.trackTrace({
      message: message,
      severityLevel: 2,
      properties: customProperties
    });
  }
};
