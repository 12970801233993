/**
 * Use to flip between ascending/descending sort comparers
 */
export const flip = f2 => (a, b) => f2(b, a);

/**
 * Compare two strings ignoring diacritics and casing
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
export const stringCompareAsc = (a, b) =>
  a === null
    ? -1
    : b === null
    ? 1
    : a.localeCompare(b, "en", { sensitivity: "base" });

/**
 * Compare two strings ignoring diacritics and casing
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
export const stringCompareDesc = flip(stringCompareAsc);

export const boolCompareTrueFirst = (a, b) => (a === b ? 0 : a ? -1 : 1);
export const boolCompareFalseFirst = flip(boolCompareTrueFirst);

/**
 * Compare two date
 * @param {string} dateStringA
 * @param {string} dateStringB
 * @returns {number}
 */

export const dateCompareAsc = (dateA, dateB) =>
  dateA === null ? -1 : dateB === null ? 1 : dateA - dateB;

/**
 * Compare two date
 * @param {string} dateStringA
 * @param {string} dateStringB
 * @returns {number}
 */

export const dateCompareDesc = flip(dateCompareAsc);

/**
 * Compare two numbers
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export const numberCompareAsc = (a, b) =>
  a === null ? -1 : b === null ? 1 : a > b ? 1 : a < b ? -1 : 0;

export const numberCompareDesc = flip(numberCompareAsc);

export const naturalStringSortAsc = (a, b) =>
  a === null
    ? -1
    : b === null
    ? 1
    : a.localeCompare(b, "en", {
        numeric: true,
        sensitivity: "base"
      });

export const naturalStringSortDesc = flip(naturalStringSortAsc);
