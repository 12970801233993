import { KOComponent } from "@shared/knockout/KOComponent";
import "./CheckInStatusSelector.scss";
import template from "./CheckInStatusSelector.template.html";
import ko from "@shared/knockout/extended";
import {
  CheckInStatusLabel,
  CheckInStatus,
  CheckInStatusSourceTypeLabel
} from "@shared/services/CheckInStatus";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { isManualCheckInFeatureEnabled } from "@app/utils/checkIn";
import { BookingPolicy } from "@shared/services/BookingPolicy";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";

const ButtonText = {
  WORK_SPACE: { book: "Book desk", change: "Change booking" }
};
const CheckInStatusSelectorVM = params => {
  if (params.options) params = params.options;

  const checkInService = CheckInServiceSingleton();
  const manualCheckInEnabled = isManualCheckInFeatureEnabled();
  const checkInStatus = checkInService.status();
  const checkInBuildingId = checkInService.buildingId;
  const { booked, buildingId } = params.dayVM;

  const userHasCheckedInDifferentBuilding = ko.pureComputed(
    () =>
      checkInStatus !== CheckInStatus.NotAtTheOffice &&
      buildingId() !== checkInBuildingId()
  );

  const buildingName = ko.pureComputed(
    () => LocationStoreSingleton().getBuildingForId(checkInBuildingId())?.name
  );

  const checkInSourceType = ko.pureComputed(
    () => CheckInStatusSourceTypeLabel[checkInService.sourceType()]
  );

  const canNotPerformShiftAction = ko.pureComputed(
    () =>
      params.bookingPolicy === BookingPolicy.BEFORE_MIDNIGHT ||
      params.dayVM.isDisabled()
  );

  const statusToShow = ko.pureComputed(() =>
    userHasCheckedInDifferentBuilding() && booked()
      ? CheckInStatusLabel[CheckInStatus.AtAnotherOffice]
      : CheckInStatusLabel[checkInStatus]
  );

  const paramOptions = ko.pureComputed(() =>
    [
      userHasCheckedInDifferentBuilding() && booked()
        ? CheckInStatus.AtAnotherOffice
        : CheckInStatus.AtTheOffice,
      CheckInStatus.NotAtTheOffice
    ].map(option => ({
      label: CheckInStatusLabel[option],
      option: option,
      disable:
        option !== checkInStatus &&
        option !== CheckInStatus.AtAnotherOffice &&
        !manualCheckInEnabled,
      isCheckingInDisabled:
        canNotPerformShiftAction() &&
        option === CheckInStatus.AtTheOffice &&
        checkInStatus === CheckInStatus.NotAtTheOffice,
      selected: ko.observable(statusToShow() === CheckInStatusLabel[option]),
      onClick: clickedOption => {
        if (!manualCheckInEnabled) {
          return;
        }
        if (
          !booked() &&
          option === CheckInStatus.AtTheOffice &&
          option !== checkInStatus
        ) {
          if (!canNotPerformShiftAction()) {
            showModal(false);
            params.openBookingFlow();
          }
        } else {
          onSelect(clickedOption);
        }
      }
    }))
  );

  const showModal = ko.observable(false);

  const onOutsideClick = () => showModal(false);
  const onKeyUp = event => {
    if (event.key === "Escape") showModal(false);
  };

  window.addEventListener("keyup", onKeyUp);
  window.addEventListener("click", onOutsideClick);

  const onSelect = clickedOption => {
    const currentSelection = ko
      .unwrap(paramOptions)
      .find(({ selected }) => selected());
    if (currentSelection) {
      currentSelection.selected(false);
    }
    clickedOption.selected(true);
    if (currentSelection.option !== clickedOption.option) {
      params.output(clickedOption.option);
      publishAIEvent(EventNames.EditManualCheckIn);
    }
    showModal(false);
  };

  const onClick = () => {
    showModal(!showModal());
  };

  return {
    paramOptions,
    onSelect,
    showModal,
    statusToShow,
    userHasCheckedInDifferentBuilding,

    checkInStatus: ko.pureComputed(() =>
      userHasCheckedInDifferentBuilding()
        ? CheckInStatus.AtAnotherOffice
        : checkInStatus
    ),
    hasShift: booked(),
    canNotPerformShiftAction,
    checkedInInfo: ko.pureComputed(
      () =>
        `${checkInSourceType()} checked ${
          checkInStatus === CheckInStatus.AtTheOffice ? "in" : "out"
        } at ${buildingName() || "unknown building"}`
    ),
    buttonText: ko.pureComputed(() => {
      const buttonText = ButtonText.WORK_SPACE;

      return booked() ? buttonText.change : buttonText.book;
    }),
    onClick,
    onWrapperClick: (data, event) => {
      const wrapperClicked = event.target === event.currentTarget;

      if (wrapperClicked) showModal(false);

      return true;
    },
    performShiftAction: () => {
      showModal(false);
      params.dayVM.shiftAction();
    },
    dispose: () => {
      window.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("click", onOutsideClick);
    }
  };
};

export const CheckInStatusSelector = KOComponent(
  "check-in-status-selector",
  CheckInStatusSelectorVM,
  template
);
