export class ConnectionApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.connectedUser = obj.connectedUser;
    this.direction = obj.direction;
    this.status = obj.status;
    this.initials = obj.connectedUser.initials;
    this.imageHash = obj.connectedUser.imageHash;

    //for sorting and searching in store
    const cleanedName = (obj.connectedUser.name || "").trim();
    this.name = cleanedName || null;
    this.email = obj.connectedUser.email;
    this.userId = obj.connectedUser.id;
  }

  static equal(c1, c2) {
    return JSON.stringify(c1) === JSON.stringify(c2);
  }
}
