import "./AttendeesList.scss";
import template from "./AttendeesList.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";

const AttendeesListVM = ({ groups, title }) => {
  const scrollElement = document.getElementsByClassName("AttendeesList-body");
  scrollElement[0].scrollTo(0, 0);
  const onBack = () => {
    Mediator().publish(Channels.ToggleAttendeesList, null);
  };

  return {
    onBack,
    groups,
    title
  };
};

export const AttendeesList = KOComponent(
  "attendees-list",
  AttendeesListVM,
  template
);
