import { tryGetClaims } from "@shared/services/auth/tryGetClaims";

export const RoleId = {
  GlobalAdmin: "GlobalMapiqAdministrator",
  SolutionArchitect: "SolutionArchitect",
  TemplateDeveloper: "TemplateDeveloper",
  AccountAdmin: "MapiqSubscriptionManager",
  AccountViewer: "AccountViewer",
  SubscriptionAdmin: "SubscriptionAdministrator",
  SubscriptionAdminOwner: "SubscriptionAdministratorOwner",
  BuildingAdmin: "BuildingAdministrator",
  ShiftAdmin: "ShiftAdministrator",
  ShiftAssistant: "ShiftAssistant",
  ShiftHost: "ShiftHost",
  AnalyticsViewer: "AnalyticsViewer"
};

export const roleOrder = {
  [RoleId.GlobalAdmin]: 0,
  [RoleId.SubscriptionAdmin]: 1,
  [RoleId.SubscriptionAdminOwner]: 2,
  [RoleId.BuildingAdmin]: 3,
  [RoleId.ShiftAdmin]: 4,
  [RoleId.ShiftAssistant]: 5,
  [RoleId.ShiftHost]: 6,
  [RoleId.AnalyticsViewer]: 7
};

const oneOf = (...roleIds) => () => {
  const userRoleIds = currentUserRoleIds();

  return roleIds.some(r => userRoleIds.includes(r));
};

const currentUserRoleIds = () => {
  const claims = tryGetClaims();

  if (claims) {
    return claims.roles || [];
  } else {
    console.warn("Attempt to fetch user roles without being signed in");
    return [];
  }
};

// Will have to be hardcoded to handle at least 1 Sub admin rule
export const currentUserRoleId = () => {
  const claims = tryGetClaims();

  if (claims) {
    const roles = claims.roles || [];
    const knownRoles = Object.values(RoleId);

    return (
      roles
        .sort((r1, r2) => roleOrder[r1] - roleOrder[r2])
        .find(id => knownRoles.includes(id)) || null
    );
  } else {
    console.warn("Attempt to fetch user role without being signed in");
  }

  return null;
};

export const Permissions = {
  // Pages
  mayAccessAdminPage: oneOf(
    RoleId.GlobalAdmin,
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost,
    RoleId.AnalyticsViewer
  ),
  mayAccessAnalyticsPage: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.AnalyticsViewer
  ),
  //  Tabs
  mayAccessHomeTab: oneOf(
    RoleId.GlobalAdmin,
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost
  ),
  mayAccessShiftsTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost
  ),
  mayAccessBuildingsTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayAccessParkingTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayAccessPeopleTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant
  ),
  mayAccessProfilesTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin
  ),
  mayAccessAnalyticsTab: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.AnalyticsViewer
  ),
  mayAccessSettingsTab: oneOf(
    RoleId.GlobalAdmin,
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost
  ),
  mayAccessAccountsTab: oneOf(
    RoleId.GlobalAdmin,
    RoleId.AccountAdmin,
    RoleId.AccountViewer,
    RoleId.SolutionArchitect
  ),
  mayAccessQueueTab: oneOf(
    RoleId.GlobalAdmin,
    RoleId.SolutionArchitect,
    RoleId.TemplateDeveloper
  ),
  // Sub-tab features
  mayDeleteShifts: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant
  ),
  mayBookOnBehalfOf: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant
  ),
  mayEditBookingPolicy: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditDefaultProfiles: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditUserCommunication: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditPrivacyPolicy: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner
  ),
  mayEditUserRetentionSetting: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner
  ),
  mayEditProfiles: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditDeskBooking: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayAccessProfiles: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost
  ),
  mayEditBuildings: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayModifyUsers: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin
  ),
  mayModifyGuests: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant
  ),
  mayAccessUserAttributes: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin,
    RoleId.ShiftAssistant,
    RoleId.ShiftHost
  ),
  mayAccessExceptions: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin,
    RoleId.ShiftAdmin
  ),
  mayEditExceptions: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditAccounts: oneOf(
    RoleId.GlobalAdmin,
    RoleId.AccountAdmin,
    RoleId.SolutionArchitect
  ),
  mayEditSignInConfigurations: oneOf(
    RoleId.GlobalAdmin,
    RoleId.SolutionArchitect
  ),
  mayEditCalendarIntegrationsConfigurations: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner
  ),
  mayEditSensorIntegrationsConfigurations: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.BuildingAdmin
  ),
  mayEditCheckInFeature: oneOf(
    RoleId.SubscriptionAdmin,
    RoleId.SubscriptionAdminOwner,
    RoleId.AccountAdmin,
    RoleId.BuildingAdmin
  ),
  mayEditExtras: oneOf(RoleId.SubscriptionAdmin, RoleId.SubscriptionAdminOwner)
};
