import "./EquipmentGroup.scss";
import template from "./EquipmentGroup.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";

const equipmentKeyMap = {
  PresentationScreen: "Presentation screen",
  Webcam: "Webcam",
  Speakerphone: "Speakerphone",
  Audio: "Audio",
  Flipboard: "Flipboard",
  InteractiveWhiteboard: "Interactive whiteboard",
  Whiteboard: "Whiteboard",
  Smartboard: "Smartboard",
  Projector: "Projector"
};

const EquipmentGroupVM = ({ equipmentKey }) => {
  const equipmentTitle = equipmentKeyMap[equipmentKey];

  return {
    equipmentKey,
    equipmentTitle
  };
};

export const EquipmentGroup = KOComponent(
  "equipment-group",
  EquipmentGroupVM,
  template
);
