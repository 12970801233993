import "./Login.scss";
import template from "./Login.template.html";

// import { Mediator } from "@shared/mediator";
// import { Channels } from "@shared/Channels";
import { KOComponent } from "@shared/knockout/KOComponent";
import { confirmDialog } from "@shared/utils/confirmDialog";
import { LoginRequest } from "@shared/services/auth/settings/LoginRequest";
import { Msal } from "@shared/services/auth/settings/MsalInstance";

const LoginVM = ({ title, text }) => {
  const onLogin = () => {
    Msal().loginRedirect(LoginRequest());
    return confirmDialog({ question: "Signing in..." });
  };

  return { onLogin, text, title };
};

export const Login = KOComponent("log-in", LoginVM, template);
