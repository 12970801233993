import "./Avatar.scss";
import template from "./Avatar.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { getFeatures, FeatureNames } from "@shared/services/Features";
import { AppApi } from "@app/services/AppApi";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { stringToColor } from "@shared/utils/avatar-helpers";

const AvatarVM = ({
  avatarClasses,
  pictureClasses,
  email = null,
  initials = null,
  attendeeResponseOrAvailabilityStatus = null,
  checkInStatus = null,
  imageHash = null,
  cssBind = null,
  isMenuBar = false,
  profilePageIsActive = false,
  pictureSize = 120
}) => {
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  const imageSrc = ko.observable(null);
  const checkInAvatarStatus = ko.pureComputed(() => ko.unwrap(checkInStatus));
  const avatarImageHash = ko.pureComputed(() => ko.unwrap(imageHash));

  const getPicture = () => {
    if (
      !getFeatures().has(FeatureNames.PROFILE_PICTURES) ||
      !avatarImageHash()
    ) {
      imageSrc(null);
    } else {
      AppApi.getProfilePicture(avatarImageHash(), pictureSize)
        .then(p => imageSrc(p))
        .catch(() => imageSrc(null));
    }
  };
  const avatarImageHashListener = avatarImageHash.subscribe(getPicture);
  getPicture();

  // If email doesn't exist it is placeholder avatar
  // If initials doesn't exitst it is attendee with location unknown
  const backgroundColor = ko.pureComputed(() =>
    !email || !initials ? "#EBEBEB" : stringToColor(email)
  );
  const borderColor = ko.pureComputed(() => (email ? "#FFFFFF" : "#EBEBEB"));
  return {
    avatarClasses,
    pictureClasses,
    initials,
    backgroundColor,
    imageSrc,
    cssBind,
    checkInFeatureEnabled,
    borderColor,
    checkInAvatarStatus,
    attendeeResponseOrAvailabilityStatus,
    isMenuBar,
    profilePageIsActive,
    dispose: () => {
      avatarImageHashListener.dispose();
    }
  };
};

export const Avatar = KOComponent("avatar", AvatarVM, template);
