import "./NotificationsView.scss";
import template from "./NotificationsView.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { NotificationStoreSingleton } from "@app/services/NotificationStore";
import { NotificationVM } from "@app/viewmodels/NotificationVM";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { AppApi } from "@app/services/AppApi";

export const NotificationsViewVM = ({ api }) => {
  const notificationStore = NotificationStoreSingleton();

  const dispose = () => {
    notifications().map(n => n.dispose());
  };

  const markNotificationsAsRead = () => {
    const patchedNotifications = notificationStore
      .allUnreadNotifications()
      .map(n => ({ ...n, isRead: true }));
    AppApi.updateNotifications(patchedNotifications)
      .then(() => notificationStore.updateSpecific(patchedNotifications))
      .catch(e => {
        // We'll swallow this error for the same reasons we're not
        // waiting for the data
        console.error(e);
      });
  };

  Mediator().subscribe(Channels.DisposeSidePanelComponents, () => {
    if (UrlNavigationSingleton().activePanelId() === "notifications") {
      dispose();
      if (notificationStore.allUnreadNotifications().length) {
        markNotificationsAsRead();
      }
    }
  });

  const onClose = () => {
    UrlNavigationSingleton().navigate(null, null);
  };

  const deleteNotification = notification => {
    notification.disabled(true);
    api
      .deleteNotification(notification.id)
      .then(() => {
        // Delete from store.
        notificationStore.remove(notification.id);
      })
      .catch(e => {
        notification.disabled(false);
        Mediator().publish(Channels.OpenStatusMessage, {
          type: "failure",
          reason: "Something went wrong. Please try again."
        });
      });
  };

  const notifications = notificationStore.all.mapArray(NotificationVM);

  return {
    onClose,
    notifications,
    deleteNotification,
    dispose
  };
};

export const NotificationsView = KOComponent(
  "notifications-view",
  NotificationsViewVM,
  template
);
