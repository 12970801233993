export const ErrorType = {
  LimitReached: "limitReached",
  LoadingFailed: "loadingFailed",
  CreateRegistrationsInThePast:
    "OfficeShifts_CannotCreateRegistrationsInThePast",
  OfficeInstructions: "OfficeInstructions",
  ConsecutiveCheckInStatusChange: "OfficeShifts_CheckInMinTimespanLimit",
  TokenNotFound: "MeetingsService_UserTokenNotFound",
  ParkingReservationLimitReached:
    "OfficeShifts_NodeOverReservationLimit_Format",
  ConnectiionLimitReached: "OfficeShifts_ConnectionLimitReached_Format"
};

export const ParkingErrorTypes = {
  ParkingLotsLimitExceeded: "BS_ParkingLotsLimitExceeded",
  NameExceedsMaxLength: "BS_PropertyExceedsMaxLength_Format"
};
