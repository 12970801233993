export const ExpandTextareaBinding = {
  register: ko => {
    ko.bindingHandlers.expandTextarea = {
      init: el => {
        el.style.height = "auto";
        el.style.height = el.scrollHeight + "px";
        el.addEventListener("input", function(e) {
          this.style.height = "auto";
          this.style.height = this.scrollHeight + "px";
        });
      }
    };
  }
};
