import template from "./CheckInIndicator.template.html";
import "./CheckInIndicator.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { FeatureNames, getFeatures } from "@shared/services/Features";

const CheckInIndicatorVM = ({ checkInStatus }) => {
  const status = ko.pureComputed(() => ko.unwrap(checkInStatus));

  const mapToCssClass = ko.pureComputed(() => {
    if (status() === CheckInStatus.AtTheOffice) return "atTheOffice";
    else if (status() === CheckInStatus.NotAtTheOffice) return "notAtTheOffice";
    else if (
      getFeatures().has(FeatureNames.WORK_DAY) &&
      status() === CheckInStatus.NotCheckedInYet
    )
      return "notCheckedInYet";
    else return "differentOffice";
  });

  return {
    mapToCssClass
  };
};

export const CheckInIndicator = KOComponent(
  "check-in-indicator",
  CheckInIndicatorVM,
  template
);
