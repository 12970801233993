import "./ConnectionsPage.scss";
import template from "./ConnectionsPage.template.html";

import ko from "@shared/knockout/extended";
import { SearchResultVM } from "../../../viewmodels/SearchResultVM";
import { ConnectionVM } from "../../../viewmodels/ConnectionVM";
import { AppApi } from "@app/services/AppApi";
import { KOComponent } from "@shared/knockout/KOComponent";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { ConnectionSuggestions } from "./ConnectionSuggestions/ConnectionSuggestions";
// This returns a constructor for a connection api model -> view model
// Whenever it gets an api model that it has constructed a view model for
// before, it returns that view model instead.
const CachedConnectionVM = (api, connectionStore, connectedColleaguesStore) => {
  const cache = new WeakMap();

  return apiModel => {
    if (!cache.has(apiModel)) {
      cache.set(
        apiModel,
        ConnectionVM(apiModel, api, connectionStore, connectedColleaguesStore)
      );
    }

    return cache.get(apiModel);
  };
};

export const ConnectionsPageVM = ({
  connectionStore,
  connectedColleaguesStore,
  api = AppApi
}) => {
  const hasLoadedConnections = connectionStore.error() === null;
  // Bake in the fixed references so we can construct with only an apiModel
  const ConVM = CachedConnectionVM(
    api,
    connectionStore,
    connectedColleaguesStore
  );

  const acceptedConnections = connectionStore.allAccepted.mapArray(ConVM);
  const waitingRequests = connectionStore.allPendingOutgoing.mapArray(ConVM);
  const receivedRequests = connectionStore.allPendingIncoming.mapArray(ConVM);
  const noConnections = ko.pureComputed(
    () => !acceptedConnections().length && !waitingRequests().length
  );

  const isAddingConnections = ko.observable(false);
  //loading state for search results
  const loading = ko.observable(false);
  const startAddingConnections = () => isAddingConnections(true);
  const stopAddingConnections = () => {
    isAddingConnections(false);
    searchInput("");
  };

  const searchInput = ko
    .observable("")
    .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

  const rawSearchResults = ko.observableArray([]);
  const searchResults = rawSearchResults.mapArray(userSearchApiModel =>
    SearchResultVM(userSearchApiModel, connectionStore, api)
  );

  const searchQuery = searchInput.map(str => {
    const trimmedString = str.trim();
    if (trimmedString.length < 2) {
      return "";
    }

    return trimmedString;
  });

  const searchSub = searchQuery.subscribe(query => {
    if (query) {
      loading(true);
      api
        .searchPeople(query)
        .then(people => {
          loading(false);
          if (query === searchQuery()) rawSearchResults(people);
        })
        .catch(e => {
          console.log(e);
          loading(false);
          rawSearchResults([]);
        });
    } else rawSearchResults([]);
  });

  //empty states
  const showTypeMoreState = ko.pureComputed(
    () => searchInput().trim().length === 1
  );
  const showTypeEmailState = ko.pureComputed(() =>
    searchQuery().length &&
    rawSearchResults().length === 0 &&
    !searchQuery().includes("@")
      ? true
      : false
  );
  const showNoResultsState = ko.pureComputed(() =>
    searchQuery().length &&
    rawSearchResults().length === 0 &&
    searchQuery().includes("@")
      ? true
      : false
  );

  const hasReachedLimit = ko.pureComputed(
    () => connectionStore.all().length >= 100
  );
  const showConnectionLimitReachedState = ko.pureComputed(
    () =>
      hasReachedLimit() &&
      !showTypeMoreState() &&
      !showTypeEmailState() &&
      !showNoResultsState()
  );
  const showInfoState = ko.pureComputed(
    () => searchInput().trim().length === 0
  );

  return {
    acceptedConnections,
    waitingRequests,
    isAddingConnections,
    startAddingConnections,
    stopAddingConnections,
    searchBarParams: {
      query: searchInput
    },
    connectionSuggestionsEnabled: getFeatures().has(
      FeatureNames.CONNECTION_SUGGESTIONS
    ),
    searchResults,
    noConnections,
    receivedRequests,
    allReceivedRequestsUpdated: receivedRequests.map(rs =>
      rs.every(r => r.updated())
    ),
    showTypeMoreState,
    showTypeEmailState,
    showNoResultsState,
    showConnectionLimitReachedState,
    showInfoState,
    loading,
    hasReachedLimit,
    hasLoadedConnections,
    someReceivedRequestUpdating: receivedRequests.map(rs =>
      rs.some(r => r.updated())
    ),

    fadeOut: (el, _i, connection) => {
      if (el.nodeType === 1) {
        el.classList.add("is-hidden");
        setTimeout(() => {
          el.remove();
        }, 500);
      }
    },

    fadeIn: (el, _i, connection) => {
      // It's the actual <li>:
      if (el.nodeType === 1) {
        // Disable transitions so we can guarantee we're skipping the hide transition
        el.style.transition = "none";
        // Add the hide class so the user does not see this element being added
        el.classList.add("is-hidden");
        // Turn on transitions
        el.style.transition = "";
        // Give the browser time to render the frame, then remove the is-hidden class
        // triggering the apear-transition
        setTimeout(() => el.classList.remove("is-hidden"));
      }
    },

    dispose: () => {
      searchSub.dispose();
    }
  };
};

export const ConnectionsPage = {
  register: ko => {
    ConnectionSuggestions.register(ko);
    ko.components.register("connections-page", { template });
  }
};

export const ConnectionsPageSidePanel = KOComponent(
  "connections-page-sidepanel",
  ConnectionsPageVM,
  template
);
