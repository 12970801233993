import * as MsalLib from "@azure/msal-browser";
import { MsalConfig } from "./MsalConfig";
import { confirmDialog } from "../../../utils/confirmDialog";
import { LoginRequest } from "./LoginRequest";
import { logout } from "../logout";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";

/**
 * @returns {MsalLib.PublicClientApplication}
 */
export const Msal = (whenSignedIn, whenNotSignedIn) => {
  if (!window.msalInstance) {
    window.msalInstance = new MsalLib.PublicClientApplication(MsalConfig());

    window.msalInstance
      .handleRedirectPromise()
      .then(response => handleResponse(response, whenSignedIn, whenNotSignedIn))
      .catch(handleError);
  }

  return window.msalInstance;
};

/**
 * If the `response` is not `null` that means we're signed in directly from the B2C (Redirect from the B2C)\
 * If the `response` is `null` the user is navigating directly to the app.
 *  - Either because the user is not signed in (The first visit)
 *  - Or because there is already an active session (in the "local storage")
 */
const handleResponse = (response, whenSignedIn, whenNotSignedIn) => {
  if (response) {
    window.msalInstance.setActiveAccount(response.account);
    whenSignedIn();
  } else {
    const activeAccount = window.msalInstance.getActiveAccount();

    if (!activeAccount) {
      whenNotSignedIn();
      Mediator().publish(Channels.OpenLogin);
    } else {
      whenSignedIn();
    }
  }
};

const handleError = error => {
  console.error(error);

  let mailto = error.errorMessage
    ? `support@mapiq.com?subject=Support request: sign-in error&body=Hey support, help me, the following error occured:%0D%0A%0D%0A${encodeURIComponent(
        error.errorMessage
      )}`
    : "support@mapiq.com?subject=Support request: sign-in error";

  let rawBody = `If the problem persists feel free to <a style="color: #69767C;" href="mailto:${mailto}">contact</a> us.<br />In the meantime, please try again.`;

  // Whenever MSAL thinks something is wrong, show an abort/retry modal
  // Clear local storage, whatever user does.
  return (
    confirmDialog({
      header: "Sorry, but we’re having trouble signing you in",
      rawBody,
      footNote: error.errorMessage,
      errorIcon: true,
      confirm: "Retry",
      cancel: "No, cancel"
    })
      // User clicks retry
      .then(() => {
        window.msalInstance.loginRedirect(LoginRequest());
        return confirmDialog({ question: "Signin in..." });
      })
      // User clicks abort
      .catch(() => {
        logout();
      })
  );
};
