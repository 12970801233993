import body from "./WorkingHoursPopup.body.template.html";
import footer from "./WorkingHoursPopup.footer.template.html";
import ko from "@shared/knockout/extended";
import { MeetApi } from "@shared/services/MeetApi";
import { TimezoneStoreSingleton } from "@admin/components/BuildingEditor/services/TimezoneStore";
import { PopupManagerVM } from "@app/components/PopupManager/PopupManagerVM";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { getArrayOfWorkingDays } from "./helpers";

const Views = {
  HOME: "Working hours",
  TIME_ZONE: "Time zone",
  WORK_DAY_START: "Work day starts",
  WORK_DAY_END: "Work day ends"
};

const getHoursFormat = i => `${i < 10 ? `0${i}` : `${i}`}`;
const getWorkingHoursOptions = (startTime, endTime) => {
  let workingHours = [];
  for (let i = startTime; i < endTime; i++) {
    const hoursFormat = getHoursFormat(i);
    workingHours.push(`${hoursFormat}:00`, `${hoursFormat}:30`);
  }

  return workingHours;
};

export const WorkingHoursPopupVM = ({
  initialTimeZone,
  initialStartWorkingTime,
  preferredBuildingTimezone,
  initialEndWorkingTime,
  closePopup,
  setWorkingHours
}) => {
  const activeView = ko.observable(Views.HOME);
  const loading = ko.observable(false);
  const timeZones = TimezoneStoreSingleton().all();

  const selectedStartWorkingTime = ko.observable(initialStartWorkingTime());
  const selectedEndWorkingTime = ko.observable(initialEndWorkingTime());
  const selectedTimeZone = ko.observable(
    preferredBuildingTimezone || initialTimeZone()
  );

  const goToHomeView = () => activeView(Views.HOME);
  const goToTimeZoneView = () => activeView(Views.TIME_ZONE);
  const goToWorkDayStartView = () => activeView(Views.WORK_DAY_START);
  const goToWorkDayEndView = () => activeView(Views.WORK_DAY_END);

  return {
    activeView,
    loading,
    homeViewIsActive: activeView.map(view => view === Views.HOME),

    activeViewParams: ko.pureComputed(() => {
      switch (activeView()) {
        case Views.WORK_DAY_START:
          return {
            options: getWorkingHoursOptions(7, 13),
            currentlySelectedOption: selectedStartWorkingTime
          };
        case Views.WORK_DAY_END:
          return {
            options: getWorkingHoursOptions(13, 20),
            currentlySelectedOption: selectedEndWorkingTime
          };
        case Views.TIME_ZONE:
          return {
            options: timeZones.map(tz => tz.timeZoneIdWithUtcOffset),
            currentlySelectedOption: selectedTimeZone
          };
        default:
          return null;
      }
    }),

    viewRowsList: [
      {
        rowLabel: Views.TIME_ZONE,
        rowText: selectedTimeZone,
        onClick: goToTimeZoneView
      },
      {
        rowLabel: Views.WORK_DAY_START,
        rowText: selectedStartWorkingTime,
        onClick: goToWorkDayStartView
      },
      {
        rowLabel: Views.WORK_DAY_END,
        rowText: selectedEndWorkingTime,
        onClick: goToWorkDayEndView
      }
    ],

    isDirty: ko.pureComputed(
      () =>
        selectedStartWorkingTime() !== initialStartWorkingTime() ||
        selectedEndWorkingTime() !== initialEndWorkingTime() ||
        selectedTimeZone() !== initialTimeZone()
    ),

    goToHomeView,
    onConfirm: () => {
      loading(true);
      MeetApi.setUserPreference({
        workingHours: {
          ianaLocalTimezone: timeZones.find(
            tz => tz.timeZoneIdWithUtcOffset === selectedTimeZone()
          )?.timeZoneId,
          days: getArrayOfWorkingDays(
            selectedStartWorkingTime(),
            selectedEndWorkingTime()
          )
        }
      })
        .then(setWorkingHours)
        .catch(() =>
          Mediator().publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: "Something went wrong. Please try again later"
          })
        )
        .finally(() => {
          loading(false);
          closePopup();
        });
    }
  };
};
const {
  getTitle,
  hasBackButton,
  hasCloseButton,
  onBack,
  showFooter
} = PopupManagerVM.Keys;

Object.assign(WorkingHoursPopupVM, {
  [getTitle]: vm => vm.activeView(),
  [hasBackButton]: vm => !vm.homeViewIsActive(),
  [hasCloseButton]: vm => vm.homeViewIsActive(),
  [onBack]: vm => vm.goToHomeView,
  [showFooter]: vm => vm.homeViewIsActive()
});

export const WorkingHoursPopup = {
  register: ko => {
    ko.components.register("working-hours-popup-body", {
      template: body
    });

    ko.components.register("working-hours-popup-footer", {
      template: footer
    });
  }
};
