import { AvailabilityStoreSingleton } from "@shared/services/AvailabilityStore";
import { Singleton } from "@shared/utils/Singleton";
import { addDays } from "date-fns";
import { AdminApi } from "./AdminApi";

const DAYS_AHEAD = 28;

const AvailabilityService = () => {
  const today = new Date();
  const api = AdminApi;

  const getAvailability = (end = DAYS_AHEAD) =>
    api
      .getWorkspaceCounts(today, addDays(today, end + 1))
      .then(AvailabilityStoreSingleton().update);

  const getAvailabilityForDateRange = (
    startDate = today,
    endDate = addDays(today, DAYS_AHEAD + 1)
  ) => api.getWorkspaceCounts(startDate, endDate);

  return {
    getAvailability,
    getAvailabilityForDateRange
  };
};

export const AvailabilityServiceSingleton = Singleton(AvailabilityService);
