import "./MeetingCreation.scss";
import template from "./MeetingCreation.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isEqual
} from "date-fns";
import { AvailabilityStatus } from "@app/apimodels/AvailabilityStatus";
import ko from "@shared/knockout/extended";
import { AppApi } from "@app/services/AppApi";
import { SuggestionVM } from "@app/viewmodels/SuggestionVM";
import { AttendeesPicker } from "./AttendeesPicker/AttendeesPicker";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
// Polyfill to enable smooth scrolling on iOS
// Should be removed once https://caniuse.com/scrollintoview lists full support for safari
import scrollPolyfill from "scroll-polyfill";
import { MeetingRoomVM } from "@app/viewmodels/MeetingRoomVM";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { MeetingAttendeeApiModel } from "@app/apimodels/MeetingAttendeeApiModel";
import { LocationType } from "@app/apimodels/LocationType";
import { RoomPicker } from "./RoomPicker/RoomPicker";
import { TimePicker } from "./TimePicker/TimePicker";
import { getInviteeAvailability } from "@app/utils/getInviteeAvailability";
import {
  isLessThanMinDuration,
  isOverMaxDuration,
  MAX_DURATION,
  openConfirmationWindow
} from "./utils";
import { areEmailsEqual } from "@shared/utils/emailHelpers";
import { MeetingService } from "@app/services/MeetingService";
import { OfficeDayVM } from "@app/viewmodels/OfficeDayVM";
import { UserApp } from "@app/UserApp";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { getDateKey } from "@shared/utils/dateHelpers";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers";

scrollPolyfill();

const MeetingCreationVM = ({
  date,
  day,
  showSidePanelCloseButton,
  endOfCalendar,
  mediator = Mediator()
}) => {
  const handleError = MeetingService().handleError;
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const hasManualSelection = getFeatures().has(FeatureNames.MANUAL_SELECTION);
  const hasManualRoomSelection = getFeatures().has(
    FeatureNames.MANUAL_ROOM_SELECTION
  );

  const subs = [];

  const isValidDate = date => date instanceof Date && !isNaN(date);

  const isInvalidTimeRange = (start, end) =>
    !isValidDate(end) ||
    !isValidDate(start) ||
    isBefore(end, start) ||
    isOverMaxDuration(end, start) ||
    isLessThanMinDuration(end, start);

  showSidePanelCloseButton(false);
  const urlNavigation = UrlNavigationSingleton();
  const hideFooter = ko.observable(false);

  const toggleFooter = () => {
    if (screen.width <= 500) hideFooter(!hideFooter());
  };

  const title = ko.observable("");
  const description = ko.observable("");
  const isOnlineMeeting = ko.observable(false);
  const selectedMeetingDuration = ko.observable(meetingDurations[1]);

  const currentUser = UserApp.resolveUser();
  const userOfficeDay = OfficeDayVM(date);

  const userAsAttendee = MeetingAttendeeApiModel.fromApiObj({
    ...currentUser,
    displayName: "You",
    shiftBuildingId: userOfficeDay.buildingId,
    shiftBuildingName: userOfficeDay.buildingName,
    availability: "Available",
    locationType: userOfficeDay.locationType
  });

  //ATTENDEES
  const selectedAttendees = ko.observableArray([]);
  const showAttendeesPicker = ko.observable(false);
  const toggleAttendeesPicker = () => {
    showAttendeesPicker(!showAttendeesPicker());
    const scrollElement = document.getElementsByClassName("SidePanel-body");
    scrollElement[0].scrollTo(0, 0);
  };

  const attendeesToShow = ko.pureComputed(() =>
    selectedAttendees().length < 9
      ? selectedAttendees()
      : selectedAttendees()
          .slice(0, 8)
          .concat({
            displayName: `+${selectedAttendees().length - 8}`,
            color: "#677884",
            backgroundColor: "rgba(0, 0, 0, 0.03)"
          })
  );

  const userEmail = UserApp.resolveUser().email;

  //SUGGESTIONS
  const selectedSuggestion = ko.observable();
  const suggestions = ko.observableArray();
  const loading = ko.observable(false);
  const showSuggestions = ko.observable(true);
  const toggleSuggestions = () => showSuggestions(!showSuggestions());

  const locationStore = LocationStoreSingleton();
  const buildings = locationStore.getBuildings();
  const selectedBuildingInitialState =
    userOfficeDay && buildings.some(b => b.id === userOfficeDay.buildingId)
      ? userOfficeDay.buildingId
      : locationStore.getPreferredBuilding()?.id ?? buildings[0].id;
  const selectedBuilding = ko.observable(selectedBuildingInitialState);

  const requestSuggestions = (duration, attendees, start, end, building) => {
    let id = 0;
    loading(true);
    AppApi.getSuggestions(
      attendees().map(a => a.email),
      duration(),
      start,
      end
    )
      .then(sgs => {
        suggestions(
          sgs.map(s => {
            const SuggestionWithId = SuggestionVM(
              day,
              selectedSuggestion,
              s,
              id,
              userAsAttendee
            );
            id += 1;
            return SuggestionWithId;
          })
        );
      })
      .catch(e => {
        handleError(e);
      })
      .finally(() => loading(false));
  };

  const requestRangeStartDateTime = isToday(date)
    ? new Date().toISOString()
    : date.toISOString();
  const requestRangeEndDateTime = addDays(date, 1).toISOString();

  const requestTwoWeeksAheadSuggestions = withToday => {
    const start = withToday
      ? requestRangeStartDateTime
      : requestRangeEndDateTime;
    return requestSuggestions(
      selectedMeetingDuration,
      selectedAttendees,
      start,
      addDays(new Date(start), 14).toISOString()
    );
  };

  const isExtraSuggestionsTileVisible = ko.observable(true);
  const suggestionAutoUpdater = ko
    .computed(() => {
      requestSuggestions(
        selectedMeetingDuration,
        selectedAttendees,
        requestRangeStartDateTime,
        requestRangeEndDateTime
      );
      isExtraSuggestionsTileVisible(true);
      selectedSuggestion(null);
    })
    .extend({ deferred: true });

  const extraSuggestionsTileVM = () => {
    const onClick = (self, e) => {
      selectedSuggestion(null);
      requestTwoWeeksAheadSuggestions(true);
      isExtraSuggestionsTileVisible(false);
    };
    return {
      onClick
    };
  };

  const startDateTime = ko.observable(null);
  const endDateTime = ko.observable(null);
  const availableAttendeesCount = ko.observable(null);
  const onSiteAttendeesCount = ko.observable(null);

  const loadingAvailability = ko.observable(false);
  const getAvailabilityForManualSelection = (
    attendees,
    meetingStartDateTime,
    meetingEndDateTime
  ) => {
    loadingAvailability(true);

    return getInviteeAvailability(
      attendees,
      meetingStartDateTime,
      meetingEndDateTime,
      isInvalidTimeRange,
      date,
      day
    )
      .catch(e => {
        handleError(e);
      })
      .finally(() => {
        loadingAvailability(false);
      });
  };

  const hasDateTimeChanges = newSelection =>
    !isEqual(startDateTime(), newSelection.timeSlotStart) ||
    !isEqual(endDateTime(), newSelection.timeSlotEnd);

  subs.push(
    selectedSuggestion.subscribe(newSelection => {
      if (newSelection) {
        if (!hasDateTimeChanges(newSelection)) {
          showSuggestions(false);
          return;
        }

        startDateTime(newSelection.timeSlotStart);
        endDateTime(newSelection.timeSlotEnd);
        availableAttendeesCount(newSelection.availableAttendeesCount);
        onSiteAttendeesCount(newSelection.localAttendeesCount);
      }
    })
  );

  const manualAvailabilityAutoUpdater = ko
    .computed(() => {
      getAvailabilityForManualSelection(
        selectedAttendees(),
        startDateTime(),
        endDateTime()
      ).then(freshAttendeeAvailabilities => {
        availableAttendeesCount(
          freshAttendeeAvailabilities.filter(
            a => a.availability === AvailabilityStatus.Available
          ).length
        );
        onSiteAttendeesCount(
          freshAttendeeAvailabilities.filter(
            a => a.locationType === LocationType.Local
          ).length
        );
      });
    })
    .extend({ deferred: true });

  const showTimePicker = ko.observable(false);
  const openTimePicker = () => showTimePicker(true);
  const discardTimePicker = () => showTimePicker(false);
  const confirmTimePicker = () => {
    showTimePicker(false);
    // just in case if confirmation didnt change already set dates we hide suggestions
    // and summary is already shown as we have the dates set
    showSuggestions(false);
  };

  const timePickerParams = {
    startDateTime,
    endDateTime,
    date,
    isInvalidTimeRange,
    userAsAttendee,
    currentUser,
    handleError,
    day,
    userOfficeDay,
    selectedAttendees,
    openTimePicker,
    discardTimePicker,
    confirmTimePicker,
    availableAttendeesCount,
    onSiteAttendeesCount,
    selectedSuggestion,
    getInviteeAvailability
  };

  const currentUserAttendeCountCorrection = ko.pureComputed(() => {
    const isUserInListOfAttendees =
      selectedAttendees().findIndex(attendee =>
        areEmailsEqual(attendee.email, userEmail)
      ) !== -1;
    return isUserInListOfAttendees ? 0 : 1;
  });

  const startTimeLabel = ko.pureComputed(() =>
    startDateTime() ? format(startDateTime(), "HH:mm") : null
  );
  const endTimeLabel = ko.pureComputed(() =>
    endDateTime() ? format(endDateTime(), "HH:mm") : null
  );
  const dateLabel = ko.pureComputed(() =>
    startDateTime() ? format(startDateTime(), "EEEE d MMMM") : null
  );
  const attendeesLabel = ko.pureComputed(
    () =>
      `${availableAttendeesCount()}/${selectedAttendees().length +
        currentUserAttendeCountCorrection()} available, ${onSiteAttendeesCount()}/${selectedAttendees()
        .length + currentUserAttendeCountCorrection()} on site`
  );

  const showSummary = ko.pureComputed(() => startDateTime() && endDateTime());
  subs.push(
    showSummary.subscribe(newValue => {
      showSuggestions(!newValue);
    })
  );

  //ROOMS
  const selectedRooms = ko.observableArray([]);
  const rooms = ko.observableArray();
  const loadingRooms = ko.observable(false);
  //if there's selectedRooms, we can load new suggestions under the hood
  const showLoadingRoomsSpinner = ko.pureComputed(
    () => loadingRooms() && !selectedRooms().length
  );

  const requestRooms = (attendees, building, startTime, endTime) => {
    loadingRooms(true);
    const attendeesAmount = attendees().some(a =>
      areEmailsEqual(a.email, userEmail)
    )
      ? attendees().length
      : attendees().length + 1;
    MeetingService()
      .getRooms({
        attendeesAmount,
        buildingId: building(),
        startDateTime: startTime,
        endDateTime: endTime
      })
      .then(availableRooms => {
        rooms(
          availableRooms.map(
            MeetingRoomVM(hasManualRoomSelection, selectedRooms)
          )
        );
      })
      .catch(e => {
        handleError(e);
      })
      .finally(() => loadingRooms(false));
  };

  const roomAutoUpdater = ko
    .computed(() => {
      if (
        startDateTime() &&
        endDateTime() &&
        !isInvalidTimeRange(startDateTime(), endDateTime())
      ) {
        requestRooms(
          selectedAttendees,
          selectedBuilding,
          startDateTime(),
          endDateTime()
        );
      }
    })
    .extend({ deferred: true });

  [startDateTime, endDateTime].forEach(value => {
    subs.push(
      value.subscribe(() => {
        //we empty selected rooms if time is changed, because they might not be available anymore
        selectedRooms([]);
      })
    );
  });

  const roomsEmptyState = ko.pureComputed(() => {
    if (!startDateTime() && !endDateTime())
      return "Please select a date & time first";
    if (
      isBefore(endDateTime(), startDateTime()) ||
      !isValidDate(endDateTime()) ||
      !isValidDate(startDateTime())
    )
      return "Please select a valid date & time";
    if (isOverMaxDuration(endDateTime(), startDateTime()))
      return `Please select a period of maximum ${MAX_DURATION} days`;
    if (isLessThanMinDuration(endDateTime(), startDateTime()))
      return `Please select a longer duration`;
    return null;
  });

  const loadingCreation = ko.observable(false);
  const enableButton = ko.pureComputed(
    () => title() && !loadingCreation() && startDateTime() && endDateTime()
  );

  const dirty = ko.pureComputed(
    () =>
      title() ||
      description() ||
      selectedAttendees().length ||
      selectedMeetingDuration() !== meetingDurations[1] ||
      startDateTime() ||
      endDateTime() ||
      selectedBuilding() !== selectedBuildingInitialState ||
      isOnlineMeeting()
  );

  const goBack = () => {
    showSidePanelCloseButton(true);
    if (!hasWorkdayFeature) {
      urlNavigation.navigate(null, "overview", [format(date, "yyyy-MM-dd")]);
    } else {
      urlNavigation.navigate(null, null, [getFormatedActiveDateFromUrl()]);
    }
  };

  const closePanel = () => {
    showSidePanelCloseButton(true);

    urlNavigation.navigate(
      null,
      null,
      hasWorkdayFeature ? [getFormatedActiveDateFromUrl()] : []
    );
  };

  const back = () => {
    dirty() ? openConfirmationWindow(goBack) : goBack();
  };

  const close = () => {
    dirty() ? openConfirmationWindow(closePanel) : closePanel();
  };

  const createMeeting = () => {
    loadingCreation(true);
    MeetingService()
      .createMeeting(
        selectedAttendees,
        startDateTime,
        endDateTime,
        title,
        description,
        selectedRooms,
        isOnlineMeeting,
        userEmail
      )
      .then(() => {
        publishAIEvent(
          EventNames.MeetingCreated,
          { origin: "NewMeeting", duration: selectedMeetingDuration().value },
          ResultTypes.Success
        );
        const start = startDateTime();
        if (isSameDay(date, start) || isAfter(start, endOfCalendar())) {
          goBack();
        } else {
          showSidePanelCloseButton(true);
          urlNavigation.navigate(
            null,
            getFeatures().has(FeatureNames.WORK_DAY) ? null : "overview",
            [getDateKey(start)]
          );
        }
      })
      .catch(() => {
        publishAIEvent(
          EventNames.MeetingCreated,
          { origin: "NewMeeting", duration: selectedMeetingDuration().value },
          ResultTypes.Fail
        );
      })
      .finally(() => loadingCreation(false));
  };

  const showRoomPicker = ko.observable(false);
  const openRoomPicker = () => {
    showRoomPicker(true);
  };

  mediator.subscribe(Channels.CloseRoomPicker, backFn => {
    backFn();
  });

  const roomPickerParams = {
    close,
    building: selectedBuildingInitialState,
    startTime: startDateTime,
    endTime: endDateTime,
    selectedRooms,
    buildings,
    hasManualRoomSelection,
    backFunction: () => {
      showRoomPicker(false);
    }
  };

  return {
    day,
    goBack,
    title,
    description,
    meetingDurations,
    selectedMeetingDuration,
    requestTwoWeeksAheadSuggestions: () =>
      requestTwoWeeksAheadSuggestions(false),
    suggestions,
    enableButton,
    loading,
    hideFooter,
    showAttendeesPicker,
    toggleAttendeesPicker,
    selectedAttendees,
    attendeesSearchApiMethod: AppApi.searchInvitees,
    loadingAvailability,
    createMeeting,
    toggleFooter,
    loadingCreation,
    close,
    back,
    buildings,
    selectedBuilding,
    selectedSuggestion,
    isOnlineMeeting,
    extraSuggestionsTileVM,
    isExtraSuggestionsTileVisible,
    attendeesToShow,
    rooms,
    loadingRooms,
    selectedRooms,
    roomsEmptyState,
    hasManualSelection,
    showRoomPicker,
    openRoomPicker,
    roomPickerParams,
    hasManualRoomSelection,
    openTimePicker,
    timePickerParams,
    showTimePicker,
    startTimeLabel,
    endTimeLabel,
    dateLabel,
    attendeesLabel,
    showSummary,
    showSuggestions,
    toggleSuggestions,
    showLoadingRoomsSpinner,
    hasWorkdayFeature,
    dispose: () => {
      suggestionAutoUpdater.dispose();
      subs.forEach(s => s.dispose());
      manualAvailabilityAutoUpdater.dispose();
      roomAutoUpdater.dispose();
    }
  };
};

const meetingDurations = [
  {
    label: "15 minutes",
    value: 15
  },
  {
    label: "30 minutes",
    value: 30
  },
  {
    label: "45 minutes",
    value: 45
  },
  {
    label: "1 hour",
    value: 60
  },
  {
    label: "1 hour 30 minutes",
    value: 90
  },
  {
    label: "2 hours",
    value: 120
  },
  {
    label: "3 hours",
    value: 180
  },
  {
    label: "4 hours",
    value: 240
  },
  {
    label: "5 hours",
    value: 300
  },
  {
    label: "6 hours",
    value: 360
  },
  {
    label: "7 hours",
    value: 420
  },
  {
    label: "8 hours",
    value: 480
  }
];

const meetingCreationComponent = KOComponent(
  "meeting-creation",
  MeetingCreationVM,
  template
);

export const MeetingCreation = {
  ...meetingCreationComponent,
  register: ko => {
    meetingCreationComponent.register(ko);
    AttendeesPicker.register(ko);
    TimePicker.register(ko);
    RoomPicker.register(ko);
  }
};
