import "./RoomPicker.scss";
import template from "./RoomPicker.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { AppApi } from "@app/services/AppApi";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { MeetingApiModel } from "@app/apimodels/MeetingApiModel";
import { openConfirmationWindow } from "../utils";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers";

const RoomPickerVM = ({
  roomPickerParams,
  showSidePanelCloseButton,
  mediator = Mediator()
}) => {
  //This picker needed to be bound to a route to be opened by various components in the app
  //(not all of them already in the side panel) but cannot be access by browser pop-state.
  //This redirects to main page if user tries to access from a bookmark / refresh.
  //It's ugly but it works (for now)
  const renderPicker = ko.observable(true);
  if (!ko.unwrap(roomPickerParams)) {
    const urlNavigation = UrlNavigationSingleton();
    mediator.publish(Channels.CloseRoomPicker, () =>
      urlNavigation.navigate(
        "",
        null,
        getFeatures().has(FeatureNames.WORK_DAY)
          ? [getFormatedActiveDateFromUrl()]
          : []
      )
    );
    renderPicker(false);
    return { renderPicker };
  }

  const {
    close = null,
    building,
    startTime,
    endTime,
    selectedRooms,
    buildings,
    hasManualRoomSelection,
    backFunction,
    addRoomPageOpenedOrigin,
    selectedMeetingId = null
  } = ko.unwrap(roomPickerParams);

  //in creation flow, selecting a room immediately adds it to the list of selectedRooms
  //in add room flow, selecting a room is a temp choice in this component, until clicking confirm.
  //Only one can be chosen at a given time
  const isAddRoomFlow = !!selectedMeetingId;
  const currentSelection = ko.observable(null);

  const goBack = () => {
    selectedRooms.remove(currentSelection());
    currentSelection(null);
    mediator.publish(Channels.CloseRoomPicker, backFunction);
    if (showSidePanelCloseButton) showSidePanelCloseButton(true);
  };
  const afterRoomSelection = isAddRoomFlow ? () => true : goBack;

  if (showSidePanelCloseButton && isAddRoomFlow) {
    showSidePanelCloseButton(false);
  }

  const selectedBuilding = ko.observable(building);
  const buildingFilterSubscription = selectedBuilding.subscribe(() => {
    if (isAddRoomFlow) {
      selectedRooms.remove(currentSelection());
      currentSelection(null);
    }
  });

  const selectedFloor = ko.observable(null);
  const floorFilterSubscription = selectedFloor.subscribe(newSelection => {
    if (newSelection) {
      publishAIEvent(
        "filterRoomsByFloor",
        { floorId: newSelection },
        ResultTypes.Undefined
      );
    }
    if (isAddRoomFlow) {
      selectedRooms.remove(currentSelection());
      currentSelection(null);
    }
  });

  const selectedRange = ko.observable(null);
  const capacityFilterSubscription = selectedRange.subscribe(newSelection => {
    if (newSelection) {
      publishAIEvent(
        "filterRoomsByCapacity",
        { rangeStart: newSelection[0], rangeEnd: newSelection[1] },
        ResultTypes.Undefined
      );
    }
    if (isAddRoomFlow) {
      selectedRooms.remove(currentSelection());
      currentSelection(null);
    }
  });

  const filterOutSelectedRooms = isAddRoomFlow
    ? () => true
    : r => !ko.unwrap(selectedRooms).some(sr => sr.id === r.id);

  const meetingStore = MeetingStoreSingleton();
  const meetingToUpdate = meetingStore.get(selectedMeetingId);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const loadingConfirmation = ko.observable(false);
  const confirm = () => {
    loadingConfirmation(true);
    const updatedMeeting = {
      ...MeetingApiModel.toUpdateApiObj(meetingToUpdate, userTimezone),
      roomEmails: selectedRooms().map(r => r.email)
    };
    AppApi.updateMeeting(updatedMeeting)
      .then(m => {
        meetingStore.addSingle(m);
        publishAIEvent(
          EventNames.RoomAdded,
          { origin: addRoomPageOpenedOrigin },
          ResultTypes.Success
        );
        mediator.publish(Channels.OpenStatusMessage, {
          title: "Room booked!",
          type: "success",
          timeout: 3000,
          htmlReason: `<span><b>${
            currentSelection().displayName
          }</b> has been added to your event.</span>`
        });
        goBack();
      })
      .catch(e => {
        let reason = null;

        if (e && e.errors) {
          const [[firstErrorValue]] = Object.values(e.errors);
          reason = firstErrorValue;
        }
        publishAIEvent(
          EventNames.RoomAdded,
          { origin: addRoomPageOpenedOrigin },
          ResultTypes.Fail
        );
        mediator.publish(Channels.OpenStatusMessage, {
          type: "failure",
          reason
        });
      })
      .finally(() => loadingConfirmation(false));
  };

  const enableConfirm = ko.pureComputed(
    () => !!currentSelection() && !loadingConfirmation()
  );

  const dirty = ko.pureComputed(() => isAddRoomFlow && currentSelection());

  const onBackClick = () =>
    dirty() ? openConfirmationWindow(goBack, "Discard room?") : goBack();

  const roomDetailsParams = ko.observable(null);
  const setRoomDetails = params => {
    roomDetailsParams(params);
  };

  Mediator().subscribe(Channels.ToggleRoomDetails, setRoomDetails);

  const meetingRoomParams = {
    hasManualRoomSelection,
    selectedRooms,
    afterRoomSelection,
    isAddRoomFlow,
    currentSelection
  };

  const roomsListParams = {
    selectedBuilding,
    selectedFloor,
    selectedRange,
    startTime,
    endTime,
    filterOutSelectedRooms,
    meetingRoomParams,
    buildings
  };

  return {
    close,
    selectedMeetingId,
    isAddRoomFlow,
    confirm,
    enableConfirm,
    dirty,
    onBackClick,
    loadingConfirmation,
    currentSelection,
    renderPicker,
    roomDetailsParams,
    roomsListParams,
    dispose: () => {
      buildingFilterSubscription.dispose();
      floorFilterSubscription.dispose();
      capacityFilterSubscription.dispose();
    }
  };
};

export const RoomPicker = KOComponent("room-picker", RoomPickerVM, template);
