import flatpickr from "flatpickr";
import "@flatpickr/dist/flatpickr.css";
import { isAfter, set } from "date-fns";

/**
 * Knockout two-way binding for a flatpickr date range widget.
 * Bind to an observable to communicate with the widget via
 * an object of { from, to }
 */
const flatpickrBinding = {
  init: function(element, valueAccessor, allBindings) {
    const obsDate = valueAccessor();
    const date = obsDate();

    const minimumDate = allBindings.has("minimumDate")
      ? allBindings.get("minimumDate")
      : null;
    // Init widget and listen to user-changes
    const picker = flatpickr(
      element,
      Object.assign(
        {},
        allBindings.has("flatpickrOpts")
          ? allBindings.get("flatpickrOpts")
          : {},
        {
          mode: "single",
          clickOpens: true,
          locale: {
            firstDayOfWeek: 1 // start week on Monday
          },
          monthSelectorType: "static",
          prevArrow: prevArrow,
          nextArrow: nextArrow,
          defaultDate: date,
          allowInput: false,
          onChange: function(dates) {
            if (dates[0]) obsDate(dates[0]);
          },
          onOpen: function() {
            if (minimumDate) {
              const minimumDateAtMidnight = set(minimumDate(), {
                hours: 0,
                minutes: 0
              });
              picker.set("minDate", minimumDateAtMidnight);
              if (isAfter(minimumDate(), date)) obsDate(minimumDateAtMidnight);
            }
          }
        }
      )
    );

    const syncPicker = date => {
      picker.setDate(date);
    };

    // Enable two-way binding; when js changes,
    // update picker
    obsDate.subscribe(syncPicker);
  }
};

//A simple time input with no visible widget
const flatpickrBindingTime = {
  init: function(element, valueAccessor, allBindings) {
    const obsDate = valueAccessor();
    const date = obsDate();

    // Init widget and listen to user-changes
    const picker = flatpickr(
      element,
      Object.assign(
        {},
        allBindings.has("flatpickrOpts")
          ? allBindings.get("flatpickrOpts")
          : {},
        {
          dateFormat: "H:i",
          clickOpens: false,
          noCalendar: true,
          enableTime: true,
          allowInput: true,
          defaultDate: date,
          onChange: function(times) {
            obsDate(times[0]);
          }
        }
      )
    );

    const syncPicker = date => {
      picker.setDate(date);
    };

    // Enable two-way binding; when js changes,
    // update picker
    obsDate.subscribe(syncPicker);
  }
};

export { flatpickrBinding, flatpickrBindingTime };

const prevArrow = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="black" fill-opacity="0.03"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.5021 21.6585C19.1377 22.0742 18.5047 22.1163 18.0882 21.7526L12.9343 17.5052C12.0219 16.7084 12.0219 15.2917 12.9343 14.4949L18.0882 10.2474C18.5047 9.88376 19.1377 9.92587 19.5021 10.3415C19.8665 10.7571 19.8243 11.3889 19.4078 11.7526L14.2539 16L19.4078 20.2475C19.8243 20.6111 19.8665 21.2429 19.5021 21.6585Z" fill="#1F1D1B"/>
</svg>`;
const nextArrow = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="black" fill-opacity="0.03"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4979 10.3415C12.8623 9.92583 13.4953 9.88371 13.9118 10.2474L19.0657 14.4948C19.9781 15.2916 19.9781 16.7083 19.0657 17.5051L13.9118 21.7526C13.4953 22.1162 12.8623 22.0741 12.4979 21.6585C12.1335 21.2429 12.1757 20.6111 12.5922 20.2474L17.7461 16L12.5922 11.7525C12.1757 11.3889 12.1335 10.7571 12.4979 10.3415Z" fill="black"/>
</svg>
`;
