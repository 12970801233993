const Const = x => y => x;

// String sanitation
const toLower = str => str.toLowerCase();
const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
const trim = str => str.trim();
const sanitize = str => toLower(normalize(trim(str)));

const splitWords = str => str.split(" ");
const toKeywords = (str, minLength) =>
  splitWords(sanitize(str)).filter(str => str.length >= minLength);

// Item matching
const matchArray = (arr, keywords) =>
  arr.map(sanitize).some(str => matchString(str, keywords));

const matchString = (string, keywords) =>
  keywords.some(word => sanitize(string).includes(word));

const matchItem = keywords => item =>
  typeof item === "string"
    ? matchArray(splitWords(item), keywords)
    : typeof item[Query.getMatchTerms] === "function"
    ? matchArray(item[Query.getMatchTerms](), keywords)
    : false;

export const Query = (minLength = 1) => str => {
  const keywords = toKeywords(str, minLength);

  if (keywords.length === 0) return Const(true);

  return matchItem(keywords);
};

Query.sanitizeString = sanitize;
Query.getMatchTerms = Symbol();
