import {
  notificationPreferencesCategoryKeys,
  notificationPreferencesTypeKeys
} from "@app/apimodels/NotificationPreferencesApiModel";

export const NotificationPreferenceVM = ({ category, type, enabled }) => ({
  category: notificationPreferencesCategoryKeys[category],
  type: notificationPreferencesTypeKeys[type],
  enabled
});
