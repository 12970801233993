export const isEmailValid = str => {
  if (!str) return true;
  const cleanString = str.trim();
  return (
    cleanString.length > 0 &&
    cleanString.lastIndexOf("@") > 0 &&
    cleanString.lastIndexOf(".") < cleanString.length - 1 &&
    cleanString.lastIndexOf(".") > cleanString.lastIndexOf("@") + 1 &&
    !cleanString.includes(" ")
  );
};

export const sanitizeEmail = str => str.trim().toLowerCase();

export const getEmailDomain = emailStr => sanitizeEmail(emailStr).split("@")[1];

export const areEmailsEqual = (a, b) =>
  a.localeCompare(b, "en", { sensitivity: "base" }) === 0;
