import { availabilityParkingData } from "@shared/viewmodels/AvailabilityParkingData";
import ko from "@shared/knockout/extended";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames } from "@app/tracking/EventNames";

export const ParkingLotVM = (parking, date, selectedParkingLot) => {
  const id = parking.id;
  const availabilityData = availabilityParkingData(date, parking);
  const isSelected = ko.pureComputed(() => id === selectedParkingLot()?.id);
  const isDisabled = ko.pureComputed(
    () => availabilityData.full() || availabilityData.closed()
  );

  const select = self => {
    if (isSelected() || isDisabled()) return;
    publishAIEvent(EventNames.ParkingLocationPickerSelectParkinglot);
    selectedParkingLot(self);
  };
  return {
    id,
    name: parking.name,
    capacity: parking.capacity,
    parkingZones: parking.parkingZones,
    full: availabilityData.full,
    closed: availabilityData.closed,
    availability: availabilityData.availability,
    availabilityLabel: availabilityData.availabilityLabel,
    colorClass: availabilityData.colorClass,
    percentage: availabilityData.percentage,

    select,
    isSelected,
    isDisabled
  };
};
