import ko from "@shared/knockout/extended";
import { groupByC } from "@shared/utils/groupBy";
import { Singleton } from "@shared/utils/Singleton.js";
import {
  getDateKey,
  getDateKeyForFeature,
  getWeekKeyForFeature
} from "@shared/utils/dateHelpers.js";

export const ParkingReservationStore = () => {
  const cache = ko.observable({});
  const hydrated = ko.observable(false);
  const all = cache.map(Object.values);
  const byWeek = all.map(groupByC(getWeekKeyForFeature));
  const byDate = all.map(groupByC(getDateKeyForFeature));

  const update = parkingReservations => {
    hydrated(true);
    cache(Object.fromEntries(parkingReservations.map(r => [r.id, r])));
  };

  const getForDate = date => {
    const k = getDateKey(date);

    return byDate()[k] || [];
  };

  const getForWeekNr = weekNr => {
    return byWeek()[weekNr] || [];
  };

  const add = parking => {
    const _cache = cache();
    _cache[parking.id] = parking;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  return {
    update,
    hydrated,
    getForDate,
    add,
    remove,
    all,
    getForWeekNr,
    parkingReservationForDate: date => getForDate(date)[0] || null
  };
};

export const ParkingReservationStoreSingleton = Singleton(
  ParkingReservationStore
);
