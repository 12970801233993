import FloorApiModel from "./FloorApiModel";
import { byPropAsc, numericCompare } from "../utils/sort";
import { parseISO } from "date-fns";

class BuildingApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name || "";
    this.capacity = obj.capacity;

    this.ianaTimezone = obj.ianaTimezone;

    this.floors = (obj.floors || [])
      .sort(byPropAsc("number", numericCompare))
      .map(f => new FloorApiModel(f));

    this.openingDays = obj.openingDays || [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];

    this.lastPublishedDate = obj.lastPublishedDate
      ? parseISO(obj.lastPublishedDate)
      : null;

    this.version = obj.version;
    //false - Mandatory -> The user must book the workspace together with the work day
    //true - Optional -> The user does not have to book a workspace together with a work day
    this.allowWorkdayWithoutWorkspace =
      obj.allowWorkdayWithoutWorkspace ?? false;

    this.type = BuildingApiModel;
  }
}

export default BuildingApiModel;
