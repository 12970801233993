import template from "./InviteesPicker.template.html";
import "./InviteesPicker.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import { AppApi } from "@app/services/AppApi";

const InviteesPickerVM = ({
  selectedInvitees,
  date,
  selectInviteesObservable,
  isDirtyObservable,
  goHome
}) => {
  return {
    selectedInvitees,
    date,
    allowOutsideOrganization: false,
    maxSelectedInvitees: 30,
    emptyText:
      "Invitees will receive an invitation to join a group booking. This invitation will expire in 3 days.",
    searchApiMethod: AppApi.searchPeopleAsInvitee,
    toggleInviteesPicker: goHome,
    onConfirm: selectInviteesObservable,
    isDirtyObservable
  };
};

export const InviteesPicker = KOComponent(
  "invitees-picker",
  InviteesPickerVM,
  template
);
