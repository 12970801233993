import { format, isSameDay, isBefore, getDay } from "date-fns";

import ko from "@shared/knockout/extended";
import { DayAvailabilityVM } from "@app/components/CalendarView/viewmodels/DayAvailabilityVM";
import {
  WorkdayColleagueTodayVM,
  sortByDefaultOffice,
  sortByCheckInStatus
} from "../../../viewmodels/ColleagueTodayVM";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { combineSorters, byPropAsc } from "@shared/utils/sort";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { shiftActionButtonVM } from "@app/utils/shiftActionButton";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { ParkingReservationVM } from "@app/viewmodels/Parking/ParkingReservationVM";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { getDateKey } from "@shared/utils/dateHelpers";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";
import { BreakpointVM } from "@app/viewmodels/ResponsiveDesign/BreakpointVM";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers.js";

export const WorkDayVM = (
  profile,
  now,
  { settings },
  selectedColleagues,
  lastVisibleWeekDay,
  showMeetings,
  mobileCalendarViewIsActive
) => day => {
  const workspaceService = WorkspaceServiceSingleton();
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const urlNavigationSingleton = UrlNavigationSingleton();
  const formattedDate = getDateKey(day);
  // Labels
  const date = format(day, "dd"); // 01
  const { isBreakpointMatched } = BreakpointVM("(max-width: 750px)");

  //We display the horizontal calendar for devices up to 750px wide and the date format is ["M", "T", ...]
  //mobileCalendarViewIsActive - when the calendar is toggled on the mobile device and the date format is ["Mon", "Tue", ...]
  const weekDay = ko.pureComputed(() => {
    const formatPattern =
      !mobileCalendarViewIsActive() && isBreakpointMatched()
        ? "iiiii"
        : "iiiiii";

    return format(day, formatPattern);
  });

  const isActive = ko.pureComputed(
    () => getFormatedActiveDateFromUrl() === formattedDate
  );

  // Booking related states
  const workspace = ko
    .pureComputed(() => workspaceService.getConfirmedForDate(day))
    .map(getFirstElement)
    .maybeMap(WorkspaceVM);

  const parkingReservation = ko
    .pureComputed(() => parkingReservationsStore.getForDate(day))
    .map(getFirstElement)
    .maybeMap(ParkingReservationVM);

  const workday = ko
    .pureComputed(() => WorkdayReservationsStoreSingleton().getForDate(day))
    .map(getFirstElement);

  const workspaceBooked = workspace.map(Boolean);
  const workdayBooked = workday.map(Boolean);

  // Time based states
  const isToday = isSameDay(now, day);
  const inPast = isBefore(day, now);

  // Profile based states (together with maxReached)
  const weekDayAllowed = profile.registrationDays.includes(weekDay());
  const dayKey = getDay(day) === 0 ? 7 : getDay(day);
  const weekDayVisible = ko.pureComputed(
    () => showMeetings || weekDayAllowed || dayKey <= lastVisibleWeekDay()
  );

  // Availability related state
  const dayAvailability = DayAvailabilityVM(day);

  const availabilityLabel = ko.pureComputed(() => {
    if (
      workspaceBooked() &&
      !dayAvailability.allClosed() &&
      !dayAvailability.allFull()
    )
      return null;

    // If we have no label, we hide the element
    const label = dayAvailability.label();
    if (!label) return null;

    //it only gets a label if it's fully booked or all buildings are closed
    if (dayAvailability.allFull()) return label;
    if (dayAvailability.allClosed()) return label;
    return null;
  });

  const shiftActionButton = shiftActionButtonVM(
    day,
    settings,
    profile,
    now,
    workspace,
    inPast
  );

  const sorter = checkInFeatureEnabled
    ? combineSorters(sortByCheckInStatus, byPropAsc("name", stringCompareAsc))
    : sortByDefaultOffice;

  const colleaguesWithWorkday = ko.pureComputed(() =>
    ConnectedColleaguesStoreSingleton().allWithWorkdayOnDate(day)
  );
  //Filter colleagues who have booked a shift today with checkin status on this day
  const colleaguesWithShift = colleaguesWithWorkday
    .mapArray(c => WorkdayColleagueTodayVM(c, day, workday))
    .sort(sorter);
  // Note, slow: O(n * m)
  const selectionFilter = selectedColleagues.map(cs =>
    cs.length ? c => cs.includes(c.id) : _ => true
  );

  const filteredColleagues = colleaguesWithShift.filter(selectionFilter);

  const navigate = () => {
    urlNavigationSingleton.navigate("", null, [formattedDate]);
    mobileCalendarViewIsActive(false);
  };

  const showOverview = () => {
    if (!inPast && !isActive()) {
      publishAIEvent(EventNames.ClickDayInCalendar, {
        shift: workspaceBooked()
      });
      navigate();
    }
  };

  return {
    day,
    isActive,
    workspace,
    // Date display
    date,
    weekDay,
    // Tile
    isToday,
    inPast,
    isDisabled: shiftActionButton.disableShiftButton,
    showAvailabilityLabel: ko.pureComputed(
      () => availabilityLabel() && !workspaceBooked()
    ),
    weekDayAllowed,
    weekDayVisible,
    workspaceBooked,
    // Label
    availabilityLabel,
    status: ko.pureComputed(() => {
      // It is possible to have just a workspace if a desk is mandatory for building without a workday until the page is refreshed and the backend generates a workday reservation
      if (workspace()) return WorkdayStatus.OfficeDay;
      return workday()?.status ?? null;
    }),
    workdayBooked,
    workspaceBooked,
    nodeId: workspace.maybeMap(shift => shift.nodeId),
    avatars: filteredColleagues,
    publishEventForAvatars: publishAIEvent(
      EventNames.HoverConnectionsInCalendar
    ),
    showParkingIcon: ko.pureComputed(
      () => getFeatures().has(FeatureNames.PARKING) && parkingReservation()
    ),
    showOverview,
    checkInFeatureEnabled,
    dataTestId: ko.pureComputed(
      () =>
        `Day-${isToday ? "currentDayShift" : "shift"}${
          workspaceBooked() ? "Booked" : "NotBooked"
        }Button`
    ),

    scrollIntoViewParams: ko.pureComputed(() => {
      if (isBreakpointMatched())
        //horizontal calendar
        return {
          block: "center",
          inline: "center"
        };
      else return { block: "start", inline: "nearest" };
    }),

    onWorkdayStatusIconClick: () => {
      if (inPast || WorkdayReservationServiceSingleton().loading()) return;

      publishAIEvent(EventNames.OpenWorkdayListFromCalendar);
      Mediator().publish(Channels.OpenWorkdayWizard, { day });
    },

    dispose: () => {
      isActive.dispose();
    }
  };
};
