import { LocationType } from "@app/apimodels/LocationType";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { isSameDay, startOfDay } from "date-fns";
import ko from "@shared/knockout/extended";

export const AttendeeWithAvailabilityVM = (
  attendee,
  day,
  hardcodedAvailability = null
) => {
  return {
    ...attendee,
    availability: hardcodedAvailability ?? attendee.availability,
    locationType: attendee.locationType ?? LocationType.Unknown,
    responseStatus: null,
    label:
      attendee.locationType === LocationType.Unknown ? null : attendee.initials,
    isOrganizer: false,
    checkInStatus: ko.pureComputed(() =>
      isSameDay(day, startOfDay(new Date()))
        ? CheckInStatusStoreSingleton().getByDirectoryObjectId(attendee.id)
        : null
    )
  };
};
