import format from "date-fns/format";

// For every notificationm type,
// For every placeholder by index
// Return how to get the value of the placeholder, and
// return whether to highlight it
export const TemplateMapping = {
  NotifyDeleteOnBehalf: {
    0: msgValues => [msgValues.AdminName, false],
    1: msgValues => [
      format(new Date(msgValues.ShiftDate), "EEEE d MMMM"),
      true
    ],
    2: msgValues => [msgValues.BuildingName, false]
  },

  NotifyBookingOnBehalf: {
    0: msgValues => [msgValues.AdminName, false],
    1: msgValues => [
      format(new Date(msgValues.ShiftDate), "EEEE d MMMM"),
      true
    ],
    2: msgValues => [msgValues.BuildingName, false]
  },

  NotifyUpdateOnBehalf: {
    0: msgValues => [msgValues.AdminName, false],
    1: msgValues => [format(new Date(msgValues.ShiftDate), "EEEE d MMMM"), true]
  },

  NotifyDeleteOnLocationClose: {
    0: msgValues => [
      format(new Date(msgValues.ShiftDate), "EEEE d MMMM"),
      true
    ],
    1: msgValues => [msgValues.BuildingName, false]
  },

  NotifyGroupInvitationCanceled: {
    0: msgValues => [msgValues.OrganizerName, false],
    1: msgValues => [
      format(new Date(msgValues.ShiftDate), "EEEE d MMMM"),
      true
    ],
    2: msgValues => [msgValues.BuildingName, false]
  },

  NotifyInAppReservationInvitationCreated: {
    0: msgValues => [msgValues.OrganizerName, false],
    1: msgValues => [msgValues.InviteeCountText, false],
    2: msgValues => [
      format(new Date(msgValues.ShiftDate), "EEEE d MMMM"),
      true
    ],
    3: msgValues => [msgValues.BuildingName, false]
  }
};

// Trim last and first character of a string
const trimInside = str => str.slice(1, str.length - 1);

// Take a {0}, a dictionary of getters, and a data source
// and return the right string
const getValue = (getters, values, curlyStr) => {
  const key = trimInside(curlyStr);
  const valueGetter = getters[key];
  return valueGetter(values);
};

// Parse a message body for making irt
export const parseNotification = ({
  messageTemplate,
  type,
  messageValues,
  message
}) => {
  try {
    const parts = [];
    let i = 0;
    for (const match of messageTemplate.matchAll(/\{\d+\}/g) || []) {
      const group = match[0];
      const start = match.index;
      const end = match.index + group.length;

      // Inject the part _before_ the dynamic group
      if (start > i) {
        parts.push({
          text: messageTemplate.slice(i, start),
          highlighted: false
        });
      }

      // Extract the groups value from the template mapping
      const [text, highlighted] = getValue(
        TemplateMapping[type],
        messageValues,
        group
      );

      // Inject this dynamic group
      parts.push({ text, highlighted });

      // Update our parsing progress
      i = end;
    }

    // Add any remaining part _after_ the dynamic groups
    if (i < messageTemplate.length)
      parts.push({ text: messageTemplate.slice(i), highlighted: false });

    return parts;
  } catch (error) {
    console.warn(
      "Could not parse notification, falling back on message",
      error
    );
    return [{ text: message ?? "", highlighted: false }];
  }
};
