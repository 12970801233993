import ko from "@shared/knockout/extended";

import template from "./WorkDayMainPage.template.html";
import { WorkDayCalendar } from "../WorkDayCalendarView/WorkDayCalendar";
import { WorkDayOverview } from "../WorkDayOverview/WorkDayOverview";
import "./WorkDayMainPage.scss";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers.js";
import { isEqual, parseISO } from "date-fns";

export const WorkDayMainPageVM = ({
  showWelcomePage,
  showCalendarView,
  showBuildingPicker,
  showNoBuildingsView,
  showNewFeatureAnnouncementView,
  calendarView,
  user,
  settings,
  preferredLocationId,
  workingHours,
  isNew,
  availabilityStore,
  mobileCalendarViewIsActive,
  newFeatureAnnouncementParams
}) => {
  const currentlySelectedDay = ko.observable(null);

  return {
    showWelcomePage,
    showCalendarView,
    showBuildingPicker,
    showNoBuildingsView,
    showNewFeatureAnnouncementView,
    calendarView,
    user,
    settings,
    preferredLocationId,
    workingHours,
    isNew,
    availabilityStore,
    settings,
    user,
    mobileCalendarViewIsActive,
    newFeatureAnnouncementParams,
    currentlySelectedDay,

    currentlySelectedDayComputation: ko.computed(() => {
      const dateKey = getFormatedActiveDateFromUrl();
      const parsedDate = dateKey && parseISO(dateKey);

      if (!isEqual(parsedDate, currentlySelectedDay()))
        currentlySelectedDay(parsedDate);
    })
  };
};
export const WorkDayMainPage = {
  register: ko => {
    ko.components.register("workDayMainPage-body", {
      template: template
    });
    WorkDayCalendar.register(ko);
    WorkDayOverview.register(ko);
  },

  bodyComponentName: "workDayMainPage-body"
};
