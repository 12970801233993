import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { ConnectionsService } from "@app/services/ConnectionService";
import { pl } from "@shared/utils/pluralize";

export const ConnectionSuggestionVM = (
  suggestion,
  connectionService,
  getSuggestions,
  sendDto,
  mediator = Mediator()
) => {
  const {
    imageHash,
    userId,
    connectionId,
    initials,
    name,
    email,
    numberOfCommonFriends,
    type
  } = suggestion;

  const loading = connectionService.loadingSuggestions;

  const sendSuggestionFeedback = status =>
    connectionService.sendSuggestionFeedback(suggestion, status, sendDto);

  return {
    userId,
    imageHash,
    initials,
    name,
    email,
    reasonLabel: ko.pureComputed(() => {
      if (type === labelTypes.CommonFriends)
        return `${numberOfCommonFriends} mutual ${pl(
          "connection",
          numberOfCommonFriends
        )}`;
      if (type === labelTypes.Random) return "Working near you";
    }),
    onConnect: () => {
      loading(true);
      ConnectionsService()
        .createConnection(connectionId)
        .then(() =>
          Promise.allSettled([sendSuggestionFeedback(true), getSuggestions()])
        );
    },
    onDismiss: () => {
      loading(true);
      sendSuggestionFeedback(false)
        .then(() => getSuggestions())
        .catch(() => {
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: "Something went wrong. Please try again"
          });
          loading(false);
        });
    }
  };
};

const labelTypes = {
  CommonFriends: "CommonFriends",
  Random: "Random"
};
