import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import { groupBy } from "@shared/utils/groupBy.js";
import ko from "@shared/knockout/extended";
import { isSameDay, startOfDay } from "date-fns";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
// Easily create slices of shifts by people in specific locations

const OfficeVisit = ({ person, shift }) => ({
  date: shift.day,
  location: {
    buildingId: shift.buildingId,
    floorId: shift.floorId,
    areaId: shift.areaId,
    deskId: shift.deskId,

    line1: shift.buildingName,
    line2: [shift.floorName, shift.areaName].filter(x => x).join(", "),

    groupId: shift.areaId || shift.floorId || shift.buildingId // Don't group on desk level
  },
  person: {
    email: person.email,
    initials: person.initials,
    name: person.name,
    imageHash: person.imageHash,
    id: person.id
  }
});

OfficeVisit.fromConList = cons =>
  cons.flatMap(personWithShifts =>
    personWithShifts.shifts.map(shift =>
      OfficeVisit({
        person: personWithShifts,
        shift
      })
    )
  );

export const ConnectedShiftsHelper = () => {
  // Step 1: transform all connections' shifts to OfficeVisits
  const getOfficeVisits = () =>
    OfficeVisit.fromConList(ConnectedColleaguesStoreSingleton().all());

  // Step 2: group by date
  const getVisistsByDate = () => groupBy(v => v.date, getOfficeVisits());

  return {
    visitsOnDate: date => {
      const current = getVisistsByDate();
      return current[date] || [];
    }
  };
};

// Step 3: provide helpers to filter by building layer
ConnectedShiftsHelper.inBuilding = (buildingId, visits) =>
  visits.filter(v => v.location.buildingId === buildingId);
ConnectedShiftsHelper.inFloor = (floorId, visits) =>
  visits.filter(v => v.location.floorId === floorId);
ConnectedShiftsHelper.inArea = (areaId, visits) =>
  visits.filter(v => v.location.areaId === areaId);
ConnectedShiftsHelper.inGroup = (groupId, visits) =>
  visits.filter(v => v.location.groupId === groupId);

ConnectedShiftsHelper.visitsInExactLocation = (date, locationId) => {
  const visitsOnDay = ConnectedShiftsHelper()
    .visitsOnDate(date)
    .map(shift =>
      Object.assign({}, shift, {
        person: {
          ...shift.person,
          checkInStatus: ko.pureComputed(() =>
            isSameDay(date, startOfDay(new Date()))
              ? CheckInStatusStoreSingleton().getById(shift.person.id)
              : null
          )
        }
      })
    );

  return ConnectedShiftsHelper.inGroup(ko.unwrap(locationId), visitsOnDay);
};
