export const getExtensions = ko => ({
  // Creates a new computed mapping a function over a value
  map: function map(f) {
    return ko.pureComputed(() => f(this()));
  },

  // Same as map, but does not map if value is undefined
  maybeMap: function maybeMapObservable(f, defaultValue = null) {
    return ko.pureComputed(() => {
      const v = this();
      return v === null ? defaultValue : f(v);
    });
  },

  // Maps a function over the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  mapArray: function mapArray(f) {
    return this.map(xs => xs.map(f));
  },

  // Flatmaps a function over the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  flatMapArray: function mapArray(f) {
    return this.map(xs => xs.flatMap(f));
  },

  concat: function concat(ys) {
    return this.map(xs => xs.concat(ko.unwrap(ys)));
  },

  // Filters the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  filter: function filter(predicate) {
    return this.map(xs => xs.filter(ko.unwrap(predicate)));
  },

  // Maps a function over the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  find: function find(predicate) {
    return this.map(xs => xs.find(predicate));
  },

  // Reduces the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  // Note: seed can be observable or non-observable
  reduce: function reduce(reducer, seed) {
    return ko.pureComputed(() => this().reduce(reducer, ko.unwrap(seed)));
  },

  // Sorts the array inside
  // Note: you need to ensure the value is an array or this
  //       will throw
  // Note: mutates the inner array
  sort: function sort(sorter) {
    return this.map(xs => xs.sort(ko.unwrap(sorter)));
  },

  // Strict equality comparer
  equalityComparer: function equals(a, b) {
    return a === b;
  }
});
