import {
  getFeatures,
  FeatureNames,
  ToggleFeatureNames
} from "@shared/services/Features";

export const isCheckInFeatureEnabled = () =>
  getFeatures().has(FeatureNames.CHECK_IN);

export const isManualCheckInFeatureEnabled = () =>
  getFeatures().has(ToggleFeatureNames.MANUAL_CHECK_IN);
