export class UserAssignmentRuleApiModel {
  /**
   * @param {string} propName The name of the user property to filter on
   * @param {stringp[]} values List of values to allow
   */
  constructor(
    propName,
    values,
    id = null,
    clearManuallyAssignedProfiles = false
  ) {
    if (id !== null) {
      this.id = id;
    }
    this.userAttributeName = propName;
    this.values = values;
    this.clearManuallyAssignedProfiles = clearManuallyAssignedProfiles;
  }

  static equal(a, b) {
    if (a === b) return true;

    return (
      a &&
      b &&
      a.id === b.id &&
      a.userAttributeName === b.userAttributeName &&
      a.clearManuallyAssignedProfiles === b.clearManuallyAssignedProfiles &&
      arraysEqualSlow(a.values.map(toUpper), b.values.map(toUpper))
    );
  }

  static fromDto(dto) {
    if (dto === null) return null;

    return new UserAssignmentRuleApiModel(
      dto.userAttributeName,
      dto.values,
      dto.id,
      dto.clearManuallyAssignedProfiles
    );
  }

  static empty() {
    return new UserAssignmentRuleApiModel(null, [], null);
  }
}

// We know that the values for a rule will be 1 to 10 in total, so O(n * m) should not be an issue
const arraysEqualSlow = (xs, ys) =>
  xs.length === ys.length && xs.every(x => ys.includes(x));

const toUpper = str => str.toUpperCase();
