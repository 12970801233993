import { returnNotOverlappingDate } from "@app/utils/formatDateToNoUTC";
import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";
import { isAfter, isBefore, isEqual, isSameDay } from "date-fns";

const MeetingStore = () => {
  // Note: we're just storing every range passed to update, which
  //       is not an ideal data format for performance, but should
  //       be fine for now because:
  //        a) we will only have a hand full of ranges
  //        b) we're only loading dat once, and while loading we
  //           ensure ranges don't overlap
  const hydratedRanges = ko.observableArray([]);
  const brokenRanges = ko.observableArray([]);

  const cache = ko.observable({});
  const all = cache.map(Object.values);

  const update = (meetings, from, to) => {
    cache(Object.fromEntries(meetings.map(p => [p.id, p])));

    // Reset range as hydrated
    hydratedRanges([[from, to]]);

    return meetings;
  };

  const addRange = (meetings, from, to) => {
    const _cache = cache();

    meetings.forEach(meeting => {
      _cache[meeting.id] = meeting;
    });

    cache.valueHasMutated();
    hydratedRanges.push([from, to]);
  };

  //used after creating a meeting
  const addSingle = meeting => {
    const _cache = cache();
    _cache[meeting.id] = meeting;
    cache.valueHasMutated();
  };

  const onDate = date =>
    all().filter(m => {
      // Three possibilities:
      //  - starts on date
      //  - end on date
      //  - starts before and ends after

      const notOverlappingEndDate = returnNotOverlappingDate(m.endDate);

      if (isSameDay(m.startDate, date)) return true;
      if (isSameDay(notOverlappingEndDate, date)) return true;
      if (isBefore(m.startDate, date) && isAfter(notOverlappingEndDate, date))
        return true;

      return false;
    });

  const remove = id => {
    const _cache = cache();
    delete _cache[id];

    cache.valueHasMutated();
  };

  const completelyBroken = ko.observable(false);
  const partiallyBroken = ko.pureComputed(() => brokenRanges().length > 0);

  return {
    all,
    addRange,
    dateHydrated: date =>
      !completelyBroken() &&
      hydratedRanges().some(([from, to]) => dateInRange(date, from, to)),

    addErrorRange: ([from, to, error]) => brokenRanges.push([from, to, error]),
    dateError: date =>
      completelyBroken() ||
      brokenRanges().some(([from, to, error]) => dateInRange(date, from, to)),

    onDate,
    update,
    get: id => cache()[id] || null,
    remove,
    completelyBroken,
    partiallyBroken,
    addSingle
  };
};

export const MeetingStoreSingleton = Singleton(MeetingStore);

const dateInRange = (date, from, to) =>
  isEqual(date, from) || (isAfter(date, from) && isBefore(date, to));
