import template from "./WorkspaceCreator.template.html";
import "./WorkspaceCreator.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import { format } from "date-fns";

export const WorkspaceBookedByTitle = {
  USER_APP: "Booked for you",
  ADMIN_APP: "Booked on behalf"
};

const WorkspaceCreatorVM = ({ workspace = null, workspaceBookedByTitle }) => {
  const isAdminBooking = workspace?.bookedByAdmin;
  const workspaceBookedBy = workspace?.createdBy || workspace?.lastModifiedBy;

  return {
    workspaceBookedByInfo: isAdminBooking
      ? `By ${workspaceBookedBy.displayName} on ${format(
          workspaceBookedBy.date,
          "dd MMMM yyyy"
        )}`
      : null,
    isAdminBooking,
    workspaceBookedByTitle
  };
};
export const WorkspaceCreator = KOComponent(
  "workspace-creator",
  WorkspaceCreatorVM,
  template
);
