import ko from "@shared/knockout/extended";
import { giveConsent, handleError } from "@app/utils/meetConsent.js";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { MeetApi } from "@shared/services/MeetApi.js";
import template from "./RestoreMeetTile.template.html";

const RestoreMeetTileVM = ({ visible }) => {
  const loading = ko.observable(false);
  return {
    visible,
    loading,
    onRestore: () => {
      loading(true);
      giveConsent().catch(e => {
        handleError(e);
        loading(false);
      });
    },
    dismiss: () => {
      MeetApi.setUserPreference({
        userClosedConsentErrorTile: true
      });
      visible(false);
    }
  };
};

export const RestoreMeetTile = KOComponent(
  "restore-meet-tile",
  RestoreMeetTileVM,
  template
);
