export class ConnectionSuggestionsApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.userId = obj.userId;
    this.created = obj.created;
    this.serveId = obj.serveId;
    this.suggestions =
      obj.suggestions?.map(suggestion => new SuggestionsApiModel(suggestion)) ??
      [];
  }
}

class SuggestionsApiModel {
  constructor(obj) {
    this.type = obj.type;
    this.numberOfCommonFriends = obj.numberOfCommonFriends;
    this.connectionId = obj.connectedUser.id;
    this.name = obj.connectedUser.name;
    this.initials = obj.connectedUser.initials;
    this.email = obj.connectedUser.email;
    this.imageHash = obj.connectedUser.imageHash;
  }
}
