import { ConnectedShiftsHelper } from "@shared/utils/ConnectedShiftsHelper";
import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./ConnectionsWorkingHere.template.html";

const ConnectionsWorkingHereVM = ({ locationId, date }) => {
  return {
    visitsInExactLocation: ConnectedShiftsHelper.visitsInExactLocation(
      date,
      locationId
    )
  };
};

export const ConnectionsWorkingHere = KOComponent(
  "connections-working-here",
  ConnectionsWorkingHereVM,
  template
);
