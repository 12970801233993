import { ConnectionApiModel } from "@app/apimodels/ConnectionApiModel";
import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";
import { sort, stringCompare } from "@shared/utils/sort";
import {
  ConnectionDirection,
  ConnectionStatus
} from "../components/popups/ConnectionsPage/ConnectionStatus";

export const ConnectionsStore = () => {
  const cache = ko.observable({});
  const all = cache.map(Object.values).map(
    sort((c1, c2) => {
      // Name based first:
      if (c1.name && c2.name) return stringCompare(c1.name, c2.name);
      if (c1.name) return -1;
      if (c2.name) return 1;

      // If both don't have names, use email
      return stringCompare(c1.email, c2.email);
    })
  );

  const allAccepted = all.filter(c => c.status === ConnectionStatus.Accepted);

  const allPendingOutgoing = all.filter(
    c =>
      c.status === ConnectionStatus.Pending &&
      c.direction === ConnectionDirection.Outgoing
  );

  const allPendingIncoming = all.filter(
    c =>
      c.status === ConnectionStatus.Pending &&
      c.direction === ConnectionDirection.Incoming
  );

  const allIgnored = all.filter(c => c.status === ConnectionStatus.Ignored);

  /**
   * Set new cache
   * @param {Array} allConnections
   */
  const update = allConnections => {
    const oldCache = cache();
    const newCache = Object.fromEntries(
      allConnections.map(c => {
        const oldRef = oldCache[c.id];

        // Reuse the references of the old API models
        if (oldRef && ConnectionApiModel.equal(c, oldRef)) {
          return [c.id, oldRef];
        }

        return [c.id, c];
      })
    );
    cache.equalityComparer = (a, b) => JSON.stringify(a) === JSON.stringify(b);
    cache(newCache);
    error(null);
  };

  const add = connection => {
    const _cache = cache();
    _cache[connection.id] = connection;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  const getByUserId = userId => all().find(c => c.userId === userId) || null;

  const error = ko.observable(null);

  const registerError = e => error(e);

  return {
    update,
    add,
    remove,
    getByUserId,
    all,
    allPendingOutgoing,
    allPendingIncoming,
    allAccepted,
    allIgnored,
    registerError,
    error
  };
};

export const ConnectionsStoreSingleton = Singleton(ConnectionsStore);
