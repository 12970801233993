import template from "./BookButtonIcon.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import "./BookButtonIcon.scss";

const BookButtonIconVM = ({ booked, disableBookButton }) => {
  return {
    booked,
    disableBookButton,
    cssClass: booked.maybeMap(booked =>
      booked ? "CalendarView-action--green" : "CalendarView-action--book"
    )
  };
};

export const BookButtonIcon = KOComponent(
  "book-button-icon",
  BookButtonIconVM,
  template
);
