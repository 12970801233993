import ko from "@shared/knockout/extended";
import { groupByC } from "@shared/utils/groupBy";
import {
  getDateKey,
  getDateKeyForFeature,
  getWeekKeyForFeature
} from "@shared/utils/dateHelpers";
import { Singleton } from "@shared/utils/Singleton";

/**
 * Stores a set of workspaces for the current selected user
 */
const WorkspaceStore = () => {
  const cache = ko.observable({});
  const all = cache.map(Object.values);

  const byWeek = all.map(groupByC(getWeekKeyForFeature));
  const byDate = all.map(groupByC(getDateKeyForFeature));

  const getAllGroups = all.filter(
    ({ groupReservationId, members }) =>
      groupReservationId && members.length > 1
  );

  /**
   * Set all workspaces (overwriting any old state)
   * @param {Array} allWorkspaces
   */
  const update = allWorkspaces =>
    cache(Object.fromEntries(allWorkspaces.map(s => [s.id, s])));

  /**
   * Get all workspaces for a given day
   * Note: for single users, you'd expect this to always be an
   * array of length 1
   * @param {Date} date
   * @returns {Array}
   */
  const getForDate = date => {
    const k = getDateKey(date);

    return byDate()[k] || [];
  };

  /**
   * Get all workspaces for a given week
   * @param {Number} weekNr
   * @returns {Array}
   */
  const getForWeekNr = weekNr => {
    return byWeek()[weekNr] || [];
  };

  const getById = id => {
    const _cache = cache();
    return _cache[id];
  };

  const add = workspace => {
    const _cache = cache();
    _cache[workspace.id] = workspace;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  return {
    update,
    getForDate,
    getForWeekNr,
    getById,
    getAllGroups,
    add,
    remove,
    all
  };
};

export const WorkspaceStoreSingleton = Singleton(WorkspaceStore);
