import bodyTemplate from "./WorkDayWizard.template.html";
import notInOfficeOptionTileTemplate from "./NotInOfficeOptionTile/NotInOfficeOptionTile.template.html";
import officeOptionTileTemplate from "./OfficeOptionTile/OfficeOptionTile.template.html";
import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { groupByAvailability } from "@shared/utils/OfficeDay/helpers";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { byPropAsc, combineSorters } from "@shared/utils/sort";
import { naturalStringSortAsc } from "@shared/utils/sortHelper";
import { PopupManagerVM } from "../PopupManager/PopupManagerVM";
import { CheckInServiceSingleton } from "@app/services/CheckInService";

import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import "./WorkDayWizard.scss";
import {
  isToday,
  addDays,
  startOfDay,
  differenceInDays,
  getISOWeek,
  format
} from "date-fns";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import {
  sortByDefaultOffice,
  sortByLastSelectedOffice
} from "@shared/utils/OfficeDay/sorters";
import {
  WorkdayStatus,
  WorkdayStatusIcon,
  WorkdayStatusLabel
} from "@shared/utils/OfficeDay/status";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";
import { UserApp } from "@app/UserApp";

export const WorkDayWizardVM = ({ day }) => {
  const checkInStore = CheckInServiceSingleton();
  const locationStore = LocationStoreSingleton();
  const checkInBuildingId = checkInStore.buildingId;
  const currentUser = UserApp.resolveUser();
  const workday = ko
    .pureComputed(() => WorkdayReservationsStoreSingleton().getForDate(day))
    .map(getFirstElement);

  const workspace = ko
    .pureComputed(() => WorkspaceServiceSingleton().getConfirmedForDate(day))
    .map(getFirstElement)
    .map(_workspace => {
      if (!_workspace) return null;
      const workspaceVM = WorkspaceVM(_workspace);
      return workspaceVM.owner.userId === currentUser.id ? workspaceVM : null;
    });

  const userIsCheckedInDifferentOffice = ko.pureComputed(() =>
    checkInStore.isCheckedInDifferentOffice(day, workday()?.nodeId)
  );

  const selectedOfficeId = ko.pureComputed(() => workday()?.nodeId);

  const checkInBuilding = ko.pureComputed(() => {
    const office = locationStore.get(checkInBuildingId());
    // In case that checkedin office is removed from users location profile
    return office && userIsCheckedInDifferentOffice()
      ? OfficeVM(office, day, selectedOfficeId(), workspace, workday)
      : null;
  });

  const sorter = combineSorters(
    sortByLastSelectedOffice,
    sortByDefaultOffice,
    byPropAsc("name", naturalStringSortAsc)
  );

  const groups = ko.pureComputed(() => {
    const offices = locationStore
      .getBuildings()
      .sort(sorter)
      .map(office =>
        OfficeVM(office, day, selectedOfficeId(), workspace, workday)
      )
      .filter(
        office =>
          !(
            isToday(day) &&
            office.id === checkInBuildingId() &&
            userIsCheckedInDifferentOffice() &&
            !office.closed()
          )
      );

    return groupByAvailability(offices);
  });

  return {
    showCheckInBuilding: ko.pureComputed(
      () =>
        isToday(day) &&
        checkInBuilding() &&
        !checkInBuilding()?.closed() &&
        userIsCheckedInDifferentOffice()
    ),
    checkInBuilding,
    groups,
    workingRemotelyParams: NotInOfficeOptionVM(
      WorkdayStatus.WorkingRemotely,
      day,
      workday
    ),
    notWorkingParams: NotInOfficeOptionVM(
      WorkdayStatus.NotWorking,
      day,
      workday
    )
  };
};

// Extend VM with helpers for popup manager
const { getTitle, hasCloseButton } = PopupManagerVM.Keys;

Object.assign(WorkDayWizardVM, {
  [getTitle]: () => "Your location",
  [hasCloseButton]: () => true
});

export const WorkDayWizard = {
  register: ko => {
    ko.components.register("not-in-office-option-tile", {
      template: notInOfficeOptionTileTemplate
    });
    ko.components.register("office-option-tile", {
      template: officeOptionTileTemplate
    });
    ko.components.register("workday-reservation-wizard-body", {
      template: bodyTemplate
    });
  }
};

const OfficeVM = (
  office,
  day,
  selectedOfficeId,
  workspace,
  workday,
  mediator = Mediator()
) => {
  const id = office.id;
  const _workspace = ko.unwrap(workspace);
  const allowWorkdayWithoutWorkspace = office.allowWorkdayWithoutWorkspace;
  const {
    closed,
    full,
    availability,
    availabilityLabel,
    colorClass,
    percentage
  } = availabilityLocationData(day, office);

  const isSelected = ko.pureComputed(() => id === selectedOfficeId);

  const profile = UserApp.resolveUser().accessProfile;

  const isTooFarAhead = ko.pureComputed(() => {
    const lastBookableDay = addDays(startOfDay(new Date()), profile.daysAhead);
    const dayDiff = differenceInDays(lastBookableDay, day);
    return dayDiff < 0;
  });

  const maxReached = ko.pureComputed(() => {
    const weekNr = getISOWeek(day);
    const workspacesThisWeek = WorkspaceServiceSingleton().getConfirmedForWeekNr(
      weekNr
    ).length;

    const max = profile?.daysPerWeek;
    return workspacesThisWeek >= max;
  });

  const closedDueRegistrationDays = !profile.registrationDays.includes(
    format(day, "eeee")
  );

  const isDisabled = ko.pureComputed(
    () =>
      closed() ||
      (!allowWorkdayWithoutWorkspace &&
        (full() ||
          (!_workspace &&
            (maxReached() || isTooFarAhead() || closedDueRegistrationDays))))
  );
  const quotaLabel = ko.pureComputed(() =>
    !allowWorkdayWithoutWorkspace && !_workspace
      ? isTooFarAhead()
        ? "You can’t book this far ahead"
        : maxReached()
        ? "Workspace quota has been reached"
        : closedDueRegistrationDays
        ? "You can`t book on this day"
        : availabilityLabel()
      : availabilityLabel()
  );

  const onClick = self => {
    if (
      isDisabled() ||
      (id === workday()?.nodeId && allowWorkdayWithoutWorkspace)
    )
      return;

    if (allowWorkdayWithoutWorkspace) {
      WorkdayReservationServiceSingleton().handleWorkdayReservation(
        self.status,
        day,
        id
      );
    } else {
      mediator.publish(Channels.OpenWorkspaceWizard, {
        date: day,
        selectedWorkdayOfficeId: id,
        shift: _workspace,
        workday
      });
    }
  };
  return {
    quotaLabel,
    id,
    name: office.name,
    allowWorkdayWithoutWorkspace,
    capacity: office.capacity,
    full,
    availability,
    colorClass,
    percentage,
    closed,
    isDisabled,
    onClick,
    isSelected,
    status: WorkdayStatus.OfficeDay
  };
};

const NotInOfficeOptionVM = (status, day, workday) => {
  return {
    isSelected: ko.pureComputed(() => workday()?.status === status),
    iconName: WorkdayStatusIcon[status],
    status,
    label: WorkdayStatusLabel[status],
    onClick: () => {
      if (workday()?.status === status) return;
      WorkdayReservationServiceSingleton().handleWorkdayReservation(
        status,
        day
      );
    }
  };
};
