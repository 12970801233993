import { pl } from "@shared/utils/pluralize";
export function UserVM(user) {
  const {
    accessProfile: { daysPerWeek, daysAhead, registrationDays },
    parkingQuotaProfile
  } = user;

  const shiftsPerWeekString = `${daysPerWeek} workspace ${pl(
    "booking",
    daysPerWeek
  )} each week`;

  const parkingDaysPerWeek = parkingQuotaProfile.daysPerWeek;
  const parkingsPerWeekString = `${parkingDaysPerWeek} parking ${pl(
    "spot",
    parkingDaysPerWeek
  )} each week`;

  const leadTime =
    daysAhead === 7
      ? "1 week"
      : daysAhead === 14
      ? "2 weeks"
      : `${daysAhead} day${daysAhead === 1 ? "" : "s"}`;

  const inAdvanceString = `You can plan ${leadTime} in advance`;

  const allWeek = registrationDays.length === 7;
  const businessDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday"
  ].every(d => registrationDays.includes(d));

  const weekend = ["Saturday", "Sunday"].find(weekendDay =>
    registrationDays.includes(weekendDay)
  );

  const daysOfWeekString = allWeek
    ? `Any day of the week`
    : businessDays
    ? `On workdays${weekend ? ` and ${weekend}` : ""}`
    : registrationDays.length
    ? `On ${registrationDays.join(", ").replace(/, ([^,]*)$/, " and $1")}`
    : "";

  return Object.assign({}, user, {
    shiftsPerWeekString,
    inAdvanceString,
    daysOfWeekString,
    parkingsPerWeekString
  });
}
