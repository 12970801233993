export const Channels = {
  //app
  CloseWelcomePage: "closeWelcomePage",
  OpenLocationPicker: "openLocationPicker",
  CloseLocationPicker: "closeLocationPicker",
  UpdateAvailability: "updateAvailability",
  OpenConnectionDeleteWindow: "openConnectionDeleteWindow",
  CloseConnectionDeleteWindow: "closeConnectionDeleteWindow",
  OpenConnectionAddWindow: "openConnectionAddWindow",
  CloseConnectionAddWindow: "closeConnectionAddWindow",
  OpenWorkspaceWizard: "openWorkspaceWizard",
  CloseWorkspaceWizard: "closeWorkspaceWizard",
  OpenConnectionFilter: "openConnectionFilter",
  CloseConnectionFilter: "closeConnectionFilter",
  OpenConnectionErrorWindow: "openConnectionErrorWindow",
  CloseConnectionErrorWindow: "closeConnectionErrorWindow",
  RequestConnectionsUpdate: "requestConnectionsUpdate",
  OpenSidePanel: "openSidePanel",
  CloseSidePanel: "closeSidePanel",
  PageIsActive: "pageIsActive",
  OpenProfilePictureUploadWindow: "openProfilePictureUploadWindow",
  DisposeSidePanelComponents: "disposeSidePanelComponents",
  ToggleAttendeesList: "toggleAttendeesList",
  ToggleRoomDetails: "toggleRoomDetails",
  ToggleConfirmationWindow: "toggleConfirmationWindow",
  CloseErrorConsentModal: "closeErrorConsentModal",
  ConsentRedirectResult: "consentRedirectResult",
  UpdateParkingAvailability: "updateParkingAvailability",
  OpenParkingReservationWindow: "openParkingReservationWindow",
  CloseParkingReservationWindow: "closeParkingReservationWindow",
  OpenWorkdayWizard: "OpenWorkdayWizard",
  CloseWorkdayWizard: "CloseWorkdayWizard",
  OpenExtrasWizard: "openExtrasWizard",
  OpenRoomPicker: "openRoomPicker",
  CloseRoomPicker: "closeRoomPicker",
  OpenBookRoom: "openBookRoom",
  CloseBookRoom: "closeBookRoom",
  HideBookedRoom: "hideBookedRoom",

  //admin
  OpenConfirmationWindow: "openConfirmationWindow",
  CloseConfirmationWindow: "closeConfirmationWindow",
  CloseBuildingCreation: "closeBuildingCreation",
  CloseBuildingEdit: "closeBuildingEdit",
  BuildingSelector: "buildingSelector",
  DiscardChanges: "discardChanges",
  UnsavedChanges: "unsavedChanges",
  UnsavedBuildingChanges: "unsavedBuildingChanges",
  OpenNewShiftSummary: "openNewShiftSummary",
  SubViewIndicator: "subViewIndicator",
  StartBuildingExport: "startBuildingExport",
  FinishBuildingExport: "finishBuildingExport",

  SensorsImported: "sensorsImported",

  OpenImportWindow: "openCustomImportWindow",
  CloseImportWindow: "closeCustomImportWindow",

  OpenFloorplanEditor: "OpenFloorplanEditor",
  CloseFloorplanEditor: "CloseFloorplanEditor",
  CloseSensorEditor: "CloseSensorEditor",

  Refresh: "refresh",
  RefreshShifts: "refreshShift",
  RefreshParkingReservations: "refreshParkingReservations",
  RefreshNumberOfUsersForShiftQuotaProfiles:
    "refreshNumberOfUsersForShiftQuotaProfiles",
  RefreshNumberOfUsersForParkingQuotaProfiles:
    "refreshNumberOfUsersForParkingQuotaProfiles",
  RefreshNumberOfUsersForShiftLocationProfiles:
    "refreshNumberOfUsersForShiftLocationProfiles",
  RefreshNumberOfUsersForParkingLocationProfile:
    "refreshNumberOfUsersForParkingLocationProfiles",
  ToggleAllowedDays: "ToggleAllowedDays",
  GetAccounts: "getAccounts",
  GetTotalNumberOfAccounts: "getTotalNumberOfAccounts",
  UpdateSelectedAccounts: "updateSelectedAccounts",
  OpenErrorMessage: "openErrorMessage",
  CloseErrorMessage: "closeErrorMessage",

  OpenModal: "openModal",
  CloseModal: "closeModal",
  OpenLogin: "openLogin",

  RefreshTab: "refreshTab",
  OpenTab: "openTab",
  NewTabArgs: "newTabArgs",
  CloseSettings: "closeSettings",

  EditLocationProfileAccess: "editLocationProfileAccess",

  DeselectAllUsers: "deselectAllUsers",
  SelectUser: "selectUser",

  CloseOfficeInstructions: "closeOfficeInstructions",

  OpenEditRegistration: "openEditRegistration",
  CloseEditRegistration: "closeEditRegistration",

  OpenBookedWorkspaceOverview: "openBookedWorkspaceOverview",

  CloseAddAccountWindow: "closeAddAccountWindow",

  CloseGroupEditor: "closeGroupEditor",

  CloseSideBuilding: "closeSideBuilding",
  OpenDaysEditor: "openDaysEditor",
  CloseDatePicker: "closeDatePicker",

  CloseFlatpickr: "closeFlatpickr",

  OpenRoomPictureUpload: "OpenRoomPictureUpload",
  CloseRoomPictureUpload: "CloseRoomPictureUpload",

  //shared components
  OpenStatusMessage: "openStatusMessage",
  CloseStatusMessage: "closeStatusMessage",
  OpenModal: "openModal",
  CloseModal: "closeModal",
  TrackAIEvent: "trackAIEvent",
  StartTrackAITimingEvent: "startTrackAITimingEvent",
  StopTrackAITimingEvent: "stopTrackAITimingEvent",

  ShowPromiseConfirmation: "showPromiseConfirmation",
  HidePromiseConfirmation: "hidePromiseConfirmation",

  HandleFilterClick: "handleFilterClick",

  //accounts
  OpenAddAccount: "openAddAccount",
  CloseAddAccount: "closeAddAccount",
  OpenStats: "openStats",
  CloseStats: "closeStats",
  DeselectAccount: "deselectAccount",
  CancelSubCreation: "cancelSubCreation",
  CloseAuthSettings: "closeAuthSettings",
  openXMLSettings: "openXMLSettings",
  AddConfiguration: "addConfiguration",

  // settings
  OpenIntegrationsSettings: "openIntegrationsSettings"
};
