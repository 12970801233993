import svgPanZoom from "svg-pan-zoom";
import ko from "@shared/knockout/extended";
import {
  getAreaIdForElement,
  getDeskIdForElement,
  LayerState
} from "@app/utils/svg-helpers.js";
import { panToElement, zoomToElement } from "./zoomToElement.js";

export const interactiveSvg = {
  init: (el, va) => {
    const {
      selectedDeskIds,
      occupiedDeskIds,
      disabledDeskIds,
      connections,
      selectedConnection,
      selectDeskIdCallback,
      ...staticParams
    } = va();
    ko.applyBindingsToNode(el, { staticSvg: staticParams });

    const svgEl = el.querySelector("svg");

    // Allow elements to stick to eachother during pan/zoom
    let hunter = null;
    let hunted = null;
    let hunterStart = null;

    // Positions the hunter next to the hunted
    const trackHunt = e => {
      if (hunter && hunted && hunterStart) {
        const huntedBb = hunted.getBoundingClientRect();

        const cx1 = huntedBb.right + 5; // 5px from right of circle
        const cy1 = huntedBb.top + huntedBb.height / 2;
        const cx2 = hunterStart.left;
        const cy2 = hunterStart.top + hunterStart.height / 2;

        const dx = cx1 - cx2;
        const dy = cy1 - cy2;

        hunter.style.transform = `translate3d(${dx}px, ${dy}px, 0)`;
      }
    };

    // Create panzoom instance for panning and zooming (doh)
    const panzoom = svgPanZoom(svgEl, {
      dblClickZoomEnabled: false,

      // If an HTML element is overlayed on top of the SVG, make sure
      // it tracks the right element when changing the viewport
      onPan: trackHunt,
      onZoom: trackHunt
    });

    panzoom.resize();
    panzoom.fit();
    panzoom.center();

    const eventDisposers = [];
    let startOfClickInteraction = null;

    // Handle connection pins
    const getPin = deskId =>
      svgEl.querySelector(
        `[data-type='deskLayer'][data-id='${deskId}'] [data-type='pinOutline']`
      );

    // TODO: include in StateUpdate
    connections.forEach(c => {
      const pin = getPin(c.workspace.deskId);

      if (pin) {
        pin.style.display = "unset";
        pin.style.stroke = "white";
        pin.style.strokeWidth = "2";
        pin.style.fill = c.color;
      }
    });

    if (ko.isWritableObservable(selectedConnection)) {
      eventDisposers.push(
        selectedConnection.subscribe(c => {
          if (c) {
            hunted = getPin(c.workspace.deskId);
            // TODO: find a better way without accessing DOM directly
            hunter = document.querySelector("[data-hunter]");
            hunterStart = hunter?.getBoundingClientRect();
            trackHunt();
          } else {
            hunted = null;
            hunter = null;
          }

          const throughMap = !!startOfClickInteraction;

          if (throughMap) {
            return;
          }

          if (c) {
            hunter.style.display = "none";

            if (c.workspace?.deskId) {
              panToElement(
                panzoom,
                svgEl.querySelector(".svg-pan-zoom_viewport"),
                svgEl.querySelector(`[data-id="${c.workspace.deskId}"]`),
                () => {
                  trackHunt();
                  hunter.style.display = "";
                }
              );
            }
          } else {
            const deskSelection = ko.unwrap(selectedDeskIds)?.[0];
            if (deskSelection) {
              panToElement(
                panzoom,
                svgEl.querySelector(".svg-pan-zoom_viewport"),
                svgEl.querySelector(`[data-id="${deskSelection}"]`)
              );
            }
          }
        })
      );
    }

    // Attach custom click/tap handlers if needed
    if (ko.isWritableObservable(selectedDeskIds)) {
      // Pan to selected desk
      const selection = selectedDeskIds.peek();
      if (selection) {
        zoomToElement(
          panzoom,
          svgEl.querySelector(".svg-pan-zoom_viewport"),
          svgEl.querySelector(`[data-id="${selection[0]}"]`)
        );
      }

      const onSelect = e => {
        const deskId = getDeskIdForElement(e.target);
        if (deskId) {
          // Only allow selections in the highlighted area
          const areaId = getAreaIdForElement(e.target);
          const overrides = ko.unwrap(staticParams.nodeOverrides) || {};
          if (overrides[areaId] !== LayerState.Highlighted) return;

          // Don't select occupied or disabled desks
          if ((ko.unwrap(occupiedDeskIds) || []).includes(deskId)) {
            const conWorkingHere = connections.find(
              c => c.workspace.deskId === deskId
            );
            if (conWorkingHere) {
              selectedConnection(conWorkingHere);
            }
            return;
          }

          if ((ko.unwrap(disabledDeskIds) || []).includes(deskId)) return;

          selectDeskIdCallback(deskId);
        }

        // Any tap that is not inside a desk and inside a connection is a deselect
        selectedConnection(null);
      };

      const onTapStart = () => {
        startOfClickInteraction = panzoom.getPan();
      };

      const onTapEnd = e => {
        // Because we don't have access to the internal state property which
        // tracks whether the user is panning, we recreate the behavior here.
        // When a touch/click starts, we check the pan location
        // When it ends, we check the total Manhattan distance the map was panned
        // If the distance is below a threshold, we assume it was a click meant
        // for selection
        if (startOfClickInteraction) {
          const PAN_THRESHOLD = 5;
          const endOfClickInteraction = panzoom.getPan();
          const dragDist =
            Math.abs(endOfClickInteraction.x - startOfClickInteraction.x) +
            Math.abs(endOfClickInteraction.y - startOfClickInteraction.y);

          if (dragDist < PAN_THRESHOLD) {
            onSelect(e);
          }

          startOfClickInteraction = null;
        }
      };

      // Attach event listeners
      ["mousedown", "touchstart"].forEach(eventName => {
        svgEl.addEventListener(eventName, onTapStart);
        eventDisposers.push({
          dispose: () => svgEl.removeEventListener(eventName, onTapStart)
        });
      });

      ["mouseup", "touchend"].forEach(eventName => {
        svgEl.addEventListener(eventName, onTapEnd);
        eventDisposers.push({
          dispose: () => svgEl.removeEventListener(eventName, onTapEnd)
        });
      });
    } else {
      zoomToElement(
        panzoom,
        svgEl.querySelector(".svg-pan-zoom_viewport"),
        svgEl.querySelector(`[data-type='floorOutline']`)
      );
    }

    ko.utils.domNodeDisposal.addDisposeCallback(el, function() {
      panzoom.destroy();
      eventDisposers.forEach(ed => ed.dispose());
    });

    return { controlsDescendantBindings: true };
  }
};
