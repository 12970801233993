import { AvailabilityParkingStoreSingleton } from "@app/services/AvailabilityParkingStore";
import { ParkingLotStoreSingleton } from "@app/services/ParkingLotStore";
import { AvailabilityDataVM } from "./AvailabilityDataVM";

export const availabilityParkingData = (day, parking) => {
  const currentParkingLot = ParkingLotStoreSingleton().getParkingLotForId(
    parking.id
  );

  const availabilityData = AvailabilityDataVM(
    parking,
    currentParkingLot,
    AvailabilityParkingStoreSingleton(),
    day
  );

  return {
    ...availabilityData
  };
};
