import {
  WorkdayStatus,
  WorkdayStatusIcon,
  WorkdayStatusLabel
} from "@shared/utils/OfficeDay/status";

export const WorkdayVM = workday => {
  const status = workday.status;

  return {
    ...workday,
    date: workday.startDate,

    statusLabel: workday.nodeName ?? WorkdayStatusLabel[status],

    isOfficeDay: status === WorkdayStatus.OfficeDay,
    icon: WorkdayStatusIcon[status]
  };
};
