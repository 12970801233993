import { ConsentStatusApiModel } from "@shared/apimodels/ConsentStatusApiModel.js";
import { GlobalMeetingSettingsApiModel } from "@shared/apimodels/GlobalMeetingSettingsApiModel.js";
import { MeetingsSettingsApiModel } from "@shared/apimodels/MeetingsSettingsApiModel.js";
import { Fetch } from "@shared/services/auth/authFetch.js";
import { parseISO } from "date-fns";

const f = Fetch(process.env.MAPIQ_API_BASE_URL_MEET);

export const MeetApi = {
  /**
   * Set whether the user _wants_ to use calendar features
   */
  setUserPreference: preference => {
    return f.patch("/meetings/me/preferences", preference);
  },

  /**
   * Retrieve whether the user _wants_ to use calendar features
   */
  getUserPreference: () => f.get("/meetings/me/preferences"),

  setUserDismissedOnboarding: adminMeetingsIntegrationOnboardingDismissed =>
    f.patch("/meetings/me/preferences", {
      adminMeetingsIntegrationOnboardingDismissed,
      // for backwards compatibility:
      solutionOnboardingDismissed: adminMeetingsIntegrationOnboardingDismissed
    }),

  /**
   * Get the URL to redirect the user to so that they can give Mapiq consent
   * to fetch their calendar
   */
  getConsentUrl: (rehearsal = false, cloudSuite, isAdmin) => {
    const makeRedirectUri = paramsDict => {
      const { origin, pathname, hash } = document.location;
      const params = new URLSearchParams(paramsDict);

      return encodeURIComponent(
        [origin, pathname, `?${params.toString()}`, hash].join("")
      );
    };

    const redirectUriSuccess = makeRedirectUri({
      redirect_source: "meet_consent",
      result: "success"
    });

    const redirectUriWhenRejects = makeRedirectUri({
      redirect_source: "meet_consent",
      result: "reject"
    });

    const redirectUriOnFailure = makeRedirectUri({
      redirect_source: "meet_consent",
      result: "failure"
    });

    let url = `/meetings/consents/user/consentUrl?redirectUri=${redirectUriSuccess}&redirectUriWhenRejects=${redirectUriWhenRejects}&redirectUriOnFailure=${redirectUriOnFailure}&isAdminPortal=${
      isAdmin ? "true" : "false"
    }`;

    if (rehearsal) {
      url += `&rehearsalCloudSuite=${cloudSuite}`;
    }

    return f.get(url);
  },

  getConsentStatus: () =>
    f.get("/meetings/consents/status").then(ConsentStatusApiModel.fromApiObj),

  /**
   * Retrieve whether a subscription admin has enabled the meeting features for users
   */
  getMeetingSettings: () => {
    return f
      .get("/meetings/settings")
      .then(MeetingsSettingsApiModel.fromApiObj);
  },
  getEvents: (from, to, isRehearsal = false) => {
    return f
      .get(
        `/meetings/me/events?startDate=${from.toISOString()}&endDate=${to.toISOString()}&isRehearsal=${isRehearsal}`
      )
      .then(apiMeetings =>
        apiMeetings.map(m => ({
          ...m,
          startDateTime: parseISO(m.startDateTime),
          endDateTime: parseISO(m.endDateTime)
        }))
      );
  },
  getGlobalMeetingSettings: () => {
    return f
      .get("/meetings/settings/global")
      .then(GlobalMeetingSettingsApiModel.fromApiObj);
  }
};
