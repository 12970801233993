export const EventNames = {
  CreateBuilding: "CreateBuilding",
  EditBuilding: "EditBuilding",
  DeleteBuilding: "DeleteBuilding",
  CreateProfile: "CreateProfile",
  EditProfile: "EditProfile",
  DeleteProfile: "DeleteProfile",
  ImportUsers: "ImportUsers",
  ExportUsers: "ExportUsers",
  EditSettings: "EditSettings",
  SelectCalendarDate: "SelectCalendarDate",
  ExportShifts: "ExportShifts",
  ExportParkingReservations: "ExportParkingReservations",

  // For deleting from the shifts pane
  DeleteShift: "DeleteShift",
  DeleteParkingReservation: "DeleteParkingReservation",

  CreateUser: "CreateUser", // v
  DeleteUser: "DeleteUser", // v
  DeleteMultipleUsers: "DeleteMultipleUsers",
  EditSingleUser: "EditSingleUser", // v
  EditMultipleUsers: "EditMultipleUsers", // v

  ExpectedGuestInUserCreation: "ExpectedGuestInUserCreation",
  ExpectedUserInGuestCreation: "ExpectedUserInGuestCreation",

  CreateGuest: "CreateGuest", // v
  DeleteGuest: "DeleteGuest", // v
  EditGuest: "EditGuest", // v

  ClickShiftEmailSingle: "ClickShiftEmailSingle",
  ClickShiftEmailOverview: "ClickShiftEmailOverview",

  BookShiftForGuest: "BookShiftForGuest", // v
  DeleteShiftForGuest: "DeleteShiftForGuest", // v
  EditShiftForGuest: "EditShiftForGuest", // v

  BookShiftForUser: "BookShiftForUser", // v
  DeleteShiftForUser: "DeleteShiftForUser", // v
  EditShiftForUser: "EditShiftForUser", // v

  CreateAdminRole: "CreateAdminRole",
  DeleteAdminRole: "DeleteAdminRole",
  ModifyAdminRole: "ModifyAdminRole",

  LoadTimeBuildingTab: "LoadTimeBuildingTab",
  LoadTimeMainApp: "LoadTimeMainApp",

  // Workday
  OpenWorkdayListFromCalendar: "OpenWorkdayListFromCalendar"
};

export const ResultTypes = {
  Success: "Success",
  Fail: "Fail",
  Undefined: "Undefined"
};
