import "./ConnectionSuggestions.scss";
import template from "./ConnectionSuggestions.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { ConnectionSuggestionVM } from "./ViewModels/ConnectionSuggestionVM";
import { ConnectionsService } from "@app/services/ConnectionService";

const ConnectionSuggestionsVM = () => {
  const connectionService = ConnectionsService();
  const loading = connectionService.loadingSuggestions;
  const sendDto = ko.observable(null);

  const connectionSuggestions = ko
    .observableArray([])
    .extend({ deferred: true });

  const getSuggestions = () => {
    loading(true);
    connectionService
      .getConnectionSuggestions()
      .then(({ id, userId, created, serveId, suggestions }) => {
        sendDto({
          suggestionId: id,
          userId,
          created,
          serveId
        });
        connectionSuggestions(
          suggestions.map(suggestion =>
            ConnectionSuggestionVM(
              suggestion,
              connectionService,
              getSuggestions,
              sendDto
            )
          )
        );
      })
      .finally(() => loading(false));
  };
  getSuggestions();

  return {
    connectionSuggestions,
    loading,
    suggestionsExist: ko.pureComputed(() => !!connectionSuggestions().length)
  };
};

export const ConnectionSuggestions = KOComponent(
  "connection-suggestions",
  ConnectionSuggestionsVM,
  template
);
