import "./Modal.scss";
import template from "./Modal.template.html";

export const Modal = {
  register: ko => {
    ko.components.register("modal", {
      template,
      // VM is copied in params
      viewModel: {
        createViewModel: ({ vm }) => {
          return vm;
        }
      }
    });
  }
};
