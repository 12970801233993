import ParkingLotZoneApiModel from "@shared/apimodels/ParkingLotZoneApiModel";
import { pl } from "@shared/utils/pluralize";
import { byPropAsc } from "@shared/utils/sort";
import { naturalStringSortAsc } from "@shared/utils/sortHelper";

class ParkingLotApiModel {
  constructor(obj) {
    this.id = obj.parkingLot.id;
    this.name = obj.parkingLot.name;
    this.capacity = obj.parkingLot.capacity;

    this.buildingIds = obj.buildingIds;
    this.parkingZones = (obj.parkingLot.parkingZones || [])
      .sort(byPropAsc("name", naturalStringSortAsc))
      .map(zone => new ParkingLotZoneApiModel(zone));
    this.accessibleFrom = this.buildingIds.length;
    this.accessibleFromString = `This parking lot is connected 
    to ${this.accessibleFrom} ${pl("building", this.accessibleFrom)}.`;
  }
}

export default ParkingLotApiModel;
