import { byPropAsc, combineSorters } from "@shared/utils/sort";
import {
  AvailabilityStatus,
  byAttendeeAvailabilityStatus
} from "@app/apimodels/AvailabilityStatus";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { AttendeeWithAvailabilityVM } from "../viewmodels/AttendeeWithAvailabilityVM";
import { AppApi } from "@app/services/AppApi";
import { UserApp } from "@app/UserApp";
import { MeetingAttendeeApiModel } from "@app/apimodels/MeetingAttendeeApiModel";
import { OfficeDayVM } from "@app/viewmodels/OfficeDayVM";

/**
 *
 * @param {InviteeVM[]} attendees
 * @param {*} meetingStartDateTime
 * @param {*} meetingEndDateTime
 * @param {*} isInvalidTimeRange
 * @returns
 */
export const getInviteeAvailability = (
  attendees,
  meetingStartDateTime,
  meetingEndDateTime,
  isInvalidTimeRange,
  date,
  day
) => {
  const currentUser = UserApp.resolveUser();
  const userOfficeDay = OfficeDayVM(date);
  const userAsAttendee = MeetingAttendeeApiModel.fromApiObj({
    ...currentUser,
    displayName: "You",
    shiftBuildingId: userOfficeDay.buildingId,
    shiftBuildingName: userOfficeDay.buildingName,
    availability: "Available",
    locationType: userOfficeDay.locationType
  });

  if (
    isInvalidTimeRange &&
    isInvalidTimeRange(meetingStartDateTime, meetingEndDateTime)
  ) {
    const clientSideCalculated = attendees
      .concat(userAsAttendee)
      .map(a => AttendeeWithAvailabilityVM(a, day, AvailabilityStatus.Unknown))
      .sort(
        combineSorters(
          byPropAsc("displayName", stringCompareAsc),
          byPropAsc("initials", stringCompareAsc),
          byPropAsc("email", stringCompareAsc)
        )
      );

    return Promise.resolve(clientSideCalculated);
  }

  return AppApi.getAttendeesAvailability(
    attendees.map(a => a.email),
    meetingStartDateTime,
    meetingEndDateTime
  ).then(as => {
    const currentUserWithAvailability = {
      ...userAsAttendee,
      availability: as.find(a => a.id === currentUser.directoryObjectId)
        ?.availability
    };

    //remove the current user and add it again with the correct property / formatting as already defined earlier
    return as
      .filter(a => a.id !== currentUser.directoryObjectId)
      .concat([currentUserWithAvailability])
      .map(a => AttendeeWithAvailabilityVM(a, day))
      .sort(
        combineSorters(
          byPropAsc("availability", byAttendeeAvailabilityStatus),
          byPropAsc("displayName", stringCompareAsc),
          byPropAsc("initials", stringCompareAsc),
          byPropAsc("email", stringCompareAsc)
        )
      );
  });
};
