import { parseISO } from "date-fns";

class ParkingReservationApiModel {
  constructor(obj) {
    this.id = obj.id;

    this.userId = obj.userId;
    this.nodeId = obj.nodeId;

    this.day = parseISO(obj.date);

    this.parkingLotId = obj.parkingLotId;
    this.zoneId = obj.zoneId;
    this.parkingLotName = obj.parkingLotName;
    this.zoneName = obj.zoneName;

    this.createdBy = obj.createdBy
      ? {
          displayName: obj.createdBy.displayName,
          email: obj.createdBy.email,
          date: parseISO(obj.createdBy.createdOrLastModifiedDate)
        }
      : null;

    this.lastModifiedBy = obj.lastModifiedBy
      ? {
          displayName: obj.lastModifiedBy.displayName,
          email: obj.lastModifiedBy.email,
          date: parseISO(obj.lastModifiedBy.createdOrLastModifiedDate)
        }
      : null;
  }
  static fromApiObj(apiObj) {
    return new ParkingReservationApiModel(apiObj);
  }
}

export default ParkingReservationApiModel;
