import "./RoomsList.scss";
import template from "./RoomsList.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import ko from "@shared/knockout/extended";
import { MeetingRoomVM } from "@app/viewmodels/MeetingRoomVM";
import { addMinutes, set } from "date-fns";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { MeetingService } from "@app/services/MeetingService";
import { UserApp } from "@app/UserApp";
import { FeatureNames } from "@shared/services/Features";
import { isConsideredFree } from "@app/utils/RoomStates";
import { getAppFeatures } from "@app/services/AppFeatures";

const RoomsListVM = ({ roomsListParams }) => {
  const {
    selectedBuilding,
    selectedFloor,
    selectedRange,
    startTime,
    endTime = null,
    filterOutSelectedRooms,
    meetingRoomParams,
    buildings = [],
    durations = [],
    hasDoubleAction = true,
    isHereNowPage = false
  } = roomsListParams;

  const {
    hasManualRoomSelection,
    selectedRooms,
    afterRoomSelection,
    isAddRoomFlow,
    currentSelection
  } = meetingRoomParams;

  const showBuildingFilter = !!ko.unwrap(buildings).length;
  const showAvailabilityData =
    isHereNowPage && getAppFeatures().has(FeatureNames.HERE_NOW_V2);
  const { enabledForUsers, userHasOptedIn } = getAppFeatures().has(
    FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW
  )
    ? getAppFeatures().get(FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW)
    : { enabledForUsers: false, userHasOptedIn: false };
  const showDurationFilter =
    !!durations.length && enabledForUsers && userHasOptedIn;

  const floorFilter = (room, floorId) =>
    floorId ? room.floorId === floorId : true;
  const floors = ko.pureComputed(
    () => LocationStoreSingleton().get(selectedBuilding()).floors
  );

  const capacityFilter = (room, range) => {
    if (!range) return true;
    const [min, max] = range;
    return room.capacity >= min && room.capacity <= max;
  };
  const rangeValues = [
    [1, 2],
    [3, 4],
    [5, 6],
    [7, 12],
    [13, 20],
    [21, Number.POSITIVE_INFINITY]
  ];
  const ranges = rangeValues.map(ar => ({
    values: ar,
    label: `${ar[0]}${isFinite(ar[1]) ? " - " + ar[1] : "+"}`
  }));

  const isNewHereNowPage =
    isHereNowPage && getAppFeatures().has(FeatureNames.HERE_NOW_V2);

  const selectedDuration = ko.observable(
    durations.find(d => d.value == 30) ?? durations[0]
  );

  const bookedRoomIds = ko.observableArray([]);
  const hideRoom = room => bookedRoomIds.push(room.id);
  Mediator().subscribe(Channels.HideBookedRoom, hideRoom);

  const showAllRoomsWithScore = isNewHereNowPage;

  const allRooms = ko.observableArray([]);
  const filteredRooms = ko.pureComputed(() =>
    allRooms()
      .filter(r =>
        showAllRoomsWithScore
          ? r.score > 0.3 && isConsideredFree(r.state)
          : true
      )
      .sort((a, b) => b.score - a.score)
      .filter(filterOutSelectedRooms)
      .filter(r => !bookedRoomIds().includes(r.id))
      .filter(r => floorFilter(r, selectedFloor()))
      .filter(r => capacityFilter(r, selectedRange()))
  );

  const loading = ko.observable(false);

  const startMeetingTime = ko.unwrap(startTime);
  const endMeetingTime = ko.unwrap(endTime);

  const getRooms = (building, start, end, selectedDuration) => {
    const availabilityStartTime = isHereNowPage
      ? set(new Date(), { seconds: 0, milliseconds: 0 })
      : start;
    const availabilityEndTime = isHereNowPage
      ? selectedDuration()
        ? addMinutes(availabilityStartTime, selectedDuration().value)
        : addMinutes(availabilityStartTime, 15)
      : end;
    if (availabilityStartTime && availabilityEndTime) {
      loading(true);
      MeetingService()
        .getRooms({
          attendeesAmount: 1,
          buildingId: building(),
          startDateTime: availabilityStartTime,
          endDateTime: availabilityEndTime,
          userId: UserApp.resolveUser().id,
          showAllRoomsWithScore
        })
        .then(rooms => {
          allRooms(
            rooms.map(
              MeetingRoomVM(
                hasManualRoomSelection,
                selectedRooms,
                afterRoomSelection,
                isAddRoomFlow,
                currentSelection,
                hasDoubleAction,
                availabilityStartTime,
                availabilityEndTime
              )
            )
          );
          //do not reset filters if we are in the "book now" flow
          if (!durations.length) {
            selectedRange(null);
            selectedFloor(null);
          }
        })
        .catch(e =>
          //TODO: determine what to do with error
          console.log(e)
        )
        .finally(() => loading(false));
    }
  };

  const roomsAutoUpdater = ko.computed(() =>
    getRooms(
      selectedBuilding,
      startMeetingTime,
      endMeetingTime,
      selectedDuration
    )
  );

  return {
    buildings,
    floors,
    ranges,
    selectedFloor,
    selectedRange,
    selectedBuilding,
    filteredRooms,
    loading,
    showBuildingFilter,
    showDurationFilter,
    selectedDuration,
    durations,
    showAvailabilityData,
    dispose: () => {
      roomsAutoUpdater.dispose();
      Mediator().subscribe(Channels.HideBookedRoom, hideRoom);
    }
  };
};

export const RoomsList = KOComponent("rooms-list", RoomsListVM, template);
