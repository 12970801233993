import { byPropAsc } from "@shared/utils/sort";
import { naturalStringSortAsc } from "@shared/utils/sortHelper";
import ParkingLotZoneApiModel from "./ParkingLotZoneApiModel";

class ParkingLotApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.capacity = obj.capacity;
    this.name = obj.name || "Unnamed parking lot";

    this.ianaTimeZoneId = obj.ianaTimeZoneId;

    this.parkingZones = (obj.parkingZones || [])
      .sort(byPropAsc("name", naturalStringSortAsc))
      .map(zone => new ParkingLotZoneApiModel(zone));

    this.openingDays = obj.openingDays || [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];

    this.type = ParkingLotApiModel;
  }
}

export default ParkingLotApiModel;
