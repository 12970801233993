import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import template from "./MeetingDetails.template.html";
import "./MeetingDetails.scss";
import { MeetingStoreSingleton } from "@app/services/MeetingStore.js";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation.js";
import { format } from "date-fns";
import { indexByC } from "@shared/utils/indexBy.js";
import { formatMeetingTime } from "../../utils/formatMeetingTime";
import { UserApp } from "@app/UserApp.js";
import { ResponseStatus } from "@app/apimodels/ResponseStatus.js";
import { AttendeesList } from "../AttendeesList/AttendeesList";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { MeetingAttendeeVM } from "@app/viewmodels/MeetingAttendeeVM";
import { RoomDetails } from "../RoomDetails/RoomDetails";
import { AppApi } from "@app/services/AppApi";
import { getAppFeatures } from "@app/services/AppFeatures";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { groupBy } from "@shared/utils/groupBy";
import { getGroup, Group } from "./attendeeGroupUtils";
import { BuildingTileConfig, TileConfig } from "./TileConfig";
import { AttendeeTile } from "../AttendeeTile/AttendeeTile";
import { getDateKey } from "@shared/utils/dateHelpers";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import {
  isLessThanMinDuration,
  isOverMaxDuration
} from "@app/components/MeetingCreation/utils";
import { OfficeDayVM } from "@app/viewmodels/OfficeDayVM";

import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { ExtrasTypes } from "@admin/components/SettingsViewer/views/ExtrasView/helpers/ExtrasTypes";

const MeetingVM = (meeting, date, mediator = Mediator()) => {
  const hasEnrichedMeetingDetails = getAppFeatures().has(
    FeatureNames.ENRICHED_MEETING_DETAILS
  );

  const urlNavigation = UrlNavigationSingleton();
  const formattedDate = getDateKey(date);
  const title = meeting.title;
  const [timeLines, duration] = formatMeetingTime(meeting);

  const currentUser = UserApp.resolveUser();
  const userAttendee = meeting.attendees.find(
    a => a.id === currentUser.directoryObjectId
  );

  const userOfficeDay = ko.pureComputed(() => OfficeDayVM(meeting.startDate));

  const userOrganizer =
    userAttendee?.responseStatus === ResponseStatus.Organizer;

  const attendees = userOfficeDay.map(currentUserShift =>
    meeting.attendees.map(
      MeetingAttendeeVM(currentUser.email, currentUserShift)
    )
  );

  const organizer = meeting.attendees.find(
    a => a.responseStatus === ResponseStatus.Organizer
  );
  const organizerLabel =
    !userOrganizer && hasEnrichedMeetingDetails
      ? `${organizer.displayName} has invited you`
      : null;

  const showOnlineMeetingLink =
    meeting.onlineMeetingUrl && hasEnrichedMeetingDetails;

  const openOnlineMeeting = () => {
    window.open(
      meeting.onlineMeetingUrl,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const preview = meeting.preview?.split(/\.{80}|_{80}/)[0];

  const showPreview = preview && hasEnrichedMeetingDetails;

  const { attendeesMeetingLocation } = MeetingAttendeesLocationVM(
    attendees,
    meeting,
    userOfficeDay
  );

  const locationStore = LocationStoreSingleton();
  const buildings = locationStore.getBuildings();

  const addRoomPageOpenedOrigin = "MeetingDetails";
  const canAddRoom =
    userOrganizer &&
    !isOverMaxDuration(meeting.endDate, meeting.startDate) &&
    !isLessThanMinDuration(meeting.endDate, meeting.startDate) &&
    getFeatures().has(FeatureNames.ADD_ROOM_TO_MEETING);
  const roomPickerParams = {
    building:
      userOfficeDay() &&
      buildings.some(b => b.id === userOfficeDay().buildingId)
        ? userOfficeDay().buildingId
        : locationStore.getPreferredBuilding()?.id ?? buildings[0].id,
    startTime: meeting.startDate,
    endTime: meeting.endDate,
    selectedRooms: ko.observableArray(meeting.meetingRooms),
    buildings,
    hasManualRoomSelection: getFeatures().has(
      FeatureNames.MANUAL_ROOM_SELECTION
    ),
    selectedMeetingId: meeting.id,
    backFunction: () =>
      urlNavigation.navigate(null, "overview", [formattedDate, meeting.id]),
    addRoomPageOpenedOrigin
  };
  const onAddRoomClick = () => {
    publishAIEvent(
      EventNames.AddRoomPageOpened,
      { origin: addRoomPageOpenedOrigin },
      ResultTypes.Undefined
    );
    mediator.publish(Channels.OpenRoomPicker, roomPickerParams);
  };

  return {
    title,
    timeLines,
    attendeesMeetingLocation,
    isAllDay: meeting.isAllDay,
    duration: `(${duration})`,
    userRsvpStatus: meeting.isCanceled
      ? "Canceled"
      : userAttendee?.responseStatus,
    meetingCanceled: meeting.isCanceled,
    userAccepted: userAttendee?.responseStatus === ResponseStatus.Accepted,
    userOrganizer,
    userDeclined: userAttendee?.responseStatus === ResponseStatus.Declined,
    // TODO: what's the difference between tentative and pending
    userTentative: [ResponseStatus.Pending, ResponseStatus.Maybe].includes(
      userAttendee?.responseStatus
    ),

    meeting,
    attendees,
    userOfficeDay,
    organizerLabel,
    organizer,
    showOnlineMeetingLink,
    openOnlineMeeting,
    description: meeting.description,
    preview,
    showPreview,
    onAddRoomClick,
    canAddRoom,

    extrasType: ExtrasTypes.HYBRID_MEETINGS
  };
};

const MeetingAttendeesLocationVM = (attendees, meeting, userOfficeDay) => {
  const byRemoteFirst = (t1, t2) => {
    if (t1.isRemote) return -1;
    if (t2.isRemote) return 1;
    return 0;
  };

  //local attendees divided by building
  const byBuilding = ko.pureComputed(() =>
    groupBy(a => a.shiftBuildingId, locationGroups()[Group.Local] || [])
  );

  //attendees divided in remote / local / unknown
  const locationGroups = ko.pureComputed(() => groupBy(getGroup, attendees()));

  const buildingsWithAttendees = ko.pureComputed(() =>
    attendees().map(a => a.shiftBuildingId)
  );
  //returned as an object with buildingIds as keys and empty arrays (of attendees) as value
  const buildingsWithOnlyMeetingRooms = ko.pureComputed(() => {
    const buildings = meeting.meetingRooms
      .map(mr => mr.buildingId)
      .filter(id => !buildingsWithAttendees().includes(id));
    return buildings.reduce((acc, value) => {
      return { ...acc, [value]: [] };
    }, {});
  });

  const tiles = ko.pureComputed(() =>
    [
      ...Object.entries({ ...byBuilding(), ...buildingsWithOnlyMeetingRooms() })
        .map(BuildingTileConfig(userOfficeDay, meeting.meetingRooms))
        .sort(BuildingTileConfig.compare),
      TileConfig([Group.Remote, locationGroups()[Group.Remote], false]),
      TileConfig([Group.Unknown, locationGroups()[Group.Unknown], false])
    ].filter(g => g.people.length > 0 || g.rooms.length > 0)
  );

  return {
    //does the user's location always go first? i.e. and in case it is grouped under `Unknown location`?
    attendeesMeetingLocation: ko.pureComputed(() =>
      userOfficeDay().isOfficeDay ? tiles() : tiles().sort(byRemoteFirst)
    )
  };
};

const MeetingDetailsVM = ({ meetingId, date }) => {
  const element = document.getElementsByClassName("MeetingDetails-body");
  element[0].scrollTo(0, 0);
  const meetingStore = MeetingStoreSingleton();
  const urlNavigation = UrlNavigationSingleton();
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const formattedDate = getDateKey(date);
  const showMeetingDescription = ko.observable(false);
  const toggleMeetingDescription = () => {
    showMeetingDescription(!showMeetingDescription());
    const scrollElement = document.getElementsByClassName("SidePanel-body");
    scrollElement[0].scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const dateHeader = ko.pureComputed(() =>
    showMeetingDescription() ? "Description" : format(date, "d MMMM")
  );

  const byId = meetingStore.all.map(indexByC(m => m.id));

  const meeting = ko.pureComputed(() => {
    if (!meetingStore.dateHydrated(date)) return null;

    // Do a case sensitive lookup, because meeting ids from the Graph API
    // might differ only in case
    const m = byId()[meetingId];
    if (!m) return null;

    return MeetingVM(m, date);
  });

  const loading = ko.pureComputed(() => !meetingStore.dateHydrated(date));
  const notFound = ko.pureComputed(
    () => meetingStore.dateHydrated(date) && meeting() === null
  );

  const goBack = () => {
    if (showMeetingDescription()) {
      toggleMeetingDescription();
      return;
    }

    urlNavigation.navigate("", hasWorkdayFeature ? null : "overview", [
      formattedDate
    ]);
  };

  const DeleteConfirmationOptions = {
    title: "Delete event?",
    subtitle: "You can't undo this action",
    primaryButtonText: "Delete",
    onPrimaryButtonClick: () => {
      //Api call instead
      return AppApi.deleteMeeting(meetingId)
        .then(() => {
          MeetingStoreSingleton().remove(meetingId);
          goBack();
        })
        .catch(e => {
          let reason = null;

          if (e && e.errors) {
            const [[firstErrorValue]] = Object.values(e.errors);
            reason = firstErrorValue;
          }

          Mediator().publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason
          });
        });
    }
  };

  const deleteEvent = () => {
    Mediator().publish(Channels.ToggleConfirmationWindow, {
      options: DeleteConfirmationOptions
    });
  };

  return {
    dateHeader,
    date,
    loading,
    notFound,
    meeting,
    isBackButtonVisible: ko.pureComputed(
      () => showMeetingDescription() || !hasWorkdayFeature
    ),
    goBack,
    deleteEvent,
    showMeetingDescription,
    toggleMeetingDescription
  };
};

const meetingDetailsComponent = KOComponent(
  "meeting-details",
  MeetingDetailsVM,
  template
);

export const MeetingDetails = {
  ...meetingDetailsComponent,
  register: ko => {
    AttendeesList.register(ko);
    AttendeeTile.register(ko);
    meetingDetailsComponent.register(ko);
    RoomDetails.register(ko);
  }
};
