import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import "./blockScroll.scss";

let styleTag = null;

export const BlockScrollBinding = {
  register: ko => {
    ko.bindingHandlers.blockScroll = {
      init: el => {
        if (!styleTag) {
          styleTag = document.createElement("style");
          document.head.prepend(styleTag);
        }

        const widthBefore = document.body.offsetWidth;
        disableBodyScroll(el);
        const widthAfter = document.body.offsetWidth;

        const width = widthAfter - widthBefore;
        styleTag.innerHTML = createCssString(width);

        ko.utils.domNodeDisposal.addDisposeCallback(el, function() {
          enableBodyScroll(el);

          styleTag.innerHTML = "";
        });
      }
    };
  }
};

const createCssString = scrollbarWidth => `
body {
  margin-right: ${scrollbarWidth}px;
}

.ModalWrapper {
  padding-right: ${scrollbarWidth}px;
}
`;
