export const AvailabilityStatus = {
  Available: "Available",
  Unavailable: "Unavailable",
  Tentative: "Tentative",
  Unknown: "Unknown"
};

const AvailabilityStatusOrder = {
  [AvailabilityStatus.Available]: 0,
  [AvailabilityStatus.Tentative]: 1,
  [AvailabilityStatus.Unknown]: 1,
  [AvailabilityStatus.Unavailable]: 2
};

export const byAttendeeAvailabilityStatus = (
  availabilityStatus1,
  availabilityStatus2
) => {
  const availabilityStatus1Order =
    AvailabilityStatusOrder[availabilityStatus1] ?? Infinity;
  const availabilityStatus2Order =
    AvailabilityStatusOrder[availabilityStatus2] ?? Infinity;
  if (availabilityStatus1Order > availabilityStatus2Order) return 1;
  if (availabilityStatus1Order < availabilityStatus2Order) return -1;
  return 0;
};
