import ko from "@shared/knockout/extended";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel";
import { ErrorType } from "@shared/utils/ErrorType";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { ConnectionsStoreSingleton } from "./ConnectionStore";
import { AppApi } from "./AppApi";

export const ConnectionsService = (api = AppApi, mediator = Mediator()) => {
  const connectionStore = ConnectionsStoreSingleton();
  const loading = ko.observable(false);
  const loadingSuggestions = ko.observable(false);

  const createConnection = userId =>
    api
      .createConnection(userId)
      .then(newConnection => {
        connectionStore.add(newConnection);
        mediator.publish(Channels.TrackAIEvent, [
          EventNames.SendConnectionRequest,
          ResultTypes.Success
        ]);
      })
      .catch(e => {
        // For 400 errors, we show the server message
        if (e.status === 400) {
          const error = Server400ErrorModel(e);

          //For errors about the connections limit reached, we show a specific prompt
          if (error.firstErrorType === ErrorType.ConnectiionLimitReached) {
            mediator.publish(Channels.OpenConnectionErrorWindow, {
              errorType: ErrorType.LimitReached
            });
          } else {
            mediator.publish(Channels.OpenStatusMessage, {
              type: "failure",
              reason: error.firstError
            });
          }
          // All other errors are handled with a status message
        } else {
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: "We couldn’t send the invite. Please try again later."
          });
        }
        mediator.publish(Channels.TrackAIEvent, [
          EventNames.SendConnectionRequest,
          ResultTypes.Fail
        ]);
      })
      .finally(() => {
        mediator.publish(Channels.CloseConnectionAddWindow);
        loading(false);
      });

  const removeConnection = (isAccepted, id) => {
    const AIEvent = isAccepted
      ? EventNames.DeleteConnection
      : EventNames.RemoveConnectionRequest;

    api
      .deleteConnection(id)
      .then(() => {
        connectionStore.remove(id);
        if (isAccepted) {
          mediator.publish(Channels.RequestConnectionsUpdate);
        }
        mediator.publish(Channels.TrackAIEvent, [AIEvent, ResultTypes.Success]);
      })
      .catch(e => {
        // Publish AI event first
        mediator.publish(Channels.TrackAIEvent, [AIEvent, ResultTypes.Fail]);

        // For 400 errors, we show the server message
        if (e.status === 400) {
          const error = Server400ErrorModel(e);

          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: error.firstError
          });
        } else {
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason: `We couldn’t ${
              isAccepted ? "delete your connection." : "remove your request."
            } Please try again later.`
          });
        }
      })
      .finally(() => {
        mediator.publish(Channels.CloseConnectionDeleteWindow);
      });
  };

  const getConnectionSuggestions = () => {
    loadingSuggestions(true);
    return AppApi.getConnectionSuggestions().then(suggestions => suggestions);
  };

  const sendSuggestionFeedback = (suggestion, status, sendDto) =>
    api.postSuggestionFeedback(
      Object.assign({}, sendDto(), {
        userIdSuggestedConnection: suggestion.connectionId,
        approved: status
      })
    );
  return {
    createConnection,
    removeConnection,
    getConnectionSuggestions,
    sendSuggestionFeedback,
    loadingSuggestions
  };
};
