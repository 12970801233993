import "./RulesBox.scss";
import template from "./RulesBox.template.html";

import { KOComponent } from "@shared/knockout/KOComponent";
import { FeatureNames, getFeatures } from "@shared/services/Features";

const RulesBoxVM = ({ user }) => {
  return {
    user,
    parkingFeatureEnabled: getFeatures().has(FeatureNames.PARKING)
  };
};

export const RulesBox = KOComponent("rules-box", RulesBoxVM, template);
