import template from "./WorkspaceNodeSummary.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import { locationLabels } from "@shared/utils/locationLabels";
import { format } from "date-fns";

const WorkspaceNodeSummaryVM = ({
  date,
  locationId,
  workspace = null,
  usePendingOrConfirmedMemberCount
}) => {
  const locationStore = LocationStoreSingleton();

  const locId = ko.unwrap(locationId);
  const location = locationStore.get(locId);
  const labels = locationLabels(locId);

  return {
    dateText: format(date, "EEEE d MMMM"),
    locationLine1: ko.pureComputed(
      () => labels[2] || workspace?.deepestBookedLeafNameWithoutDesk
    ),
    locationLine2: ko.pureComputed(
      () => labels[3] || workspace?.buildingAndFloorName
    ),
    availability: ko.pureComputed(() =>
      location
        ? availabilityLocationData(
            date,
            location,
            null,
            usePendingOrConfirmedMemberCount
          )
        : null
    )
  };
};

export const WorkspaceNodeSummary = KOComponent(
  "workspace-node-summary",
  WorkspaceNodeSummaryVM,
  template
);
