import { differenceInDays, min } from "date-fns";
import addDays from "date-fns/addDays";

export const splitRange = (from, to, daysPerChunk) => {
  const range = differenceInDays(to, from);
  const chunks = Math.ceil(range / daysPerChunk);

  return Array.from(Array(chunks), (_, i) => [
    addDays(from, i * daysPerChunk),
    min([to, addDays(from, i * daysPerChunk + daysPerChunk)])
  ]);
};
