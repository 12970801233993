import {
  addDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format
} from "date-fns";

export const formatMeetingTime = ({ startDate, endDate, isAllDay = false }) => {
  const start = format(startDate, "EEEE d LLLL");
  const end = format(endDate, "EEEE d LLLL");

  const days = differenceInDays(endDate, startDate);
  const hours = differenceInHours(endDate, startDate) % 24;
  const minutes = differenceInMinutes(endDate, startDate) % 60;

  if (isAllDay) {
    const endBeforeMidnight = format(addDays(endDate, -1), "EEEE d LLLL");

    if (start === endBeforeMidnight) return [["All day"]];
    return [[`${start} through`, `${endBeforeMidnight}`]];
  }

  const startT = format(startDate, "HH:mm");
  const endT = format(endDate, "HH:mm");

  const daysLabel = days > 0 ? `${days}d` : "";
  const hoursLabel = hours > 0 || (days > 0 && minutes > 0) ? `${hours}h` : "";
  const minutesLabel = minutes > 0 ? `${minutes}m` : "";

  const durationLabel = [daysLabel, hoursLabel, minutesLabel]
    .filter(s => s.length)
    .join(" ");

  if (start === end) {
    return [[`${start} ${startT} to ${endT}`], durationLabel];
  }

  return [[`${start} ${startT} to`, `${end} ${endT}`], durationLabel];
};
