import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./MeetingCard.template.html";
import "./MeetingCard.scss";

export const MeetingCardVM = ({ meeting }) => {
  return {
    meeting
  };
};

export const MeetingCard = KOComponent("meeting-card", MeetingCardVM, template);
