import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import template from "./IntroductionTile.template.html";

export const IntroductionTile = KOComponent(
  "introduction-tile",
  ({
    visible,
    introductionTileParams: {
      title,
      subTitle,
      buttonText,
      iconName,
      showLoading,
      handleError,
      onPrimaryButtonClick,
      onClose
    }
  }) => {
    const loading = ko.observable(false);

    return {
      visible,
      loading,
      title,
      subTitle,
      buttonText,
      iconName,
      onButtonClick: () => {
        if (showLoading) {
          loading(true);
        }
        onPrimaryButtonClick()
          .catch(e => {
            handleError(e);
          })
          .finally(() => {
            //always clear the loading state, in case the user interrupts the auth flow (so no redirect)
            //timeout to avoid the loading state going off already before auth page is open
            setTimeout(() => loading(false), 1000);
          });
      },
      dismiss: () => {
        onClose();
        visible(false);
      }
    };
  },

  template
);
