import template from "./AttendeeResponseStatus.template.html";
import "./AttendeeResponseStatus.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { ResponseStatus } from "@app/apimodels/ResponseStatus";
import { AvailabilityStatus } from "@app/apimodels/AvailabilityStatus";

const AttendeeResponseStatusVM = ({ attendeeResponseOrAvailabilityStatus }) => {
  const mapStatusToIconKey = responseOrAvailabilityStatus => {
    switch (responseOrAvailabilityStatus) {
      case ResponseStatus.Accepted:
      case ResponseStatus.Organizer:
      case AvailabilityStatus.Available:
        return "GreenCheckmark";
      case ResponseStatus.Declined:
      case AvailabilityStatus.Unavailable:
        return "RedCross";
      case ResponseStatus.Maybe:
      case ResponseStatus.Pending:
      case AvailabilityStatus.Tentative:
      case AvailabilityStatus.Unknown:
        return "GreyQuestionMark";
      default:
        return null;
    }
  };

  const iconToShow = ko.pureComputed(() =>
    mapStatusToIconKey(ko.unwrap(attendeeResponseOrAvailabilityStatus))
  );

  return {
    iconToShow
  };
};

export const AttendeeResponseStatus = KOComponent(
  "attendee-response-status",
  AttendeeResponseStatusVM,
  template
);
