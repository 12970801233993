/** Creates a login request object from env variables */
export const LoginRequest = loginHint => {
  const req = {
    scopes: process.env.MAPIQ_AUTH_LOGIN_SCOPES.split(",")
  };

  if (loginHint) {
    req.loginHint = loginHint;
  }

  return req;
};
