import { MeetApi } from "@shared/services/MeetApi.js";
import { Mediator } from "@shared/mediator.js";
import { Channels } from "@shared/Channels.js";
import { ConsentRedirectResult } from "@shared/utils/ConsentRedirectResult";

export const Error = {
  ConsentUrl: "ConsentUrl",
  PreferenceOff: "PreferenceOff",
  PreferenceOn: "PreferenceOn",
  CalendarPreference: "CalendarPreference"
};

const publishError = reason => {
  Mediator().publish(Channels.OpenStatusMessage, {
    type: "failure",
    reason
  });
};

const publishConsentError = reason => {
  Mediator().publish(Channels.ConsentRedirectResult, reason);
};

export const handleError = error => {
  switch (error) {
    case Error.ConsentUrl:
      return publishConsentError(ConsentRedirectResult.Failure);
    case Error.PreferenceOff:
    case Error.PreferenceOn:
      return publishError("We were unable to store your preference.");
    case Error.CalendarPreference:
      return publishError("We were unable to update your calendar preference.");
    default:
      return publishError(
        "Something went wrong. Please reload the page and try again."
      );
  }
};

export const giveConsent = () =>
  MeetApi.getConsentUrl()
    .then(url => {
      if (url === "") {
        MeetApi.setUserPreference({ enableCalendar: true })
          .catch(e => {
            throw Error.CalendarPreference;
          })
          .then(() => {
            location.reload();
          });
      } else {
        document.location = url;
      }
    })
    .catch(e => {
      throw Error.ConsentUrl;
    });

export const enable = async () => {
  // Fire & forget, it's no big deal if it fails
  MeetApi.setUserPreference({
    userClosedConsentErrorTile: false
  });

  await giveConsent();
};

export const disable = () =>
  MeetApi.setUserPreference({ enableCalendar: false })
    .then(() => {
      document.location.reload();
    })
    .catch(e => {
      throw Error.PreferenceOff;
    });
