import "./ConnectionDeleteWindow.scss";
import template from "./ConnectionDeleteWindow.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import { ConnectionStatus } from "../popups/ConnectionsPage/ConnectionStatus";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { ConnectionsService } from "@app/services/ConnectionService";

const ConnectionDeleteWindowVM = ({ connection, mediator = Mediator() }) => {
  const { status, id } = connection;
  const isAccepted = status === ConnectionStatus.Accepted;
  const question = isAccepted
    ? "Delete connection?"
    : "Remove pending connection request?";
  const firstButtonText = "Cancel";
  const secondButtonText = isAccepted ? "Delete" : "Remove";

  const cancel = () => {
    mediator.publish(Channels.CloseConnectionDeleteWindow);
    mediator.publish(Channels.OpenConnectionsPage);
  };

  return {
    connection,
    isAccepted,
    question,
    firstButtonText,
    secondButtonText,
    cancel,
    confirm: () => {
      ConnectionsService().removeConnection(isAccepted, id);
    }
  };
};

export const ConnectionDeleteWindow = KOComponent(
  "connection-delete",
  ConnectionDeleteWindowVM,
  template
);
