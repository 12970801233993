export class MeetingsSettingsApiModel {
  constructor(obj) {
    this.enabledOffice365 = obj.enabledOffice365;
    this.office365FirstEnableTime = obj.office365FirstEnableTime;
    this.enabledGoogleWorkspace = obj.enabledGoogleWorkspace;
    this.googleWorkspaceFirstEnableTime = obj.googleWorkspaceFirstEnableTime;
  }

  static fromApiObj(obj) {
    return new MeetingsSettingsApiModel(obj);
  }

  static toApiObj(vm) {
    return {
      enabledOffice365: vm.enabledOffice365(),
      enabledGoogleWorkspace: vm.enabledGoogleWorkspace()
    };
  }
}
