import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel.js";
import "./FloorMap.scss";
import template from "./FloorMap.template.html";
import { MapStoreSingleton } from "./services/mapStore.js";
import ko from "@shared/knockout/extended";
import { staticSvg } from "./bindings/staticSvg.js";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { interactiveSvg } from "./bindings/interactiveSvg.js";
import { LayerState, LayerType } from "@app/utils/svg-helpers.js";
import { FeatureNames, getFeatures } from "@shared/services/Features.js";

const { tryExtractErrorMessage } = Server400ErrorModel;

const FloorMapVM = ({
  floorId,
  buildingId,

  typeDefaults = {
    [LayerType.Desk]: LayerState.Hidden,
    [LayerType.Area]: LayerState.Default,
    [LayerType.Floor]: LayerState.Default
  },
  nodeOverrides = {},
  selectedDeskIds,
  disabledDeskIds,
  occupiedDeskIds,
  connections = [],
  selectedConnection,
  interactive = false,
  showMeetingRooms = false,
  mapStore = MapStoreSingleton(),
  selectDeskIdCallback = () => {}
}) => {
  const mapInitialized = ko.observable(false);
  const mapLoading = ko.observable(true);
  const mapError = ko.observable(null);
  const mapSvg = ko.observable(null);

  // Load map SVG
  mapStore
    .getMap(ko.unwrap(buildingId), ko.unwrap(floorId))
    .then(svgString => {
      mapSvg(svgString);
      mapInitialized(true);
    })
    .catch(e => {
      console.error(e);
      mapInitialized(false);
      mapError(tryExtractErrorMessage(e, "Failed to load map"));
    })
    .finally(() => {
      mapLoading(false);
    });

  return {
    interactive,
    mapInitialized,
    mapLoading,
    mapError,
    mapSvg,
    typeDefaults,
    nodeOverrides,
    selectedDeskIds,
    disabledDeskIds,
    occupiedDeskIds,
    connections,
    selectedConnection,
    showMeetingRooms,
    selectDeskIdCallback,
    shadowClass: getFeatures().has(FeatureNames.MAP_RESTYLE_SHADOWS)
      ? ""
      : "FloorMap--no-shadows",
    noop: () => {},
    dispose: () => {}
  };
};

const FloorMapComponent = KOComponent("floor-map", FloorMapVM, template);

export const FloorMap = Object.assign({}, FloorMapComponent, {
  register: ko => {
    ko.bindingHandlers.staticSvg = staticSvg;
    ko.bindingHandlers.interactiveSvg = interactiveSvg;
    FloorMapComponent.register(ko);
  }
});
