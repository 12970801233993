// values returned from BE are PacalCase. we need camelCase.
export const notificationPreferencesCategoryKeys = {
  Connections: "connections",
  ShiftReminder: "shiftReminder",
  Updates: "updates"
};

export const notificationPreferencesTypeKeys = {
  UserEmail: "userEmail",
  PushNotification: "pushNotification"
};

export const notificationPreferencesCategories = {
  [notificationPreferencesCategoryKeys.Connections]: "Connections",
  [notificationPreferencesCategoryKeys.ShiftReminder]: "ShiftReminder",
  [notificationPreferencesCategoryKeys.Updates]: "Updates"
};

export const notificationPreferencesTypes = {
  [notificationPreferencesTypeKeys.UserEmail]: "UserEmail",
  [notificationPreferencesTypeKeys.PushNotification]: "PushNotification"
};

export class NotificationPreferencesApiModel {
  constructor(obj) {
    this.userId = obj.userId;
    this.preferences = obj.preferences;
  }
}
