import "./ConsentFlowErrorModal.scss";
import template from "./ConsentFlowErrorModal.template.html";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { KOComponent } from "@shared/knockout/KOComponent";

export const ConsentFlowErrorModalVM = () => {
  const closeModal = () => {
    Mediator().publish(Channels.CloseErrorConsentModal);
  };
  return { closeModal };
};

export const ConsentFlowErrorModal = KOComponent(
  "consent-flow-error-modal",
  ConsentFlowErrorModalVM,
  template
);
