import template from "./WorkSpace.template.html";
import "@shared/style/Workspace/Workspace.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import {
  getWorkspaceIcon,
  getWorkspaceIconFromWorkspace,
  WorkspaceAvailability
} from "@app/utils/workspaceHelpers";
import { notEmpty } from "@shared/utils/capacityHelper";
import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { compareStatus } from "@shared/services/CheckInStatus";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore";
import WorkAreaApiModel from "@shared/apimodels/WorkAreaApiModel.js";
import FloorApiModel from "@shared/apimodels/FloorApiModel.js";
import BuildingApiModel from "@shared/apimodels/BuildingApiModel.js";
import { UserApp } from "@app/UserApp";

const WorkSpaceVM = ({
  selectedOfficeId,
  userSelectedDifferentOffice = ko.observable(false),
  openOfficePicker = () => {},
  openWorkspacePicker,
  selectedLocationId,
  openInviteesPicker = () => {},
  deskSection,
  workspace,
  selectedInvitees = ko.observableArray([]),
  initialSelectedInvitees = ko.observableArray([]),
  selectedInviteesIsDirty = ko.observable(false),
  userSelectedDifferentNode,
  checkedInDifferentOfficeId,
  lastSelectedId,
  date,
  groupValidationErrors = ko.observableArray([]),
  loading,
  deskSelectionMode,
  areaViewMode,
  selectedDeskIds,
  usePendingOrConfirmedMemberCount,
  selectedWorkdayOfficeId,
  userChooseLastSelectedWorkdayId,
  selectedUserId = null, //admin specific
  hasInviteesFeature = getFeatures().has(FeatureNames.GROUP_RESERVATIONS),
  forceNewDeskSuggestions
}) => {
  const locationStore = LocationStoreSingleton();
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);

  // If user finish booking flow selectedLocationId will be used
  // For initial location we will check does checkin, workspace,last selected and prefered building exist(not full/closed)
  const locationId = ko.pureComputed(
    () => selectedLocationId() ?? selectedOfficeId()
  );

  // In case that last selected building or workspace building don't exist in user location profile
  const location = ko.pureComputed(() => locationStore.get(locationId()));

  // From selectedOfficeId we will get always workspaceid even when a office is full/closed or it is not part of user location profile
  const locationIsNotInLocationProfileWithNoChanges = ko.pureComputed(
    () =>
      !location() &&
      !userSelectedDifferentOffice() &&
      !userSelectedDifferentNode()
  );

  const checkedInOfficeExist = ko.pureComputed(
    () =>
      checkedInDifferentOfficeId &&
      locationStore.get(checkedInDifferentOfficeId)
  );

  const buildingName = ko.pureComputed(() =>
    locationIsNotInLocationProfileWithNoChanges()
      ? workspace?.officeName
      : locationStore.getBuildingForId(locationId())?.name ?? null
  );

  const floorName = ko.pureComputed(() =>
    locationIsNotInLocationProfileWithNoChanges()
      ? workspace?.floorName
      : locationStore.getFloorForId(locationId())?.name ?? null
  );

  const areaName = ko.pureComputed(() =>
    locationIsNotInLocationProfileWithNoChanges()
      ? workspace?.workAreaName
      : locationStore.getWorkAreaForId(locationId())?.name ?? null
  );

  const iconName = ko.pureComputed(() =>
    location()
      ? getWorkspaceIcon(location())
      : getWorkspaceIconFromWorkspace(workspace)
  );

  const locationInfo = ko.pureComputed(() => {
    if (!buildingName()) return null;

    return areaName() ?? floorName() ?? WorkspaceAvailability.ANY_DESK;
  });

  const deskInfo = ko
    .pureComputed(() => {
      if (!floorName()) return null;

      const anyDeskString = isGroupBooking()
        ? `Any ${groupSize()} available desks`
        : WorkspaceAvailability.ANY_DESK;

      const anyDesk = areaName()
        ? `, ${anyDeskString.toLocaleLowerCase()}`
        : anyDeskString;

      const floorInfo = `${floorName()}${anyDesk}`;

      if (!location()) {
        return workspace.workAreaId
          ? workspace?.deskId
            ? `${floorName()}, ${workspace.deskName}`
            : floorInfo
          : anyDesk;
      }
      return location().type === WorkAreaApiModel
        ? locationStore.getFloorForId(location().id)?.hasMap
          ? floorName()
          : floorInfo
        : anyDesk;
    })
    .extend({ deferred: true });

  const nodesExistInBuilding = !!locationStore.getBuildingForId(locationId())
    ?.floors.length;

  const deskInfoVisible = ko.pureComputed(
    () => workspace || nodesExistInBuilding
  );
  const nodesInBuilding = ko.pureComputed(() => {
    // In case that last selected doesn't exist, preffered building is disabled
    // or user select invitees for group reservation flow selectedOfficeId will be null
    const nodes = selectedOfficeId()
      ? locationStore.getFlattedNodesFromBuilding(locationId())
      : [];
    return nodes.filter(notEmpty);
  });

  const onlyNodeInBuildingWithoutDesks = ko.pureComputed(
    () =>
      nodesInBuilding().length === 1 && !nodesInBuilding()[0]?.hasBookableDesks
  );

  const disableDeskTile = ko.pureComputed(
    () => !location() || !nodesExistInBuilding || loading()
  );

  if (
    onlyNodeInBuildingWithoutDesks() &&
    !(
      !hasWorkdayFeature &&
      !userSelectedDifferentOffice() &&
      checkedInDifferentOfficeId
    )
  ) {
    selectedLocationId(nodesInBuilding()[0]?.id);
  }

  const officeIsBookableOnRootLevel = ko.pureComputed(
    () => !!selectedOfficeId() && location() && !nodesExistInBuilding
  );

  // Group size = invitees + owner (current user)
  const groupSize = ko.pureComputed(() => selectedInvitees().length + 1);

  const isGroupBooking = ko.pureComputed(
    () =>
      getFeatures().has(FeatureNames.GROUP_RESERVATIONS) &&
      selectedInvitees().length > 0
  );
  const increasedNumberOfInvitees = ko.pureComputed(
    () => selectedInvitees().length > initialSelectedInvitees().length
  );
  const availabilityData = location.maybeMap(loc => {
    const locationToCheck =
      workspace && increasedNumberOfInvitees()
        ? locationStore.getBuildingForId(loc.id)
        : loc;

    const isSameLocation = {
      [WorkAreaApiModel]: loc.id === workspace?.workAreaId,
      [FloorApiModel]: loc.id === workspace?.floorId,
      [BuildingApiModel]: loc.id === workspace?.buildingId
    };

    return availabilityLocationData(
      date,
      locationToCheck,
      groupSize(),
      usePendingOrConfirmedMemberCount && workspace && isSameLocation[loc.type]
    );
  });

  const officeIsUnsupportedForGroupBooking = availabilityData.maybeMap(a =>
    a.unavailableForGroupBookingDueToDeskBookingOnly()
  );

  const insufficientDesksForGroupBooking = availabilityData.maybeMap(a =>
    a.unavailableForGroupBookingDueToAvailability()
  );

  const errorMessage = ko.pureComputed(() => {
    if (officeIsUnsupportedForGroupBooking()) {
      return "Group booking is not yet available in the selected office";
    }

    if (insufficientDesksForGroupBooking()) {
      return `There are no desks available for a group booking of ${groupSize()} people`;
    }

    return null;
  });

  const showDeskEmpty = ko.pureComputed(() => {
    const workspaceExist = Boolean(workspace);
    const selectedOfficeIdExist = Boolean(selectedWorkdayOfficeId);
    const userSelectedLastUsedOffice = userChooseLastSelectedWorkdayId();
    // If user opens sidepanel to see workspace info and click edit button we won't show desk empty tile
    const editWorkspaceWithoutNewOffice =
      workspaceExist && !selectedOfficeIdExist;

    const sameOfficeFromWorkspaceIsChoosenWhileEditing =
      workspaceExist && workspace.buildingId === selectedWorkdayOfficeId;

    if (hasWorkdayFeature) {
      if (
        officeIsBookableOnRootLevel() ||
        officeIsUnsupportedForGroupBooking() ||
        selectedLocationId() ||
        insufficientDesksForGroupBooking() ||
        (!increasedNumberOfInvitees() &&
          (userSelectedLastUsedOffice ||
            sameOfficeFromWorkspaceIsChoosenWhileEditing ||
            editWorkspaceWithoutNewOffice))
      )
        return false;

      return true;
    }

    // Old logic which we delete after workday release

    // On initial render we will check if does workspace exists and if the check-in office exists (the user is checked in a different office and that office is part of the user location profile/not deleted)
    // If the workspace doesn't exist we check if the last selected location is enabled(not full/closed/exist) and check-in doesn't exist
    // If both are not true then we check if the default office or newly chosen office is bookable on a root level

    if (
      (!userSelectedDifferentOffice() &&
        ((workspace && !checkedInOfficeExist() && !selectedWorkdayOfficeId) ||
          (!workspace &&
            !selectedWorkdayOfficeId &&
            lastSelectedId() &&
            !checkedInOfficeExist()))) ||
      officeIsBookableOnRootLevel() ||
      selectedLocationId() ||
      officeIsUnsupportedForGroupBooking() ||
      insufficientDesksForGroupBooking()
    )
      return false;

    // If selectedOfficeId doesn't exist we show the desk as empty
    // If a user is checked in a different office on the initial modal opening show the desk empty state
    // If a user didn't finish choosing node selected office has more then 1 node we will show the desk empty state
    if (
      (selectedWorkdayOfficeId &&
        ((!userChooseLastSelectedWorkdayId() &&
          !onlyNodeInBuildingWithoutDesks()) ||
          selectedInvitees()?.length > 0)) ||
      !selectedOfficeId() ||
      (checkedInOfficeExist() && !userSelectedDifferentNode()) ||
      (selectedOfficeId() &&
        !selectedLocationId() &&
        !userChooseLastSelectedWorkdayId())
    )
      return true;
  });

  return {
    deskSelectionMode,
    areaViewMode,
    date,
    locationId,
    selectedDeskIds,

    deskPickerParams: {
      date,
      deskSection,
      workspace,
      locationId,
      selectedUserId,
      deskSelectionMode,
      areaViewMode,
      showMapIcon: false,
      showLastSelectedDesk: true,
      selectedDeskIds,
      isGroupBooking,
      groupSize,
      forceNewDeskSuggestions
    },

    showMap: ko.pureComputed(() => {
      const hasSelectedNewLocation = !!selectedLocationId();

      return (
        (hasSelectedNewLocation || !increasedNumberOfInvitees()) &&
        location()?.type === WorkAreaApiModel &&
        locationStore.getFloorForId(locationId())?.hasMap
      );
    }),
    biggerMap: ko.pureComputed(() => deskSelectionMode() || areaViewMode()),
    workspace,
    deskSection,
    userSelectedDifferentNode,
    selectedOfficeId,
    selectedLocationId,
    openOfficePicker,
    openInviteesPicker,
    hasWorkdayFeature,
    hasInviteesFeature,
    selectedInvitees,
    groupValidationErrors,
    userSelectedDifferentOffice,
    locationInfo,
    deskInfo,
    disableDeskTile,
    deskInfoVisible,
    nodesExistInBuilding,
    errorMessage,
    showDeskEmpty,
    showLocationInfo: ko.pureComputed(() => {
      if (hasWorkdayFeature) return !showDeskEmpty() && !errorMessage();

      // If a user is checked in a different office and it exists we will force a user to choose a node or choose a different office
      if (
        (checkedInOfficeExist() &&
          !selectedLocationId() &&
          !userSelectedDifferentOffice() &&
          !officeIsBookableOnRootLevel()) ||
        officeIsUnsupportedForGroupBooking() ||
        insufficientDesksForGroupBooking() ||
        (selectedWorkdayOfficeId &&
          !userChooseLastSelectedWorkdayId() &&
          !officeIsBookableOnRootLevel() &&
          !selectedLocationId())
      )
        return false;

      // There are two ways when we show data

      // 1. On initial render(the user didn't choose office we did it automatically)
      // If workspace exist(we don't care does office for workspace exist or it is part of user location profile)
      // If last selected location is enabled(not closed/full/exist)

      // 2. After the user choose a different office then we gave a suggestion
      // If the chosen office is bookable on the root level(just building)
      // If the user finished choosing a node
      return (
        (workspace && !userSelectedDifferentOffice()) ||
        (!userSelectedDifferentOffice() && lastSelectedId()) ||
        officeIsBookableOnRootLevel() ||
        selectedLocationId()
      );
    }),

    officeIsUnsupportedForGroupBooking,
    insufficientDesksForGroupBooking,

    openDeskBooking: () => {
      if (disableDeskTile()) return;
      openWorkspacePicker();
    },
    iconName,
    loading,
    officeLabel: ko.pureComputed(() => buildingName() ?? Labels.Office),
    deskSectionLabel: ko.pureComputed(() =>
      selectedInvitees()?.length > 0 ? "Desks" : "Desk"
    ),
    deskLabel: ko.pureComputed(() =>
      (floorName() || areaName()) ?? selectedInvitees()?.length > 0
        ? Labels.Desks
        : Labels.Desk
    ),
    colleagues: ko.pureComputed(() =>
      ConnectedColleaguesStoreSingleton()
        .allWithReservationOnDateInNode(date, locationId())
        .sort((a, b) => {
          if (!isCheckInFeatureEnabled()) return;
          return compareStatus(a.checkInStatus(), b.checkInStatus());
        })
    ),

    nodeLabel: ko.pureComputed(() => {
      const currentUser = UserApp.resolveUser();
      const deskSectionLabel = deskSection()?.labelExplainer();

      if (
        officeIsUnsupportedForGroupBooking() ||
        // remove after workday release
        !selectedOfficeId() ||
        selectedOfficeId() === currentUser?.defaultBuildingId
      )
        return "";

      if (
        (hasWorkdayFeature &&
          userChooseLastSelectedWorkdayId() &&
          workspace?.buildingId !== selectedWorkdayOfficeId &&
          !userSelectedDifferentNode()) ||
        (!hasWorkdayFeature &&
          !userSelectedDifferentOffice() &&
          !userSelectedDifferentNode() &&
          !workspace)
      )
        return "Last selected";

      return deskSectionLabel;
    }),
    parentClassNameForAvatars: ".WorkspaceWizard-body",
    dispose: deskSection.dispose
  };
};

export const WorkSpace = KOComponent("work-space", WorkSpaceVM, template);

const Labels = {
  Office: "Select an office",
  Desks: "Select desks",
  Desk: "Select a desk"
};
