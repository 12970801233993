import BuildingApiModel from "@shared/apimodels/BuildingApiModel";
import ParkingReservationApiModel from "@shared/apimodels/ParkingReservationApiModel";
import { authFetch, authFetchResponse } from "@shared/services/auth/authFetch";
import { ConnectionApiModel } from "../apimodels/ConnectionApiModel";
import { NotificationPreferencesApiModel } from "../apimodels/NotificationPreferencesApiModel";
import { UserSearchApiModel } from "../apimodels/UserSearchApiModel";
import { NotificationApiModel } from "@admin/apimodels/NotificationApiModel.js";
import { AdminExceptionApiModel } from "@shared/apimodels/AdminExceptionApiModel.js";
import { MeetingApiModel } from "@app/apimodels/MeetingApiModel";
import { CheckInApiModel } from "@app/apimodels/CheckInApiModel";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel.js";
import { MeetApi } from "@shared/services/MeetApi.js";
import { confirmDialog } from "@shared/utils/confirmDialog.js";
import { Fetch } from "@shared/services/auth/authFetch.js";
import { SuggestionApiModel } from "@app/apimodels/SuggestionApiModel";
import { CloudSuiteError } from "@shared/utils/CloudSuiteError";
import { InviteeSearchApiModel } from "@app/apimodels/InviteeSearchApiModel";
import { MeetingRoomApiModel } from "@app/apimodels/MeetingRoomApiModel";
import ParkingLotApiModel from "@shared/apimodels/ParkingLotApiModel";
import { getDateKey } from "@shared/utils/dateHelpers";
import { MeetingAttendeeApiModel } from "@app/apimodels/MeetingAttendeeApiModel";
import { ConnectionSuggestionsApiModel } from "@app/apimodels/ConnectionSuggestionsApiModel";
import {
  GroupReservationApiModel,
  ExtendedGroupReservationApiModel,
  InviteeReservationApiModel
} from "@app/apimodels/GroupReservationApiModel";
import { WorkspaceApiModel } from "@shared/apimodels/WorkspaceApiModel";
import { ConnectedColleagueApiModel } from "@app/apimodels/ConnectedColleagueApiModel";
import { WorkdayApiModel } from "@shared/apimodels/WorkdayApiModel";
import { RoomWithAvailabilityApiModel } from "@app/apimodels/RoomWithAvailabilityApiModel";
import { startOfWeek } from "date-fns";
import { ExtrasApiModel } from "@shared/apimodels/Extras";

const f = Fetch(process.env.MAPIQ_API_BASE_URL);
export const AppApi = {
  getConnectionsCheckInStatus: () =>
    authFetch(`/localization/me/connections/checkin/status`),
  getCheckInStatus: () =>
    authFetch("/localization/me/checkin/status").then(
      apiObj => new CheckInApiModel(apiObj)
    ),
  postCheckInStatus: (buildingId, type) =>
    authFetch("/localization/me/checkin", {
      method: "POST",
      body: JSON.stringify({ buildingId, eventType: type }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }).then(apiObj => new CheckInApiModel(apiObj)),

  sendLocationPreference: buildingId =>
    authFetch(`/shifts/me`, {
      method: "PUT",
      body: JSON.stringify({ defaultBuildingId: buildingId }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),
  setUserIsNewToFalse: () =>
    authFetch(`/shifts/me`, {
      method: "PUT",
      body: JSON.stringify({ isNew: false }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),
  getParkingReservations: (startDate, endDate) =>
    f
      .get(
        `/parking/me/reservations?startDate=${getDateKey(
          startDate
        )}&endDate=${getDateKey(endDate)}`
      )
      .then(reservations =>
        reservations.map(
          reservation => new ParkingReservationApiModel(reservation)
        )
      ),

  createParkingReservation: parkingReservation =>
    f.post("/parking/me/reservations", parkingReservation),
  updateParkingReservation: (currentReservationId, newParkingLocationId) =>
    f.patch(`/parking/me/reservations/${currentReservationId}`, {
      nodeId: newParkingLocationId
    }),
  deleteParkingReservation: reservationId =>
    f.delete(`/parking/me/reservations/${reservationId}`),
  getParkingLotsForBuilding: buildingId =>
    f
      .get(`/shifts/buildings/${buildingId}/ParkingLots`)
      .then(parkingLots =>
        parkingLots.map(parkingLot => new ParkingLotApiModel(parkingLot))
      ),
  getParkingAvailability: date => {
    const dateString = getDateKey(date);
    return authFetch(
      `/parking/reservations/count?startDate=${dateString}&endDate=${dateString}`,
      { headers: { "x-api-version": "2.0" } }
    );
  },
  getProfilePicture: (pictureHash, pictureSize) => {
    return f
      .get(`/shifts/ProfilePictures/${pictureHash}?size=${pictureSize}`)
      .then(blob => {
        if (blob instanceof Blob) {
          return URL.createObjectURL(blob);
        }

        throw "Expected a blob response";
      });
  },
  createProfilePicture: body => {
    return authFetch("/shifts/ProfilePictures", {
      method: "POST",
      body: body,
      headers: {
        "x-api-version": "2.0"
      }
    });
  },
  updateProfilePicture: body => {
    return authFetch("/shifts/ProfilePictures", {
      method: "PUT",
      body: body,
      headers: {
        "x-api-version": "2.0"
      }
    });
  },
  deleteProfilePicture: () => {
    return f.delete(`/shifts/ProfilePictures`);
  },

  getProfilePicture: (pictureHash, pictureSize) => {
    return f
      .get(`/shifts/ProfilePictures/${pictureHash}?size=${pictureSize}`)
      .then(blob => {
        if (blob instanceof Blob) {
          return URL.createObjectURL(blob);
        }
        throw "Expected a blob response";
      });
  },

  exportBookingHistory: () =>
    authFetchResponse("/me/booking-overviews", {
      headers: { "accept": "text/csv", "x-api-version": "2.0" }
    }),

  postWorkspace: workspace =>
    f.post("/me/workspace-reservations", workspace, {
      "Content-Type": "application/json",
      "x-api-version": "2.0"
    }),

  getWorkspaces: startOfCalendar =>
    f
      .get(`/me/workspace-reservations?startDate=${startOfCalendar}`, {
        "x-api-version": "2.0"
      })
      .then(reservations => reservations.map(r => new WorkspaceApiModel(r))),

  getWorkspaceReservation: id =>
    f
      .get(`/me/workspace-reservations/${id}`, {
        "x-api-version": "2.0"
      })
      .then(reservation => new WorkspaceApiModel(reservation)),

  deleteWorkspaceReservation: reservationId =>
    f.delete(`/me/workspace-reservations/${reservationId}`, null, {
      "x-api-version": "2.0"
    }),

  editWorkspaceReservation: (reservationId, members) =>
    f.patch(
      `/me/workspace-reservations/${reservationId}`,
      {
        members
      },
      {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    ),

  getWorkdays: () => {
    const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const formattedDate = getDateKey(firstDayOfWeek);

    return f
      .get(`/me/workdays?startDate=${formattedDate}`, {
        "x-api-version": "2.0"
      })
      .then(workdays => workdays.map(workday => new WorkdayApiModel(workday)));
  },

  getWorkdayForSingleDay: date => {
    const formattedDate = getDateKey(date);

    return f
      .get(`/me/workdays?startDate=${formattedDate}&endDate=${formattedDate}`, {
        "x-api-version": "2.0"
      })
      .then(workdays => {
        const workday = workdays[0];

        return workday ? new WorkdayApiModel(workday) : null;
      });
  },

  createWorkdayReservation: workday =>
    f
      .post("/me/workdays", workday, {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      })
      .then(workday => new WorkdayApiModel(workday)),

  updateWorkdayReservation: (id, workday) =>
    f
      .put(`/me/workdays/${id}`, workday, {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      })
      .then(workday => new WorkdayApiModel(workday)),

  getGroupWorkspaceReservations: (startDateTime, endDateTime) =>
    authFetch(
      `/me/group-workspace-reservations?startDate=${getDateKey(
        startDateTime
      )}&endDate=${getDateKey(endDateTime)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-version": "2.0"
        }
      }
    ).then(apiArray =>
      apiArray.map(apiObj => new ExtendedGroupReservationApiModel(apiObj))
    ),

  postGroupWorkspaceReservation: groupReservation =>
    authFetch(`/me/group-workspace-reservations`, {
      method: "POST",
      body: JSON.stringify(groupReservation),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }).then(apiObj => new GroupReservationApiModel(apiObj)),

  updateGroupWorkspaceReservationInvitation: (groupId, reservationId, status) =>
    authFetch(
      `/me/group-workspace-reservations/${groupId}/reservations/${reservationId}/status`,
      {
        method: "PUT",
        body: JSON.stringify({
          status
        }),
        headers: {
          "Content-Type": "application/json",
          "x-api-version": "2.0"
        }
      }
    ).then(apiObj => new InviteeReservationApiModel(apiObj)),

  getBuildingsForUser: () =>
    authFetch(`/shifts/me/buildings`, {
      headers: { "x-api-version": "2.0" }
    }).then(obj => obj.map(b => new BuildingApiModel(b))),

  getConnectedColleagues: (startDate, endDate) =>
    f
      .get(
        `/me/connections/reservations?startDate=${getDateKey(
          startDate
        )}&endDate=${getDateKey(endDate)}`,
        { "x-api-version": "2.0" }
      )
      .then(obj => obj.map(c => new ConnectedColleagueApiModel(c))),

  getConnections: () => {
    return authFetch(`/shifts/connections`, {
      headers: { "x-api-version": "2.0" }
    }).then(cs => cs.map(c => new ConnectionApiModel(c)));
  },
  //this endpoint is valid both for deleting existing connections and cancel connections request
  deleteConnection: id => {
    return authFetch(`/shifts/connections/${id}`, {
      method: "DELETE",
      headers: {
        "x-api-version": "2.0"
      }
    });
  },

  /*

    This will return something like 
    
    [ { id, name, email, isNew } ]


  */
  searchPeople: str => {
    return authFetch(
      `/shifts/people?search=${encodeURIComponent(str)}`
    ).then(users => users.map(u => new UserSearchApiModel(u)));
  },

  //this endpoint is valid both for accepting and ignoring a request
  updateConnection: (id, newStatus) => {
    return authFetch(`/shifts/connections/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ Status: newStatus }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }).then(updatedConnection => new ConnectionApiModel(updatedConnection));
  },
  createConnection: userId => {
    return authFetch(`/shifts/connections`, {
      method: "POST",
      body: JSON.stringify({ ToUserId: userId }),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }).then(newConnection => new ConnectionApiModel(newConnection));
  },
  // Suggestions
  getConnectionSuggestions: () =>
    authFetch(`/shifts/Connections/suggestions`, {
      headers: { "x-api-version": "2.0" }
    }).then(
      connectionSuggestions =>
        new ConnectionSuggestionsApiModel(connectionSuggestions)
    ),

  postSuggestionFeedback: feedback =>
    authFetch(`/SuggestionFeedback/PostConnectionFeedback`, {
      method: "POST",
      body: JSON.stringify(feedback),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    }),
  updateUserPreferences: newSetting => {
    return authFetch(`/shifts/me/preferences`, {
      method: "PATCH",
      body: JSON.stringify(newSetting),
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2.0"
      }
    });
  },

  getExtras: () =>
    f
      .get("/extrasLinks")
      .then(extras => extras.map(extra => new ExtrasApiModel(extra))),
  getExceptions: endDate =>
    authFetch(
      `/shifts/me/openingdayexceptions?from=${getDateKey(endDate)}`
    ).then(exs => exs.map(AdminExceptionApiModel.fromApiObj)),

  //Time zones
  getTimezones: () => f.get("/available-time-zones"),
  // Notifications
  getNotifications: () =>
    authFetch(
      "/notifications",
      {},
      process.env.MAPIQ_API_BASE_URL_NOTI
    ).then(notis => notis.map(NotificationApiModel.fromApiObj)),
  deleteNotification: id =>
    authFetch(
      `/notifications/${id}`,
      {
        method: "DELETE"
      },
      process.env.MAPIQ_API_BASE_URL_NOTI
    ),
  updateNotifications: notifications =>
    authFetch(
      "/notifications",
      {
        method: "PATCH",
        body: JSON.stringify(notifications),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_NOTI
    ),
  getMeetings: (startDate, endDate) =>
    authFetch(
      `/me/meetings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      {},
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    )
      .then(meetings => meetings.map(MeetingApiModel.fromApiObj))
      .catch(handlePossibleConsentError),

  getSuggestions: (
    attendees,
    duration,
    startDateTime,
    endDateTime,
    buildingId = null
  ) =>
    authFetch(
      `/me/meetingtimesuggestions`,
      {
        method: "POST",
        body: JSON.stringify({
          attendees: attendees,
          durationInMinutes: duration.value,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          buildingId: buildingId
        }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    ).then(ts => ts.map(SuggestionApiModel.fromApiObj)),
  createMeeting: meetingObj =>
    authFetch(
      `/me/meetings`,
      {
        method: "POST",
        body: JSON.stringify(meetingObj),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    ).then(MeetingApiModel.fromApiObj),
  searchInvitees: searchQuery => {
    return authFetch(
      `/me/invitees?search=${encodeURIComponent(searchQuery)}`,
      {},
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    ).then(invitees => invitees.map(i => new InviteeSearchApiModel(i)));
  },
  searchPeopleAsInvitee: searchQuery => {
    return authFetch(
      `/shifts/people?search=${encodeURIComponent(searchQuery)}`
    ).then(users => users.map(u => new InviteeSearchApiModel(u)));
  },
  deleteMeeting: meetingId => {
    return authFetch(
      `/me/meetings/${meetingId}`,
      { method: "DELETE" },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    );
  },
  getMeetingRoomPicture: (buildingId, hash, width, height = null) => {
    const dimensionParam = width
      ? `width=${width}`
      : height
      ? `height=${height}`
      : "";
    return authFetch(
      `/buildings/${buildingId}/pictures?hash=${hash}&` + dimensionParam,
      {},
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    );
  },
  getAvailableMeetingRooms: (
    attendeesAmount,
    buildingId,
    startDateTime,
    endDateTime
  ) =>
    authFetch(
      `/availabilities/availableRooms?minNumberOfSeats=${attendeesAmount}&buildingId=${buildingId}&startDateTime=${startDateTime.toISOString()}&endDateTime=${endDateTime.toISOString()}`,
      {},
      process.env.API_BASE_URL_GATEWAY
    ).then(ts => ts.map(MeetingRoomApiModel.fromApiObj)),
  getAllRooms: requestObj =>
    authFetch(
      `/me/rooms/suggestions`,
      {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.API_BASE_URL_GATEWAY
    ).then(ts => ts.map(RoomWithAvailabilityApiModel.fromApiObj)),
  getAttendeesAvailability: (attendees, startDateTime, endDateTime) =>
    authFetch(
      `/me/calendars/availabilities`,
      {
        method: "POST",
        body: JSON.stringify({
          emails: attendees,
          startDateTimeUTC: startDateTime,
          endDateTimeUTC: endDateTime
        }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.API_BASE_URL_GATEWAY
    ).then(ts => ts.map(MeetingAttendeeApiModel.fromApiObj)),

  updateMeeting: meetingObj =>
    authFetch(
      `/me/meetings/${meetingObj.id}`,
      {
        method: "PUT",
        body: JSON.stringify(meetingObj),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    ).then(MeetingApiModel.fromApiObj),

  getNotificationPreferences: () =>
    authFetch(
      `/notifications/users/preferences`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    ).then(r => new NotificationPreferencesApiModel(r)),

  updateNotificationPreferences: preferences =>
    authFetch(
      `/notifications/users/preferences`,
      {
        method: "PUT",
        body: JSON.stringify(preferences),
        headers: {
          "Content-Type": "application/json"
        }
      },
      process.env.MAPIQ_API_BASE_URL_GATEWAY
    )
};

const handlePossibleConsentError = err => {
  const { firstErrorType } = Server400ErrorModel(err);

  const cloudSuiteIntegrationErrors = [
    CloudSuiteError.Unspecified,
    CloudSuiteError.Generic,
    CloudSuiteError.InvalidGrant,
    CloudSuiteError.MailboxNotEnabledForRestAPI
  ];

  if (cloudSuiteIntegrationErrors.includes(firstErrorType)) {
    return MeetApi.getConsentUrl().then(consentUrl => {
      return confirmDialog({
        question: "We were not able to connect to your calendar.",
        confirm: "Renew consent",
        cancel: "Disable calendar"
      })
        .then(async () => {
          document.location = consentUrl;
        })
        .catch(e => {
          if (e === confirmDialog.REJECTION) {
            return MeetApi.setUserPreference({ enableCalendar: false }).then(
              () => {
                document.location.reload();
              }
            );
          } else {
            throw e;
          }
        });
    });
  }

  throw err;
};
