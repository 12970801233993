import template from "./DeskPicker.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { DeskSection } from "@shared/viewmodels/DeskSection.js";
import "./DeskPicker.scss";

const DeskPickerVM = ({
  date,
  deskSection = ko.observable(null),
  workspace,
  locationId,
  selectedUserId = null,
  deskSelectionMode = ko.observable(false),
  areaViewMode,
  disableDeskChoosing = false,
  showMapIcon = true,
  showLastSelectedDesk = false,
  selectedDeskIds,
  isGroupBooking,
  groupSize,
  forceNewDeskSuggestions = ko.observable(false)
}) => {
  if (ko.isWritableObservable(deskSection)) {
    deskSection(
      DeskSection({
        locationId,
        date,
        workspace,
        selectedUserId,
        selectedDeskIds,
        disableDeskChoosing,
        showMapIcon,
        showLastSelectedDesk,
        isGroupBooking,
        groupSize,
        forceNewDeskSuggestions
      })
    );
  }

  return {
    deskSelectionMode,
    areaViewMode,
    deskSection,
    showSmallMap: ko.pureComputed(
      () => !deskSelectionMode() && !areaViewMode()
    ),
    viewMap: () => areaViewMode(true),

    changeDesk: () => {
      deskSelectionMode(true);
      deskSection().selectedConnection(null);
    },
    dispose: () => deskSection()?.dispose?.()
  };
};

export const DeskPicker = KOComponent("desk-picker", DeskPickerVM, template);
