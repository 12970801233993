/** Creates a config object from env variables */
export const MsalConfig = () => ({
  auth: {
    clientId: process.env.MAPIQ_AUTH_CLIENT_ID,
    authority: process.env.MAPIQ_AUTH_AUTHORITY,
    knownAuthorities: [process.env.MAPIQ_AUTH_B2C_DOMAIN_HOST],
    validateAuthority: false,
    protocolMode: process.env.MAPIQ_AUTH_MSAL_PROTOCOL
  },
  cache: {
    cacheLocation: "localStorage"
  }

  // In the dev b2c, the tokens are only valid for 5 minutes, which
  // happens to be the default setting value (300). If you don't want
  // a token to be fetched for every request, update this to 60, or something
  // else below 300.

  // system: {
  //   tokenRenewalOffsetSeconds: 60
  // }
});
