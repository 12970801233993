import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import template from "./Extras.template.html";
import extraEntryTemplate from "./templates/extra-entry.template.html";
import "./Extras.scss";
import { ExtrasStoreSingleton } from "@shared/services/Extras/ExtrasStore";
import {
  ExtrasTypes,
  getExtraDisplayName,
  getExtraIconName
} from "@admin/components/SettingsViewer/views/ExtrasView/helpers/ExtrasTypes";
import { byPropAsc } from "@shared/utils/sort";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { ExtrasWizard } from "./components/ExtrasWizard/ExtrasWizard";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { FeatureNames, getFeatures } from "@shared/services/Features";

const ExtrasPopupTitle = {
  ADD_EXTRAS: "Add extras",
  ARRANGE_EXTRAS: "Arrange extras"
};

const ExtrasVM = ({ extrasType, numberOfExtrasToShow = 1 }) => {
  const extrasStore = ExtrasStoreSingleton();
  const extrasPopupTitle = getExtrasInPopupHeading(extrasType);

  const extraVMs = ko.pureComputed(() =>
    extrasStore.hydrated()
      ? extrasStore
          .getByType(extrasType)
          .sort(byPropAsc("name", stringCompareAsc))
          .map(ExtraVM)
      : []
  );

  const openPopup = () =>
    Mediator().publish(Channels.OpenExtrasWizard, {
      title: extrasPopupTitle,
      extraVMs
    });

  return {
    extrasType,

    openPopupExtraEntry: {
      iconName: "addExtras",
      displayName: extrasPopupTitle,
      description: null,
      onClick: openPopup,
      goToIconName: "chevronRight"
    },

    showExtras: ko.pureComputed(
      () => getFeatures().has(FeatureNames.EXTRAS) && Boolean(extraVMs().length)
    ),

    extrasToShow: ko.pureComputed(() =>
      extraVMs().slice(0, numberOfExtrasToShow)
    ),

    showExtrasInPopup: extraVMs.maybeMap(
      e => e.length > numberOfExtrasToShow,
      false
    )
  };
};

const ExtrasComponent = KOComponent("extras", ExtrasVM, template);

export const Extras = Object.assign({}, ExtrasComponent, {
  register: ko => {
    ExtrasComponent.register(ko);
    ExtrasWizard.register(ko);

    ko.components.register("extra-entry", {
      template: extraEntryTemplate
    });
  }
});

const ExtraVM = extra => ({
  displayName: getExtraDisplayName(extra.name, extra.type),
  iconName: getExtraIconName(extra.name),
  goToIconName: "externalLink",
  description: extra.description,

  onClick: () => window.open(extra.url, "_blank")
});

const getExtrasInPopupHeading = extrasType => {
  switch (extrasType) {
    case ExtrasTypes.WORKDAY:
    case ExtrasTypes.HYBRID_MEETINGS:
      return ExtrasPopupTitle.ADD_EXTRAS;
    case ExtrasTypes.USER_PROFILE:
      return ExtrasPopupTitle.ARRANGE_EXTRAS;
  }
};
