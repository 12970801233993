import BuildingApiModel from "../apimodels/BuildingApiModel";
import { authFetch } from "./auth/authFetch";
import SettingsApiModel from "../apimodels/SettingsApiModel";
import { getDateKey } from "@shared/utils/dateHelpers";
const smsBaseUrl =
  process.env.MAPIQ_API_BASE_URL_SMS || process.env.MAPIQ_API_BASE_URL;

export const Api = {
  getSettings: () =>
    authFetch("/shifts/settings").then(apiObj => new SettingsApiModel(apiObj)),
  getBuilding: buildingId =>
    authFetch(`/shifts/buildings/${buildingId}`).then(
      obj => new BuildingApiModel(obj)
    ),
  getDeskSuggestions: (areaId, date, deskCount, currentReservationId) => {
    const dateStr = typeof date === "string" ? date : getDateKey(date);

    const params = new URLSearchParams({
      areaId,
      date: dateStr,
      ...(deskCount && { deskCount }),
      ...(currentReservationId && { currentReservationId })
    }).toString();

    return authFetch(`/shifts/Me/desksuggestions?${params}`);
  },
  getDesks: areaId => {
    return authFetch(`/shifts/buildings/areas/${areaId}/desks`);
  },

  getFeatures: () => authFetch("/me/enabledFeatures", {}, smsBaseUrl),

  getDeskBookingStatus: (areaId, date) => {
    const dateStr = typeof date === "string" ? date : getDateKey(date);

    return authFetch(
      `/shifts/buildings/areas/${areaId}/DeskBookingStatus?date=${dateStr}`
    );
  }
};
