import ko from "@shared/knockout/extended";

import "./SelectBox.scss";
import template from "./SelectBox.template.html";

import filterIcon from "./icons/filter.template.html";
import inlineFilterIcon from "./icons/inline-filter.template.html";
import sortIcon from "./icons/sort.template.html";
import menuIcon from "./icons/menu.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { stringMatchesQuery } from "@admin/utils/searchHelper";

const SelectBoxVM = params => {
  if (params.options) params = params.options;

  const multiSelect = params.multiSelect || false;
  const id = params.id || null;
  if (!id) {
    console.warn(
      "A SelectBox without id was provided, without an id it won't work."
    );
  }

  const showSearchBar = params.showSearchBar || false;
  const searchQuery = ko.observable("");
  const allowEmptySelect = multiSelect || params.allowEmptySelect;
  const optionLabels = params.options || [];
  const getOptionLabel = params.getOptionLabel || (x => x);
  const getOptionId = params.getOptionId || (x => x);
  const hasAllOption = !!params.allOptionsLabel;
  const allOptionsLabel = params.allOptionsLabel;
  const loadingOptions = params.loading || false;
  //default color scheme: white button for no filters selected, blue for at least one selected
  //inverted color scheme: white button for all filters selected, blue for at least one unselected
  const invertColorScheme = params.invertColorScheme || false;
  //determine if we use a big button with icon + label or only a SVG icon
  const useButton = params.useButton === false ? false : true;

  const paramOptions = ko.pureComputed(() =>
    ko
      .unwrap(optionLabels)
      .map(option => ({
        isAllOptions: false,
        label: getOptionLabel(option),
        option: option,
        testId: `${id}-${getOptionLabel(option).replace(/\s/g, "")}`,
        selected: ko.observable(
          params
            .output()
            .map(getOptionId)
            .includes(getOptionId(option))
        )
      }))
      .filter(({ label }) => stringMatchesQuery(label, searchQuery()))
  );

  const options = ko.pureComputed(() => {
    if (!hasAllOption) return paramOptions();
    // Create the special "All" item
    const showAllOption = {
      // Indicate that this is the "special" option, so we can implement
      // custom behavior in other parts of the code.
      isAllOptions: true,
      label: allOptionsLabel,
      testId: `${id}-All`,
      selected: ko.computed({
        read: () => {
          if (
            paramOptions().some(o => o.selected()) ||
            (showSearchBar && params.output().length)
          ) {
            return false;
          }

          return true;
        },
        write: () => {
          if (showSearchBar) {
            showDropdown(false);
            searchQuery("");
            params.output.removeAll();
          }
          paramOptions().forEach(opt => opt.selected(false));
        }
      })
    };
    // Prepend it to the regular options, and return that list
    return [showAllOption, ...paramOptions()];
  });

  params.resetFilter?.subscribe(newValue => {
    if (newValue) {
      if (showSearchBar) searchQuery("");

      params.output.removeAll();
      paramOptions().forEach(opt => opt.selected(false));
      showDropdown(false);
      params.resetFilter(false);
    }
  });
  const showDropdown = ko.observable(false);
  Mediator().subscribe(Channels.HandleFilterClick, id => {
    if (!id || id !== params.id) showDropdown(false);
    else {
      showDropdown(!showDropdown());
      Mediator().publish(Channels.CloseFlatpickr);
    }
  });

  const onClick = () => {
    Mediator().publish(Channels.HandleFilterClick, id);
  };

  const clearSearchQuery = () => {
    searchQuery("");
  };

  window.addEventListener("click", () => {
    if (showSearchBar) searchQuery("");
    showDropdown(false);
  });

  window.addEventListener("keyup", e => {
    if (e.key === "Escape") {
      if (showSearchBar) searchQuery("");

      showDropdown(false);
    }
  });

  const handleSelectedOptions = () => {
    params.output(selectedOptions());

    if (params.handleFilter) {
      if (
        id !== "AccessProfile" &&
        id !== "LocationProfile" &&
        id !== "ParkingQuotaProfile" &&
        id !== "ParkingLocationProfile"
      )
        params.handleFilter(id, selectedOptions()[0]);
      else params.handleFilter(id, selectedOptions()[0]?.id());
    }
  };
  const onSelect = (clickedOption, _event) => {
    if (multiSelect) {
      // Multi select just always toggles the clicked option
      clickedOption.selected(!clickedOption.selected());
    } else {
      // Single select
      const isDeselectionAttempt = clickedOption.selected();
      if (isDeselectionAttempt) {
        if (allowEmptySelect) {
          clickedOption.selected(false);
          showDropdown(false);
        }
      } else {
        // Get rid of the previous selection
        const currentSelection = ko
          .unwrap(options)
          .find(({ selected }) => selected());
        if (currentSelection) {
          if (!showSearchBar) currentSelection.selected(false);
          else {
            if (params.output().length >= 1) currentSelection.selected(false);
          }
        }
        clickedOption.selected(true);
        showDropdown(false);
      }
    }
    handleSelectedOptions();
  };

  const selectedOptions = ko
    .pureComputed(() =>
      ko
        .unwrap(options)
        .filter(({ selected }) => selected())
        // If there is an "All" selection, we remove it to indicate
        // that there is no active filtering going on. The selectedOptions
        // array will be empty, and the parent component can disable its filter
        .filter(({ isAllOptions }) => !isAllOptions)
        .map(({ option }) => option)
    )
    .extend({ deferred: true });

  const isTheButtonBlue = ko.pureComputed(() => {
    if (id === "column") return;
    return invertColorScheme
      ? selectedOptions().length < options().length
      : showSearchBar
      ? params.output().length > 0
      : selectedOptions().length > 0;
  });

  return {
    id,
    iconHtml: params.icon || menuIcon,
    label: params.label,
    options,
    searchQuery,
    showSearchBar,
    clearSearchQuery,
    onSelect,
    showDropdown,
    onClick,
    selectedOptions,
    isTheButtonBlue,
    isEditMode: params.isEditMode,
    useButton,
    loadingOptions
  };
};

export const Icons = {
  filter: filterIcon,
  sort: sortIcon,
  inlineFilter: inlineFilterIcon
};

export const SelectBox = KOComponent("select-box", SelectBoxVM, template);
