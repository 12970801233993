import { ConsentRedirectResult } from "./ConsentRedirectResult";

export const processPossibleConsentRedirectResult = () => {
  const searchQ = new URLSearchParams(document.location.search);
  const REDIRECT_SOURCE_KEY = "redirect_source";
  const RESULT_KEY = "result";
  const redirectSource = searchQ.get(REDIRECT_SOURCE_KEY);
  const result = searchQ.get(RESULT_KEY);

  if (redirectSource) {
    // Remove redirected from URL to prevent it from being included
    // if user bookmarks this page
    removeQueryParametersFromURL([REDIRECT_SOURCE_KEY, RESULT_KEY]);

    if (redirectSource === "meet_consent") {
      switch (result) {
        case "success":
          return ConsentRedirectResult.Success;
        case "failure":
        case "reject":
          return ConsentRedirectResult.Failure;
        default:
          return ConsentRedirectResult.Unknown;
      }
    }
  }

  return ConsentRedirectResult.Unavailable;
};

export const removeQueryParametersFromURL = queryParamsArray => {
  const searchQ = new URLSearchParams(document.location.search);
  const wasRedirected = searchQ.get("redirected") === "true";
  if (wasRedirected) {
    searchQ.delete("redirected");
    replaceUserUrl(searchQ);
  }
  if (Array.isArray(queryParamsArray) && queryParamsArray.length) {
    queryParamsArray.forEach(element => {
      searchQ.delete(element);
    });
    replaceUserUrl(searchQ);
  }
  return wasRedirected;
};

const replaceUserUrl = searchQ => {
  const newQuery = searchQ.toString();
  const newUrl = new URL(document.location.href);
  newUrl.search = newQuery.length > 1 ? newQuery : "";
  window.history.replaceState("", document.title, newUrl.toString());
};
