import ko from "@shared/knockout/extended";
const lottieBinding = {
  init: function(element, valueAccessor, allBindings) {
    //animation logic
    let animate;
    const props = valueAccessor();
    //data to animate
    const animationData = props.data;
    if (animationData) {
      import(
        /* webpackChunkName: "lottie-web" */ "lottie-web/build/player/lottie_light.min.js"
      )
        .then(lottie => {
          //lazy loading of data to be animated
          animationData
            .then(data => {
              if (animate) animate.destroy();
              animate = lottie.default.loadAnimation(
                Object.assign(
                  {},
                  {
                    container: element,
                    renderer: "svg",
                    loop: false,
                    autoPlay: false,
                    animationData: data
                  },
                  //if you need additional/different options, this will override the above
                  allBindings.has("lottieOptions")
                    ? allBindings.get("lottieOptions")
                    : {}
                )
              );
              //if scaling is required (for CheckInTile it is necessary, since the cups are larger), pass as a parameter
              //You can see an example in CheckInHasShiftTile.js and CheckInHasShiftTile template
              if (props.dataScale)
                lottieScale(
                  props.dataScale.reducer,
                  element,
                  props.dataScale.xPositionInPixels,
                  props.dataScale.yPositionInPixels
                );
            })
            .catch(() => {
              if (props.showAnimation) props.showAnimation(false);
            });
        })
        .catch(() => {
          if (props.showAnimation) props.showAnimation(false);
        });
    }
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      if (animate) animate.destroy();
    });
  }
};

const lottieScale = (
  reducer,
  container,
  xPositionInPixels,
  yPositionInPixels
) => {
  if (reducer < 1)
    return Error(
      "Scale should always be a positive floating number greater than 1"
    );

  let svgElement = container.querySelector("svg");
  let newFrameWidth = svgElement.viewBox.baseVal.width / reducer;
  let newFrameHeight = svgElement.viewBox.baseVal.height / reducer;
  // For example:
  // An SVG animation with width 1920px would now become 960px wide with scale 2.
  // The same happens with height
  svgElement.width.baseVal.value = svgElement.viewBox.baseVal.width = newFrameWidth;
  svgElement.height.baseVal.value = svgElement.viewBox.baseVal.height = newFrameHeight;
  // Only change the starting X and Y coords if the frame size is decreasing
  const x = newFrameWidth + xPositionInPixels;
  const y = newFrameHeight + yPositionInPixels;
  svgElement.viewBox.baseVal.x = x;
  svgElement.viewBox.baseVal.y = y;
};

export { lottieBinding };
