const DayIndex = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
};

class ProfileApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.daysPerWeek = obj.daysPerWeek;
    this.daysAhead = obj.daysAhead;
    this.registrationDays = obj.registrationDays.sort(
      (a, b) => DayIndex[a] - DayIndex[b]
    );
  }

  static equal(a, b) {
    return (
      a &&
      b &&
      a.id === b.id &&
      a.name === b.name &&
      a.daysPerWeek === b.daysPerWeek &&
      a.daysAhead === b.daysAhead &&
      a.registrationDays.join("") === b.registrationDays.join("")
    );
  }
}

export default ProfileApiModel;
