export const LayerState = {
  Default: "",
  Hidden: "is-hidden",
  Dimmed: "is-dimmed",
  Disabled: "is-disabled",
  Booked: "is-booked",
  Highlighted: "is-highlighted",
  Selected: "is-selected"
};

export const LayerType = {
  Floor: "floorLayer",
  Area: "areaLayer",
  Desk: "deskLayer"
};

export const State = ({ typeDefaults = {}, nodeOverrides = {} }) => {
  return State.merge(State.empty(), {
    typeDefaults,
    nodeOverrides
  });
};

State.merge = (s1, s2) => ({
  typeDefaults: Object.assign({}, s1.typeDefaults, s2.typeDefaults),
  nodeOverrides: Object.assign({}, s1.nodeOverrides, s2.nodeOverrides)
});

State.empty = () => ({
  typeDefaults: {
    [LayerType.Floor]: LayerState.Default,
    [LayerType.Area]: LayerState.Default,
    [LayerType.Desk]: LayerState.Default
  },
  nodeOverrides: {}
});

export const IdSelector = nodeId =>
  nodeId ? `[data-id="${nodeId}"]` : `[data-id]`;
const Q = (el, selector) => Array.from(el.querySelectorAll(selector));

export const StateUpdate = (state = State.empty()) => ({
  runIO: svg => {
    const idLayers = Q(svg, IdSelector());

    idLayers.forEach(el => {
      const id = el.dataset.id;
      const newClass =
        id in state.nodeOverrides
          ? state.nodeOverrides[id]
          : state.typeDefaults[el.dataset.type] ?? LayerState.Default;

      el.setAttribute("class", newClass);
    });
  }
});

/**
 * @param {HTMLElement} el
 * @returns {Boolean}
 */
export const getDeskIdForElement = el => {
  if (!el || el.tagName === "svg") return null;

  switch (el.dataset.type) {
    // Layers "above" a desk
    case "areaLayer":
    case "floorLayer":
    case "areaOutline":
    case "floorOutline":
      return null;
    // The right layer
    case "deskLayer":
      return el.dataset.id;
    // Other cases: traverse upwards
    case "deskOutline":
    case undefined: // Inside an outline (any geometry)
    default:
      return getDeskIdForElement(el.parentElement);
  }
};

export const getAreaIdForElement = el => {
  if (!el || el.tagName === "svg") return null;

  switch (el.dataset.type) {
    case "floorLayer":
    case "floorOutline":
      return null;
    case "areaLayer":
      return el.dataset.id;
    case "areaOutline":
    case "deskLayer":
    case "deskOutline":
    case undefined: // Inside an outline (any geometry)
    default:
      return getAreaIdForElement(el.parentElement);
  }
};
