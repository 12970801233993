import ko from "@shared/knockout/extended";
import { startOfDay, startOfWeek, format } from "date-fns";
import { Mediator } from "@shared/mediator";
import { Api } from "@shared/services/Api";
import { AppApi } from "./services/AppApi";
import { Channels } from "@shared/Channels";
import { logout } from "@shared/services/auth/logout";
import { UserVM } from "./viewmodels/UserVM";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { confirmDialog } from "@shared/utils/confirmDialog";
import {
  publishEvent,
  startTrackEvent,
  stopTrackEvent
} from "@shared/utils/AppInsights";
import { ConnectionsStoreSingleton } from "./services/ConnectionStore";
import { PreferencesStoreSingleton } from "../shared/services/PreferencesStore";
import { ConnectionFilterVM } from "./components/popups/ConnectionFilter/ConnectionFilter";
import { PopupManagerVM } from "./components/PopupManager/PopupManagerVM";
import { PopupConfig } from "./components/PopupManager/PopupConfig";
import { ConnectedColleaguesStoreSingleton } from "./services/ConnectedColleaguesStore";
import { UrlNavigationSingleton } from "./utils/URLNavigation";
import { NotificationStoreSingleton } from "./services/NotificationStore";
import { ExceptionStoreSingleton } from "@shared/services/ExceptionsStore.js";
import { ProfilePageVM } from "./components/ProfilePage/ProfilePage";
import { HomeViewerVM } from "./components/HomeViewer/HomeViewer";
import { CheckInStatusStoreSingleton } from "./services/CheckInStatusStore";
import { ProfilePictureUploadVM } from "./components/popups/ProfilePictureUpload/ProfilePictureUpload";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { ConsentRedirectResult } from "@shared/utils/ConsentRedirectResult";
import { removeQueryParametersFromURL } from "@shared/utils/processPossibleConsentRedirectResult";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { getFeatures, FeatureNames } from "@shared/services/Features";
import { ParkingReservationVM } from "./components/Parking/ParkingReservation/ParkingReservation";
import { getAppFeatures } from "./services/AppFeatures";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { WorkSpaceWizardVM } from "./components/WorkSpaceWizard/WorkSpaceWizard";
import { MeetApi } from "@shared/services/MeetApi";
import { getArrayOfWorkingDays } from "./components/popups/WorkingHours/helpers";
import { WorkspaceServiceSingleton } from "./services/WorkspaceService";
import { AvailabilityServiceSingleton } from "@shared/services/AvailabilityService";
import { NowPageVM } from "./components/NowPage/NowPage";
import { WorkDayMainPageVM } from "./components/WorkDayMainPage/WorkDayMainPage";
import { WorkDayWizardVM } from "./components/WorkDayWizard/WorkDayWizard";
import { ConnectedColleaguesServiceSingleton } from "./services/ConnectedColleaguesService";
import { MeetingStoreSingleton } from "./services/MeetingStore";
import { splitRange } from "./utils/splitRange";
import { WorkdayReservationServiceSingleton } from "./services/WorkdayReservationService";
import {
  getEndOfCalendar,
  getWorkdayEndOfCalendar
} from "./utils/getEndOfCalendar";
import HereNowAnnouncementImage from "@shared/images/here-now.png";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers";
import { NotificationPreferencesServiceSingleton } from "@app/services/NotificationPreferencesService";
import { getDateKey } from "@shared/utils/dateHelpers";
import { ExtrasStoreSingleton } from "@shared/services/Extras/ExtrasStore";
import { ExtrasWizardVM } from "./components/Extras/components/ExtrasWizard/ExtrasWizard";

let currentUser = null;
const preferredLocationId = ko.observable(null);

export const UserApp = apiUser => {
  currentUser = apiUser;

  const redirectResultMessage = ko.observable(
    ConsentRedirectResult.Unavailable
  );

  const showErrorConsentModal = ko.pureComputed(
    () => redirectResultMessage() === ConsentRedirectResult.Failure
  );

  const loading = ko.observable(true);
  const checkInFeatureEnabled = isCheckInFeatureEnabled();

  const mediator = Mediator();
  const error = ko.observable(null);

  const api = Object.assign(Api, AppApi);

  const user = new UserVM(apiUser);

  const settings = ko.observable(null);
  const notificationPreferences = ko.observable({});
  const checkInService = CheckInServiceSingleton();
  const preferencesStore = PreferencesStoreSingleton();
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const urlNavigation = UrlNavigationSingleton();
  const notificationPreferencesService = NotificationPreferencesServiceSingleton();
  const parkingFeatureEnabled = getFeatures().has(FeatureNames.PARKING);
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);

  const getExtras = (() => {
    if (getFeatures().has(FeatureNames.EXTRAS))
      api.getExtras().then(ExtrasStoreSingleton().update);
  })();

  notificationPreferencesService.getPreferences().then(notificationPreferences);
  preferencesStore.update(apiUser.userPreferences);

  const locPref = user.defaultBuildingId;
  // Ensure up to date (in case changed on server from another device)
  if (locPref)
    localStorage.setItem(BuildingNodeTypes.LastChosenOffice, locPref);
  preferredLocationId(locPref);
  const locationStore = LocationStoreSingleton(preferredLocationId);

  const defaultOffice = ko.pureComputed(locationStore.getPreferredBuilding);
  const workspaceService = WorkspaceServiceSingleton();
  const availabilityService = AvailabilityServiceSingleton();
  const selectedColleagues = ko.observableArray([]);
  const selectionCount = selectedColleagues.map(cs => cs.length);

  // This fetches your connections that have booked shifts for the
  // period visible on the calendar
  const connectedColleaguesStore = ConnectedColleaguesStoreSingleton();
  const connectedColleaguesService = ConnectedColleaguesServiceSingleton();

  const endOfCalendar = hasWorkDayFeature
    ? getWorkdayEndOfCalendar()
    : getEndOfCalendar(user);

  const getColleagues = () =>
    connectedColleaguesService
      .getConnectedColleaguesOrCached(endOfCalendar())
      .then(() => {
        if (checkInFeatureEnabled) fetchCheckInStatus();
      });

  const getParkingReservations = () => {
    if (!parkingFeatureEnabled) return;
    api
      .getParkingReservations(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfCalendar()
      )
      .then(parkingReservationsStore.update);
  };

  // Profile Picture
  const imageHash = ko.observable(user.imageHash);

  // This fetches a list of all your connections, including pending ones (invites)
  const connectionStore = ConnectionsStoreSingleton();
  const getConnections = () =>
    api
      .getConnections()
      .then(connections => connectionStore.update(connections))
      .catch(e => {
        connectionStore.registerError(e);
      });

  getConnections();

  // This is a mechanism that allows us to easily update both connections and
  // social shifts from anywhere in the UI. It protects against multiple requests
  // to these endpoints running at once.
  const connectionUpdateRequested = ko
    .observable(0)
    .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

  connectionUpdateRequested.subscribe(requestCount => {
    if (requestCount > 0) {
      Promise.all([getConnections(), getColleagues()]).finally(() => {
        connectionUpdateRequested(0);
      });
    }
  });

  // Exceptions only need to be fetched once per session and we won't wait
  // for them to load (as their name suggests: they are "exception" and
  // you won't encounter them very often)
  const exceptionStore = ExceptionStoreSingleton();

  AppApi.getExceptions(new Date())
    .then(exceptionStore.update)
    .catch(e => {
      // We'll swallow this error for the same reasons we're not
      // waiting for the data
      console.error(e);
    });

  // Notifications
  const notificationStore = NotificationStoreSingleton();

  // Create single function to re-use.
  const fetchNotifications = () => {
    AppApi.getNotifications()
      .then(notificationStore.update)
      .catch(e => {
        // We'll swallow this error for the same reasons we're not
        // waiting for the data
        console.error(e);
      });
  };

  // Init the above function before the interval, so it fetches the notifications
  // right when the user opens the app.
  fetchNotifications();

  // setInterval to fetch notifications every 1 minute.
  setInterval(() => {
    fetchNotifications();
  }, 60000);

  // Requestin an update flips the flag to true
  mediator.subscribe(Channels.RequestConnectionsUpdate, () => {
    connectionUpdateRequested(connectionUpdateRequested() + 1);
  });

  // Check in status store
  const checkInStatusStore = CheckInStatusStoreSingleton();

  // Create single reusable function
  const fetchCheckInStatus = () => {
    AppApi.getConnectionsCheckInStatus().then(status =>
      checkInStatusStore.update(status)
    );
  };

  if (checkInFeatureEnabled) {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        getColleagues();
        getConnections();
        checkInService.getCheckInStatus();
      }
    });

    //check if callendar view is active to get new shifts and checkin status
    urlNavigation.activePanelId.subscribe(activeView => {
      if (activeView === "connections") {
        getConnections();
      }
      if (activeView === null) {
        getColleagues();
        checkInService.getCheckInStatus();
      }
    });
  }
  const mobileCalendarViewIsActive = ko.observable(false);
  const calendarView = {
    profile: user.accessProfile,
    now: startOfDay(new Date()),
    user,
    stores: {
      settings
    },
    selectedColleagues,
    selectionCount,
    endOfCalendar,
    mobileCalendarViewIsActive
  };
  const workspacesPromise = workspaceService.getWorkspaces().then(() => {
    getColleagues();
    getParkingReservations();
  });

  const buildingPromise = api.getBuildingsForUser().then(locationStore.update);

  availabilityService.getAvailability(user.accessProfile.daysAhead);

  const settingsPromise = Api.getSettings().then(settings);

  const workingHours = ko.observable(
    getAppFeatures().get(FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW)
      ?.workingHours
  );

  const loadMeetingsToStore = async (from, to, chunkSize) => {
    const meetingStore = MeetingStoreSingleton();
    const chunks = splitRange(from, to, chunkSize);
    const errors = [];

    for (const [chunkStart, chunkEnd] of chunks) {
      try {
        const meetings = await AppApi.getMeetings(chunkStart, chunkEnd);
        meetingStore.addRange(meetings, chunkStart, chunkEnd);
      } catch (e) {
        errors.push([e, chunkStart, chunkEnd]);
        meetingStore.addErrorRange([chunkStart, chunkEnd, e]);
      }
    }

    // For now, even with 1 error, put the store in error mode
    if (errors.length) {
      let errorMessage = "Parts of your calendar failed to load.";

      if (errors.length === chunks.length) {
        meetingStore.completelyBroken(true);
        errorMessage = "We were unable to load your calendar";
      }

      Mediator().publish(Channels.OpenStatusMessage, {
        type: "failure",
        reason: errorMessage
      });
    }
  };

  const hybridMeetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const occupancyFeature = FeatureNames.OCCUPANCY_SENSOR;

  const {
    enabledForUsers,
    userHasOptedIn,
    tokenAvailable,
    maxDaysPerRequest,
    hideHereNowAnnouncement
  } =
    getAppFeatures().has(hybridMeetingsFeature) &&
    getAppFeatures().get(hybridMeetingsFeature).enabledForUsers
      ? getAppFeatures().get(hybridMeetingsFeature)
      : {
          enabledForUsers: false,
          userHasOptedIn: false,
          hideHereNowAnnouncement: getAppFeatures().has(occupancyFeature)
            ? getAppFeatures().get(occupancyFeature).hideHereNowAnnouncement
            : true
        };

  const showMeetings = enabledForUsers && userHasOptedIn && tokenAvailable;

  const showNewFeatureAnnouncement = ko.observable(
    !hideHereNowAnnouncement &&
      getAppFeatures().has(FeatureNames.HERE_NOW) &&
      (enabledForUsers ||
        (getAppFeatures().has(FeatureNames.HERE_NOW_V2) &&
          getAppFeatures().has(FeatureNames.OCCUPANCY_SENSOR)))
  );
  const newFeatureAnnouncementParams = {
    showNewFeatureAnnouncement,
    text:
      "Looking for a place to have a spontaneous catch-up or call? You can now review all rooms that are not booked at this moment to easily find a space that fits your needs.",
    title: "Easily find an available room",
    navigateAction: () => urlNavigation.navigate("now", null),
    //if the update to the BE fails we still go on with navigation;
    //the user will see the announcement again at the next load
    updateAction: () =>
      MeetApi.setUserPreference({
        userClosedHereNowAnnouncement: true
      }),
    image: HereNowAnnouncementImage,
    buttonText: "Let's go"
  };

  if (showMeetings) {
    loadMeetingsToStore(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfCalendar(),
      maxDaysPerRequest
    );
  }

  // VIEW MANAGEMENT
  const userIsNew = ko.observable(apiUser.isNew !== false);
  mediator.subscribe(Channels.CloseWelcomePage, () => {
    userIsNew(false);
  });
  const officeCapacityAvailable = ko
    .pureComputed(locationStore.totalCapacity)
    .map(Boolean);
  const isSingleOfficeEnvironment = ko
    .pureComputed(locationStore.getBuildings)
    .map(offices => offices.length === 1);
  const userHasPreferredOffice = ko
    .pureComputed(preferredLocationId)
    .map(id => id && !!locationStore.get(id));

  const activeView = ko.pureComputed(() =>
    getActiveView({
      loading: loading(),
      userIsNew: userIsNew(),
      officeCapacityAvailable: officeCapacityAvailable(),
      isSingleOfficeEnvironment: isSingleOfficeEnvironment(),
      userHasPreferredOffice: userHasPreferredOffice(),
      showNewFeatureAnnouncement: showNewFeatureAnnouncement(),
      apiUser
    })
  );
  // Core views:
  //  One of these is true
  // TODO Rename showCalendarView after work day release
  const showLoadingView = activeView.map(eq(Views.Loading));
  const showWelcomePage = activeView.map(eq(Views.Welcome));
  const showBuildingPicker = activeView.map(eq(Views.BuildingPicker));
  const showCalendarView = activeView.map(eq(Views.Calendar));
  const showNoBuildingsView = activeView.map(eq(Views.NoBuildings));
  const showNewFeatureAnnouncementView = activeView.map(
    eq(Views.NewFeatureAnnouncement)
  );

  const mainPageParams = {
    showWelcomePage,
    showCalendarView,
    showBuildingPicker,
    showNoBuildingsView,
    showNewFeatureAnnouncementView,
    calendarView,
    user,
    settings,
    preferredLocationId,
    workingHours,
    isNew: apiUser.isNew,
    // New one for which is part of day overview
    user,
    mobileCalendarViewIsActive,
    newFeatureAnnouncementParams
  };

  const profilePageParams = {
    user,
    workingHours,
    mediator,
    api,
    preferredLocationId,
    settings,
    imageHash,
    notificationPreferences
  };

  const filterPanelParams = {
    connectedColleaguesStore,
    selectedConnections: selectedColleagues
  };

  const connectionPanelParams = {
    connectionStore,
    connectedColleaguesStore
  };

  const notificationsPanelParams = {
    api
  };
  const overviewPanelParams = {
    locationStore,
    settings,
    defaultOffice,
    endOfCalendar,
    user
  };
  const activeDateFromUrl = ko.pureComputed(() =>
    getFormatedActiveDateFromUrl()
  );
  const roomPickerParams = ko.observable(null);
  Mediator().subscribe(Channels.OpenRoomPicker, params => {
    roomPickerParams(params);
    urlNavigation.navigate(
      null,
      "add-room",
      hasWorkDayFeature ? [activeDateFromUrl()] : []
    );
  });
  Mediator().subscribe(Channels.CloseRoomPicker, backFn => {
    roomPickerParams(null);
    backFn();
  });
  const bookRoomParams = ko.observable(null);
  Mediator().subscribe(Channels.OpenBookRoom, params => {
    bookRoomParams(params);
    UrlNavigationSingleton().navigate(null, "book");
  });
  Mediator().subscribe(Channels.CloseBookRoom, () => {
    bookRoomParams(null);
    history.back();
  });

  const getPages = () => {
    const meetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;

    const pages = [
      getHomePage(mainPageParams),
      {
        path: "profile",
        heading: "Your profile",
        content: {
          VMConstructor: ProfilePageVM,
          params: profilePageParams,
          bodyComponentName: "profile-body"
        }
      }
    ];

    if (
      (getAppFeatures().has(meetingsFeature) &&
        getAppFeatures().has(FeatureNames.HERE_NOW) &&
        getAppFeatures().get(meetingsFeature).enabledForUsers === true) ||
      (getAppFeatures().has(FeatureNames.OCCUPANCY_SENSOR) &&
        getAppFeatures().has(FeatureNames.HERE_NOW) &&
        getAppFeatures().has(FeatureNames.HERE_NOW_V2))
    )
      pages.push({
        path: "now",
        heading: null,
        content: {
          VMConstructor: NowPageVM,
          params: {},
          bodyComponentName: "now-page"
        }
      });

    return pages;
  };

  const getPanels = () => [
    {
      path: "connections",
      heading: "Your connections",
      content: {
        bodyComponentName: "connections-page-sidepanel",
        params: connectionPanelParams
      }
    },
    {
      path: "filter",
      heading: "Filter connections",
      content: {
        VMConstructor: ConnectionFilterVM,
        params: filterPanelParams,
        bodyComponentName: "cf-body",
        footerComponentName: "cf-footer"
      }
    },
    {
      path: "notifications",
      heading: "Notifications",
      content: {
        params: notificationsPanelParams,
        bodyComponentName: "notifications-view"
      }
    },
    {
      path: "overview",
      heading: null,
      content: {
        params: overviewPanelParams,
        bodyComponentName: "day-overview"
      }
    },
    {
      path: "add-room",
      heading: null,
      content: {
        params: { roomPickerParams },
        bodyComponentName: "room-picker"
      }
    },
    {
      path: "book",
      heading: null,
      content: {
        params: { bookRoomParams },
        bodyComponentName: "book-room"
      }
    }
  ];

  // Main loading spinner needs buildings, workdays,workspaces and settings
  // Availability can come in later
  Promise.all([
    buildingPromise,
    WorkdayReservationServiceSingleton().getWorkdays(),
    workspacesPromise,
    settingsPromise
  ])
    .then(() => {
      // This will render the app to the screen
      loading(false);
      urlNavigation.init(getPages(), getPanels());
    })
    .catch(e => {
      console.error(e);

      return confirmDialog({
        question:
          "We could not reach the server. You can retry, or get in touch with support@mapiq.com",
        confirm: "Reload!",
        cancel: "Log out"
      })
        .then(() => {
          document.location.reload();
        })
        .catch(() => {
          logout();
        });
    });

  // Overlays:
  //  All these views are overlays on top of one of the core views
  const ConnectionDeleteWindow = ko.observable(null);
  const ConnectionAddWindow = ko.observable(null);
  const statusMessage = ko.observable(null);
  const ConnectionErrorWindow = ko.observable(null);
  const ConfirmationWindow = ko.observable(null);

  mediator.subscribe(Channels.OpenStatusMessage, result => {
    statusMessage({ result });
  });

  mediator.subscribe(Channels.CloseStatusMessage, () => {
    statusMessage(null);
  });

  mediator.subscribe(Channels.OpenConnectionDeleteWindow, connection => {
    ConnectionDeleteWindow({
      connection
    });
  });

  mediator.subscribe(Channels.CloseConnectionDeleteWindow, () => {
    ConnectionDeleteWindow(null);
  });

  mediator.subscribe(Channels.OpenConnectionAddWindow, ({ name, userId }) => {
    ConnectionAddWindow({
      name,
      userId
    });
  });

  mediator.subscribe(Channels.CloseConnectionAddWindow, () => {
    ConnectionAddWindow(null);
  });

  mediator.subscribe(Channels.OpenConnectionErrorWindow, errorType => {
    ConnectionErrorWindow({
      errorType
    });
  });

  mediator.subscribe(Channels.CloseConnectionErrorWindow, () => {
    ConnectionErrorWindow(null);
  });

  mediator.subscribe(Channels.ToggleConfirmationWindow, params => {
    ConfirmationWindow(params);
  });

  const getAvailability = () =>
    availabilityService.getAvailability(user.accessProfile.daysAhead);
  mediator.subscribe(Channels.UpdateAvailability, getAvailability);

  mediator.subscribe(
    Channels.TrackAIEvent,
    ([eventName, result, bookingType]) =>
      publishEvent("UserApp", eventName, result, bookingType)
  );
  mediator.subscribe(Channels.StartTrackAITimingEvent, ([eventName]) =>
    startTrackEvent(eventName)
  );

  mediator.subscribe(
    Channels.StopTrackAITimingEvent,
    ([eventName, result, extraProps = {}]) =>
      stopTrackEvent("UserApp", eventName, result, extraProps)
  );

  // Popups – new style

  const popupManager = PopupManagerVM();

  // CONNECTION FILTER
  mediator.subscribe(
    Channels.OpenConnectionFilter,
    function openConnectionFilter() {
      popupManager.showPopup(
        PopupConfig({
          title: "Filter connections",
          ContentVM: ConnectionFilterVM,
          contentParams: {
            connectedColleaguesStore: connectedColleaguesStore,
            selectedConnections: selectedColleagues
          },
          bodyComponentName: "cf-body",
          footerComponentName: "cf-footer"
        })
      );
    }
  );

  const showPopup = params => {
    popupManager.showPopup(PopupConfig(params));
  };
  // REGISTRATION WIZARD
  mediator.subscribe(
    Channels.OpenWorkspaceWizard,
    function openWorkspaceWizard({
      date,
      shift,
      onBookingMade,
      selectedWorkdayOfficeId
    }) {
      popupManager.showPopup(
        PopupConfig({
          headerType: PopupConfig.headerType.WhiteCenterAligned,
          ContentVM: WorkSpaceWizardVM,
          fixedHeight: true,
          wider: true,
          contentParams: {
            date,
            selectedWorkdayOfficeId,
            workspace: shift,
            api,
            onBookingMade,
            preferredLocationId,
            user
          },

          bodyComponentName: "workspace-wizard-body",
          footerComponentName: "workspace-wizard-footer"
        })
      );
    }
  );

  mediator.subscribe(Channels.OpenWorkdayWizard, ({ day }) => {
    const WorkDayWizardParams = {
      headerType: PopupConfig.headerType.WhiteCenterAligned,
      ContentVM: WorkDayWizardVM,
      fixedHeight: true,
      wider: true,
      contentParams: { day },
      bodyComponentName: "workday-reservation-wizard-body"
    };

    showPopup(WorkDayWizardParams);
  });
  // Parking Reservation Wizard
  mediator.subscribe(Channels.OpenParkingReservationWindow, ({ date }) => {
    popupManager.showPopup(
      PopupConfig({
        headerType: PopupConfig.headerType.WhiteCenterAligned,
        ContentVM: ParkingReservationVM,
        contentParams: {
          date,
          settings,
          user
        },
        bodyComponentName: "parking-reservation-body",
        footerComponentName: "parking-reservation-footer"
      })
    );
  });
  // Profile Wizard
  mediator.subscribe(Channels.OpenProfilePictureUploadWindow, user => {
    popupManager.showPopup(
      PopupConfig({
        headerType: PopupConfig.headerType.WhiteCenterAlignedWithBigFont,
        ContentVM: ProfilePictureUploadVM,
        contentParams: {
          user,
          api,
          imageHash
        },
        bodyComponentName: "profilePicture-upload-body"
      })
    );
  });

  mediator.subscribe(Channels.OpenExtrasWizard, params => {
    popupManager.showPopup(
      PopupConfig({
        headerType: PopupConfig.headerType.WhiteCenterAligned,
        ContentVM: ExtrasWizardVM,
        contentParams: params,
        bodyComponentName: "extras-wizard-body"
      })
    );
  });

  [
    // Close commands from non-popup components:
    Channels.CloseConnectionFilter,
    Channels.CloseWorkspaceWizard,
    Channels.CloseWorkdayWizard,
    Channels.CloseParkingReservationWindow
  ].forEach(c => {
    mediator.subscribe(c, popupManager.closePopup);
  });

  mediator.subscribe(Channels.CloseErrorConsentModal, message => {
    redirectResultMessage(ConsentRedirectResult.Unavailable);
  });

  mediator.subscribe(Channels.ConsentRedirectResult, result => {
    switch (result) {
      case ConsentRedirectResult.Success:
        console.log("User has given consent to connect to calendar.");
        break;
      case ConsentRedirectResult.Failure:
        console.log("User did not complete the consent flow");
        redirectResultMessage(result);
        break;
      case ConsentRedirectResult.Unknown:
        console.log(
          "User went through consent flow but it's unknown whether they succeeded"
        );
    }
  });

  const buildingsSub = locationStore.hydrated.subscribe(() => {
    if (!isSingleOfficeEnvironment() || !apiUser.isNew) return;
    const wh = {
      workingHours: {
        ianaLocalTimezone: locationStore.getBuildings()[0].ianaTimezone,
        days: getArrayOfWorkingDays()
      }
    };
    //Set working hours for single office environments on first login
    MeetApi.setUserPreference(wh)
      //In case the user has already enabled the calendar by skipping the onboarding flow,
      //we want them to see the working hours before refreshing the page
      .then(updatedPreferences =>
        workingHours(updatedPreferences.workingHours)
      );
  });

  return {
    showErrorConsentModal,
    hasWorkDayFeature,
    mobileCalendarViewIsActive,
    dateTitle: ko.pureComputed(() => {
      const formatType = mobileCalendarViewIsActive() ? "MMMM" : "EEEE d MMMM";

      //workday off -> display today's date
      const date = hasWorkDayFeature
        ? new Date(activeDateFromUrl())
        : new Date();

      return format(date, formatType);
    }),
    togglerIconName: ko.pureComputed(() =>
      mobileCalendarViewIsActive() ? "dayViewToggle" : "calendarViewToggle"
    ),
    navigateToToday: () => {
      const formattedDate = getDateKey(new Date());
      const activePageId = urlNavigation.activePageId();

      if (activePageId === formattedDate) {
        if (mobileCalendarViewIsActive()) mobileCalendarViewIsActive(false);
        return;
      }

      urlNavigation.navigate("", null, [formattedDate]);
      mobileCalendarViewIsActive(false);
    },

    showWorkdayIcons: ko.pureComputed(
      () => !loading() && hasWorkDayFeature && showCalendarView()
    ),
    toggleViewsOnMobile: () => {
      if (!hasWorkDayFeature) return;
      mobileCalendarViewIsActive(!mobileCalendarViewIsActive());
    },
    showRedirectPopup: ko.observable(removeQueryParametersFromURL()),
    popupManager,
    error,
    // Core views
    showLoadingView,
    showWelcomePage,
    showBuildingPicker,
    showNoBuildingsView,
    showCalendarView,
    showNewFeatureAnnouncementView,
    // Overlays
    statusMessage,
    ConnectionDeleteWindow,
    ConnectionAddWindow,
    ConnectionErrorWindow,
    ConfirmationWindow,
    // Other
    calendarView,
    user,
    defaultOffice,
    mediator,
    preferredLocationId,
    api,
    logOut: () => {
      logout();
    },
    loading,
    loadingColleagues: connectedColleaguesService.loading,
    locationStore,
    settings,
    connectionStore,

    hasConnectionFilter: connectedColleaguesStore.all.map(cs => cs.length),
    selectionCount,

    showConnectionFilter: () => {
      urlNavigation.navigate("", "filter");
    },

    imageHash,
    dispose: () => {
      buildingsSub.dispose();
      mediator.unsubscribe(Channels.UpdateAvailability, getAvailability);
    }
  };
};

UserApp.resolveUser = () => Object.freeze(currentUser);
UserApp.preferredLocationId = ko.pureComputed(preferredLocationId);

const Views = {
  Welcome: "Welcome",
  NoBuildings: "NoBuildings",
  BuildingPicker: "BuildingPicker",
  Calendar: "Calendar",
  Loading: "Loading",
  NewFeatureAnnouncement: "NewFeatureAnnouncement"
};

const getActiveView = ({
  loading,
  userIsNew,
  officeCapacityAvailable,
  isSingleOfficeEnvironment,
  userHasPreferredOffice,
  showNewFeatureAnnouncement,
  apiUser
}) => {
  if (loading) return Views.Loading;

  if (userIsNew) return Views.Welcome;

  if (!officeCapacityAvailable) return Views.NoBuildings;

  if (!isSingleOfficeEnvironment && !userHasPreferredOffice)
    return Views.BuildingPicker;

  if (!apiUser.isNew && showNewFeatureAnnouncement) {
    return Views.NewFeatureAnnouncement;
  }

  return Views.Calendar;
};

const eq = a => b => a === b;

const getHomePage = mainPageParams => {
  if (getFeatures().has(FeatureNames.WORK_DAY)) {
    return {
      path: "",
      content: {
        VMConstructor: WorkDayMainPageVM,
        params: mainPageParams,
        bodyComponentName: "workDayMainPage-body"
      }
    };
  } else
    return {
      path: "",
      content: {
        VMConstructor: HomeViewerVM,
        params: mainPageParams,
        bodyComponentName: "homeViewer-body"
      }
    };
};
