import template from "./ParkingLotPicker.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import { byPropAsc } from "@shared/utils/sort";
import { naturalStringSortAsc } from "@shared/utils/sortHelper";
import { injectLineBreaksAtSlash } from "@shared/utils/text-helpers";
import { notEmpty } from "@shared/utils/capacityHelper";
import { ParkingLotVM } from "@app/viewmodels/Parking/ParkingLotVM";
import { ParkingZoneVM } from "@app/viewmodels/Parking/ParkingZoneVM";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import "./ParkingLotPicker.scss";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames } from "@app/tracking/EventNames";

export const ParkingLotPickerVM = ({
  date,
  parkingLotStore,
  selectedParkingLocationId
}) => {
  const selectedParkingLot = ko.observable(null);
  const selectedParkingZone = ko.observable(null);
  const visibleLots = ko.observable(!selectedParkingLot());
  const visibleZones = ko.observable(!selectedParkingZone());

  const toggleLots = () => {
    visibleLots(!visibleLots());
    if (visibleLots())
      publishAIEvent(EventNames.ParkingLocationPickerOpenParkinglot);
  };
  const toggleZones = () => {
    visibleZones(!visibleZones());
    if (visibleZones())
      publishAIEvent(EventNames.ParkingLocationPickerOpenZone);
  };

  const scrollToTop = () => {
    const scrollElement = document.getElementsByClassName("Card-scrollbody");
    scrollElement[0].scrollTo(0, 0);
  };

  const selectedParkingLotListener = selectedParkingLot.subscribe(scrollToTop);
  const selectedParkingZoneListener = selectedParkingZone.subscribe(
    scrollToTop
  );

  const parkingLots = ko
    .pureComputed(() => parkingLotStore.getParkings())
    .filter(notEmpty)
    .sort(byPropAsc("name", naturalStringSortAsc))
    .mapArray(p => ParkingLotVM(p, date, selectedParkingLot));

  const zones = selectedParkingLot.maybeMap(
    ({ parkingZones, closed }) =>
      closed()
        ? []
        : parkingZones
            .filter(notEmpty)
            .map(z => ParkingZoneVM(z, date, selectedParkingZone)),
    []
  );

  const parkingLotsListener = ko.computed(() => {
    if (parkingLots().length === 1) {
      selectedParkingLot(parkingLots()[0]);
    }
  });
  const zonesListener = ko.computed(() => {
    if (zones().length === 1) {
      selectedParkingZone(zones()[0]);
    }
  });
  const initialSelectionId = ParkingReservationStoreSingleton().getForDate(
    date
  )[0]?.nodeId;

  if (initialSelectionId) {
    const parkingLotDto = parkingLotStore.getParkingLotForId(
      initialSelectionId
    );
    const parkingZoneDto = parkingLotStore.getZoneForId(initialSelectionId);

    if (parkingLotDto) {
      const parkingLotVm = parkingLots().find(p => p.id === parkingLotDto.id);
      visibleLots(true);
      selectedParkingLot(parkingLotVm);
      if (parkingZoneDto) visibleLots(false);
    }
    if (parkingZoneDto) selectedParkingZone(parkingZoneDto);
  } else scrollToTop();

  const lotSub = selectedParkingLot.subscribe(newParkingLot => {
    if (
      !newParkingLot.parkingZones.find(
        zone => zone.id === selectedParkingZone()?.id
      )
    )
      selectedParkingZone(null);
    if (newParkingLot) {
      /*close the accordion if there are parking zones otherwise no */
      visibleLots(!newParkingLot.parkingZones.length);
      visibleZones(true);
    }
  });

  const parkingSelection = ko.pureComputed(
    () => selectedParkingZone() || selectedParkingLot()
  );
  const autoCloseSub = ko.computed(() => {
    const parkingLocation = parkingSelection();
    if (!parkingLocation) return;

    const hasZones =
      parkingLocation.parkingZones && parkingLocation.parkingZones.length > 0;

    if (!hasZones) selectedParkingLocationId(parkingLocation.id);
  });
  return {
    parkingLots,
    visibleLots,
    visibleZones,
    toggleLots,
    toggleZones,
    selectedParkingLot,
    selectedParkingZone,
    zones,
    injectLineBreaksAtSlash,
    dispose: () => {
      lotSub.dispose();
      selectedParkingLotListener.dispose();
      selectedParkingZoneListener.dispose();
      autoCloseSub.dispose();
      zonesListener.dispose();
      parkingLotsListener.dispose();
    }
  };
};
export const ParkingLotPicker = KOComponent(
  "parking-lot-picker",
  ParkingLotPickerVM,
  template
);
