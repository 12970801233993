import ko from "@shared/knockout/extended";

export const PopupManagerVM = () => {
  const popup = ko.observable(null);

  // Fixed body height after initial render to prevent visual
  // jumps
  const height = ko.observable(null);
  const fixedHeight = ko.pureComputed(() => popup()?.fixedHeight);
  const saveHeight = el => {
    if (fixedHeight()) return;
    // Save the height of the parentElement (the one that has the scroll bar if our
    // content overflows)
    height(el.parentElement.offsetHeight);
  };

  /** @param {PopupConfig} popupConfig */
  const showPopup = popupConfig => {
    height(null);
    popup(popupConfig);
  };
  const closePopup = () => {
    popup(null);
  };

  const popupVisible = popup.map(Boolean);

  const onKeyup = e => {
    if (e.key === "Escape") closePopup();
  };

  window.addEventListener("keyup", onKeyup);

  // Construct the inner viewmodel _once_ after loading a config
  const contentVM = popup.map(({ ContentVM, contentParams }) =>
    ContentVM({ ...contentParams, closePopup })
  );

  // contentVMs can control the show/hide logic of the footer,
  // if needed
  const showFooter = overridable(
    "showFooter",
    PopupManagerVM.Keys.showFooter,
    popup,
    contentVM
  );

  const title = overridable(
    "title",
    PopupManagerVM.Keys.getTitle,
    popup,
    contentVM
  );

  const subHeading = overridable(
    "subHeading",
    PopupManagerVM.Keys.getSubHeading,
    popup,
    contentVM
  );

  const hasBackButton = overridable(
    "hasBackButton",
    PopupManagerVM.Keys.hasBackButton,
    popup,
    contentVM
  );

  const hasCloseButton = overridable(
    "hasCloseButton",
    PopupManagerVM.Keys.hasCloseButton,
    popup,
    contentVM
  );

  const onBack = overridable(
    "onBack",
    PopupManagerVM.Keys.onBack,
    popup,
    contentVM
  );

  const onConfirm = overridable(
    "onConfirm",
    PopupManagerVM.Keys.onConfirm,
    popup,
    contentVM
  );

  const hasConfirmButton = overridable(
    "hasConfirmButton",
    PopupManagerVM.Keys.hasConfirmButton,
    popup,
    contentVM
  );

  const disableConfirmButton = overridable(
    "disableConfirmButton",
    PopupManagerVM.Keys.disableConfirmButton,
    popup,
    contentVM
  );

  const disableClosing = overridable(
    "disableClosing",
    PopupManagerVM.Keys.disableClosing,
    popup,
    contentVM
  );
  return {
    popup,
    popupVisible,
    showPopup,
    closePopup,

    // Heading
    title,
    subHeading,
    disableClosing,

    hasCloseButton,

    hasBackButton,
    onBack: (data, event) => {
      const fn = onBack();

      if (typeof fn === "function") {
        fn(data, event);
      }
    },

    hasConfirmButton,
    disableConfirmButton,
    onConfirm: (data, event) => {
      const fn = onConfirm();

      if (typeof fn === "function") {
        fn(data, event);
      }
    },

    hasSubHeading: subHeading.map(Boolean),
    subHeadingIcon: popup.map(p => p.subHeadingIcon),
    headerClass: popup.map(p => p.headerClass),
    headerIconColor: popup.map(p => p.headerIconColor),

    // Consistent height of scroll container after initial render
    height,
    saveHeight,
    fixedHeight,
    wider: ko.pureComputed(() => popup()?.wider),

    // Construct vm and inject close etc.
    contentVM,

    // Option to dynamically hide/show the footer
    showFooter,

    // Close on clicks directly in wrapper
    onWrapperClick: (data, event) => {
      const wrapperClicked = event.target === event.currentTarget;

      if (wrapperClicked && !disableClosing()) closePopup();

      return true;
    },

    dispose: () => {
      window.removeEventListener("keyup", onKeyup);
    }
  };
};

PopupManagerVM.Keys = {
  showFooter: Symbol(),
  getTitle: Symbol(),
  getSubHeading: Symbol(),
  hasBackButton: Symbol(),
  onBack: Symbol(),
  hasCloseButton: Symbol(),
  disableCloseButton: Symbol(),
  hasConfirmButton: Symbol(),
  disableConfirmButton: Symbol(),
  onConfirm: Symbol()
};

// Accepts two configs and two keys.
// If there's a way to get a value from the last config (vm), it uses that value
// If not, it resorts to the first config
const overridable = (configKey, overrideKey, obsConfig, obsVM) => {
  return ko
    .pureComputed(() => {
      const cfg = obsConfig();

      const getOverride = cfg.ContentVM[overrideKey];
      if (getOverride) {
        return ko.unwrap(getOverride(obsVM()));
      }

      return cfg[configKey];
    })
    .extend({ deferred: true });
};
