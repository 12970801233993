import ko from "@shared/knockout/extended";
import WorkAreaApiModel from "@shared/apimodels/WorkAreaApiModel";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore.js";
import {
  getWorkspaceIcon,
  WorkspaceAvailability
} from "@app/utils/workspaceHelpers";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { compareStatus } from "@shared/services/CheckInStatus";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { WorkspaceLocationVM } from "@shared/viewmodels/WorkspaceLocationVM";

export const NodeAvailabilityVM = (
  date,
  node,
  selectedInvitees,
  usePendingOrConfirmedMemberCount
) => {
  const capacity = node ? node.capacity : Infinity;

  const { disabled, disabledReason } = WorkspaceLocationVM(
    date,
    node.id,
    selectedInvitees,
    usePendingOrConfirmedMemberCount
  );
  return {
    capacity,
    isDisabled: disabled,
    availability: disabledReason
  };
};

export const NodeVM = ({
  date,
  openSummary,
  selectedLocationId,
  deepestWorkspaceNodeId,
  deskSection,
  userSelectedDifferentNode,
  selectedDeskIds,
  goHome,
  node,
  selectedInvitees,
  increasedNumberOfInvitees,
  usePendingOrConfirmedMemberCount,
  userChooseLastSelectedWorkdayId,
  //admin app
  lastUsedLocationId,
  isLocationRestricted = () => false
}) => {
  const nodeId = node.id;
  const locationStore = LocationStoreSingleton();
  const connectedColleaguesStore = ConnectedColleaguesStoreSingleton();

  const { isDisabled, availability, capacity } = NodeAvailabilityVM(
    date,
    node,
    selectedInvitees,
    usePendingOrConfirmedMemberCount
  );

  const hasBookableDesks = node?.hasBookableDesks ?? null;

  const openDeskInfo = () => {
    if (isDisabled()) return;
    openSummary(nodeId);
  };

  const isGroupBooking = ko.pureComputed(
    () => typeof selectedInvitees === "function" && selectedInvitees()?.length
  );

  const groupSize = isGroupBooking() ? selectedInvitees().length + 1 : 1;

  const selectedId = ko.pureComputed(() =>
    increasedNumberOfInvitees()
      ? selectedLocationId()
      : userChooseLastSelectedWorkdayId() && !selectedLocationId()
      ? lastUsedLocationId
      : selectedLocationId() || deepestWorkspaceNodeId || lastUsedLocationId
  );

  return {
    colleagues: ko.pureComputed(() =>
      connectedColleaguesStore
        .allWithReservationOnDateInNode(date, nodeId)
        .sort((a, b) => {
          if (!isCheckInFeatureEnabled()) return;
          return compareStatus(a.checkInStatus(), b.checkInStatus());
        })
    ),
    nodeId,
    lastUsedLocationId,
    hasBookableDesks,
    isSelected: ko.pureComputed(() => nodeId === selectedId()),

    capacity,
    isDisabled,

    location: node.name,
    iconName: ko.pureComputed(() =>
      getWorkspaceIcon(locationStore.get(nodeId))
    ),
    deskInfo: ko.pureComputed(() => {
      const anyDeskString = isGroupBooking()
        ? `Any ${groupSize} available desks`
        : WorkspaceAvailability.ANY_DESK;

      if (node.type !== WorkAreaApiModel)
        return availability() || anyDeskString;

      const floorName = locationStore.getFloorForId(nodeId)?.name;

      return `${floorName}, ${(
        availability() ||
        (node.hasBookableDesks
          ? isGroupBooking()
            ? `a group of ${groupSize} desks will be assigned`
            : "a desk will be assigned"
          : anyDeskString)
      ).toLocaleLowerCase()}`;
    }),

    openDeskInfo,
    onSelect: () => {
      if (isDisabled()) return;
      selectedLocationId(nodeId);
      userSelectedDifferentNode(true);
      selectedDeskIds([]);
      deskSection(null);
      goHome();
    },

    showRestrictedLocationIcon: isLocationRestricted(nodeId)
  };
};
