import template from "./AvatarsGroup.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import "./AvatarsGroup.scss";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";

const AvatarsGroupVM = ({
  avatars,
  sliceAtNumber,
  checkAreNamesOutsideBoundries = false,
  parentClass = null,
  spreadAvatars = false,
  publishEvent = () => {}
}) => {
  const showNames = ko.observable(false);
  const nameTooltipPosition = ko.observable("below");

  const toggleNamesOn = () => {
    publishEvent();
    showNames(true);
  };

  const toggleNamesOff = () => showNames(false);

  const determineTooltipClass = (_data, event) => {
    if (!checkAreNamesOutsideBoundries) return;
    const hoveredElement = event.target;
    const position = hoveredElement.getBoundingClientRect();
    const parentPosition = parentClass
      ? document.querySelector(parentClass).getBoundingClientRect().bottom
      : window.innerHeight;
    const distanceToBottom = parentPosition - position.bottom;

    // Determine if we show the tooltip on top or below
    const breakpoint = avatars().length * 20;
    if (distanceToBottom < breakpoint) nameTooltipPosition("above");
    else nameTooltipPosition("below");
  };

  const avatarsToShow = avatars
    .map(a =>
      a.length < sliceAtNumber + 1
        ? a
        : a.slice(0, sliceAtNumber).concat([
            {
              initials: `+${a.length - sliceAtNumber}`,
              checkInStatus: ko.observable(null),
              imageHash: null,
              email: null
            }
          ])
    )
    .extend({ deferred: true });

  return {
    determineTooltipClass,
    avatarsToShow,
    avatarsInToolTip: avatars,
    avatarsExist: ko.pureComputed(() => avatars().length),
    spreadAvatars,
    showNames,
    nameTooltipPosition,
    checkInFeatureEnabled: isCheckInFeatureEnabled(),
    toggleNamesOff,
    toggleNamesOn
  };
};

export const AvatarsGroup = KOComponent(
  "avatars-group",
  AvatarsGroupVM,
  template
);
