import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import template from "./ExportBookingOverview.template.html";
import "./ExportBookingOverview.scss";
import { AppApi } from "@app/services/AppApi";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { saveAs } from "file-saver";

const ExportHistoricOverviewVM = ({ settings }) => {
  const loading = ko.observable(false);
  let fileName = "";

  return {
    //it will always be plural since the minimum is 7 days
    infoText: settings.maybeMap(
      ({ retentionPeriod }) =>
        `Data will be available for ${retentionPeriod} days`,
      "Data will be available during the retention period as set by your administrator"
    ),
    loading,

    onClick: () => {
      if (loading()) return;
      loading(true);
      AppApi.exportBookingHistory()
        .then(response => {
          //We know how the header looks exactly and how we can split it
          //Also we expect/accept only text/csv content-type (client: 'accept' request header -> server: 'content-type' response header) and we can resolve the promise with a Blob
          //Split by quotas because otherwise the quotas will remain in the file name (string) and they will be replaced by '_'

          fileName = response.headers.get("content-disposition").split('"')[1];
          return response.blob();
        })
        .then(blob => saveAs(blob, fileName))
        .catch(() =>
          Mediator().publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason:
              "Something went wrong. We were unable to download your booking history."
          })
        )
        .finally(() => loading(false));
    }
  };
};

export const ExportHistoricOverview = KOComponent(
  "export-historic-overview",
  ExportHistoricOverviewVM,
  template
);
