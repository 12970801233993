import { Msal } from "./settings/MsalInstance";

export const logout = () => {
  // Clear everything, for 2 reasons:
  //  1. We've had big issues with old msal config stuff staying in localstorage, preventing
  //     new logins
  //  2. We're storing some preferred location stuff that would be fair to not leave behind

  const account = Msal().getActiveAccount();

  localStorage.clear();

  if (account) {
    const logoutRequest = {
      account: account
    };

    Msal().logoutRedirect(logoutRequest);
  } else {
    Msal().logoutRedirect();
  }
};
