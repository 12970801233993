import { Mediator } from "@shared/mediator";
import { capitalizeSentence } from "@shared/utils/text-helpers";
import { Channels } from "@shared/Channels";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel";
import { isBefore, startOfDay } from "date-fns";
import { ErrorType } from "@shared/utils/ErrorType";

export const ActionTypes = {
  Booked: { successfulAction: "booked", failedAction: "book" },
  Updated: { successfulAction: "updated", failedAction: "update" },
  Deleted: { successfulAction: "deleted", failedAction: "delete" }
};
export const ActionObjs = {
  Workspace: "workspace",
  Parking: "parking booking",
  Bookings: "bookings"
};

export const showSuccessMessage = (actionType, actionObj, message = null) => {
  Mediator().publish(Channels.OpenStatusMessage, {
    title: `${capitalizeSentence(actionType.successfulAction)}!`,
    type: "success",
    reason:
      message ||
      `Your ${actionObj} ${getVerb(actionObj)} been successfully ${
        actionType.successfulAction
      }`
  });
};

export const showFailureMessage = (actionType, actionObj, error, date) => {
  let errorReason = null;
  if (error.status === 400) {
    const errorModel = Server400ErrorModel(error);
    if (
      errorModel.firstErrorType === ErrorType.CreateRegistrationsInThePast &&
      isBefore(date, startOfDay(new Date()))
    )
      errorReason = `You cannot ${actionType.failedAction} a ${actionObj} in the past`;
    else errorReason = errorModel.firstError;
  } else
    errorReason = `We couldn’t ${actionType.failedAction} your ${actionObj}. Please try again later`;

  Mediator().publish(Channels.OpenStatusMessage, {
    type: "failure",
    reason: errorReason
  });
};

const getVerb = actionObj =>
  actionObj === ActionObjs.Bookings ? "have" : "has";
