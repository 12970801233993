import { Mediator } from "@shared/mediator";
import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton";
import { Channels } from "@shared/Channels";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { getDateKey } from "@shared/utils/dateHelpers";

const today = getDateKey(new Date());

const NavigationState = (pageObs, panelObs, pathObs) => {
  const navigationState = ko
    .pureComputed(() => ({
      page: pageObs(),
      panel: panelObs(),
      path: pathObs()
    }))
    .extend({ deferred: true });

  navigationState.equalityComparer = NavigationState.equals;

  return navigationState;
};

NavigationState.equals = (s1, s2) => {
  if (s1 === s2) return true;
  return (
    s1 &&
    s2 &&
    s1.page === s2.page &&
    s1.panel === s2.panel &&
    s1.path.length === s2.path.length &&
    s1.path.every((x, i) => s2.path[i] === x)
  );
};

const tryGetPage = (path, supportedPages) => {
  const mainPagePath = "";

  const referencedPage = supportedPages.find(
    p => p.path === path[0]?.toLowerCase()
  );

  const activePage =
    referencedPage || supportedPages.find(p => p.path === mainPagePath);

  if (!activePage)
    throw "Navigation occured to an unknown page and no default path was configured";

  return [activePage, path.slice(referencedPage ? 1 : 0)];
};

const tryGetPanel = (fullPath, supportedPanels) => {
  // Panels come last, so it's [ pageId, ...pageParams, panelId ]
  const possiblePanelId = fullPath.at(-1)?.toLowerCase();

  const activePanel =
    supportedPanels.find(p => p.path === possiblePanelId) ?? null;

  const remainingPath = activePanel ? fullPath.slice(0, -1) : fullPath;

  return [activePanel, remainingPath];
};

// This is about updating the URL when a side panel closes, and reacting to URL
// changes, optionally opening side panels
const URLNavigation = () => {
  let initialized = false;
  let supportedPages = [];
  let supportedPanels = [];

  // Keep track of the active page and panel
  //  Note: panel can be null, page will never be
  const activePage = ko.observable(null);
  const activePanel = ko.observable(null);
  const restPath = ko.observable(null);

  const navigationState = NavigationState(activePage, activePanel, restPath);

  navigationState.subscribe(s => {
    Mediator().publish(urlNavigationChannel, s);
  });

  // Finds the correct combination of a page, optional side panel and
  // optional other path arguments from a string that was either
  // passed to .navigate or came from a browser pop-state event.
  const processPath = strPath => {
    const fullPath = strPath
      .slice(1) // Remove the # character
      .split("/")
      .map(str => str.trim())
      .filter(str => str.length);

    // Paths consist of [ pageId, ...pageParams?, panelId? ]
    const [page, panelPath] = tryGetPage(fullPath, supportedPages);
    const [panel, path] = tryGetPanel(panelPath, supportedPanels);

    return { page, panel, path };
  };

  // Translates any hash string to the right navigation state
  const handleUrlChange = hash => {
    const { page, panel, path } = processPath(hash);

    activePage(page);
    activePanel(panel);
    restPath(path);
  };

  // A side effect of using this class is that it parses the current document URL
  // this is only called in UserApp.js
  const init = (pageConfig, panelConfig) => {
    supportedPages = pageConfig;
    supportedPanels = panelConfig;
    initialized = true;

    const hash = document.location.hash;

    if (getFeatures().has(FeatureNames.WORK_DAY) && !hash)
      history.pushState(null, null, `#${today}`);

    handleUrlChange(hash);

    window.addEventListener("popstate", _event => {
      const hash = document.location.hash;
      handleUrlChange(hash);
    });
  };

  /**
   * Navigate to a page + panel combination.
   * To
   * @param {string?} pageId Special value null keeps the currently active page
   * @param {string?} panelId Pass null to close any open side panel
   * @param {string[]?]} extraPath
   */
  const navigate = (pageId, panelId, extraPath = null) => {
    if (!initialized)
      throw "URL Navigation needs to be initialized before navigation methods can be called.";

    Mediator().publish(Channels.DisposeSidePanelComponents);

    const newPageId = pageId ?? activePage().path;
    const isPageSwitch = newPageId !== activePage().path;

    // Caller did not specify a path change
    if (extraPath === null) {
      extraPath = isPageSwitch ? [] : restPath();
    }

    const hash =
      "#" +
      [newPageId, ...extraPath, panelId]
        .filter(part => Boolean(part))
        .join("/");

    handleUrlChange(hash);

    if (hash !== document.location.hash) {
      history.pushState(null, null, hash);
    }
  };

  return {
    init,
    navigate,
    activePanelId: activePanel.maybeMap(p => p.path),
    activePageId: activePage.maybeMap(p => p.path),
    restPath
  };
};
export const UrlNavigationSingleton = Singleton(URLNavigation);

export const urlNavigationChannel = "urlNavigation";
