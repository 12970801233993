import {
  stringToColor,
  initialsForName
} from "../../shared/utils/avatar-helpers";
import ko from "@shared/knockout/extended";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { isSameDay, startOfDay } from "date-fns";

export const InviteeVM = (apiModel, day) => {
  const selectedForDeletion = ko.observable(false);
  const checkInStatusStore = CheckInStatusStoreSingleton();
  return {
    selectedForDeletion,
    email: apiModel.email,
    backgroundColor: apiModel.color ?? stringToColor(apiModel.email),
    color: "#000",
    displayName: apiModel.displayName ?? apiModel.name ?? apiModel.email,
    directoryObjectId: apiModel.directoryObjectId,
    id: apiModel.id,
    initials:
      apiModel.initials || initialsForName(apiModel.name || apiModel.email),
    imageHash: apiModel.imageHash,
    checkInStatus: ko.pureComputed(() =>
      isSameDay(day, startOfDay(new Date()))
        ? checkInStatusStore.getById(apiModel.id)
        : null
    )
  };
};
