const dirtyPush = (xs, x) => (xs.push(x), xs);
const identity = x => x;

/**
 * Groups an array using a grouper function
 * @param {function:*} getGroupId - unary function that returns a number or string to be used as group key
 * @param {[*]} xs - The array to group
 * @returns {object}
 */
export const groupBy = (getGroupId, xs, transform = identity) =>
  xs.reduce((groups, x) => {
    const key = getGroupId(x);
    groups[key] = dirtyPush(groups[key] || [], transform(x));

    return groups;
  }, {});

export const groupByC = getGroupId => xs => groupBy(getGroupId, xs);
