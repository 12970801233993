import ko from "@shared/knockout/extended";
import {
  showFailureMessage,
  showSuccessMessage
} from "@app/utils/FeedbackMessage";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./GroupInvitation.template.html";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { GroupWorkspaceReservationServiceSingleton } from "@app/services/GroupWorkspaceReservationService";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { format } from "date-fns";

export const GroupInvitationVM = ({
  groupWorkspace,
  currentUser,
  currentWorkspace,
  date,
  saveCallback = null
}) => {
  const workspaceService = WorkspaceServiceSingleton();
  const gwrService = GroupWorkspaceReservationServiceSingleton();
  const urlNavigation = UrlNavigationSingleton();

  const saving = ko.observable(null);

  const currentUserInvitation = groupWorkspace.members.find(
    i => i.userId === currentUser.id
  );

  const hasReservation = currentWorkspace.map(Boolean);

  const statusObs = ko.observable(currentUserInvitation.status);

  const getConfirmationText = () => {
    const hasOwnedGroupBooking = currentWorkspace.maybeMap(
      reservation =>
        reservation.owner.userId === currentUser.id &&
        reservation.groupReservationId,
      false
    )();

    const title = hasOwnedGroupBooking
      ? "Replace group booking?"
      : "Replace current booking?";

    const subtitle = hasOwnedGroupBooking
      ? "You've already booked desks for this day. Accepting this invitation will delete your current group booking and those of your invitees."
      : "You’ve already booked a desk for this day. Accepting this invitation will delete your current desk booking.";

    const primaryButtonText = hasOwnedGroupBooking
      ? "Yes, replace and cancel"
      : "Replace";

    const successfulAction = hasOwnedGroupBooking
      ? "Desk booking replaced and cancellations sent"
      : "Desk booking replaced";

    return { title, subtitle, primaryButtonText, successfulAction };
  };

  const overwriteExistingReservation = () => {
    const {
      title,
      subtitle,
      primaryButtonText,
      successfulAction
    } = getConfirmationText();

    Mediator().publish(Channels.ToggleConfirmationWindow, {
      options: {
        title,
        subtitle,
        smallerSubText: true,
        buttonInColumnOrder: true,
        primaryButtonText,
        primaryButtonClass: "MpqButton--secondaryGray",
        onPrimaryButtonClick: () =>
          saveAccept().then(() =>
            showSuccessMessage(
              { successfulAction },
              null,
              "Your previous booking is deleted and your new desk has been booked."
            )
          )
      }
    });
  };

  const saveAccept = () =>
    save(ReservationStatus.Confirmed)
      .then(() => {
        typeof saveCallback === "function" && saveCallback();
      })
      .catch(error => {
        handleError(error, "accept");
        throw error;
      });

  const save = status => {
    saving(true);
    const _currentWorkspaceId = ko.unwrap(currentWorkspace)?.id;

    return gwrService
      .updateGroupWorkspaceReservationInvitation(
        groupWorkspace.groupReservationId,
        currentUserInvitation.reservationId,
        status
      )
      .then(async result => {
        if (status === ReservationStatus.Confirmed) {
          // fetch updated workspace (force refresh)
          await workspaceService.getWorkspaceById(groupWorkspace.id, true);

          if (hasReservation) {
            // delete currentWorkspace from store
            workspaceService.remove(_currentWorkspaceId);
          }
        }

        if (status === ReservationStatus.InvitationDeclined) {
          // delete from store
          workspaceService.remove(groupWorkspace.id);
        }

        statusObs(result.status);
      })
      .finally(() => saving(false));
  };

  const handleError = (error, actionText) =>
    showFailureMessage({ failedAction: actionText }, "booking", error);

  const openGWRInvite = () => {
    if (saving()) return;

    urlNavigation.navigate("", "overview", [
      format(date, "yyyy-MM-dd"),
      "workspace-invite",
      groupWorkspace.id
    ]);
  };

  return {
    ...groupWorkspace,
    statusObs,
    saving,
    expiryLabel: currentUserInvitation?.expiryLabel,
    openGWRInvite,
    decline: () => {
      save(ReservationStatus.InvitationDeclined)
        .then(() => {
          typeof saveCallback === "function" && saveCallback();
        })
        .catch(error => handleError(error, "decline"));
    },
    accept: () => {
      hasReservation()
        ? overwriteExistingReservation()
        : saveAccept().then(() =>
            showSuccessMessage(
              { successfulAction: "Group booking accepted" },
              null,
              "Your desk has been booked"
            )
          );
    }
  };
};

export const GroupInvitation = KOComponent(
  "group-invitation",
  ({ vm }) => vm,
  template
);
