import "./StatusMessage.scss";
import template from "./StatusMessage.template.html";

import { Mediator } from "../../mediator";
import { Channels } from "../../Channels";
import { KOComponent } from "../../knockout/KOComponent";

const StatusMessageVM = ({ result, mediator = Mediator() }) => {
  const onClick = () => {
    mediator.publish(Channels.CloseStatusMessage, "");
  };

  const successful = result.type === "success" ? true : false;

  const title = result.title || (successful ? "Succeeded!" : "Failed");
  const iconName = result.iconName ?? result.type;
  const message = result.reason;
  const htmlMessage = result.htmlReason;

  const subMessage = result.subReason;

  if (successful) {
    setTimeout(() => {
      mediator.publish(Channels.CloseStatusMessage, "");
    }, result.timeout ?? 2000);
  }

  return {
    hasTimeout: successful,
    title,
    successful,
    message,
    htmlMessage,
    iconName,
    subMessage,
    onClick
  };
};

export const StatusMessage = KOComponent(
  "status-message",
  StatusMessageVM,
  template
);
