// Hides some inconsistent naming on the server. Use toDto to transform back to those names.
class SettingsApiModel {
  constructor(obj) {
    // max 240 char long string
    this.officeInstructions = obj.officeInstructions;

    // Either "SameDay" or "BeforeMidnight"
    this.registrationPolicy = obj.registrationPolicy;

    // GUID of an access profile
    this.defaultQuotaProfileId = obj.defaultAccessProfile;

    //Contact email address
    this.contactEmailAddress = obj.contactEmailAddress;

    //GUID of an access profile
    this.defaultLocationProfileId = obj.defaultLocationProfile;

    this.defaultParkingQuotaProfileId = obj.defaultParkingQuotaProfileId;

    this.defaultParkingLocationProfileId = obj.defaultParkingLocationProfileId;

    //URL
    this.companyWebsite = obj.companyWebsite;

    // Number between 7 and 600
    this.retentionPeriod = obj.shiftsRetentionPeriodDays;

    this.userRetentionPeriodDays = obj.userRetentionPeriodDays;

    // URL
    this.customPrivacyPolicy = obj.customPrivacyPolicyUrl;

    // URL
    this.defaultPrivacyPolicy = obj.defaultPrivacyPolicyUrl;

    // URL
    this.effectivePrivacyPolicy = obj.effectivePrivacyPolicyUrl;
  }

  toPutDto() {
    return {
      officeInstructions: this.officeInstructions || null,
      registrationPolicy: this.registrationPolicy,
      defaultAccessProfile: this.defaultQuotaProfileId,
      contactEmailAddress: this.contactEmailAddress || null,
      defaultLocationProfile: this.defaultLocationProfileId,
      companyWebsite: this.companyWebsite || null,
      shiftsRetentionPeriodDays: this.retentionPeriod || null,
      customPrivacyPolicyUrl: this.customPrivacyPolicy || null,
      userRetentionPeriodDays: this.userRetentionPeriodDays || null,
      defaultParkingQuotaProfileId: this.defaultParkingQuotaProfileId,
      defaultParkingLocationProfileId: this.defaultParkingLocationProfileId
    };
  }

  /**
   * Compare two api objects for equality
   * @param {SettingsApiModel} a
   * @param {SettingsApiModel} b
   */
  static equal(a, b) {
    return (
      a.officeInstructions !== b.officeInstructions ||
      a.registrationPolicy !== b.registrationPolicy ||
      a.defaultQuotaProfileId !== b.defaultQuotaProfileId ||
      a.contactEmailAddress !== b.contactEmailAddress ||
      a.defaultLocationProfileId !== b.defaultLocationProfileId ||
      a.companyWebsite !== b.companyWebsite ||
      a.retentionPeriod !== b.retentionPeriod ||
      a.customPrivacyPolicy !== b.customPrivacyPolicy ||
      a.defaultPrivacyPolicy !== b.defaultPrivacyPolicy ||
      a.effectivePrivacyPolicy !== b.effectivePrivacyPolicy ||
      a.userRetentionPeriodDays !== b.userRetentionPeriodDays ||
      a.defaultParkingQuotaProfileId !== b.defaultParkingQuotaProfileId ||
      a.defaultParkingLocationProfileId !== b.defaultParkingLocationProfileId
    );
  }
}

export default SettingsApiModel;
