import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";
import { numericCompare, byPropDesc } from "@shared/utils/sort.js";

const NotificationStore = () => {
  const hydrated = ko.observable(false);

  const cache = ko.observable({});
  const all = cache
    .map(Object.values)
    .sort(byPropDesc("creationDateTime", numericCompare));

  const allUnreadNotifications = all.filter(c => c.isRead === false);

  const update = notifications => {
    hydrated(true);

    cache(Object.fromEntries(notifications.map(p => [p.id, p])));

    return notifications;
  };

  const add = notification => {
    const _cache = cache();
    _cache[notification.id] = notification;

    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];

    cache.valueHasMutated();
  };

  const updateSpecific = notifications => {
    const _cache = cache();
    notifications.forEach(notification => {
      _cache[notification.id] = notification;
    });
    cache.valueHasMutated();
  };

  return {
    hydrated,
    allUnreadNotifications,
    update,
    add,
    all,
    get: id => cache()[id] || null,
    remove,
    updateSpecific
  };
};

export const NotificationStoreSingleton = Singleton(NotificationStore);
