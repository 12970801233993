import "./ConnectionErrorWindow.scss";
import template from "./ConnectionErrorWindow.template.html";

// import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { ErrorType } from "../../../shared/utils/ErrorType";

const ConnectionErrorWindowVM = ({ errorType, mediator = Mediator() }) => {
  const isLoadingError = errorType === ErrorType.LoadingFailed;
  const title = isLoadingError ? "Oops..." : "LimitReached";
  const text = isLoadingError
    ? "We have problems showing your connections, try again later if you want them to appear"
    : "Delete some connections to be able to make new ones";
  const showLoadingPicture = isLoadingError;
  const showLimitPicture = !isLoadingError;
  const showButton = isLoadingError ? true : false;
  if (!isLoadingError) {
    setTimeout(() => {
      mediator.publish(Channels.CloseConnectionErrorWindow);
    }, 2000);
  }

  const close = () => {
    mediator.publish(Channels.CloseConnectionErrorWindow);
  };

  return {
    close,
    title,
    text,
    showLoadingPicture,
    showLimitPicture,
    showButton
  };
};

export const ConnectionErrorWindow = KOComponent(
  "connection-error",
  ConnectionErrorWindowVM,
  template
);
