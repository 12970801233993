import template from "./WorkDayOverviewTabs.template.html";
import emptyStateTemplate from "./EmptyState/EmptyState.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { ConnectionsTab } from "./ConnectionsTab/ConnectionsTab";
import { CalendarTab } from "./CalendarTab/CalendarTab";
import { getAppFeatures } from "@app/services/AppFeatures";
import { FeatureNames } from "@shared/services/Features";

import "./WorkDayOverviewTabs.scss";
import "./EmptyState/EmptyState.scss";

const WorkDayOverviewTabVM = ({ day }) => {
  const meetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const { enabledForUsers } = getAppFeatures().has(meetingsFeature)
    ? getAppFeatures().get(meetingsFeature)
    : { enabledForUsers: false };

  const showCalendarTab = enabledForUsers;

  const tabs = [
    {
      id: "connections",
      title: "Connections",
      isActive: ko.observable(false),
      day
    }
  ];

  if (showCalendarTab) {
    tabs.unshift({
      id: "calendar",
      title: "Calendar",
      isActive: ko.observable(false),
      day
    });
  }

  const toggleTab = data => {
    tabs.forEach(t =>
      t.id === data.id ? t.isActive(true) : t.isActive(false)
    );
  };

  tabs[0].isActive(true);

  return {
    day,
    tabs,
    calendarTab: showCalendarTab ? tabs[0] : null,
    connectionsTab: showCalendarTab ? tabs[1] : tabs[0],
    toggleTab
  };
};

const WorkDayOverviewTabComponent = KOComponent(
  "work-day-overview-tabs",
  WorkDayOverviewTabVM,
  template
);

export const WorkDayOverviewTabs = Object.assign(
  {},
  WorkDayOverviewTabComponent,
  {
    register: ko => {
      WorkDayOverviewTabComponent.register(ko);
      ConnectionsTab.register(ko);
      CalendarTab.register(ko);

      ko.components.register("empty-state", {
        template: emptyStateTemplate
      });
    }
  }
);
