export const OpeningDays = [
  { label: "Mon", dayName: "Monday" },
  { label: "Tue", dayName: "Tuesday" },
  { label: "Wed", dayName: "Wednesday" },
  { label: "Thu", dayName: "Thursday" },
  { label: "Fri", dayName: "Friday" },
  { label: "Sat", dayName: "Saturday" },
  { label: "Sun", dayName: "Sunday" }
];

export const OpeningDayCodes = {
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
  Sunday: "Sun"
};

export const WorkingDays = OpeningDays.slice(0, 5).map(d => d.dayName);

const openingDaysOrder = {
  [OpeningDayCodes.Monday]: 0,
  [OpeningDayCodes.Tuesday]: 1,
  [OpeningDayCodes.Wednesday]: 2,
  [OpeningDayCodes.Thursday]: 3,
  [OpeningDayCodes.Friday]: 4,
  [OpeningDayCodes.Saturday]: 5,
  [OpeningDayCodes.Sunday]: 6
};

export const compareOpeningDays = (a, b) => {
  const scoreA = openingDaysOrder[a] ?? Infinity;
  const scoreB = openingDaysOrder[b] ?? Infinity;

  return scoreA > scoreB ? 1 : scoreA < scoreB ? -1 : 0;
};
