import { Msal } from "./settings/MsalInstance";
import { tryGetClaims } from "@shared/services/auth/tryGetClaims";
import { confirmDialog } from "../../utils/confirmDialog";
import { logout } from "./logout";
import { LoginRequest } from "./settings/LoginRequest";

let hasHadValidTokenThisSession = false;
let pendingTokenResponse = null;
let silentTokenRequestRejected = false;

export const getToken = async () => {
  if (silentTokenRequestRejected) {
    console.log(
      "Api call came in after we already concluded that we can't renew."
    );

    // Return a promise that never resolves, use can click Renew to get token via redirect
    return new Promise((res, rej) => {});
  }

  // Getting here usually means a 401 removed the token, but we
  // are still signed in (i.e. API token expired but Sign in token did not)
  // Note: now that acquireTokenSilent uses an ajax call, we can safely do it on safari!

  const account = Msal().getActiveAccount();

  if (!account) {
    // This should never happen as the 'getToken' must be called only after an account has been
    // set active. But anyways, we must guard the null here, specially coming from a third party.
    return confirmDialog({
      question:
        "Uh oh. Something went wrong, try to sign in again or get in touch with support@mapiq.com (active_account_not_found)",
      confirm: "Sign out"
    }).then(() => {
      logout();
      return confirmDialog({ question: "Signing out..." });
    });
  }

  try {
    if (!pendingTokenResponse) {
      pendingTokenResponse = Msal().acquireTokenSilent(
        Object.assign({ account }, LoginRequest())
      );
    }

    const tokenResponse = await pendingTokenResponse;
    pendingTokenResponse = null;

    hasHadValidTokenThisSession = true;
    return tokenResponse.accessToken;
  } catch (err) {
    console.info("Failed to acquire token silently");
    silentTokenRequestRejected = true;

    // Redirect straight away if the first one of the session fails to be acquired silently
    if (!hasHadValidTokenThisSession) {
      const claims = tryGetClaims();
      const loginHint = claims?.sign_in_name;

      Msal().acquireTokenRedirect(
        Object.assign({ account }, LoginRequest(loginHint))
      );
      return confirmDialog({ question: "Checking your account..." });
    }
  }

  return confirmDialog({
    question: "Your session has timed out",
    confirm: "Renew",
    cancel: "Sign out"
  })
    .catch(e => {
      logout();
      return confirmDialog({ question: "Signing out..." });
    })
    .then(() => {
      const claims = tryGetClaims();
      const loginHint = claims?.sign_in_name;
      Msal().acquireTokenRedirect(
        Object.assign({ account }, LoginRequest(loginHint))
      );
    })
    .then(() => {
      // The redirect can take a while, this ensures you'll see a message
      return confirmDialog({ question: "Redirecting..." });
    });
};
