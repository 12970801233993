import ko from "@shared/knockout/extended";
import { ExceptionStoreSingleton } from "@shared/services/ExceptionsStore";
import { format, startOfDay } from "date-fns";
import { locationLabels } from "@shared/utils/locationLabels";
import { availabilityLocationData } from "./AvailabilityLocationData";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

export const WorkspaceLocationVM = (
  date,
  locationId,
  selectedInvitees = null,
  usePendingOrConfirmedMemberCount
) => {
  const locationStore = LocationStoreSingleton();
  const locationFloor = locationStore.getFloorForId(locationId);
  const location = locationStore.getBuildingForId(locationId);
  const area = locationStore.getWorkAreaForId(locationId);
  const exStore = ExceptionStoreSingleton();

  const getLocation = () => area ?? locationFloor ?? location;

  const formatedDay = startOfDay(date);
  const locationIsClosed = ko.pureComputed(
    () =>
      (location &&
        !location.openingDays.includes(format(formatedDay, "eeee"))) ||
      exStore.nodeIsClosed(exStore, formatedDay, location) ||
      exStore.nodeIsClosed(exStore, formatedDay, locationFloor) ||
      exStore.nodeIsClosed(exStore, formatedDay, area)
  );

  const isGroupBooking = ko.pureComputed(
    () => typeof selectedInvitees === "function" && selectedInvitees()?.length
  );

  const {
    unavailableForGroupBookingDueToDeskBookingOnly,
    unavailableForGroupBookingDueToAvailability,
    groupAvailabilityLabel,
    full: locationIsFull
  } = availabilityLocationData(
    formatedDay,
    getLocation(),
    isGroupBooking() ? selectedInvitees().length + 1 : 1,
    usePendingOrConfirmedMemberCount
  );

  const [locationLine1, locationLine2] = locationLabels(locationId);

  return {
    location,
    disabled: ko.pureComputed(
      () =>
        locationIsFull() ||
        locationIsClosed() ||
        unavailableForGroupBookingDueToDeskBookingOnly() ||
        unavailableForGroupBookingDueToAvailability()
    ),
    disabledReason: ko.pureComputed(() =>
      locationIsClosed()
        ? "Closed"
        : locationIsFull()
        ? "Full"
        : isGroupBooking()
        ? groupAvailabilityLabel()
        : ""
    ),
    locationLine1,
    locationLine2
  };
};
