import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import ko from "@shared/knockout/extended";
import { getISOWeek } from "date-fns";
import { DayVM } from "./DayVM";

export const WeekVM = (
  profile,
  now,
  stores,
  allColleagues,
  selectedColleagues,
  lastVisibleWeekDay,
  showMeetings
) => ([weekKey, weekDays]) => {
  const workspaceService = WorkspaceServiceSingleton();

  // Label
  const weekNr = Number(weekKey);
  const currentWeekNr = getISOWeek(now);
  const heading =
    weekNr === currentWeekNr
      ? "This week"
      : weekNr === currentWeekNr + 1
      ? "Next week"
      : `Week ${weekNr}`;

  // Check booking rules for max per week quota
  const shiftsThisWeek = ko.pureComputed(
    () => workspaceService.getConfirmedForWeekNr(weekNr).length
  );
  const max = profile.daysPerWeek;
  const maxReached = shiftsThisWeek.map(count => count >= max);
  const quotaLabel = shiftsThisWeek.map(
    count => `${maxReached() ? "Max. reached " : ""}${count}/${max}`
  );

  // Create viewmodels for each day
  const days = weekDays.map(
    DayVM(
      profile,
      now,
      stores,
      allColleagues,
      selectedColleagues,
      lastVisibleWeekDay,
      showMeetings
    )
  );

  return {
    heading,
    days,
    quotaLabel,
    maxReached
  };
};
