import template from "./WorkDayStatusTile.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";

import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import {
  isCheckInFeatureEnabled,
  isManualCheckInFeatureEnabled
} from "@app/utils/checkIn";
import {
  CheckInStatusSourceType,
  CheckInStatus
} from "@shared/services/CheckInStatus";
import "./WorkDayStatusTile.scss";
import { isToday } from "date-fns/esm";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import {
  getWorkdayIcon,
  getWorkdayTitle
} from "@shared/utils/OfficeDay/status";

const WorkdayStatusTileVM = ({ day }) => {
  const checkInService = CheckInServiceSingleton();
  const loading = WorkdayReservationServiceSingleton().loading;
  const status = ko
    .pureComputed(() => WorkdayReservationsStoreSingleton().getForDate(day))
    .map(getFirstElement)
    .maybeMap(w => w.status, null);

  return {
    loading,
    status,
    iconName: ko.pureComputed(() => getWorkdayIcon(status())),
    title: ko.pureComputed(() => getWorkdayTitle(status())),
    showUpdateBuildingToChecinInBuildingButton: ko.pureComputed(
      () =>
        isToday(day) &&
        checkInService.status() === CheckInStatus.AtTheOffice &&
        (isManualCheckInFeatureEnabled() ||
          (isCheckInFeatureEnabled() && CheckInStatusSourceType.Automatic))
    ),

    onUpdate: () =>
      Mediator().publish(Channels.OpenWorkdayWizard, {
        day
      }),
    onClick: () => {
      if (loading()) return;
      Mediator().publish(Channels.OpenWorkdayWizard, {
        day
      });
    }
  };
};

export const WorkdayStatusTile = KOComponent(
  "workday-status-tile",
  WorkdayStatusTileVM,
  template
);
