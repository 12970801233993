export const EmptyStates = {
  CALENDAR_TAB: {
    iconName: "teamVideoMeeting",
    title: "What's on the agenda for today?",
    showFirstExplainer: false,
    firstExplainer: "",
    secondExplainer:
      "See your upcoming meetings, who’s joining, and from where",
    buttonText: "Sync your calendar"
  },
  CONNECTIONS_TAB: {
    iconName: "sadChairs",
    title: "Where are your colleagues today?",
    firstExplainer: "None of your connected colleagues are in the office today",
    secondExplainer:
      "Add your colleagues to see when and where they’ll be in the office",
    buttonText: "Connect with colleagues"
  }
};
