/* Notes:

  - Each panel should have a URL that updates automatically
  - Navigating to such a URL should open the panel straight away
  - Panels only manage navigation, what goes inside is part of dynamic content passed via components

  - There is always _one_ side panel component in the App's index.html
  - All of the communication goes through the mediator

*/
import "./SidePanel.scss";
import template from "./SidePanel.template.html";
import { Mediator } from "@shared/mediator";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import {
  urlNavigationChannel,
  UrlNavigationSingleton
} from "@app/utils/URLNavigation";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { FeatureNames, getFeatures } from "@shared/services/Features.js";
import { getFormatedActiveDateFromUrl } from "@shared/utils/OfficeDay/helpers.js";

// This is all about sliding in a nice piece of UI, keep track of its
// open/close state and put a component inside
const SidePanelVM = () => {
  const urlNavigation = UrlNavigationSingleton();

  const isOpen = ko.observable(false);

  const heading = ko.observable("");
  const body = ko.observable(null);
  const footer = ko.observable(null); //optional
  const id = ko.observable("");

  const showSidePanelCloseButton = ko.observable(true);

  const open = ({
    config: {
      path: panelId,
      heading: newHeading,
      content: {
        bodyComponentName,
        // Flavor 1:
        // This works for components that construct their own VM from a params object
        params = {},
        // Flavor 2:
        // This works for components that share a VM that is bound via a with binding
        VMConstructor = cfg => cfg,
        footerComponentName
      }
    },
    path
  }) => {
    heading(newHeading);
    id(panelId);

    const componentParams = VMConstructor(
      panelId === "overview" || panelId === "add-room" || panelId === "book"
        ? { ...ko.unwrap(params), showSidePanelCloseButton }
        : params
    );

    body({
      name: bodyComponentName,
      params: { ...componentParams, path }
    });

    if (footerComponentName) {
      footer({
        name: footerComponentName,
        params: { ...componentParams, path }
      });
    } else {
      footer(null);
    }

    isOpen(true);
  };

  const closeUI = () => {
    isOpen(false);
    clearAllBodyScrollLocks();
  };

  const showCloseButtonOnMobile = ko.pureComputed(() =>
    ["filter", "overview", "book"].includes(id())
  );

  Mediator().subscribe(urlNavigationChannel, ({ panel, path }) => {
    if (panel) {
      open({ config: panel, path });
    } else {
      closeUI();
    }
  });
  // Add event which updates the CSS variable to the height of our viewport
  // Ios & Android (possible) viewport bugfix
  // Note: Fairly "new". One of our most logged errors (Dec 2021).
  //       We'll put it behind feature detection for now.
  //       https://caniuse.com/mdn-api_visualviewport
  if ("visualViewport" in window) {
    window.visualViewport.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--viewport-height",
        `${visualViewport.height}px`
      );
    });

    // Set the initial value for our CSS property after load
    document.addEventListener("DOMContentLoaded", () => {
      document.documentElement.style.setProperty(
        "--viewport-height",
        `${visualViewport.height}px`
      );
    });
  }

  return {
    open,
    close: () => {
      if (getFeatures().has(FeatureNames.WORK_DAY)) {
        const dateKey = getFormatedActiveDateFromUrl();

        if (dateKey) {
          urlNavigation.navigate(null, null, [dateKey]);
          return;
        }
      }

      urlNavigation.navigate(null, null, []);
    },
    heading,
    isOpen,
    body,
    footer,
    showCloseButtonOnMobile,
    showSidePanelCloseButton
  };
};

export const SidePanel = KOComponent("side-panel", SidePanelVM, template);
