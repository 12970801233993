import template from "./WorkingHoursPopupActiveView.template.html";
import "./WorkingHoursPopupActiveView.scss";
import { KOComponent } from "@shared/knockout/KOComponent";

const WorkingHoursPopupActiveViewVM = ({
  options,
  currentlySelectedOption,
  goToHomeView
}) => {
  return {
    options: options.map(option => ({
      label: option,
      isActive: option === currentlySelectedOption()
    })),
    onSelect: selectedOption => {
      currentlySelectedOption(selectedOption.label);
      goToHomeView();
    }
  };
};

export const WorkingHoursPopupActiveView = KOComponent(
  "working-hours-popup-active-view",
  WorkingHoursPopupActiveViewVM,
  template
);
