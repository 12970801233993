import template from "./WorkSpaceSummary.template.html";
import "@shared/style/Workspace/WorkSpaceSummary.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";

const WorkSpaceSummaryVM = ({
  summaryLocationId,
  date,
  deskSection,
  selectedDeskIds,
  selectedUserId,
  areaViewMode,
  usePendingOrConfirmedMemberCount,
  selectedInvitees = ko.observableArray([]),
  isGroupBooking,
  workspace
}) => {
  const locationId = summaryLocationId;
  // Group size = invitees + owner (current user)
  const groupSize = ko.pureComputed(
    () => (ko.unwrap(selectedInvitees)?.length || 0) + 1
  );

  return {
    locationId,
    selectedDeskIds,
    areaViewMode,
    date,
    enlargedMap: areaViewMode,
    deskSection,
    groupSize,
    isGroupBooking,
    selectedUserId,
    workspaceNodeSummaryParams: {
      date,
      locationId,
      usePendingOrConfirmedMemberCount:
        usePendingOrConfirmedMemberCount &&
        workspace?.workAreaId === locationId()
    }
  };
};

export const WorkSpaceSummary = KOComponent(
  "workspace-summary",
  WorkSpaceSummaryVM,
  template
);
