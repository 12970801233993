export const ResponseStatus = {
  Accepted: "Accepted",
  Declined: "Declined",
  Maybe: "Maybe",
  Pending: "Pending",
  Organizer: "Organizer"
};

const ResponseStatusOrder = {
  [ResponseStatus.Accepted]: 0,
  [ResponseStatus.Organizer]: 1,
  [ResponseStatus.Maybe]: 2,
  [ResponseStatus.Pending]: 2,
  [ResponseStatus.Declined]: 3
};

export const byAttendeeResponseStatus = (responseStatus1, responseStatus2) => {
  const responseStatus1Order = ResponseStatusOrder[responseStatus1] ?? Infinity;
  const responseStatus2Order = ResponseStatusOrder[responseStatus2] ?? Infinity;

  if (responseStatus1Order > responseStatus2Order) return 1;
  if (responseStatus1Order < responseStatus2Order) return -1;
  return 0;
};
