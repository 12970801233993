import ko from "@shared/knockout/extended";

export const stringCompare = (a, b) =>
  a && b ? a.localeCompare(b) : !a ? 1 : !b ? -1 : 0;
export const numericCompare = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

export const byPropAsc = (key, comparer = stringCompare) => (a, b) =>
  comparer(ko.unwrap(a[key]), ko.unwrap(b[key]));
export const byPropDesc = (key, comparer = stringCompare) => (a, b) =>
  comparer(b[key], a[key]);

export const sort = comparer => xs => xs.sort(comparer);

export const combineSorters = (...sorters) => (a, b) => {
  let result = 0;
  for (const f of sorters) {
    result = f(a, b);
    if (result !== 0) return result;
  }

  return result;
};
