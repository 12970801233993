export const AnalyticsPageConfigApiModel = ({
  displayName,
  pageId,
  groupingId,
  isDefaultPage
}) => ({
  displayName,
  pageId,
  groupingId,
  isDefaultPage
});
