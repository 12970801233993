import { authFetch } from "./auth/authFetch.js";
import { loadFeatures } from "./Features.js";
export const login = () =>
  authFetch("/shifts/me/login", { method: "POST" }).then(async user => {
    // This is in our login flow as a chained call because the back-end team
    // was not able to include feature information within the user's login
    // response.
    // Once they merge the two endpoints in the future it should be simple
    // to remove the separate call here and consume the features directly
    // from the user dto.
    try {
      await loadFeatures();
    } catch (e) {
      console.error("Failed to load features", e);
    }

    return user;
  });
