import arrowDown from "./svgs/designsystem/Arrow-down.template.html";
import arrowLeft from "./svgs/designsystem/Arrow-left.template.html";
import arrowRight from "./svgs/designsystem/Arrow-right.template.html";
import calendar from "./svgs/designsystem/Calendar.template.html";
import arrowUp from "./svgs/designsystem/Arrow-up.template.html";
import check from "./svgs/designsystem/Check.template.html";
import greenBackgroundCheck from "./svgs/designsystem/GreenBackgroundCheck.template.html";
import close from "./svgs/designsystem/Close.template.html";
import download from "./svgs/designsystem/Download.template.html";
import draw from "./svgs/designsystem/Draw.template.html";
import edit from "./svgs/designsystem/Edit.template.html";
import filter from "./svgs/designsystem/Filter.template.html";
import fit from "./svgs/designsystem/Fit.template.html";
import hamburger from "./svgs/designsystem/Hamburger.template.html";
import hand from "./svgs/designsystem/Hand.template.html";
import info from "./svgs/designsystem/Info.template.html";
import line from "./svgs/designsystem/Line.template.html";
import lock from "./svgs/designsystem/Lock.template.html";
import minus from "./svgs/designsystem/Minus.template.html";
import move from "./svgs/designsystem/Move.template.html";
import outline from "./svgs/designsystem/Outline.template.html";
import plus from "./svgs/designsystem/Plus.template.html";
import redo from "./svgs/designsystem/Redo.template.html";
import search from "./svgs/designsystem/Search.template.html";
import settings from "./svgs/designsystem/Settings.template.html";
import trash from "./svgs/designsystem/Trash.template.html";
import undo from "./svgs/designsystem/Undo.template.html";
import upload from "./svgs/designsystem/Upload.template.html";
import visible from "./svgs/designsystem/Visible.template.html";
import warning from "./svgs/designsystem/Warning.template.html";
import zoomIn from "./svgs/designsystem/Zoom in.template.html";
import zoomOut from "./svgs/designsystem/Zoom out.template.html";
import rotateLeft from "./svgs/designsystem/Rotate-left.template.html";
import rotateRight from "./svgs/designsystem/Rotate-right.template.html";
import duplicate from "./svgs/designsystem/Duplicate.template.html";
import profile from "./svgs/designsystem/Profile.template.html";
import booked from "./svgs/designsystem/Booked.template.html";
import bell from "./svgs/designsystem/Bell.template.html";
import map from "./svgs/designsystem/Map.template.html";
import dots from "./svgs/designsystem/Dots.template.html";

export const DesignSystemIcons = {
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  calendar,
  check,
  greenBackgroundCheck,
  close,
  download,
  draw,
  edit,
  filter,
  fit,
  hamburger,
  hand,
  info,
  line,
  lock,
  minus,
  move,
  outline,
  plus,
  redo,
  search,
  settings,
  trash,
  undo,
  upload,
  visible,
  warning,
  zoomIn,
  zoomOut,
  rotateLeft,
  rotateRight,
  duplicate,
  profile,
  booked,
  bell,
  map,
  dots
};
