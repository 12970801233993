export const indexBy = (getKey, xs) =>
  xs.reduce(
    (index, x) =>
      Object.assign(index, {
        [getKey(x)]: x
      }),
    {}
  );

export const indexByC = getKey => xs => indexBy(getKey, xs);
