import "./NoBuildingsView.scss";
import template from "./NoBuildingsView.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";

const NoBuildingsViewVM = ({}) => {
  return {};
};

export const NoBuildingsView = KOComponent(
  "no-buildings",
  NoBuildingsViewVM,
  template
);
