class UserPreferencesApiModel {
  constructor(obj) {
    this.general = {
      hasLoggedIn:
        obj.shifts_FirstLoginTime ?? obj.firstLoginTime ? true : false,
      hasSeenWelcomeMessage:
        obj.shifts_LastReadWelcomeMessageTime ?? obj.lastReadWelcomeMessageTime
          ? true
          : false
    };
    this.shifts = {
      hasMadeBooking:
        obj.shifts_FirstRegistrationTime ?? obj.firstRegistrationTime
          ? true
          : false
    };

    this.meetings = {
      enableCalendar: obj.enableCalendar ? true : false,
      hasNotDismissedMeetingOnboardingPrompt:
        obj.solutionOnboardingDismissed ??
        obj.adminMeetingIntegrationOnboardingDismissed
          ? false
          : true
    };

    this.checkIn = {
      hasNotDismissedCheckInApiInactivityWarning: !obj.adminCheckInApiInactivityWarningDismissed
    };
    this.parking = {
      userDismissedParkingSuggestionPopUp:
        obj.parking_UserDismissedParkingSuggestionPopUp,
      userDismissedParkingIntroduction:
        obj.parking_UserDismissedParkingIntroduction
    };
  }
}

export default UserPreferencesApiModel;
