import { Fetch } from "@shared/services/auth/authFetch.js";

const f = Fetch(process.env.MAPIQ_API_BASE_URL_BLD);

const getFloorplan_UNPUBLISHED = (buildingId, floorId) => {
  return f.get(`/admin/buildings/${buildingId}/floors/${floorId}/svgpreview`);
};

const getFloorplan_PUBLISHED = (buildingId, floorId) => {
  return f.get(`/buildings/${buildingId}/floors/${floorId}/floorplan/svg`);
};

export const BuildingApi = {
  getFloorplan: (buildingId, floorId, version, unpublished = false) => {
    const getter = unpublished
      ? getFloorplan_UNPUBLISHED
      : getFloorplan_PUBLISHED;

    return getter(buildingId, floorId);
  }
};
