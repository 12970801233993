import { DayAvailabilityVM } from "@app/components/CalendarView/viewmodels/DayAvailabilityVM";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { Channels } from "@shared/Channels";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import { pl } from "@shared/utils/pluralize";
import {
  differenceInDays,
  addDays,
  startOfDay,
  format,
  isAfter,
  isSameDay,
  getISOWeek
} from "date-fns";

export const shiftActionButtonVM = (
  day = ko.unwrap(day),
  settings,
  profile,
  now,
  shift,
  inPast,
  selectedWorkdayOfficeId = null,
  checkForUnavailableInOptionalDeskBookingBuilding = false,
  mediator = Mediator()
) => {
  const weekDay = format(day, "iiii");
  const weekDayAllowed = profile.registrationDays.includes(weekDay);

  const booked = shift.map(Boolean);
  const lastBookableDay = addDays(startOfDay(now), profile.daysAhead);

  const afterToday = isAfter(day, now);
  const isToday = isSameDay(now, day);

  const rangeSetting = settings.maybeMap(({ registrationPolicy }) =>
    registrationPolicy === "SameDay" ||
    registrationPolicy === "CreateSameDayDeleteBeforeMidnight"
      ? 0
      : 1
  );

  const dayDiff = differenceInDays(day, lastBookableDay);
  const inRange = rangeSetting.maybeMap(s => {
    const notTooEarly = dayDiff + profile.daysAhead >= s;
    const notTooLate = dayDiff <= 0;

    return notTooEarly && notTooLate;
  }, false);

  const isFarAhead = ko.pureComputed(() => !inRange() && !isToday);

  const dayAvailability = DayAvailabilityVM(day);

  const weekNr = getISOWeek(day);
  const shiftsThisWeek = ko.pureComputed(
    () => WorkspaceServiceSingleton().getConfirmedForWeekNr(weekNr).length
  );
  const max = profile.daysPerWeek;
  const maxReached = shiftsThisWeek.map(count => count >= max);

  const optionalDeskBookingBuildingIsUnavailable = ko
    .pureComputed(() => {
      const workday =
        WorkdayReservationsStoreSingleton().getForDate(day)[0] ?? null;
      const office = LocationStoreSingleton().getBuildingForId(workday?.nodeId);
      if (
        !getFeatures().has(FeatureNames.WORK_DAY) ||
        !checkForUnavailableInOptionalDeskBookingBuilding ||
        !workday ||
        !office
      ) {
        return false;
      }

      const { full, closed } = availabilityLocationData(day, office);

      return full() || closed();
    })
    .extend({ deferred: true });

  const disableShiftButton = ko.pureComputed(() => {
    if (inPast) return true;
    if (booked()) return false;

    return (
      !inRange() ||
      !weekDayAllowed ||
      maxReached() ||
      dayAvailability.allFull() ||
      dayAvailability.allClosed() ||
      optionalDeskBookingBuildingIsUnavailable()
    );
  });

  const bookShift = () => {
    mediator.publish(Channels.OpenWorkspaceWizard, {
      date: day,
      selectedWorkdayOfficeId
    });
  };

  const editShift = () => {
    mediator.publish(Channels.OpenWorkspaceWizard, {
      date: day,
      shift: shift(),
      canEdit: settings.maybeMap(
        ({ registrationPolicy }) =>
          afterToday || registrationPolicy !== "BeforeMidnight",
        // Assume it's allowed if somehow we don't have settings. Server will do a final check
        true
      ),
      canDelete: settings.maybeMap(
        ({ registrationPolicy }) =>
          afterToday || registrationPolicy === "SameDay",
        // Assume it's allowed if somehow we don't have settings. Server will do a final check
        true
      ),
      selectedWorkdayOfficeId
    });
  };

  /*Update this:
    - Now a workday must be booked first (for this update text)
    - It does not necessarily mean that everything is closed (if the building has optional desk booking, a workday can be created there)
    - ...
  */
  const cannotBookWarning = ko.pureComputed(() => {
    // Firs thing to check: are we out of range?
    if (inPast) {
      return "You can not book this day because it is in the past.";
    } else if (isFarAhead()) {
      const bookableFrom = addDays(now, dayDiff);
      return `This day is too far in the future for you to book. To book, wait until ${format(
        bookableFrom,
        "MMMM d"
      )} or get in touch with your manager.`;
    } else if (maxReached()) {
      return `You've used up your quota for this week. To book, delete another workspace from this calendar week or get in touch with your manager.`;
    } else if (!weekDayAllowed) {
      return `You are not allowed to book workspaces on ${format(
        day,
        "iiii"
      )}s. If you need to be in the office this day, get in touch with your manager.`;
    } else if (dayAvailability.allClosed()) {
      return `Everything is closed on ${format(day, "eeee")}.`;
    }
    return "You can not book this day. Get in touch with your manager if you do need to be in the office.";
  });

  const shiftLabel = ko.pureComputed(() => {
    if (isFarAhead()) return "You can’t book this far ahead";
    if (maxReached()) return "Quota has been reached";
    if (!weekDayAllowed || inPast || !inRange())
      return "You can’t book on this day";
    if (dayAvailability.allClosed()) return "All locations are closed";
    if (dayAvailability.allFull() || optionalDeskBookingBuildingIsUnavailable())
      return "No desks available";
    return "Book a desk";
  });
  const numberOfBookings = ko.pureComputed(() => max - shiftsThisWeek());

  const bookingsLeft = ko.pureComputed(() =>
    disableShiftButton()
      ? null
      : `${numberOfBookings()} ${pl(
          "booking",
          numberOfBookings()
        )} left this week`
  );
  return {
    disableShiftButton,
    bookShift,
    editShift,
    cannotBookWarning,
    inRange,
    dayDiff,
    shiftLabel,
    maxReached,
    isFarAhead,
    bookingsLeft
  };
};
