import ko from "@shared/knockout/extended";

export const BreakpointVM = mQuery => {
  const isBreakpointMatched = ko.observable(false);

  const setIsBreakpointMatched = query => isBreakpointMatched(query.matches);

  const mediaQuery = window.matchMedia(mQuery);
  setIsBreakpointMatched(mediaQuery);

  mediaQuery.addEventListener("change", setIsBreakpointMatched);
  return {
    isBreakpointMatched,
    dispose: () =>
      mediaQuery.removeEventListener("change", setIsBreakpointMatched)
  };
};
