import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./ConnectCalendarView.template.html";
import "./ConnectCalendarView.scss";
import { enable, handleError } from "@app/utils/meetConsent";

export const ConnectCalendarViewVM = ({ params }) => {
  const { title, subtitle } = params;
  const connectCalendar = () => enable().catch(e => handleError(e));
  return {
    connectCalendar,
    title,
    subtitle
  };
};

export const ConnectCalendarView = KOComponent(
  "connect-calendar",
  ConnectCalendarViewVM,
  template
);
