export class ConsentStatusApiModel {
  constructor(obj) {
    this.isValidAccessToken = obj.isValidAccessToken;
    this.isValidRefreshToken = obj.isValidRefreshToken;
  }

  static fromApiObj(obj) {
    return new ConsentStatusApiModel(obj);
  }
}
