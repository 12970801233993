import { WorkspaceApiModel } from "@shared/apimodels/WorkspaceApiModel";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { WorkdayApiModel } from "@shared/apimodels/WorkdayApiModel";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";

export class ConnectedColleagueApiModel {
  constructor(obj) {
    const cleanedName = (obj.user.name || "").trim();

    this.id = obj.user.id;
    this.name = cleanedName || null;
    this.initials = obj.user.initials;
    this.email = obj.user.email;
    this.imageHash = obj.user.imageHash;
    this.shifts = obj.workspaceReservations
      .filter(r => r.status === ReservationStatus.Confirmed)
      .map(s => new WorkspaceApiModel(s));
    this.workdays = obj.workdays
      .filter(w => w.status === WorkdayStatus.OfficeDay)
      .map(w => new WorkdayApiModel(w));
  }
}
