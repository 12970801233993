import { UserAssignmentRuleApiModel } from "./UserAssignmentRuleApiModel";

export class LocationProfileApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.allowedLocationIds = obj.allowedLocationIds;
    this.disallowedLocationIds = obj.disallowedLocationIds;
    this.allowAll = obj.allowAll;
    this.rule = UserAssignmentRuleApiModel.fromDto(obj.rule);
  }

  static equal(a, b) {
    return (
      a &&
      b &&
      a.id === b.id &&
      a.name === b.name &&
      a.allowAll === b.allowAll &&
      a.allowedLocationIds.join("") === b.allowedLocationIds.join("") &&
      a.disallowedLocationIds.join("") === b.disallowedLocationIds.join("") &&
      (a.rule === b.rule || UserAssignmentRuleApiModel.equal(a.rule, b.rule))
    );
  }

  static fromDto(apiObj) {
    return new LocationProfileApiModel(apiObj);
  }
}
