import { AdminExceptionApiModel } from "@shared/apimodels/AdminExceptionApiModel.js";
import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";
import { byPropAsc, numericCompare } from "@shared/utils/sort.js";

const ExceptionStore = () => {
  const hydrated = ko.observable(false);

  const cache = ko.observable({});
  const all = cache
    .map(Object.values)
    .sort(byPropAsc("startDate", numericCompare));

  const update = exceptions => {
    hydrated(true);

    cache(Object.fromEntries(exceptions.map(p => [p.id, p])));

    return exceptions;
  };

  const add = exception => {
    const _cache = cache();
    _cache[exception.id] = exception;

    cache.valueHasMutated();
  };

  const batchAdd = exceptions => {
    const _cache = cache();

    exceptions.forEach(u => (_cache[u.id] = u));

    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];

    cache.valueHasMutated();
  };

  const nodeIsClosedDirectly = (exceptions, nodeId) =>
    exceptions.some(e => e.closedNodeIds.includes(nodeId));

  return {
    hydrated,
    update,
    add,
    batchAdd,
    all,
    get: id => cache()[id] || null,
    remove,

    // Warning: this only checks down the tree. I.e. if you
    // pass it a floor of a closed building, it will not return
    // the right answer...
    nodeIsClosed: (self, day, node, skipChildren = false) => {
      if (!node) return false;

      // Get all relevant exceptions
      const activeExceptions = all().filter(e =>
        AdminExceptionApiModel.overlaps(e, day)
      );

      // (1) This actual node is closed
      if (nodeIsClosedDirectly(activeExceptions, node.id)) return true;

      // (2) Node is not closed, but it has children and all of those are
      //     closed.
      const children = node.floors || node.workAreas || node.areas || [];
      if (children.length && skipChildren === false)
        return children.every(c => self.nodeIsClosed(self, day, c));

      return false;
    }
  };
};

export const ExceptionStoreSingleton = Singleton(ExceptionStore);
