import { Mediator } from "../mediator";
import { Channels } from "../Channels";

export const confirmDialog = ({
  question,
  rawBody,
  header,
  footNote,
  errorIcon,
  confirm,
  cancel
}) =>
  new Promise(function(resolve, reject) {
    Mediator().publish(Channels.OpenModal, {
      onClose: () => {
        Mediator().publish(Channels.CloseModal);
        reject(confirmDialog.REJECTION);
      },
      onConfirm: () => {
        Mediator().publish(Channels.CloseModal);
        resolve(true);
      },

      question,
      rawBody,
      header,
      footNote,
      errorIcon,
      confirm,
      cancel
    });
  });

confirmDialog.REJECTION = "User canceled flow in confirm modal";
