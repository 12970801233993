import { isAfter, isBefore, parseISO, format } from "date-fns";

export class AdminExceptionApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.reason = obj.reason;
    this.description = obj.description || null;
    this.startDate = parseISO(obj.startDate);
    this.endDate = parseISO(obj.endDate);
    this.closedNodeIds = obj.closedNodeIds;
  }

  static addEmpty() {
    return {
      id: null,
      reason: "Other",
      description: null,
      startDate: new Date(),
      endDate: new Date(),
      closedNodeIds: []
    };
  }

  static fromApiObj(obj) {
    return new AdminExceptionApiModel(obj);
  }

  static toApiObj(vm) {
    // Pass dates as LocalDate because it's expected by the API.
    return {
      id: vm.id || null,
      description: vm.description(),
      startDate: format(vm.startDate(), "yyyy-MM-dd"),
      endDate: format(vm.endDate(), "yyyy-MM-dd"),
      reason: vm.reason(),
      closedNodeIds: vm.closedNodeIds()
    };
  }

  static overlaps({ startDate, endDate }, day) {
    if (isBefore(endDate, day)) return false;
    if (isAfter(startDate, day)) return false;

    return true;
  }
}
