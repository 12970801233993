import { Msal } from "./settings/MsalInstance";

export const tryGetClaims = () => {
  const activeAccount = Msal().getActiveAccount();

  if (activeAccount) {
    return activeAccount.idTokenClaims;
  }

  return null;
};
