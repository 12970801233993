import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./WorkDayStatusIcon.template.html";
import ko from "@shared/knockout/extended";
import "./WorkDayStatusIcon.scss";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { WorkdayVM } from "@shared/viewmodels/OfficeDay/WorkdayVM";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";

const WorkDayStatusIconVM = ({ date, onClick }) => {
  const workday = ko
    .pureComputed(() => WorkdayReservationsStoreSingleton().getForDate(date))
    .map(getFirstElement)
    .maybeMap(WorkdayVM);

  return {
    workday,
    iconName: workday.maybeMap(({ icon }) => icon, "unknownWorkdayStatus"),

    onClick
  };
};

export const WorkDayStatusIcon = KOComponent(
  "work-day-status-icon",
  WorkDayStatusIconVM,
  template
);
