import "./style.scss";
import ko from "@shared/knockout/extended";
import { WelcomePage } from "./components/WelcomePage/WelcomePage";
import { BuildingPicker } from "./components/BuildingPicker/BuildingPicker";
import { ConnectionFilter } from "./components/popups/ConnectionFilter/ConnectionFilter";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { CalendarView } from "./components/CalendarView/CalendarView";
import { LocationPicker } from "../shared/components/LocationPicker/LocationPicker";
import { Modal } from "@shared/components/Modal/Modal";
import { StatusMessage } from "@shared/components/StatusMessage/StatusMessage";
import { UserApp } from "./UserApp";
import { logout } from "@shared/services/auth/logout";
import { confirmDialog } from "@shared/utils/confirmDialog";
import { Msal } from "@shared/services/auth/settings/MsalInstance";
import { tryGetClaims } from "@shared/services/auth/tryGetClaims";
import { NoBuildingsView } from "./components/NoBuildingsView/NoBuildingsView";
import { Login } from "@shared/components/Login/Login";
import { MpqIcon } from "@shared/components/MpqIcon/MpqIcon";
import { initializeAppInsights } from "@shared/utils/AppInsights";
import { MenuBar } from "@shared/components/MenuBar/MenuBar";
import {
  ConnectionsPage,
  ConnectionsPageSidePanel
} from "./components/popups/ConnectionsPage/ConnectionsPage";
import { ConnectionDeleteWindow } from "./components/ConnectionDeleteWindow/ConnectionDeleteWindow";
import { ConnectionAddWindow } from "./components/ConnectionAddWindow/ConnectionAddWindow";
import { PopupManager } from "./components/PopupManager/PopupManager";
import { ConnectionErrorWindow } from "./components/ConnectionErrorWindow/ConnectionErrorWindow";
import { RulesBox } from "./components/RulesBox/RulesBox";
import { BlockScrollBinding } from "@shared/bindings/blockScroll";
import { SelectBox } from "@shared/components/SelectBox/SelectBox";
import { FloorMap } from "@shared/components/FloorMap/FloorMap.js";
import { SidePanel } from "@app/components/SidePanel/SidePanel.js";
import { NotificationsView } from "./components/NotificationsView/NotificationsView";
import "./utilities.scss"; // Keep this as the last import so that its styling is not overriden.
import { login } from "@shared/services/Login.js";
import { PageContent } from "./components/PageContent/PageContent";
import { HomeViewer } from "./components/HomeViewer/HomeViewer.js";
import { ProfilePage } from "./components/ProfilePage/ProfilePage";
import CurrentUserApiModel from "@shared/apimodels/CurrentUserApiModel.js";
import { loadAppFeatures } from "./services/AppFeatures.js";
import { ProfilePictureUpload } from "./components/popups/ProfilePictureUpload/ProfilePictureUpload";
import { Avatar } from "./components/Avatar/Avatar.js";
import { DefaultOfficeView } from "./components/DefaultOfficeView/DefaultOfficeView";
import { DayOverview } from "./components/DayOverview/DayOverview";
import { CheckInIndicator } from "@shared/components/CheckIn/CheckInIndicator";
import { CheckInStatusSelector } from "./components/CalendarView/tiles/CheckInTile/CheckInStatusSelector/CheckInStatusSelector";
import { ConfirmationWindow } from "@shared/components/ConfirmationWindow/ConfirmationWindow";
import { lottieBinding } from "./utils/LottieBinding";
import {
  scrollCardIntoViewBinding,
  scrollIntoViewBinding,
  scrollToTopBinding
} from "./components/DefaultOfficeView/scrollIntoViewBinding";
import { ExpandTextareaBinding } from "@shared/bindings/expandTextarea";
import { processPossibleConsentRedirectResult } from "@shared/utils/processPossibleConsentRedirectResult";
import { ConsentFlowErrorModal } from "./components/ConsentFlowErrorModal/ConsentFlowErrorModal";
import { ConsentRedirectResult } from "@shared/utils/ConsentRedirectResult";
import { MeetApi } from "@shared/services/MeetApi";
import { RedirectPopup } from "@shared/components/RedirectPopup/RedirectPopup";
import { ParkingLotPicker } from "@app/components/Parking/ParkingLotPicker/ParkingLotPicker";
import { ParkingReservation } from "@app/components/Parking/ParkingReservation/ParkingReservation";
import { BookButtonIcon } from "@app/components/BookButtonIcon/BookButtonIcon";
import { SearchBar } from "@shared/components/SearchBar/SearchBar";
import {
  flatpickrBinding,
  flatpickrBindingTime
} from "@app/utils/AppFlatpickrBinding";
import { WorkSpaceWizard } from "@app/components/WorkSpaceWizard/WorkSpaceWizard";
import { WorkDayMainPage } from "./components/WorkDayMainPage/WorkDayMainPage";
import { NowPage } from "./components/NowPage/NowPage";
import { AvatarsGroup } from "./components/AvatarsGroup/AvatarsGroup";
import { MeetingCard } from "./components/DayOverview/MeetingCard/MeetingCard";
import { ConnectCalendarView } from "./components/DayOverview/ConnectCalendarView/ConnectCalendarView";
import { WorkspaceNodeSummary } from "@shared/components/Workspace/WorkspaceNodeSummary/WorkspaceNodeSummary";
import { WorkspaceCreator } from "@shared/components/Workspace/WorkspaceCreator/WorkspaceCreator";
import { RoomsList } from "./components/MeetingCreation/RoomPicker/RoomsList/RoomsList";
import { WorkDayWizard } from "@app/components/WorkDayWizard/WorkDayWizard";
import { DeskPicker } from "@shared/components/DeskPicker/DeskPicker";
import { BookRoom } from "./components/BookRoom/BookRoom";
import { NewFeatureAnnouncement } from "./components/HomeViewer/NewFeatureAnnouncement/NewFeatureAnnouncement";
import { Extras } from "./components/Extras/Extras";

function initRegistration() {
  const app = ko.observable(null);
  //register components
  HomeViewer.register(ko);
  WorkDayMainPage.register(ko);
  AvatarsGroup.register(ko);
  SearchBar.register(ko);
  ParkingReservation.register(ko);
  ParkingLotPicker.register(ko);
  ProfilePage.register(ko);
  PageContent.register(ko);
  ProfilePictureUpload.register(ko);
  WelcomePage.register(ko);
  BuildingPicker.register(ko);
  ConnectionFilter.register(ko);
  CalendarView.register(ko);
  LocationPicker.register(ko);
  NoBuildingsView.register(ko);
  MenuBar.register(ko);
  ConnectionsPage.register(ko);
  ConnectionDeleteWindow.register(ko);
  ConnectionAddWindow.register(ko);
  WorkSpaceWizard.register(ko);
  PopupManager.register(ko);
  ConnectionErrorWindow.register(ko);
  RulesBox.register(ko);
  SidePanel.register(ko);
  CheckInStatusSelector.register(ko);
  ConfirmationWindow.register(ko);
  CheckInIndicator.register(ko);
  DefaultOfficeView.register(ko);
  StatusMessage.register(ko);
  Modal.register(ko);
  Login.register(ko);
  SelectBox.register(ko);
  MpqIcon.register(ko);
  BookButtonIcon.register(ko);
  BlockScrollBinding.register(ko);
  FloorMap.register(ko);
  ExpandTextareaBinding.register(ko);
  RedirectPopup.register(ko);
  ConnectionsPageSidePanel.register(ko);
  NotificationsView.register(ko);
  Avatar.register(ko);
  DayOverview.register(ko);
  ConsentFlowErrorModal.register(ko);
  NowPage.register(ko);
  MeetingCard.register(ko);
  ConnectCalendarView.register(ko);
  WorkspaceNodeSummary.register(ko);
  WorkspaceCreator.register(ko);
  RoomsList.register(ko);
  WorkDayWizard.register(ko);
  DeskPicker.register(ko);
  BookRoom.register(ko);
  NewFeatureAnnouncement.register(ko);
  Extras.register(ko);

  ko.bindingHandlers.lottie = lottieBinding;
  ko.bindingHandlers.scrollIntoView = scrollIntoViewBinding;
  ko.bindingHandlers.scrollToTop = scrollToTopBinding;
  ko.bindingHandlers.scrollCardIntoView = scrollCardIntoViewBinding;
  ko.bindingHandlers.flatpickr = flatpickrBinding;
  ko.bindingHandlers.flatpickrTime = flatpickrBindingTime;

  const modalModel = ko.observable(null);
  Mediator().subscribe(Channels.OpenModal, modalModel);
  Mediator().subscribe(Channels.CloseModal, () => {
    modalModel(null);
  });

  const loginPrompt = ko.observable(false);
  Mediator().subscribe(Channels.OpenLogin, () => loginPrompt(true));

  const initApp = () => {
    const consentRedirectResult = processPossibleConsentRedirectResult();
    console.log(`Initializing app...`);

    if (process.env.APP_VERSION) {
      console.log(`version ${process.env.APP_VERSION}`);
    }

    login()
      //Check the consent redirect result and set user preferences
      .then(async user => {
        if (consentRedirectResult === ConsentRedirectResult.Success) {
          try {
            await MeetApi.setUserPreference({ enableCalendar: true });
          } catch (e) {
            throw Error.PreferenceOn;
          }
        }

        return user;
      })
      // Take the generic enabled features for the subscription
      // but enrich them with user app specific configurations
      .then(async user => {
        try {
          await loadAppFeatures();
        } catch (e) {
          // Assume all features are turned off, don't
          // warn user for now.
          console.error(e);
        }
        return user;
      })
      .then(userDto => {
        const user = new CurrentUserApiModel(userDto);
        try {
          // Create the app
          app(UserApp(user));
          Mediator().publish(
            Channels.ConsentRedirectResult,
            consentRedirectResult
          );
        } catch (err) {
          console.error(err);
          return confirmDialog({
            question:
              "Something went wrong rendering the app. Get in touch with support@mapiq.com",
            confirm: "Retry"
          }).then(() => {
            document.location.reload();
          });
        }
      })
      .catch(e => {
        return confirmDialog({
          question:
            "We could not reach the server. You can retry, or get in touch with support@mapiq.com",
          confirm: "Reload",
          cancel: "Sign out"
        })
          .then(() => {
            document.location.reload();
          })
          .catch(() => {
            logout();
            confirmDialog({ question: "Signing out..." });
          });
      });
  };

  // SET UP
  const whenSignedIn = () => {
    // Track page view and inject sub. id in all calls
    const claims = tryGetClaims();
    const subId = claims?.subscription_id;
    initializeAppInsights(subId);

    // Boot the user app
    initApp();
  };

  const whenNotSignedIn = () => {
    // Track unauthenticated pageview
    initializeAppInsights(null);
  };

  // Trigger the sign in logic
  Msal(whenSignedIn, whenNotSignedIn);
  ko.applyBindings(
    {
      modalModel,
      loginPrompt,
      loginTitle: "A meaningful day at work",
      loginText:
        "Mapiq helps streamline your workdays and find the right facilities for you and your team.",
      app,
      logoutClick: logout,

      blockScroll: app.maybeMap(a => a.popupManager.popupVisible(), false)
    },
    document.documentElement
  );
}

window.ko = ko; // DEBUG
initRegistration();
