import "./MenuBar.scss";
import template from "./MenuBar.template.html";
import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { Permissions } from "@admin/components/SettingsViewer/views/Roles.js";
import { ConnectionsStoreSingleton } from "@app/services/ConnectionStore";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { NotificationStoreSingleton } from "@app/services/NotificationStore";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { getAppFeatures } from "@app/services/AppFeatures";
import { getDateKey, isDateInstance } from "@shared/utils/dateHelpers";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames } from "@app/tracking/EventNames";

const eq = a => b => a === b;

// Warning: these must be in sync with the config passed to URLNavigation's `init`
//          from UserApp
const PageId = {
  Calendar: "",
  Profile: "profile"
};

const PanelId = {
  Connections: "connections",
  Notifications: "notifications"
};

let workdayRestPath = [getDateKey(new Date())];

const MenuBarVM = ({ icon, loading, imageHash }) => {
  const urlNavigation = UrlNavigationSingleton();
  const connectionStore = ConnectionsStoreSingleton();
  const notificationStore = NotificationStoreSingleton();
  const meetingsFeature = FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW;
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const activePageId = urlNavigation.activePageId;
  const occupancyFeature = FeatureNames.OCCUPANCY_SENSOR;

  const enableNowPage =
    (getAppFeatures().has(meetingsFeature) &&
      getAppFeatures().has(FeatureNames.HERE_NOW) &&
      getAppFeatures().get(meetingsFeature).enabledForUsers === true) ||
    (getAppFeatures().has(occupancyFeature) &&
      getAppFeatures().has(FeatureNames.HERE_NOW) &&
      getAppFeatures().has(FeatureNames.HERE_NOW_V2));
  if (enableNowPage) {
    PageId["Now"] = "now";
  }

  const activeViewId = ko
    .pureComputed(() => urlNavigation.activePanelId() ?? activePageId())
    .extend({ deferred: true });

  const showConnectionsIndicator = ko.pureComputed(() =>
    connectionStore.allPendingIncoming().length ? true : false
  );

  const showNotificationsIndicator = ko.pureComputed(
    () =>
      notificationStore.allUnreadNotifications().length &&
      activeViewId() !== PanelId.Notifications
  );

  const calendarRestPathComputation = ko
    .computed(() => {
      if (!hasWorkdayFeature) return [];

      const _restPath = urlNavigation.restPath();

      if (isDateInstance(_restPath)) workdayRestPath = _restPath;

      return workdayRestPath;
    })
    .extend({ deferred: true });

  return {
    loading,
    icon,

    // Navigation actions
    showCalendarPage: () =>
      urlNavigation.navigate(
        PageId.Calendar,
        null,
        calendarRestPathComputation()
      ),
    showConnectionsPage: () =>
      urlNavigation.navigate(null, PanelId.Connections),
    showNotificationsPage: () =>
      urlNavigation.navigate(null, PanelId.Notifications),
    showProfilePage: () => urlNavigation.navigate(PageId.Profile, null),
    showNowPage: () => {
      publishAIEvent(EventNames.HereNowPageOpened);
      urlNavigation.navigate(PageId.Now, null);
    },

    // Display states
    calendarActive: activeViewId.map(eq(PageId.Calendar)),
    connectionsActive: activeViewId.map(eq(PanelId.Connections)),
    notificationsActive: activeViewId.map(eq(PanelId.Notifications)),
    profileActive: activeViewId.map(eq(PageId.Profile)),
    nowActive: activeViewId.map(eq(PageId.Now)),

    // Badges
    showConnectionsIndicator,
    showNotificationsIndicator,

    // Other
    imageHash,
    userIsAdmin: Permissions.mayAccessAdminPage(),
    openAdminPortal: () => {
      const adminUrl = process.env.MAPIQ_ADMIN_BASE_URL || "/admin";
      window.open(
        adminUrl,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    enableNowPage,
    dispose: calendarRestPathComputation.dispose
  };
};

export const MenuBar = KOComponent("menu-bar", MenuBarVM, template);
