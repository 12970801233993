import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";

export const publishAIEvent = (aiEvent, aiExtraProps, aiResultType) => {
  Mediator().publish(Channels.TrackAIEvent, [
    aiEvent,
    aiResultType,
    aiExtraProps
  ]);
};
