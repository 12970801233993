export const Suggestion = (
  suggestionId,
  suggestion,
  desk,
  floor,
  area,
  visit = null
) => ({
  suggestionId,
  booked: suggestion.booked,
  isEnabled: desk.isEnabled,
  explanation: suggestion.explanation,
  score: suggestion.score,
  deskName: desk.name,
  areaName: area.name,
  floorName: floor.name,
  deskId: desk.id,
  visit
});
