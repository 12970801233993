import template from "./OfficePicker.template.html";
import "./OfficePicker.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { OfficeVM } from "@app/viewmodels/WorkSpace/OfficeVM";
import { byPropAsc } from "@shared/utils/sort";
import { naturalStringSortAsc } from "@shared/utils/sortHelper";
import { groupByAvailability } from "@shared/utils/OfficeDay/helpers";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

const OfficePickerVM = ({
  workspace,
  date,
  selectedOfficeId,
  userSelectedDifferentOffice,
  selectedLocationId,
  selectedDeskIds,
  goHome,
  selectedInvitees,
  usePendingOrConfirmedMemberCount: _usePendingOrConfirmedMemberCount
}) => {
  const groups = ko.pureComputed(() => {
    return groupByAvailability(
      LocationStoreSingleton()
        .getBuildings()
        .sort(byPropAsc("name", naturalStringSortAsc))
        .map(office => {
          const usePendingOrConfirmedMemberCount =
            _usePendingOrConfirmedMemberCount &&
            office.id === workspace?.buildingId;

          return OfficeVM({
            office,
            date,
            selectedOfficeId,
            userSelectedDifferentOffice,
            selectedLocationId,
            selectedDeskIds,
            goHome,
            selectedInvitees,
            usePendingOrConfirmedMemberCount
          });
        })
    );
  });

  return { groups };
};

export const OfficePicker = KOComponent(
  "office-picker",
  OfficePickerVM,
  template
);
