import template from "./LocationPicker.template.html";
import "./LocationPicker.scss";

import ko from "@shared/knockout/extended";

import { format } from "date-fns";
import { KOComponent } from "@shared/knockout/KOComponent";
import { availabilityLocationData } from "../../viewmodels/AvailabilityLocationData";
import { injectLineBreaksAtSlash } from "@shared/utils/text-helpers";
import { notEmpty } from "@shared/utils/capacityHelper";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

const LocationPickerVM = ({
  date,
  onDone,
  initialSelection = null,
  // Admin only features
  // 1. Return true to show a Home indicator next to the user's default building
  showDefaultBuildingIndicatorForBuildingId = buildingId => false,
  // 2. If any locations in the location store are in restricted areas,
  //    use this function to indicate that with a Lock icon
  showRestrictedIndicatorForLocationId = locationId => false
}) => {
  const locationStore = LocationStoreSingleton();
  const title = "Pick a workspace";
  const offices = ko
    .pureComputed(locationStore.getBuildings)
    .filter(notEmpty)
    .map(bs => bs.sort((b1, b2) => b1.name.localeCompare(b2.name)))
    .mapArray(o =>
      Object.assign(
        {
          showDefaultBuildingIndicator: ko.pureComputed(() =>
            showDefaultBuildingIndicatorForBuildingId(o.id)
          ),
          showRestrictedIndicator: ko.pureComputed(() =>
            showRestrictedIndicatorForLocationId(o.id)
          )
        },
        o,
        availabilityLocationData(date, o)
      )
    );

  const preferred = locationStore.getPreferredBuilding() || { id: null };

  // Preselect the preferred office if it's not closed
  const selectedOffice = ko.observable(
    offices().find(o => !o.closed && o.id === preferred.id) || null
  );
  const selectedFloor = ko.observable(null);
  const selectedArea = ko.observable(null);

  const visibleOffices = ko.observable(!selectedOffice());
  const visibleFloors = ko.observable(!selectedFloor());
  const visibleAreas = ko.observable(!selectedArea());

  const floors = selectedOffice.maybeMap(
    ({ closed, floors }) =>
      closed()
        ? []
        : floors
            .filter(notEmpty)
            .sort((f1, f2) => f1.number - f2.number)
            .map(f =>
              Object.assign(
                {
                  showRestrictedIndicator: ko.pureComputed(() =>
                    showRestrictedIndicatorForLocationId(f.id)
                  )
                },
                f,
                availabilityLocationData(date, f)
              )
            ),
    []
  );

  const areas = selectedFloor.maybeMap(
    ({ workAreas, closed }) =>
      closed()
        ? []
        : workAreas
            .filter(notEmpty)
            .sort((a1, a2) => a1.name.localeCompare(a2.name))
            .map(w =>
              Object.assign(
                {
                  showRestrictedIndicator: ko.pureComputed(() =>
                    showRestrictedIndicatorForLocationId(w.id)
                  )
                },
                w,
                availabilityLocationData(date, w)
              )
            ),
    []
  );

  const selectOffice = (data, event) => {
    selectedOffice(data);
    const scrollElement = document.getElementsByClassName("Card-scrollbody");
    scrollElement[0].scrollTo(0, 0);
  };

  const selectFloor = (data, event) => {
    selectedFloor(data);
    const scrollElement = document.getElementsByClassName("Card-scrollbody");
    scrollElement[0].scrollTo(0, 0);
  };

  const selectArea = (data, event) => {
    selectedArea(data);
  };
  const initialSelectionId = ko.unwrap(initialSelection);
  // Handle initial selection before attaching behavior through subscriptions
  if (initialSelectionId) {
    // Note: pre-selecting the last level doesn't really work with this UI...
    //       There's only the automatic opening of the summary, no manual
    //       confirmation. Setting the complete slection here would make it very
    //       unclear for the user how to proceed.
    //       Current solution is to only select the layer above, and open the last
    //       layer.

    const officeDto = locationStore.getBuildingForId(initialSelectionId);
    const floorDto = locationStore.getFloorForId(initialSelectionId);
    const areaDto = locationStore.getWorkAreaForId(initialSelectionId);

    if (officeDto) {
      const officeVm = offices().find(o => o.id === officeDto.id);

      if (officeVm && floorDto) {
        visibleOffices(false); // Close the office section
        selectOffice(officeVm);
      }
    }

    if (floorDto) {
      const floorVm = floors().find(o => o.id === floorDto.id);
      if (floorVm && areaDto) {
        visibleFloors(false);
        selectFloor(floorVm);
      }
    }
  }

  const officeSub = selectedOffice.subscribe(newOffice => {
    selectedFloor(null);
    if (newOffice && !newOffice.closed()) {
      visibleOffices(false);
      visibleFloors(true);
    }
  });

  const floorSub = selectedFloor.subscribe(newFloor => {
    selectedArea(null);
    if (newFloor && !newFloor.closed()) {
      visibleFloors(false);
      visibleAreas(true);
    }
  });

  const areaSub = selectedArea.subscribe(newArea => {
    if (newArea && !newArea.closed()) {
      visibleAreas(false);
    }
  });

  const toggleOffices = () => visibleOffices(!visibleOffices());
  const toggleFloors = () => visibleFloors(!visibleFloors());
  const toggleAreas = () => visibleAreas(!visibleAreas());

  // Auto select in lists of 1
  const autoSelectSubs = [];

  autoSelectSubs.push(
    offices.subscribe(os => {
      if (os.length === 1) {
        selectedOffice(os[0]);
      }
    })
  );

  autoSelectSubs.push(
    floors.subscribe(fs => {
      if (fs.length === 1) {
        selectedFloor(fs[0]);
      }
    })
  );

  autoSelectSubs.push(
    areas.subscribe(as => {
      if (as.length === 1) {
        selectedArea(as[0]);
      }
    })
  );

  const deepestSelection = ko.pureComputed(
    () => selectedArea() || selectedFloor() || selectedOffice()
  );

  const autoCloseSub = deepestSelection.subscribe(location => {
    if (!location) return;
    if (location.closed()) return;

    const hasFloors = location.floors && location.floors.length > 0;
    const hasAreas = location.workAreas && location.workAreas.length > 0;
    const hasSpaceAvailable = location.availability() > 0;

    const isBookable = !hasFloors && !hasAreas && hasSpaceAvailable;

    if (isBookable) onDone(location.id);
  });

  const onBack = () => {
    onDone(null);
  };

  return {
    date,
    dateHeader: format(date, "EEEE d MMM"),

    selectedOffice,
    selectedFloor,
    selectedArea,

    visibleOffices,
    visibleFloors,
    visibleAreas,
    toggleOffices,
    toggleFloors,
    toggleAreas,

    offices,
    floors,
    areas,

    onBack,
    title,

    selectArea,
    selectFloor,
    selectOffice,
    injectLineBreaksAtSlash,
    // Method called by knockout when removing the component from the DOM
    dispose: () => {
      officeSub.dispose();
      floorSub.dispose();
      areaSub.dispose();

      autoSelectSubs.forEach(s => s.dispose());
      autoCloseSub.dispose();
    }
  };
};

export const LocationPicker = KOComponent(
  "location-picker",
  LocationPickerVM,
  template
);
