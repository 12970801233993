// import svgPanZoom from "svg-pan-zoom";
import { StateUpdate } from "@app/utils/svg-helpers.js";
import ko from "@shared/knockout/extended";

// Takes an SVG string and an observable SVG State and makes sure the image is rendered
// correctly in the DOM
export const staticSvg = {
  init: (el, va) => {
    const {
      svgString,
      typeDefaults,
      nodeOverrides,
      showMeetingRooms = false
    } = va();

    // Safegaurds against accidentally passing observable svg strings
    if (ko.isSubscribable(svgString))
      throw "Binding does not support dynamic SVG";

    el.innerHTML = svgString;

    const svgEl = el.querySelector("svg");
    if (!svgEl) throw "SVG string did not contain a valid SVG element";

    // The viewbBox attribute was removed from the svg image by request of the mobile
    // team.
    // Problem: without a viewbox the SVG isn't responsive when resizing. And we're
    // currently showing it in multiple thumbnails on the screen.
    // Calculating it client-side fails when the element is in a hidden part of the DOM.
    // 1: Manage our own viewbox...
    const bbox = svgEl.getBBox();
    const rect = svgEl.getBoundingClientRect();

    // If we know the bbox of the svg coordinate system and the size in the view,
    // we can add a padding to the viewBox in pixels:
    if (bbox.width && rect.width) {
      const PAD = 5; // 5px border
      const scale = bbox.width / rect.width;
      const buffer = scale * PAD;

      svgEl.setAttribute(
        "viewBox",
        `${bbox.x - buffer} ${bbox.y - buffer} ${bbox.width +
          2 * buffer} ${bbox.height + 2 * buffer}`
      );
    } else {
      // If the svg is not already in view, the bbox will be 0-width
      // We use a trick to get the viewBox: we draw the element in a hidden
      // element and immediately remove it.
      const backupBbox = bruteForceSvgBbox(svgEl);

      // If this viewbox has a width, we can assume it to be correct.
      if (backupBbox.width) {
        // We can't make a pixel based padding, but we can add a padding that at least
        // corrects for our centered strokes.
        const PAD_SVG = 2;
        svgEl.setAttribute(
          "viewBox",
          `${backupBbox.x - PAD_SVG} ${backupBbox.y -
            PAD_SVG} ${backupBbox.width + 2 * PAD_SVG} ${backupBbox.height +
            2 * PAD_SVG}`
        );
      }
    }

    // 2: Remove vector effect
    svgEl
      .querySelectorAll("[vector-effect='non-scaling-stroke']")
      .forEach(el => {
        el.removeAttribute("vector-effect");
      });

    // 3: Unhide meeting rooms (added hidden for backwards compatibility)
    if (showMeetingRooms) {
      const meetingRoomLayer = svgEl.querySelector(
        "[data-type='meetingRoomsLayer']"
      );
      if (meetingRoomLayer) meetingRoomLayer.removeAttribute("visibility");
    }

    // 4: Unhide facilities (added hidden for backwards compatibility)
    const facilityLayers = svgEl.querySelector("[data-type='facilityLayers']");

    if (facilityLayers) facilityLayers.removeAttribute("visibility");

    const updateLogic = ko
      .computed(() => {
        const update = StateUpdate({
          typeDefaults: ko.unwrap(typeDefaults),
          nodeOverrides: ko.unwrap(nodeOverrides)
        });

        update.runIO(el);
      })
      .extend({ deferred: true });

    ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
      updateLogic.dispose();
    });
    return { controlsDescendantBindings: true };
  }
};

const bruteForceSvgBbox = svgEl => {
  const hiddenDiv = document.createElement("div");
  hiddenDiv.setAttribute(
    "style",
    "position:absolute; visibility:hidden; width:0; height:0"
  );
  document.body.appendChild(hiddenDiv);

  const tempSvg = svgEl.cloneNode(true);
  hiddenDiv.appendChild(tempSvg);

  const bb = tempSvg.getBBox();
  document.body.removeChild(hiddenDiv);
  return bb;
};
