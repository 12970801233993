import { isSameDay, startOfDay } from "date-fns";
import ko from "@shared/knockout/extended";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { compareStatus } from "@shared/services/CheckInStatus";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

export function ColleagueTodayVM(connectedColleague, date, shift) {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const locationStore = LocationStoreSingleton();
  const todayShift = connectedColleague.shifts.find(s =>
    isSameDay(s.day, date)
  );

  const defaultOffice = ko.pureComputed(() =>
    shift()
      ? locationStore.get(shift().buildingId)
      : locationStore.getPreferredBuilding()
  );

  if (!todayShift) return null;

  const { floorName, areaName } = todayShift;

  return {
    id: connectedColleague.id,
    name: connectedColleague.name,
    email: connectedColleague.email,
    initials: connectedColleague.initials,
    imageHash: connectedColleague.imageHash,
    nodeId: todayShift.nodeId,
    buildingId: todayShift.buildingId,
    buildingName: todayShift.buildingName,
    floorId: todayShift.floorId,
    floorName: todayShift.floorName,
    areaId: todayShift.areaId,
    areaName: todayShift.areaName,

    locationLabel:
      floorName && areaName ? `${floorName}, ${areaName}` : floorName || "",

    deepestLocationLabel: areaName ? areaName : floorName || "",

    isInDefaultOffice: defaultOffice.maybeMap(
      ({ id }) => id === todayShift.buildingId,
      false
    ),
    checkInStatus: ko.pureComputed(() =>
      isSameDay(date, startOfDay(new Date()))
        ? checkInStatusStore.getById(connectedColleague.id)
        : null
    )
  };
}

export const WorkdayColleagueTodayVM = (
  connectedColleague,
  date,
  userWorkday
) => {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const locationStore = LocationStoreSingleton();

  const todayWorkday = connectedColleague.workdays.find(s =>
    isSameDay(s.startDate, date)
  );

  const todayWorkspace = connectedColleague.shifts.find(s =>
    isSameDay(s.day, date)
  );

  const userWorkdayOfficeId = ko.pureComputed(
    () => ko.unwrap(userWorkday)?.nodeId
  );

  const defaultOffice = ko.pureComputed(() =>
    userWorkdayOfficeId()
      ? locationStore.get(userWorkdayOfficeId())
      : locationStore.getPreferredBuilding()
  );

  const isInDefaultOffice = defaultOffice.maybeMap(
    ({ id }) => id === todayWorkday.nodeId,
    false
  );

  return {
    id: connectedColleague.id,
    name: connectedColleague.name,
    email: connectedColleague.email,
    initials: connectedColleague.initials,
    imageHash: connectedColleague.imageHash,
    buildingId: todayWorkday.nodeId,
    buildingName: todayWorkday.nodeName,
    deepestLocationLabel: todayWorkspace
      ? todayWorkspace.areaName
        ? todayWorkspace.areaName
        : todayWorkspace.floorName || ""
      : "",

    isInDefaultOffice,

    checkInStatus: ko.pureComputed(() =>
      isSameDay(date, startOfDay(new Date()))
        ? checkInStatusStore.getById(connectedColleague.id)
        : null
    )
  };
};

export const sortByDefaultOffice = (a, b) => {
  const aInDefault = a.isInDefaultOffice();
  const bInDefault = b.isInDefaultOffice();

  if (aInDefault === bInDefault) return 0;

  if (aInDefault) return -1;
  if (bInDefault) return 1;
};

export const sortByCheckInStatus = (a, b) =>
  compareStatus(a.checkInStatus(), b.checkInStatus());
