import "./BookRoomSummary.scss";
import template from "./BookRoomSummary.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { openConfirmationWindow } from "../../MeetingCreation/utils";
import { MeetingService } from "@app/services/MeetingService";
import { UserApp } from "@app/UserApp";
import { AppApi } from "@app/services/AppApi";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { differenceInMinutes, format } from "date-fns";

const BookRoomSummaryVM = ({ params }) => {
  const {
    showSidePanelCloseButton,
    showBookRoomSummary,
    room,
    startTime,
    endTime,
    date
  } = params;
  const hideFooter = ko.observable(false);
  showSidePanelCloseButton(false);
  const scrollElement = document.getElementsByClassName("SidePanel-body");
  scrollElement[0].scrollTo(0, 0);

  const toggleFooter = () => {
    if (screen.width <= 500) hideFooter(!hideFooter());
  };

  const title = ko.observable(room.displayName);
  const description = ko.observable("");
  const isOnlineMeeting = ko.observable(false);

  const selectedAttendees = ko.observableArray([]);
  const showAttendeesPicker = ko.observable(false);
  const toggleAttendeesPicker = () => {
    showAttendeesPicker(!showAttendeesPicker());
    const scrollElement = document.getElementsByClassName("SidePanel-body");
    scrollElement[0].scrollTo(0, 0);
  };

  const attendeesToShow = ko.pureComputed(() =>
    selectedAttendees().length < 9
      ? selectedAttendees()
      : selectedAttendees()
          .slice(0, 8)
          .concat({
            displayName: `+${selectedAttendees().length - 8}`,
            color: "#677884",
            backgroundColor: "rgba(0, 0, 0, 0.03)"
          })
  );

  const loadingCreation = ko.observable(false);
  const enableButton = ko.pureComputed(() => title() && !loadingCreation());
  const userEmail = UserApp.resolveUser().email;

  const dirty = ko.pureComputed(
    () =>
      description().length ||
      selectedAttendees().length ||
      isOnlineMeeting() ||
      title() !== room.displayName
  );

  const goBack = () => {
    showSidePanelCloseButton(true);
    showBookRoomSummary(false);
  };

  const closePanel = () => {
    showSidePanelCloseButton(true);
    UrlNavigationSingleton().navigate(null, null);
  };

  const close = () => {
    dirty() ? openConfirmationWindow(closePanel) : closePanel();
  };

  const back = () => {
    dirty() ? openConfirmationWindow(goBack) : goBack();
  };

  const createMeeting = () => {
    loadingCreation(true);
    MeetingService()
      .createMeeting(
        selectedAttendees,
        startTime,
        endTime,
        title,
        description,
        [room],
        isOnlineMeeting,
        userEmail
      )
      .then(() => {
        publishAIEvent(
          EventNames.MeetingCreated,
          {
            origin: "BookRoomNow",
            duration: differenceInMinutes(endTime, startTime)
          },
          ResultTypes.Success
        );
        Mediator().publish(Channels.HideBookedRoom, room);
        showSidePanelCloseButton(true);
        showBookRoomSummary(false);
        history.back();
      })
      .catch(() => {
        publishAIEvent(
          EventNames.MeetingCreated,
          {
            origin: "BookRoomNow",
            duration: differenceInMinutes(endTime, startTime)
          },
          ResultTypes.Fail
        );
      })
      .finally(() => loadingCreation(false));
  };

  const bookingInfo = `${format(startTime, "eeee d MMMM HH:mm")} - ${format(
    endTime,
    "HH:mm"
  )}`;

  return {
    back,
    close,
    enableButton,
    title,
    description,
    isOnlineMeeting,
    toggleAttendeesPicker,
    selectedAttendees,
    showAttendeesPicker,
    attendeesToShow,
    toggleFooter,
    hideFooter,
    bookingInfo,
    room,
    createMeeting,
    loadingCreation,
    date,
    attendeesSearchApiMethod: AppApi.searchInvitees
  };
};

export const BookRoomSummary = KOComponent(
  "book-room-summary",
  BookRoomSummaryVM,
  template
);
