import { LocationType } from "@app/apimodels/LocationType";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { WorkdayStatus } from "@shared/utils/OfficeDay/status";

/**
 * This VM is only built to make it easier to apply HM related changes if the workday flag is on and to support the previous implementation as well
 * The VM returns the object with the attributes that are needed, and if the flag is on, it returns from the workday and otherwise from the workspace object
 * @param {Date} date - The date for which the workday/workspace is required
 */

const getLocationType = (isOfficeDay, isRemote) =>
  isOfficeDay
    ? LocationType.Local
    : isRemote
    ? LocationType.Remote
    : LocationType.Unknown;

export const OfficeDayVM = date => {
  if (getFeatures().has(FeatureNames.WORK_DAY)) {
    const workday = WorkdayReservationsStoreSingleton().getForDate(date)[0];

    const isOfficeDay = Boolean(workday?.nodeId);
    const isRemote = [
      WorkdayStatus.WorkingRemotely,
      WorkdayStatus.NotWorking
    ].some(status => workday?.status === status);

    return {
      buildingId: workday?.nodeId ?? null,
      buildingName: workday?.nodeName ?? null,
      isOfficeDay,
      isRemote,
      locationType: getLocationType(isOfficeDay, isRemote)
    };
  } else {
    const workspace = WorkspaceServiceSingleton().getConfirmedForDate(date)[0];

    const isOfficeDay = Boolean(workspace);
    const isRemote = !isOfficeDay;

    return {
      buildingId: workspace?.buildingId,
      buildingName: workspace?.buildingName,
      isOfficeDay,
      isRemote,
      locationType: getLocationType(isOfficeDay, isRemote)
    };
  }
};
