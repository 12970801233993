import "./RoomDetails.scss";
import template from "./RoomDetails.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { EquipmentGroup } from "./EquipmentGroup/EquipmentGroup";
import { pl } from "@shared/utils/pluralize";
import { AppApi } from "@app/services/AppApi";
import ko from "@shared/knockout/extended";
import { format } from "date-fns";
import { capitalizeSentence } from "@shared/utils/text-helpers";

const suitableForMap = {
  InformalMeeting: "informal meeting",
  FormalMeeting: "formal meeting",
  Brainstorm: "brainstorm",
  Videoconference: "videoconference",
  Videconference: "videoconference",
  PrivateCall: "private call",
  Collaboration: "collaboration",
  Presentation: "presentation",
  Conversation: "conversation",
  OneToOne: "one-on-one",
  Lecture: "lecture"
};

const RoomDetailsVM = ({
  room,
  date,
  showHeader = true,
  showAvailabilityData = false
}) => {
  const roomTitle = room.displayName;
  const roomLocation = `${room.buildingName}, ${room.floorName}`;
  const roomCapacity = `${room.capacity} ${pl("seat", room.capacity)}`;
  const roomSuitableFor = room.activities?.length
    ? capitalizeSentence(room.activities.map(a => suitableForMap[a]).join(", "))
    : null;
  const roomEquipment = room.equipment?.length ? room.equipment : null;

  const onBack = () => {
    Mediator().publish(Channels.ToggleRoomDetails, null);
  };

  const pictureContainer = document.getElementById(
    "RoomDetails-PictureContainer"
  );

  const hasPicture = ko.observable(!!room.imageHash);
  const picture = ko.observable(null);
  const loadingPicture = ko.observable(false);

  if (hasPicture()) {
    loadingPicture(true);
    AppApi.getMeetingRoomPicture(
      room.buildingId,
      room.imageHash,
      pictureContainer.offsetWidth
    )
      .then(blob => {
        picture(URL.createObjectURL(blob));
      })
      .catch(e => {
        hasPicture(false);
      })
      .finally(() => {
        loadingPicture(false);
      });
  }

  return {
    onBack,
    header: date ? format(date, "d MMMM") : "Room info",
    roomTitle,
    roomLocation,
    roomCapacity,
    roomSuitableFor,
    roomEquipment,
    picture,
    hasPicture,
    loadingPicture,
    showHeader,
    roomScheduleLabel: room.roomScheduleLabel,
    roomSensorLabel: room.roomSensorLabel,
    roomSensorSubtitle: room.roomSensorSubtitle,
    roomScheduleColor: room.roomScheduleColor,
    roomSensorColor: room.roomSensorColor,
    roomScheduleSubtitleDetailsPage: room.roomScheduleSubtitleDetailsPage,
    showAvailabilityData
  };
};

export const RoomDetailsComponent = KOComponent(
  "room-details",
  RoomDetailsVM,
  template
);

export const RoomDetails = {
  ...RoomDetailsComponent,
  register: ko => {
    RoomDetailsComponent.register(ko);
    EquipmentGroup.register(ko);
  }
};
