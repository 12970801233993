import { parseISO } from "date-fns";
import { MeetingRoomApiModel } from "./MeetingRoomApiModel";

export class RoomWithAvailabilityApiModel {
  constructor(obj) {
    this.scheduleInformation = obj.scheduleInformation.map(
      s => new ScheduleInformationApiModel(s)
    );
    this.scheduleAvailability = obj.scheduleAvailability;
    this.sensorAvailability = obj.sensorAvailability;
    this.numberOfPeople = obj.numberOfPeople;
    this.state = obj.state;
    this.suggestionId = obj.suggestionData.suggestionId;
    this.score = obj.suggestionData?.score;
    this.sensorStateTimestamp = obj.sensorStateTimestamp;
  }

  static fromApiObj(obj) {
    return Object.assign(
      {},
      new MeetingRoomApiModel(obj),
      new RoomWithAvailabilityApiModel(obj)
    );
  }
}

class ScheduleInformationApiModel {
  constructor(obj) {
    this.availability = obj.availability;
    this.start = parseISO(obj.start);
    this.end = parseISO(obj.end);
  }
}
