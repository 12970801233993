import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { LocationStoreSingleton } from "@shared/services/LocationStore";

const storedItemName = "lastBookedLocationInfo";

export const getLastUsedLocationIdFromStorage = (fn, userId) => {
  return (
    [
      BuildingNodeTypes.Area,
      BuildingNodeTypes.Floor,
      BuildingNodeTypes.Building
    ]
      .map(nodeType => fn(nodeType, userId))
      .find(Boolean) || null
  );
};

export const getValueFromLocalStorage = (key, _) => {
  const value = localStorage.getItem(key);
  return isValueCorrect(value) ? value : null;
};

export const getLastBookedNodeIdFromSessionStorage = (nodeType, userId) => {
  if (!isValueCorrect(userId) && userId !== null) return null;

  const lastBookedLocationNodes = sessionStorage.getItem(
    `${userId}_${storedItemName}`
  );

  if (!lastBookedLocationNodes) return null;

  const nodeTypeId = JSON.parse(lastBookedLocationNodes)[nodeType];

  return isValueCorrect(nodeTypeId) ? nodeTypeId : null;
};
export const saveWorkspaceToLocalStorage = (nodeId, deskId = null) => {
  const locationStore = LocationStoreSingleton();
  localStorage.setItem(
    BuildingNodeTypes.Building,
    locationStore.getBuildingForId(nodeId)?.id ?? null
  );
  localStorage.setItem(
    BuildingNodeTypes.Floor,
    locationStore.getFloorForId(nodeId)?.id ?? null
  );
  localStorage.setItem(
    BuildingNodeTypes.Area,
    locationStore.getWorkAreaForId(nodeId)?.id ?? null
  );
  localStorage.setItem(BuildingNodeTypes.Desk, deskId ?? null);
};

export const storeLastBookedNodeIdsToSessionStorage = (
  nodeId,
  deskId,
  userId
) => {
  const locationStore = LocationStoreSingleton();

  const itemValue = {
    [BuildingNodeTypes.Building]: getId(locationStore.getBuildingForId(nodeId)),
    [BuildingNodeTypes.Floor]: getId(locationStore.getFloorForId(nodeId)),
    [BuildingNodeTypes.Area]: getId(locationStore.getWorkAreaForId(nodeId)),
    [BuildingNodeTypes.Desk]:
      deskId ??
      getLastBookedNodeIdFromSessionStorage(BuildingNodeTypes.Desk, userId) ??
      null
  };

  sessionStorage.setItem(
    `${userId}_${storedItemName}`,
    JSON.stringify(itemValue)
  );
};

const getId = node => node?.id ?? null;

const isValueCorrect = val =>
  typeof val === "string" && val !== "null" && val.length;
