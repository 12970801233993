import "./AttendeeTile.scss";
import template from "./AttendeeTile.template.html";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import ko from "@shared/knockout/extended";
import { stringCompareAsc } from "@shared/utils/sortHelper.js";
import { LocationType } from "@app/apimodels/LocationType";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import {
  byAttendeeResponseStatus,
  ResponseStatus
} from "@app/apimodels/ResponseStatus";
import { pl } from "@shared/utils/pluralize";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { isSameDay, startOfDay } from "date-fns";
import { byPropAsc, combineSorters } from "@shared/utils/sort";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { OfficeDayVM } from "@app/viewmodels/OfficeDayVM";

const AttendeeTileVM = ({
  icon,
  heading,
  people,
  canBeHighlighted,
  isRemote,
  date,
  groupKey,
  rooms
}) => {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const checkInFeatureEnabled = isCheckInFeatureEnabled();

  const highlighted = ko.pureComputed(() => {
    const userOfficeDay = OfficeDayVM(date);

    if (userOfficeDay.isRemote) return isRemote;

    const shiftBuildingId =
      people && people.length ? people[0].shiftBuildingId : null;
    return (
      shiftBuildingId &&
      userOfficeDay.buildingId === shiftBuildingId &&
      canBeHighlighted
    );
  });

  const attendees = people
    .sort(
      combineSorters(
        byPropAsc("responseStatus", byAttendeeResponseStatus),
        byPropAsc("displayName", stringCompareAsc),
        byPropAsc("initials", stringCompareAsc),
        byPropAsc("email", stringCompareAsc)
      )
    )
    .map(p => ({
      label: p.locationType === LocationType.Unknown ? null : p.initials,
      displayName: p.displayName,
      email: p.email,
      isOrganizer: p.responseStatus === ResponseStatus.Organizer,
      imageHash: p.imageHash,
      responseStatus: p.responseStatus,
      checkInStatus: ko.pureComputed(() =>
        isSameDay(date, startOfDay(new Date()))
          ? checkInStatusStore.getByDirectoryObjectId(p.id)
          : null
      )
    }));

  const MAX_AVATARS = 7;

  const avatars =
    attendees.length >= MAX_AVATARS
      ? attendees.slice(0, MAX_AVATARS - 1).concat({
          label: `+${attendees.length - MAX_AVATARS + 1}`,
          imageHash: null,
          email: null,
          checkInStatus: ko.observable(null),
          responseStatus: null
        })
      : attendees;
  const attendeesVisible = ko.observable(false);
  const toggleAttendees = () => attendeesVisible(!attendeesVisible());
  const showAttendees = () => attendeesVisible(true);
  const hideAttendees = () => attendeesVisible(false);
  const getCapacityText = value => `${value} ${pl("seat", value)}`;

  const onClick = () => {
    if (!attendees.length) return;
    Mediator().publish(Channels.ToggleAttendeesList, {
      groups: [{ groupKey, attendees }],
      title: "Invitees"
    });
  };

  const onRoomClick = room => {
    Mediator().publish(Channels.ToggleRoomDetails, { room, date });
  };

  return {
    icon,
    heading,
    highlighted,
    attendees,
    attendeesVisible,
    toggleAttendees,
    showAttendees,
    hideAttendees,
    onClick,
    onRoomClick,
    rooms,
    getCapacityText,
    avatars,
    checkInFeatureEnabled
  };
};

export const AttendeeTile = KOComponent(
  "attendee-tile",
  AttendeeTileVM,
  template
);
