import { groupBy } from "@shared/utils/groupBy";
import ko from "@shared/knockout/extended";
import { byPropAsc } from "@shared/utils/sort";
import { boolCompareFalseFirst } from "@shared/utils/sortHelper";
import { notEmpty } from "@shared/utils/capacityHelper";
import { WorkspaceLocationVM } from "@shared/viewmodels/WorkspaceLocationVM";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { getDateKey } from "../dateHelpers";
import { isAfter, isBefore, isValid, parseISO } from "date-fns";
import {
  getEndOfCalendar,
  getWorkdayEndOfCalendar
} from "@app/utils/getEndOfCalendar.js";
import { UserApp } from "@app/UserApp.js";
import { FeatureNames, getFeatures } from "@shared/services/Features.js";

export const groupByAvailability = locations =>
  Object.entries(
    groupBy(
      location =>
        ko.unwrap(location.isDisabled) ? "Unavailable at this time" : "",
      locations.filter(notEmpty)
    )
  )
    .map(([availability, locations]) => ({
      availability,
      locations
    }))
    .sort(byPropAsc("availability", boolCompareFalseFirst));

export const isStringEqual = (x, y) => x?.trim() === y?.trim();

export const getOfficeId = ({
  ids,
  selectedWorkdayOfficeId,
  lastUsedLocationId,
  deepestWorkspaceNodeId,
  checkedInDifferentOfficeId = null,
  date,
  selectedInvitees = ko.observableArray([])
}) => {
  const locationStore = LocationStoreSingleton();
  if (selectedWorkdayOfficeId) {
    if (
      selectedWorkdayOfficeId ===
      locationStore.getBuildingForId(deepestWorkspaceNodeId)?.id
    )
      return deepestWorkspaceNodeId;

    if (
      selectedWorkdayOfficeId ===
      locationStore.getBuildingForId(lastUsedLocationId)?.id
    )
      return (
        isLocationDisabled(date, lastUsedLocationId, selectedInvitees) ??
        selectedWorkdayOfficeId
      );
  }

  return (
    ids.find(id => {
      const loc = locationStore.get(id);
      // If workspace exist it will be always returned even if office is removed from location profile or it is full\
      if (deepestWorkspaceNodeId && id === deepestWorkspaceNodeId) return id;

      // In case that user is checked in a different office and that office is removed from the location profile we won't care
      if (
        !loc ||
        (checkedInDifferentOfficeId &&
          !locationStore.get(checkedInDifferentOfficeId))
      )
        return;

      return isLocationDisabled(date, id, selectedInvitees);
    }) ?? null
  );
};

const isLocationDisabled = (date, id, selectedInvitees) => {
  const { disabled } = WorkspaceLocationVM(date, id, selectedInvitees);
  if (disabled()) return null;
  return id;
};

const today = new Date();
export const getFormatedActiveDateFromUrl = () => {
  const nav = UrlNavigationSingleton();

  // Dates are only in the path on the home page
  if (nav.activePageId() !== "") {
    return null;
  }

  const [dateKey] = nav.restPath() ?? [];
  const date = parseISO(dateKey);

  // Default to today
  if (!isValid(date)) {
    return getDateKey(today);
  }

  // Limit to schedule
  // Note: should we also replace the url with the new key as
  //       a side effect?
  if (isBefore(date, today)) {
    return getDateKey(today);
  }

  const endOfCalendar = getFeatures().has(FeatureNames.WORK_DAY)
    ? getWorkdayEndOfCalendar()
    : getEndOfCalendar(UserApp.resolveUser());

  if (isAfter(date, endOfCalendar())) {
    return getDateKey(endOfCalendar());
  }

  return dateKey;
};
