import {
  format,
  isSameDay,
  addDays,
  startOfDay,
  isBefore,
  isAfter,
  getDay
} from "date-fns";

import ko from "@shared/knockout/extended";
import { DayAvailabilityVM } from "./DayAvailabilityVM";
import {
  ColleagueTodayVM,
  sortByDefaultOffice
} from "../../../viewmodels/ColleagueTodayVM";
import { MeetingStoreSingleton } from "@app/services/MeetingStore";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { compareStatus } from "@shared/services/CheckInStatus";
import { combineSorters, byPropAsc } from "@shared/utils/sort";
import { stringCompareAsc } from "@shared/utils/sortHelper";
import { shiftActionButtonVM } from "@app/utils/shiftActionButton";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { ParkingReservationVM } from "@app/viewmodels/Parking/ParkingReservationVM";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";

export const DayVM = (
  profile,
  now,
  { settings },
  allColleagues,
  selectedColleagues,
  lastVisibleWeekDay,
  showMeetings
) => day => {
  const workspaceService = WorkspaceServiceSingleton();
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  const urlNavigationSingleton = UrlNavigationSingleton();
  // Labels
  const date = format(day, "dd"); // 01
  const month = format(day, "LLL"); // Jan
  const weekDay = format(day, "iiii"); // Monday

  // Booking related states
  const shift = ko
    .pureComputed(() => workspaceService.getConfirmedForDate(day))
    .map(getFirstElement)
    .maybeMap(WorkspaceVM);

  const parkingReservation = ko
    .pureComputed(() => parkingReservationsStore.getForDate(day))
    .map(getFirstElement)
    .maybeMap(ParkingReservationVM);
  const booked = shift.map(Boolean);
  const lastBookableDay = addDays(startOfDay(now), profile.daysAhead);

  // Time based states
  const isToday = isSameDay(now, day);
  const inPast = isBefore(day, now);
  const inFuture = isAfter(day, lastBookableDay);

  // Profile based states (together with maxReached)
  const weekDayAllowed = profile.registrationDays.includes(weekDay);
  const dayKey = getDay(day) === 0 ? 7 : getDay(day);
  const weekDayVisible = ko.pureComputed(
    () => showMeetings || weekDayAllowed || dayKey <= lastVisibleWeekDay()
  );

  // Availability related state
  const dayAvailability = DayAvailabilityVM(day);

  const availabilityLabel = ko.pureComputed(() => {
    if (booked() && !dayAvailability.allClosed() && !dayAvailability.allFull())
      return null;

    // If we have no label, we hide the element
    const label = dayAvailability.label();
    if (!label) return null;

    //it only gets a label if it's fully booked or all buildings are closed
    if (dayAvailability.allFull()) return label;
    if (dayAvailability.allClosed()) return label;
    return null;
  });

  const isAdminBooking = ko.pureComputed(() => shift()?.bookedByAdmin);

  const adminLabel = ko.pureComputed(() =>
    shift() && isAdminBooking() ? "Booked for you" : null
  );

  const shiftActionButton = shiftActionButtonVM(
    day,
    settings,
    profile,
    now,
    shift,
    inPast
  );

  const shiftAction = () =>
    booked()
      ? urlNavigationSingleton.navigate("", "overview", [
          format(day, "yyyy-MM-dd"),
          "workspace"
        ])
      : shiftActionButton.disableShiftButton()
      ? (data, event) => {
          alert(shiftActionButton.cannotBookWarning());
        }
      : shiftActionButton.bookShift();

  const onRegisterClick = () => {
    const aiEvent = booked()
      ? EventNames.OpenBookingDetailsInCalendar
      : EventNames.StartShiftBookingInCalendar;
    publishAIEvent(aiEvent);
    shiftAction();
  };
  //CONNECTIONS
  const sortByCheckInStatus = (a, b) =>
    compareStatus(a.checkInStatus(), b.checkInStatus());

  const sorter = checkInFeatureEnabled
    ? combineSorters(sortByCheckInStatus, byPropAsc("name", stringCompareAsc))
    : sortByDefaultOffice;

  //Filter colleagues who have booked a shift today with checkin status on this day
  const colleaguesWithShift = allColleagues
    .filter(c => c.shifts.some(s => isSameDay(s.day, day)))
    .mapArray(c => ColleagueTodayVM(c, day, shift))
    .sort(sorter);
  // Note, slow: O(n * m)
  const selectionFilter = selectedColleagues.map(cs =>
    cs.length ? c => cs.includes(c.id) : _ => true
  );

  const filteredColleagues = colleaguesWithShift.filter(selectionFilter);

  const avatars = filteredColleagues
    .map(cs =>
      cs.length < 6
        ? cs
        : cs.slice(0, 5).concat([
            {
              initials: `+${cs.length - 5}`,
              checkInStatus: ko.observable(null),
              imageHash: null,
              email: null
            }
          ])
    )
    .extend({ deferred: true });

  const showNames = ko.observable(false);
  const nameTooltipPosition = ko.observable("below");

  const toggleNamesOn = (data, event) => {
    publishAIEvent(EventNames.HoverConnectionsInCalendar);
    const hoveredElement = event.target;
    const position = hoveredElement.getBoundingClientRect();
    const distanceToBottom = window.innerHeight - position.bottom;

    // Determine if we show the tooltip on top or below
    const breakpoint = filteredColleagues().length * 20;
    if (distanceToBottom < breakpoint) nameTooltipPosition("above");
    else nameTooltipPosition("below");

    showNames(true);
  };

  const toggleNamesOff = () => showNames(false);

  const meetingStore = MeetingStoreSingleton();
  const meetingsLabel = ko.pureComputed(() => {
    if (meetingStore.dateError(day)) return null; // Could show a (!) here in the UI...
    if (!meetingStore.dateHydrated(day)) return null;
    return meetingStore.onDate(day).length;
  });
  const loadingMeetings = ko.pureComputed(() => {
    return !meetingStore.dateHydrated(day) && !meetingStore.dateError(day);
  });

  const showOverview = () => {
    if (!inPast) {
      publishAIEvent(EventNames.ClickDayInCalendar, {
        shift: booked()
      });
      urlNavigationSingleton.navigate("", "overview", [
        format(day, "yyyy-MM-dd")
      ]);
    }
  };

  return {
    day,
    // Date display
    date,
    month,
    weekDay,
    // Tile
    isToday,
    isDisabled: shiftActionButton.disableShiftButton,
    weekDayAllowed,
    weekDayVisible,
    booked,
    inPast,
    inFuture,
    inRange: shiftActionButton.inRange,
    isFull: dayAvailability.allFull,
    isClosed: dayAvailability.allClosed,
    isSingleBuilding: dayAvailability.singleBuilding,
    maxReached: shiftActionButton.maxReached,
    isFirstNotAllowedDay: shiftActionButton.dayDiff === 1,
    // Label
    availabilityLabel,
    adminLabel,
    fullLabel: shift.maybeMap(s => s.fullLabel),
    parkingReservationLabel: parkingReservation.maybeMap(
      reservation => reservation.locationLabel
    ),
    loadingParkingReservations: ko.pureComputed(
      () => !parkingReservationsStore.hydrated()
    ),
    buildingLabel: shift.maybeMap(s => s.officeName),
    fullLocationLabel: shift.maybeMap(s => s.fullLocationLabel),

    shiftId: shift.maybeMap(shift => shift.id),
    buildingId: shift.maybeMap(shift => shift.buildingId),
    nodeId: shift.maybeMap(shift => shift.nodeId),
    parkingReservationId: parkingReservation.maybeMap(
      parkingReservation => parkingReservation.id
    ),
    // TODO: Fix a bug that causes clicks inside a popup overlay to trigger this action
    //       You can "predict" the bug by looking at the pointer. If you're hovering a
    //       non interactive part of the popup and the pointer changes, the click will
    //       likely go to the underlying calendar...
    shiftAction,
    onRegisterClick,
    avatars,
    toggleNamesOn,
    toggleNamesOff,
    showNames,
    nameTooltipPosition,
    colleaguesInToolTip: filteredColleagues,
    meetingsLabel,
    loadingMeetings,
    showParkingIcon: ko.pureComputed(
      () => getFeatures().has(FeatureNames.PARKING) && parkingReservation()
    ),
    showMeetings,
    showOverview,
    checkInFeatureEnabled,
    dataTestId: ko.pureComputed(
      () =>
        `Day-${isToday ? "currentDayShift" : "shift"}${
          booked() ? "Booked" : "NotBooked"
        }Button`
    )
  };
};
