import { publishAIEvent } from "@admin/components/ShiftsTab/utils/Publishers";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { WorkspaceStoreSingleton } from "@shared/services/WorkspaceStore";
import { GroupWorkspaceStoreSingleton } from "@shared/services/GroupWorkspaceStore";
import { AppApi } from "./AppApi";
import { Singleton } from "@shared/utils/Singleton";
import { WorkdayReservationServiceSingleton } from "./WorkdayReservationService";
import { saveWorkspaceToLocalStorage } from "@shared/utils/OfficeDay/storageHelpers";

const GroupWorkspaceReservationService = () => {
  const workspaceStore = WorkspaceStoreSingleton();
  const groupwWorkspaceStore = GroupWorkspaceStoreSingleton();

  const handleGroupBookingUpdate = async ({
    reservationId,
    previousBooking,
    eventName
  }) => {
    // The response of a group booking creation/update is incompatible with WorkspaceReservationApiModel
    // So here we have to fetch it again.
    const reservation = await AppApi.getWorkspaceReservation(reservationId);
    await WorkdayReservationServiceSingleton().getWorkdayForSingleDay(
      reservation.day
    );
    workspaceStore.remove(previousBooking?.id);
    workspaceStore.add(reservation);

    Mediator().publish(Channels.UpdateAvailability);
    publishAIEvent(eventName, ResultTypes.Success);

    return reservation;
  };

  // fetch new results or return results from store
  const getGroupWorkspaces = (
    startDateTime,
    endtDateTime,
    forceRefresh = false
  ) => {
    if (forceRefresh || !groupwWorkspaceStore.all()?.length) {
      return AppApi.getGroupWorkspaceReservations(startDateTime, endtDateTime)
        .then(groupReservations => {
          groupwWorkspaceStore.addMultiple(groupReservations);
          return groupReservations;
        })
        .catch(error => error);
    }

    return new Promise(res => res(groupwWorkspaceStore.all()));
  };

  const getGroupWorkspaceById = (
    id,
    startDateTime,
    endtDateTime,
    forceRefresh = false
  ) => {
    const fromCache = groupwWorkspaceStore.getById(id);
    const _forceRefresh =
      forceRefresh || !groupwWorkspaceStore.all()?.length || !fromCache;

    return getGroupWorkspaces(startDateTime, endtDateTime, _forceRefresh).then(
      groupWorkspaces => {
        return groupWorkspaces.find(groupWorkspace => groupWorkspace.id === id);
      }
    );
  };

  const addGroupWorkspaceReservation = (groupReservation, previousBooking) =>
    AppApi.postGroupWorkspaceReservation(groupReservation).then(result => {
      saveWorkspaceToLocalStorage(result.owner.nodeId);
      handleGroupBookingUpdate({
        reservationId: result.owner.reservationId,
        previousBooking,
        eventName: EventNames.GroupWorkspaceReservationCreated
      });
    });

  const updateGroupWorkspaceReservationInvitation = async (
    groupReservationId,
    reservationId,
    status,
    previousBooking
  ) => {
    const eventName =
      status === ReservationStatus.Confirmed
        ? EventNames.GroupWorkspaceInvitationAccepted
        : EventNames.GroupWorkspaceInvitationDeclined;

    const result = await AppApi.updateGroupWorkspaceReservationInvitation(
      groupReservationId,
      reservationId,
      status
    ).catch(err => {
      publishAIEvent(eventName, ResultTypes.Fail);
      throw err;
    });

    if (status === ReservationStatus.Confirmed) {
      await handleGroupBookingUpdate({
        reservationId: result.reservationId,
        previousBooking,
        eventName
      });
    } else {
      publishAIEvent(eventName, ResultTypes.Success);
    }

    return result;
  };

  return {
    ...groupwWorkspaceStore,
    getGroupWorkspaceById,
    getGroupWorkspaces,
    addGroupWorkspaceReservation,
    updateGroupWorkspaceReservationInvitation
  };
};

export const GroupWorkspaceReservationServiceSingleton = Singleton(
  GroupWorkspaceReservationService
);
