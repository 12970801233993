export const EventNames = {
  BookShift: "BookShift",
  EditShiftLocation: "EditShiftLocation",
  DeleteShift: "DeleteShift",
  UpdatePreferredBuilding: "UpdatePreferredBuilding",
  SendConnectionRequest: "SendConnectionRequest",
  AcceptConnectionRequest: "AcceptConnectionRequest",
  IgnoreConnectionRequest: "IgnoreConnectionRequest",
  RemoveConnectionRequest: "RemoveConnectionRequest",
  DeleteConnection: "DeleteConnection",
  OpenConnectionsPage: "OpenConnectionsPage",
  NoResultsInCalendarFilter: "NoResultsInCalendarFilter",
  UseCalendarFilter: "UseCalendarFilter",

  //Check-in
  CheckOutManually: "CheckOutManually",
  ConfirmCheckout: "ConfirmCheckout",
  CheckInManually: "CheckInManually",
  EditManualCheckIn: "EditManualCheckIn",

  //Calendar view events
  ClickTodayTile: "ClickTodayTile",
  ClickDayInCalendar: "ClickDayInCalendar",
  StartShiftBookingInCalendar: "StartShiftBookingInCalendar",
  OpenBookingDetailsInCalendar: "OpenBookingDetailsInCalendar",
  HoverConnectionsInCalendar: "HoverConnectionsInCalendar",

  //Side panel events
  CloseSidePanelOnOutsideClick: "CloseSidePanelOnOutsideClick",
  CloseSidePanelOnCloseButton: "CloseSidePanelOnCloseButton",

  //Day overview events
  StartShiftBookingInDayOverview: "StartShiftBookingInDay",
  OpenBookingDetailsInDayOverview: "OpenBookingDetailsInDay",
  SelectConnectionsInDayOverview: "SelectConnectionsInDay",
  SelectCalendarInDayOverview: "SelectCalendarInDay",
  StartEventBookingInDayOverview: "StartEventBookingInDay",
  OpenEventInDayOverview: "OpenEventInDay",

  //Meeting events
  MeetingCreated: "MeetingCreated",
  MeetingUpdated: "MeetingUpdated",
  AddRoomPageOpened: "AddRoomPageOpened",
  RoomAdded: "RoomAdded",
  HereNowPageOpened: "HereNowPageOpened",

  //Parking events
  StartParkingReservationInDayOverview: "StartParkingReservationInDayOverview",
  StartEditParkingReservationInDayOverview:
    "StartEditParkingReservationInDayOverview",
  //parking location picker
  ParkingLocationPickerOpenParkinglot: "ParkingLocationPickerOpenParkinglot",
  ParkingLocationPickerSelectParkinglot:
    "ParkingLocationPickerSelectParkinglot",
  ParkingLocationPickerOpenZone: "ParkingLocationPickerOpenZone",
  ParkingLocationPickerSelectZone: "ParkingLocationPickerSelectZone",
  //click on the buttons ('DeleteParkingReservation' - confirmation of deletion through the confirmation window)
  EditParkingReservation: "EditParkingReservation",
  StartDeleteParkingReservation: "StartDeleteParkingReservation",
  DeleteParkingReservation: "DeleteParkingReservation",
  ReserveParkingSpot: "ReserveParkingSpot",
  //the user will/will not reserve parking (through the confirmation window) after the booked shift
  StartParkingReservationAfterShift: "StartParkingReservationAfterShift",
  DismissParkingReservationAfterShift: "DismissParkingReservationAfterShift",

  // Workday
  OpenWorkdayListFromCalendar: "OpenWorkdayListFromCalendar",

  // Group reservations
  GroupWorkspaceReservationCreated: "GroupWorkspaceReservationCreated",
  GroupWorkspaceInvitationAccepted: "GroupWorkspaceReservationAccepted",
  GroupWorkspaceInvitationDeclined: "GroupWorkspaceInvitationDeclined"
};

export const ResultTypes = {
  Success: "Success",
  Fail: "Fail",
  Undefined: "Undefined"
};

export const BookingType = {
  ByLocation: "ByLocation",
  ByColleague: "ByColleague",
  LastUsed: "LastUsed"
};
