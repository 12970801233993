export class MeetingAttendeeApiModel {
  constructor(obj) {
    this.id = obj.directoryObjectId || null;
    this.email = obj.email;
    this.displayName = obj.displayName || obj.email;
    this.initials = obj.initials;
    this.imageHash = obj.imageHash;
    this.shiftBuildingId = obj.shiftBuildingId || null;
    //for attendees
    this.responseStatus =
      obj.responseStatus === "Tentative" ? "Maybe" : obj.responseStatus || null;
    //for invitees
    this.availability = obj.availability || null;
    this.locationType = obj.locationType;
    this.shiftBuildingName = obj.shiftBuildingName;
  }

  static fromApiObj(obj) {
    return new MeetingAttendeeApiModel(obj);
  }
}
