class WorkAreaApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name || "";
    this.capacity = obj.capacity;
    this.hasBookableDesks = obj.hasBookableDesks ?? false;

    this.type = WorkAreaApiModel;
  }
}

export default WorkAreaApiModel;
