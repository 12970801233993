import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton";

/**
 * Stores a set of workspaces for the current selected user
 */
const GroupWorkspaceStore = () => {
  const cache = ko.observable({});
  const all = cache.map(Object.values);

  /**
   * Set all workspaces (overwriting any old state)
   * @param {Array} allWorkspaces
   */
  const update = allWorkspaces =>
    cache(Object.fromEntries(allWorkspaces.map(s => [s.id, s])));

  const getById = id => {
    const _cache = cache();
    return _cache[id];
  };

  /**
   * add multiple to cache
   * @param {Array} allWorkspaces
   */
  const addMultiple = allWorkspaces => {
    const _cache = cache();
    cache(
      Object.assign(
        _cache,
        Object.fromEntries(allWorkspaces.map(w => [w.id, w]))
      )
    );
  };

  const add = groupWorkspace => {
    const _cache = cache();
    _cache[groupWorkspace.id] = groupWorkspace;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  return {
    update,
    getById,
    addMultiple,
    add,
    remove,
    all
  };
};

export const GroupWorkspaceStoreSingleton = Singleton(GroupWorkspaceStore);
