import bodyTemplate from "./ConnectionFilter.body.template.html";
import footerTemplate from "./ConnectionFilter.footer.template.html";

import connectionTemplate from "./Connection.template.html";
import "./ConnectionFilter.scss";

import ko from "@shared/knockout/extended";
import { Query } from "@shared/utils/Query";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { byPropAsc } from "@shared/utils/sort";
import { ConnectedColleagueVM } from "@app/viewmodels/ConnectedColleagueVM";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { isCheckInFeatureEnabled } from "@app/utils/checkIn";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
export const ConnectionFilterVM = ({
  connectedColleaguesStore,
  selectedConnections,
  mediator = Mediator()
}) => {
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  // Text input in search field
  const rawSearchQuery = ko.observable("");

  const filterFn = rawSearchQuery.map(Query(2));

  const connections = connectedColleaguesStore.all.mapArray(
    ConnectedColleagueVM
  );

  const unfilteredConnectionsWithShifts = connections
    .filter(c => c.shifts.length > 0)
    .map(a => a.length);

  const visibleConnections = connections
    .filter(filterFn)
    .sort(byPropAsc("name"));

  const withShifts = visibleConnections.filter(c => c.shifts.length > 0);
  const withoutShifts = visibleConnections.filter(c => c.shifts.length === 0);

  const hasSelection = selectedConnections.map(hasLength);

  const clearSelection = () => {
    rawSearchQuery("");
    selectedConnections([]);
  };

  const height = ko.observable("auto");

  const showEmptyState = visibleConnections.map(isEmpty);

  const aiSub = showEmptyState.subscribe(newState => {
    if (newState) publishAIEvent(EventNames.NoResultsInCalendarFilter);
  });

  const closeConnectionFilterPopup = () => {
    UrlNavigationSingleton().navigate(null);
    publishAIEvent(
      EventNames.UseCalendarFilter,
      { selectedConnections: selectedConnections().length },
      ResultTypes.Success
    );
  };

  return {
    hasSelection,
    clearSelection,
    searchBarParams: {
      query: rawSearchQuery
    },
    selectedConnections,
    withShifts,
    withoutShifts,
    goToConnections: () => mediator.publish(Channels.OpenConnectionsPage),
    showEmptyState,
    checkInFeatureEnabled,

    height,
    saveHeight: el => {
      height(el.offsetHeight);
    },

    closeConnectionFilterPopup,
    unfilteredConnectionsWithShifts,

    dispose: () => {
      aiSub.dispose();
    }
  };
};

export const ConnectionFilter = {
  register: ko => {
    ko.components.register("cf-connection", {
      template: connectionTemplate
    });

    ko.components.register("cf-body", {
      template: bodyTemplate
    });

    ko.components.register("cf-footer", {
      template: footerTemplate
    });
  }
};

// Utils
const hasLength = xs => xs.length > 0;
const isEmpty = xs => xs.length === 0;
