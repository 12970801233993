import { UserApp } from "@app/UserApp.js";
import { getGroupIcon } from "./attendeeGroupUtils.js";

// Tile configs are used to easily collect the props needed
// for the AttendeeTile component
export const TileConfig = ([
  groupKey,
  people = [],
  canBeHighlighted = true
]) => ({
  heading: `${groupKey} (${people.length})`,
  icon: getGroupIcon(groupKey),
  isRemote: groupKey === "Remote",
  people,
  groupKey,
  canBeHighlighted,
  rooms: []
});

// Building tile configs have some more logic: they look up their
// heading from within their people array and define whether their
// building id matches that of a user shift
export const BuildingTileConfig = (userOfficeDay, meetingRooms) => ([
  buildingId,
  people,
  canBeHighlighted = true
]) => {
  const rooms = meetingRooms.filter(r => r.buildingId === buildingId);

  const buildingName = people.length
    ? people[0].shiftBuildingName
    : rooms[0].buildingName;

  return {
    heading: `${buildingName}${
      people.length ? " (" + people.length + ")" : ""
    }`,
    groupKey: buildingName,
    icon: getGroupIcon(buildingId),
    people,
    canBeHighlighted,
    isRemote: false,
    userHasShiftHere: userOfficeDay.map(
      officeDay => officeDay.buildingId === buildingId
    ),
    isPreferredBuilding: UserApp.preferredLocationId.map(
      id => id === buildingId
    ),
    rooms
  };
};

// Tiles that have the user shift should go first, others are
// sorted alphabetically
BuildingTileConfig.compare = (t1, t2) => {
  if (t1.userHasShiftHere() && !t2.userHasShiftHere()) {
    return -1;
  }

  if (t2.userHasShiftHere() && !t1.userHasShiftHere()) {
    return 1;
  }

  if (t1.isPreferredBuilding()) {
    return -1;
  }

  if (t2.isPreferredBuilding()) {
    return 1;
  }

  return t1.heading.localeCompare(t2.heading);
};
