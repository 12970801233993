import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./CheckInHasShiftTile.template.html";
import "../CheckInTile.scss";
import "../../TodayTile.scss";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { CheckInServiceSingleton } from "@app/services/CheckInService.js";
import { BookingPolicy } from "@shared/services/BookingPolicy";
import {
  isCheckInFeatureEnabled,
  isManualCheckInFeatureEnabled
} from "@app/utils/checkIn";
import { EventNames } from "@app/tracking/EventNames";
import { determineStatusToSend } from "../helpers";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { getWorkspaceIcon } from "@app/utils/workspaceHelpers";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";

const CheckInHasShiftTileVM = ({
  dayVM,
  settings,
  visible,
  mediator = Mediator()
}) => {
  const checkInFeatureEnabled = isCheckInFeatureEnabled();
  const manualCheckInEnabled = isManualCheckInFeatureEnabled();
  const checkInService = CheckInServiceSingleton();
  const loading = checkInService.loading;
  const workspaceService = WorkspaceServiceSingleton();
  const handleComingButtonClick = () => {
    publishAIEvent(EventNames.CheckInManually, { shift: true });
    checkInService.checkIn(dayVM.buildingId());
  };

  const handleNotComingButtonClick = () => {
    publishAIEvent(EventNames.CheckOutManually);
    mediator.publish(Channels.ToggleConfirmationWindow, {
      options: {
        title: "Not coming to the office?",
        subtitle:
          "All your reservations will be cancelled and spots will become available to others.",
        primaryButtonText: "Delete",
        onPrimaryButtonClick: () => {
          publishAIEvent(EventNames.ConfirmCheckout);
          return workspaceService.deleteWorkspace({ id: dayVM.shiftId() });
        }
      }
    });
  };

  const checkInStatusSelectorParams = {
    bookingPolicy: settings()?.registrationPolicy,
    dayVM,
    output: newCheckInStatus => {
      const buildingId = dayVM.buildingId();
      if (newCheckInStatus === CheckInStatus.AtTheOffice)
        checkInService.checkIn(buildingId);
      else checkInService.checkOut(checkInService.buildingId());
    }
  };

  return {
    dayVM,
    visible,
    checkInFeatureEnabled,
    manualCheckInEnabled,
    hasCheckInStatus: checkInService.hasCheckInStatus,
    loading,
    notComingButtonIsVisible:
      settings()?.registrationPolicy === BookingPolicy.SAME_DAY,
    checkInStatusSelectorParams,
    labels: ko
      .pureComputed(() => {
        const node = LocationStoreSingleton().get(dayVM.nodeId());

        return [
          { label: dayVM.buildingLabel(), iconName: null },
          {
            label: dayVM.fullLocationLabel(),
            iconName: node ? getWorkspaceIcon(node) : "workspaceDesk"
          },
          getFeatures().has(FeatureNames.PARKING)
            ? {
                label: dayVM.parkingReservationLabel(),
                iconName: "parkingCarWhite"
              }
            : null
        ].filter(obj => obj?.label && obj?.label?.length > 0);
      })
      .extend({ deferred: true }),

    checkInStatusSelectorParams,

    onTileClick: () => {
      determineStatusToSend(dayVM, true);
      dayVM.shiftAction();
    },
    handleComingButtonClick,
    handleNotComingButtonClick
  };
};

export const CheckInHasShiftTile = KOComponent(
  "check-in-has-shift-tile",
  CheckInHasShiftTileVM,
  template
);
