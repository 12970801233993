import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { capitalizeSentence } from "@shared/utils/text-helpers";
import { Server400ErrorModel } from "@shared/viewmodels/Server400ErrorModel";

export const showSuccessMessage = (
  isSingle,
  userInfo,
  iconName,
  showSubReason = true
) => {
  Mediator().publish(Channels.OpenStatusMessage, {
    title: "Deleted!",
    iconName: iconName,
    type: "success",
    reason: `${
      isSingle ? "The booking was" : "Bookings have been"
    } successfully deleted.`,
    subReason: showSubReason
      ? `${capitalizeSentence(userInfo)} will be notified.`
      : null
  });
};

export const showFailureMessage = (error, isSingle) => {
  let errorReason = null;
  if (error.status === 400)
    errorReason = Server400ErrorModel(error).firstErrorType;

  Mediator().publish(Channels.OpenStatusMessage, {
    type: "failure",
    reason: errorReason || `Failed to delete booking${isSingle ? "" : "s"}.`
  });
};

export const publishAIEvent = (eventName, resultType) =>
  Mediator().publish(Channels.TrackAIEvent, [eventName, resultType]);
