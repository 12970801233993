import ko from "@shared/knockout/extended";

const scrollIntoViewBinding = {
  update: function(element, valueAccessor, allBindings) {
    const selected = ko.unwrap(valueAccessor());
    const scrollIntoViewParams = allBindings.get("scrollIntoViewParams") ?? {
      block: "start",
      inline: "nearest"
    };
    if (selected)
      setTimeout(() =>
        element.scrollIntoView({ ...scrollIntoViewParams, behavior: "smooth" })
      );
  }
};

const scrollToTopBinding = {
  init: function(element) {
    element.scrollTo(0, 0);
  }
};

// This scrolls a horizontal element to the center of its scroll container
const scrollCardIntoViewBinding = {
  update: function(element, valueAccessor) {
    const selected = ko.unwrap(valueAccessor());
    if (selected) {
      const p = element;
      const parent = p.parentElement.getBoundingClientRect();
      const self = p.getBoundingClientRect();

      const offset = (parent.width - self.width) / 2;

      // We want to give a for-each binding the chance to complete rendering
      // before we start scrolling to something. This prevents elements being
      // added _after_ we start a scroll to cancel the scroll animation.
      setTimeout(() => {
        p.parentElement.scrollTo({
          top: 0,
          left: p.offsetLeft - offset,
          behavior: "smooth"
        });
      }, 0);
    }
  }
};

export { scrollIntoViewBinding, scrollCardIntoViewBinding, scrollToTopBinding };
