const ElementKey = Symbol();

export const KOComponent = (
  componentName,
  vmFactory,
  template,
  registerFirst = []
) => ({
  register: ko => {
    registerFirst.forEach(component => {
      component.register(ko);
    });

    ko.components.register(componentName, {
      template,
      viewModel: {
        createViewModel: (params, _componentInfo) => {
          const vm = vmFactory({
            ...params,
            [ElementKey]: _componentInfo.element
          });

          if (vm) {
            // Check if our factory has a name and if the toStringTag is not
            // yet set.
            if (vmFactory.name && vm[Symbol.toStringTag] === undefined) {
              vm[Symbol.toStringTag] = vmFactory.name;
            }
          } else {
            console.warn(
              `createViewModel for ${vmFactory.name} did not return an instance`
            );
          }

          return vm;
        }
      }
    });
  },
  componentName
});

KOComponent.getElementFromparams = params => params[ElementKey];
