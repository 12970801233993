export function stringMatchesQuery(string, query) {
  const normalizedString = removesDiacritics(string);
  const normalizedQuery = removesDiacritics(query);
  return normalizedString
    .toLowerCase()
    .replace(/[\s-]/g, "")
    .includes(normalizedQuery.toLowerCase().replace(/[\s-]/g, ""));
}

const removesDiacritics =
  typeof "".normalize !== "undefined"
    ? string => string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    : string => string;
