import { BuildingApi } from "@app/services/BuildingApi.js";
import { Singleton } from "@shared/utils/Singleton.js";

const MapStore = () => {
  const cache = {};

  const loadMap = (buildingId, floorId) => {
    return BuildingApi.getFloorplan(buildingId, floorId).then(svgString => {
      cache[floorId] = svgString;
      return svgString;
    });
  };

  return {
    clearCache(floorId) {
      delete cache[floorId];
    },
    getMap(buildingId, floorId) {
      if (cache[floorId]) return Promise.resolve(cache[floorId]);

      return loadMap(buildingId, floorId);
    }
  };
};

export const MapStoreSingleton = Singleton(MapStore);
