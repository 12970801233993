import { WorkingDays } from "@shared/utils/OpeningDays";

export const getArrayOfWorkingDays = (
  startWorkingTime = "09:00",
  endWorkingTime = "17:00"
) =>
  WorkingDays.map(dayName => ({
    day: dayName,
    times: [
      {
        startTimeLocal: `${startWorkingTime}:00`,
        endTimeLocal: `${endWorkingTime}:00`
      }
    ]
  }));
