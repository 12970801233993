import { ParkingLotStoreSingleton } from "@app/services/ParkingLotStore";
import { AvailabilityParkingStoreSingleton } from "@app/services/AvailabilityParkingStore";
import ko from "@shared/knockout/extended";
import { ParkingLotDataVM } from "@app/viewmodels/Parking/ParkingLotDataVM";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { getFeatures, FeatureNames } from "@shared/services/Features";
import { AppApi } from "@app/services/AppApi";
import { ParkingReservationStoreSingleton } from "@app/services/ParkingReservationStore";
import ParkingReservationApiModel from "@shared/apimodels/ParkingReservationApiModel";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { format } from "date-fns";
import { PreferencesStoreSingleton } from "@shared/services/PreferencesStore";
import { getISOWeek } from "date-fns";
import { EventNames } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";

export const ParkingService = (date, user, mediator = Mediator()) => {
  const parkingLotStore = ParkingLotStoreSingleton();
  const availabilityParkingStore = AvailabilityParkingStoreSingleton();
  const parkingReservationsStore = ParkingReservationStoreSingleton();
  const preferencesStore = PreferencesStoreSingleton();
  const urlNavigation = UrlNavigationSingleton();
  const parkingFeatureEnabled = getFeatures().has(FeatureNames.PARKING);
  const workdayFeatureDisabled = !getFeatures().has(FeatureNames.WORK_DAY);
  const parkingQuotaProfile = user.parkingQuotaProfile;
  const parkingLocationProfile = user.parkingLocationProfile;
  const workspaceQuotaProfile = user.accessProfile;
  const profilesAllowParking =
    !!parkingQuotaProfile.daysPerWeek &&
    (!!parkingLocationProfile.allowedLocationIds.length ||
      parkingLocationProfile.allowAll);

  const loading = ko.observable(false);
  const parkingData = ko.observable(null);
  const hasError = ko.observable(false);

  const getParkingAvailabilty = date =>
    AppApi.getParkingAvailability(date)
      .then(availabilityParkingStore.update)
      .catch(() => hasError(true));

  const getParkingLots = buildingId =>
    AppApi.getParkingLotsForBuilding(buildingId)
      .then(parkingLotStore.update)
      .catch(() => hasError(true));

  const dismissParkingIntroduction = () =>
    AppApi.updateUserPreferences({
      userDismissedParkingIntroduction: true
    }).then(() => preferencesStore.setUserDismissedParkingIntroduction(true));

  const handleParkingUpdate = parkingPromise =>
    parkingPromise
      .then(apiParking => {
        if (!preferencesStore.getUserDismissedParkingIntroduction())
          dismissParkingIntroduction();
        mediator.publish(Channels.UpdateParkingAvailability);
        const newParkingReservation = new ParkingReservationApiModel(
          apiParking
        );
        parkingReservationsStore.add(newParkingReservation);
        return newParkingReservation;
      })
      .catch(e => {
        throw e;
      });
  const postParkingReservation = parking =>
    handleParkingUpdate(AppApi.createParkingReservation(parking));

  const updateParkingReservation = ({
    currentReservationId,
    newParkingLocationId
  }) =>
    handleParkingUpdate(
      AppApi.updateParkingReservation(
        currentReservationId,
        newParkingLocationId
      )
    );

  const removeParkingReservation = id => {
    parkingReservationsStore.remove(id);
    mediator.publish(Channels.UpdateParkingAvailability);
  };

  const deleteParkingReservation = reservationId =>
    AppApi.deleteParkingReservation(reservationId)
      .then(() => {
        removeParkingReservation(reservationId);
      })
      .catch(e => {
        throw e;
      });

  const onDismissalSuggestionPopUp = () => {
    AppApi.updateUserPreferences({
      userDismissedParkingSuggestionPopUp: true
    }).then(() =>
      preferencesStore.setUserDismissedParkingSuggestionPopUp(true)
    );
  };
  const setParkingData = (shift, date) => {
    parkingData(
      ParkingLotDataVM(shift, date, parkingQuotaProfile, workspaceQuotaProfile)
    );
  };
  const removeParkingReservationDifferentBuilding = date => {
    const parkingReservation = parkingReservationsStore.parkingReservationForDate(
      date
    );
    const parkingLot = parkingLotStore.getParkingLotForId(
      parkingReservation?.nodeId
    );
    if (parkingReservation && !parkingLot) {
      removeParkingReservation(parkingReservation.id);
      if (!getFeatures().has(FeatureNames.WORK_DAY))
        urlNavigation.navigate("", "overview", [format(date, "yyyy-MM-dd")]);
    }
  };

  return {
    parkingData,
    parkingQuotaProfile,
    loading,
    hasError,
    getParkingAvailabilty,
    userCanSeeSuggestionPopUp: () =>
      workdayFeatureDisabled &&
      parkingFeatureEnabled &&
      !hasError() &&
      parkingData()?.userCanBook &&
      profilesAllowParking &&
      !preferencesStore.getUserDismissedParkingSuggestionPopUp(),

    userCanSeeIntroductionBanner: ko
      .pureComputed(
        () =>
          parkingFeatureEnabled &&
          parkingQuotaProfile.daysPerWeek -
            parkingReservationsStore.getForWeekNr(getISOWeek(date)).length >
            0 &&
          profilesAllowParking &&
          !preferencesStore.getUserDismissedParkingIntroduction()
      )
      .extend({ notify: "always" }),

    profilesAllowParking,
    getParkingData: shift => {
      loading(true);
      if (
        !getFeatures().has(FeatureNames.PARKING) ||
        !shift ||
        !profilesAllowParking
      )
        return Promise.resolve()
          .then(() => setParkingData(shift, date))
          .finally(() => loading(false));

      return Promise.all([
        getParkingLots(shift.buildingId || shift.nodeId),
        getParkingAvailabilty(date)
      ])
        .then(() => setParkingData(shift, date))
        .then(() => {
          if (getFeatures().has(FeatureNames.WORK_DAY)) {
            removeParkingReservationDifferentBuilding(date);
          }
        })
        .finally(() => loading(false));
    },
    removeParkingReservation,
    removeParkingReservationDifferentBuilding,
    postParkingReservation,
    updateParkingReservation,
    deleteParkingReservation,
    openParkingConfirmationWindow: () => {
      mediator.publish(Channels.ToggleConfirmationWindow, {
        options: {
          iconName: "car",
          iconSize: 52,
          title: "Parking is now available",
          subtitle: "Do you also want to book a parking spot?",
          primaryButtonText: "Book a parking spot",
          secondaryButtonText: "No thanks",
          primaryButtonClass: "MpqButton--black MpqButton--secondaryGray",
          secondaryButtonClass: "MpqButton--outlineBlack",
          smallerSubText: true,
          buttonInColumnOrder: true,
          onPrimaryButtonClick: () => {
            publishAIEvent(EventNames.StartParkingReservationAfterShift);
            urlNavigation.navigate("", "overview", [
              format(date, "yyyy-MM-dd")
            ]);
            onDismissalSuggestionPopUp();

            return Promise.resolve();
          },
          onSecondaryButtonClick: () => {
            publishAIEvent(EventNames.DismissParkingReservationAfterShift);
            onDismissalSuggestionPopUp();
          }
        }
      });
    },
    openParkingReservation: () => {
      if (parkingReservationsStore.getForDate(date)[0])
        publishAIEvent(EventNames.StartEditParkingReservationInDayOverview);
      else publishAIEvent(EventNames.StartParkingReservationInDayOverview);

      mediator.publish(Channels.OpenParkingReservationWindow, { date });
    },
    dismissParkingIntroduction
  };
};
