import ko from "@shared/knockout/extended";

// TODO: Ensure it works when multiple bindings of the same group change visibility
export const toasterBinding = {
  init: (el, va) => {
    const visible = va();
    const groupId = el.getAttribute("data-toastergroup");
    el.style.display = "none";

    ko.computed(() => {
      const isVisible = visible();

      const others = Array.from(
        document.querySelectorAll(`[data-toastergroup=${groupId}`)
      ).filter(other => other !== el);

      // Store current positions
      const posBefore = others.map(el => el.getBoundingClientRect());

      // Perform change:
      if (isVisible) {
        el.style.display = "";
      } else {
        el.style.display = "none";
      }

      // Determine final positions
      const posAfter = others.map(el => el.getBoundingClientRect());

      const animOpts = {
        easing: "ease-in-out",
        duration: 350
      };

      // Animate from old to new position
      others.forEach((o, i) => {
        const dy = posBefore[i].top - posAfter[i].top;
        const dx = posBefore[i].left - posAfter[i].left;

        o.animate(
          [
            { transform: `translate3d(${dx}px, ${dy}px, 0)` },
            { transform: `` }
          ],
          animOpts
        );
      });
    });
  }
};
