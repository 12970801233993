import ProfileApiModel from "@shared/apimodels/ProfileApiModel";
import UserPreferencesApiModel from "./UserPreferencesApiModel";

class CurrentUserApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.directoryObjectId = obj.directoryObjectId;
    this.defaultBuildingId = obj.defaultBuildingId;
    this.isNew = obj.isNew;

    this.firstName = obj.firstName;
    this.fullName = obj.firstName + " " + obj.lastName;
    this.email = obj.email;
    this.companyName = obj.companyName;
    this.initials = obj.initials;

    this.imageHash = obj.imageHash;
    this.accessProfile = new ProfileApiModel(obj.accessProfile);
    this.parkingQuotaProfile = obj.parkingQuotaProfile;
    this.parkingLocationProfile = obj.parkingLocationProfile;

    this.userPreferences = new UserPreferencesApiModel(obj.preferences);
  }
}

export default CurrentUserApiModel;
