import ko from "@shared/knockout/extended";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { byPropAsc, numericCompare } from "@shared/utils/sort.js";
import { endOfWeek, max, addDays } from "date-fns";
import { UserApp } from "@app/UserApp";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";

export const getEndOfCalendar = currentUser =>
  ko.pureComputed(() => {
    const shifts = WorkspaceServiceSingleton()
      .all()
      .sort(byPropAsc("day", numericCompare));
    const latestShift = shifts[shifts.length - 1];
    const lastAllowedDay = addDays(
      new Date(),
      currentUser.accessProfile.daysAhead
    );

    const lastDay = latestShift
      ? max([latestShift.day, lastAllowedDay])
      : lastAllowedDay;

    return endOfWeek(lastDay, { weekStartsOn: 1 });
  });

export const getWorkdayEndOfCalendar = () =>
  ko.pureComputed(() => {
    const workdays = WorkdayReservationsStoreSingleton()
      .all()
      .sort(byPropAsc("startDate", numericCompare));
    const currentUser = UserApp.resolveUser();

    const latestWorkday = workdays[workdays.length - 1];
    const lastAllowedDay = addDays(
      new Date(),
      currentUser.accessProfile.daysAhead
    );

    const lastDay = latestWorkday
      ? max([latestWorkday.startDate, lastAllowedDay])
      : lastAllowedDay;

    return endOfWeek(lastDay, { weekStartsOn: 1 });
  });
