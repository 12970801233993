import { getToken } from "./getToken";
import { traceWarning } from "@shared/utils/AppInsights";

const OFFICE_SHIFTS_BASE_URL = process.env.MAPIQ_API_BASE_URL;

const fetchWithToken = (url, opts) => token => {
  const headers = {
    ...opts.headers,
    Authorization: `Bearer ${token}`,

    // For now we want to force English responses
    // In the (upcoming) react app we can remove this override because there we want to support multilingual responses
    "accept-language": "en"
  };

  // Merge options
  const options = {
    ...opts,
    headers
  };

  return fetch(url, options);
};

const throwError = async (response, path) => {
  const isNotValidationEndpoint = !path.toLowerCase().endsWith("/validate");
  const responseStatus = response.status;

  if (responseStatus === 404) {
    throw "Not implemented: 404";
  } else if (responseStatus === 400) {
    const err = await response.json();
    if (isNotValidationEndpoint) {
      traceWarning(
        `MapiqONE-web: ${responseStatus} ${err.title}`,
        Object.assign({ endpoint: path }, err.errors ? err.errors : err)
      );
    }
    throw err;
  }
  if (isNotValidationEndpoint) {
    traceWarning(`MapiqONE-web: ${responseStatus}`, { endpoint: path });
  }

  throw responseStatus;
};

export const authFetchResponse = (
  path,
  opts = {},
  baseUrl = OFFICE_SHIFTS_BASE_URL
) =>
  getToken()
    .then(fetchWithToken(`${baseUrl}${path}`, opts))
    .then(async response => {
      if (!response.ok) return throwError(response, path);
      else return response;
    });

export const authFetch = (path, opts = {}, baseUrl = OFFICE_SHIFTS_BASE_URL) =>
  authFetchResponse(path, opts, baseUrl).then(async response =>
    isJsonResponse(response)
      ? response.json()
      : isBlobResponse(response)
      ? response.blob()
      : response.text()
  );

export const Fetch = baseUrl => ({
  fetch: (url, headers) => authFetch(url, headers, baseUrl),
  get: (url, headers = {}) => authFetch(url, headers, baseUrl),
  post: (url, body, headers = {}) =>
    authFetch(
      url,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...headers
        }
      },
      baseUrl
    ),

  put: (url, body, headers = {}) =>
    authFetch(
      url,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...headers
        }
      },
      baseUrl
    ),

  patch: (url, body, headers = {}) =>
    authFetch(
      url,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...headers
        }
      },
      baseUrl
    ),

  delete: (url, body = null, headers = {}) => {
    return body
      ? authFetch(
          url,
          {
            method: "DELETE",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
              ...headers
            }
          },
          baseUrl
        )
      : authFetch(url, { method: "DELETE", headers }, baseUrl);
  }
});

const isJsonResponse = r => {
  const contentType = r.headers.get("content-type");
  return contentType && contentType.includes("application/json");
};

const isBlobResponse = r => {
  const contentType = r.headers.get("content-type");
  return (
    contentType &&
    (contentType.includes("text/csv") ||
      contentType.includes("image/jpeg") ||
      contentType.includes("image/png") ||
      contentType.includes("zip"))
  );
};
