export const setHas = k => s => s.has(k);
export const setAdd = (k, s) => s.add(k);
export const setDelete = (k, s) => (s.delete(k), s);

export const setEqual = (s1, s2) =>
  s1.size === s2.size && Array.from(s1).every(x => s2.has(x));

export const setSubtract = (s1, s2) => {
  const s3 = new Set(s1);

  s2.forEach(k => {
    s3.delete(k);
  });

  return s3;
};

export const setSum = (s1, s2) => {
  const s3 = new Set(s1);

  s2.forEach(k => {
    s3.add(k);
  });

  return s3;
};
