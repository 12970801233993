import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton";
import { getDateKey, getDateKeyForFeature } from "@shared/utils/dateHelpers";
import { groupByC } from "@shared/utils/groupBy";

const WorkdayReservationsStore = () => {
  const cache = ko.observable({});
  const all = cache.map(Object.values);
  const byDate = all.map(groupByC(getDateKeyForFeature));

  const update = workdays => {
    cache(Object.fromEntries(workdays.map(w => [w.id, w])));
  };

  const getForDate = date => {
    const k = getDateKey(date);

    return byDate()[k] || [];
  };

  const add = workday => {
    const _cache = cache();
    _cache[workday.id] = workday;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  return {
    getForDate,
    add,
    update,
    remove,
    all
  };
};

export const WorkdayReservationsStoreSingleton = Singleton(
  WorkdayReservationsStore
);
