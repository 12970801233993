import { pl } from "@shared/utils/pluralize";
import {
  intervalToFormattedDuration,
  roundDownDurationIfMoreThanDay,
  formatDuration
} from "@shared/utils/durationHelpers";
import {
  differenceInMinutes,
  format,
  isToday,
  parseISO,
  set,
  intervalToDuration,
  addMinutes,
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval
} from "date-fns";
import { Mediator } from "@shared/mediator";
import ko from "@shared/knockout/extended";
import { Channels } from "@shared/Channels";

const now = ko.observable(set(new Date(), { seconds: 0, milliseconds: 0 }));

Mediator().subscribe(Channels.OpenBookRoom, () => {
  now(set(new Date(), { seconds: 0, milliseconds: 0 }));
});

export const RoomScheduleState = {
  Available: "Available",
  Tentative: "Tentative",
  Unavailable: "Unavailable",
  Unknown: "Unknown"
};

export const RoomSensorState = {
  Available: "Available",
  Occupied: "Occupied",
  Away: "Away",
  Unknown: "Unknown"
};

export const RoomState = {
  BookingAboutToStartAndFree: "BookingAboutToStartAndFree",
  BookingAboutToStartAndInUse: "BookingAboutToStartAndInUse",
  BookingAboutToStart: "BookingAboutToStart",
  BookingAboutToStartAndUnknownSensor: "BookingAboutToStartAndUnknownSensor",
  BookedAndFree: "BookedAndFree",
  BookedAndInUse: "BookedAndInUse",
  Booked: "Booked",
  BookedAndUnknownSensor: "BookedAndUnknownSensor",
  BookingAboutToEndAndFree: "BookingAboutToEndAndFree",
  BookingAboutToEndAndInUse: "BookingAboutToEndAndInUse",
  BookingAboutToEnd: "BookingAboutToEnd",
  BookingAboutToEndAndUnknownSensor: "BookingAboutToEndAndUnknownSensor",
  NotBookedAndFree: "NotBookedAndFree",
  NotBookedAndInUse: "NotBookedAndInUse",
  NotBooked: "NotBooked",
  NotBookedAndUnknownSensor: "NotBookedAndUnknownSensor",
  Free: "Free",
  InUse: "InUse",
  Untraceable: "Untraceable",
  UnknownSensor: "UnknownSensor",
  Unknown: "Unknown"
};

//to determine rooms to hide in UI
export const isConsideredFree = roomState => {
  switch (roomState) {
    case RoomState.NotBookedAndFree:
    case RoomState.Free:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.NotBooked:
    case RoomState.NotBookedAndInUse:
    case RoomState.NotBookedAndUnknownSensor:
    case RoomState.BookingAboutToEnd:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToEndAndUnknownSensor:
    case RoomState.BookedAndFree:
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.UnknownSensor:
    case RoomState.BookingAboutToStartAndUnknownSensor:
    case RoomState.Untraceable:
    case RoomState.BookingAboutToStart:
    case RoomState.BookingAboutToStartAndInUse:
      return true;
    case RoomState.BookedAndUnknownSensor:
    case RoomState.BookedAndInUse:
    case RoomState.InUse:
    case RoomState.Booked:
    default:
      return false;
  }
};

export const RoomSensorLabel = roomState => {
  switch (roomState) {
    case RoomState.Free:
    case RoomState.BookedAndFree:
    case RoomState.NotBookedAndFree:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToStartAndFree:
      return "Seems to be empty";
    case RoomState.InUse:
    case RoomState.BookedAndInUse:
    case RoomState.NotBookedAndInUse:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToStartAndInUse:
      return "Seems to be in use";
    case RoomState.Untraceable:
    case RoomState.Booked:
    case RoomState.NotBooked:
    case RoomState.BookingAboutToEnd:
    case RoomState.BookingAboutToStart:
      return "No live data";
    default:
      return null;
  }
};

export const RoomScheduleLabel = (
  roomState,
  scheduleInformation,
  currentTime = now()
) => {
  switch (roomState) {
    case RoomState.Booked:
    case RoomState.BookedAndFree:
    case RoomState.BookedAndInUse:
    case RoomState.BookedAndUnknownSensor:
      return "Booked";
    case RoomState.NotBooked:
    case RoomState.NotBookedAndFree:
    case RoomState.NotBookedAndInUse:
    case RoomState.NotBookedAndUnknownSensor:
      return "Not booked";
    case RoomState.BookingAboutToEnd:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToEndAndUnknownSensor:
      return getAvailableInText(scheduleInformation, currentTime);
    case RoomState.BookingAboutToStart:
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.BookingAboutToStartAndInUse:
    case RoomState.BookingAboutToStartAndUnknownSensor:
      return getAvailableForText(scheduleInformation, currentTime);
    default:
      return null;
  }
};

export const RoomSensorSubtitle = (
  roomState,
  sensorStateTimestamp,
  currentTime = now()
) => {
  switch (roomState) {
    case RoomState.Free:
    case RoomState.BookedAndFree:
    case RoomState.NotBookedAndFree:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.InUse:
    case RoomState.BookedAndInUse:
    case RoomState.NotBookedAndInUse:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToStartAndInUse:
      return getSensorUpdatedAgoFormattedText(
        sensorStateTimestamp,
        currentTime
      );
    default:
      return null;
  }
};

export const RoomScheduleColor = roomState => {
  switch (roomState) {
    case RoomState.Booked:
    case RoomState.BookedAndFree:
    case RoomState.BookedAndInUse:
    case RoomState.BookedAndUnknownSensor:
      return "#FF9299";
    case RoomState.NotBooked:
    case RoomState.NotBookedAndFree:
    case RoomState.NotBookedAndInUse:
    case RoomState.NotBookedAndUnknownSensor:
      return "#77D99E";
    default:
      return "#FAB667";
  }
};

export const RoomSensorColor = roomState => {
  switch (roomState) {
    case RoomState.Free:
    case RoomState.BookedAndFree:
    case RoomState.NotBookedAndFree:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToStartAndFree:
      return "#77D99E";
    case RoomState.InUse:
    case RoomState.BookedAndInUse:
    case RoomState.NotBookedAndInUse:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToStartAndInUse:
      return "#FF9299";
    default:
      return "#BDC4C9";
  }
};

export const RoomScheduleSubtitleDetailsPage = (
  roomState,
  scheduleInformation = [],
  currentTime = now(),
  durationInMinutes = 30
) => {
  const firstMeeting = getFirstScheduleInformationThatStartsWithinInterval(
    scheduleInformation,
    { minStartDate: currentTime }
  );

  switch (roomState) {
    case RoomState.NotBookedAndFree:
    case RoomState.NotBookedAndInUse:
    case RoomState.NotBookedAndUnknownSensor:
    case RoomState.NotBooked: {
      if (firstMeeting && isToday(firstMeeting.start))
        return `Next booking at ${format(firstMeeting.start, "HH:mm")}`;
      return "No more bookings today";
    }
    case RoomState.BookedAndFree:
    case RoomState.BookedAndInUse:
    case RoomState.BookedAndUnknownSensor:
    case RoomState.Booked:
      return getBookedUntilText(
        scheduleInformation,
        currentTime,
        durationInMinutes
      );
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.BookingAboutToStartAndInUse:
    case RoomState.BookingAboutToStartAndUnknownSensor:
    case RoomState.BookingAboutToStart:
      return `Next booking at ${format(firstMeeting.start, "HH:mm")}`;
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToEndAndUnknownSensor:
    case RoomState.BookingAboutToEnd:
      const firstScheduleInformationAfterAboutToEndPeriod = getFirstScheduleInformationAfterAboutToEndPeriod(
        scheduleInformation,
        currentTime
      );
      if (firstScheduleInformationAfterAboutToEndPeriod)
        return `Next booking at ${format(
          firstScheduleInformationAfterAboutToEndPeriod.start,
          "HH:mm"
        )}`;
      return "No more bookings today";
    default:
      return null;
  }
};

export const RoomScheduleSubtitleNowPage = (
  roomState,
  scheduleInformation = [],
  currentTime = now(),
  durationInMinutes = 30
) => {
  switch (roomState) {
    case RoomState.BookedAndFree:
    case RoomState.BookedAndInUse:
    case RoomState.BookedAndUnknownSensor:
    case RoomState.Booked:
      return getBookedUntilText(
        scheduleInformation,
        currentTime,
        durationInMinutes
      );
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.BookingAboutToStartAndInUse:
    case RoomState.BookingAboutToStartAndUnknownSensor:
    case RoomState.BookingAboutToStart:
      return getAvailableForText(scheduleInformation, currentTime);
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToEndAndUnknownSensor:
    case RoomState.BookingAboutToEnd:
      return getAvailableInText(scheduleInformation, currentTime);
    default:
      return null;
  }
};

const getSensorUpdatedAgoFormattedText = (sensorStateTimestamp, now) => {
  const sensorLastUpdatedAgo = intervalToDuration({
    start: set(parseISO(sensorStateTimestamp), {
      seconds: 0,
      milliseconds: 0
    }),
    end: now
  });
  const roundedDuration = roundDownDurationIfMoreThanDay(sensorLastUpdatedAgo);
  const isUpdatedLessThanMinuteAgo = Object.values(roundedDuration).every(
    d => !d
  );

  if (isUpdatedLessThanMinuteAgo) return "Just updated";

  const { days, months, weeks, years } = roundedDuration ?? {};
  const isUpdatedMoreThanDayAgo = !!(days || months || weeks || years);
  const formattedDuration = formatDuration(
    roundedDuration,
    isUpdatedMoreThanDayAgo
  );

  return `Update from sensor ${formattedDuration} ago`;
};

const getBookedUntilText = (
  scheduleInformation = [],
  startDateTime,
  durationOfTimeslotInMinutes
) => {
  const availableTimeslot = getFirstAvailableTimeslot(
    scheduleInformation,
    startDateTime,
    durationOfTimeslotInMinutes
  );
  return `Booked until ${format(availableTimeslot.start, "HH:mm")}`;
};

const getAvailableForText = (scheduleInformation = [], startDateTime) => {
  const formattedDuration = intervalToFormattedDuration(
    scheduleInformation[0].start,
    startDateTime,
    true
  );

  return `Available for ${formattedDuration}`;
};

const getAvailableInText = (scheduleInformation = [], startDateTime) => {
  const lastAboutToEndBooking = getLastAboutToEndScheduleInformation(
    scheduleInformation,
    startDateTime
  );
  const minutesOccupied = differenceInMinutes(
    lastAboutToEndBooking.end,
    startDateTime
  );
  return `Available in ${minutesOccupied} ${pl("minute", minutesOccupied)}`;
};

const roomAvailabilityPeriodOfInterest = 15;

/**
 *
 * @param {*} scheduleInformation
 * @param {{minStartDate?:Date, maxStartDate?:Date}} interval
 * @returns
 */
const getScheduleInformationsThatStartsWithinInterval = (
  scheduleInformation = [],
  { minStartDate, maxStartDate }
) => {
  if (!minStartDate && !maxStartDate) return scheduleInformation;

  return scheduleInformation.filter(
    ({ start }) =>
      (!minStartDate || !isBefore(start, minStartDate)) &&
      (!maxStartDate || !isAfter(start, maxStartDate))
  );
};

/**
 *
 * @param {*} scheduleInformation
 * @param {{minStartDate?:Date, maxStartDate?:Date}} interval
 * @returns
 */
const getFirstScheduleInformationThatStartsWithinInterval = (
  scheduleInformation = [],
  interval
) => {
  const timeslotsWithinInterval = getScheduleInformationsThatStartsWithinInterval(
    scheduleInformation,
    interval
  );
  return timeslotsWithinInterval[0];
};

export const getFirstScheduleInformationAfterAboutToEndPeriod = (
  scheduleInformation = [],
  startDateTime
) =>
  getFirstScheduleInformationThatStartsWithinInterval(scheduleInformation, {
    minStartDate: addMinutes(startDateTime, roomAvailabilityPeriodOfInterest),
    maxStartDate: endOfDay(startDateTime)
  });

export const getLastAboutToEndScheduleInformation = (
  scheduleInformation = [],
  startDateTime
) => {
  const timeslots = scheduleInformation.filter(
    ({ end }) =>
      !isAfter(end, addMinutes(startDateTime, roomAvailabilityPeriodOfInterest))
  );

  return timeslots[timeslots.length - 1];
};

/**
 * Look trough meeting room schedule data and finds first free timeslot for provided duration.
 * @returns Start and end of timeslot or `undefined` if no free timeslot could be found.
 */
const getFirstAvailableTimeslot = (
  scheduleInformation = [],
  startDateTime,
  durationOfTimeslotInMinutes
) => {
  const start = startDateTime;

  if (!scheduleInformation.length)
    return {
      start,
      end: addMinutes(start, durationOfTimeslotInMinutes)
    };

  const scheduleAfterWhichTimeslotCouldBeFitted = scheduleInformation.find(
    (currentSchedule, index) => {
      const nextSchedule = scheduleInformation[index + 1];
      if (!nextSchedule) return true; // room is available after current schedule

      const startOfTimeslotToCheck = currentSchedule.end;
      const endOfTimeslotToCheck = addMinutes(
        startOfTimeslotToCheck,
        durationOfTimeslotInMinutes
      );

      return isWithinInterval(endOfTimeslotToCheck, {
        start: startOfTimeslotToCheck,
        end: nextSchedule.start
      });
    }
  );

  if (!scheduleAfterWhichTimeslotCouldBeFitted) return;

  const startOfTimeslot = scheduleAfterWhichTimeslotCouldBeFitted.end;
  return {
    start: startOfTimeslot,
    end: addMinutes(startOfTimeslot, durationOfTimeslotInMinutes)
  };
};

export const calculateDynamicFreeTimeslot = (
  scheduleInformation = [],
  roomState,
  startDate,
  durationOfTimeslotInMinutes
) => {
  const isBooked =
    roomState === RoomState.Booked ||
    roomState === RoomState.BookedAndFree ||
    roomState === RoomState.BookedAndInUse ||
    roomState === RoomState.BookedAndUnknownSensor;
  const isAboutToEnd =
    roomState === RoomState.BookingAboutToEnd ||
    roomState === RoomState.BookingAboutToEndAndFree ||
    roomState === RoomState.BookingAboutToEndAndInUse ||
    roomState === RoomState.BookingAboutToEndAndUnknownSensor;

  if (isBooked) return;

  const lastAboutToEndBooking = getLastAboutToEndScheduleInformation(
    scheduleInformation,
    startDate
  );

  const actualStartDate = isAboutToEnd ? lastAboutToEndBooking?.end : startDate;
  const desiredEndDate = addMinutes(
    actualStartDate,
    durationOfTimeslotInMinutes
  );

  const firstMeetingExcludingAboutToEndMeetings = getFirstScheduleInformationThatStartsWithinInterval(
    scheduleInformation,
    {
      minStartDate: actualStartDate,
      maxStartDate: desiredEndDate
    }
  );

  return {
    start: actualStartDate,
    end: firstMeetingExcludingAboutToEndMeetings?.start ?? desiredEndDate
  };
};
