import bodyTemplate from "./WorkSpaceWizard.body.template.html";
import footerTemplate from "./WorkSpaceWizard.footer.template.html";
import "./WorkSpaceWizard.scss";

import ko from "@shared/knockout/extended";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";

import { format } from "date-fns";
import { PopupManagerVM } from "../PopupManager/PopupManagerVM";
import { getDateKey } from "@shared/utils/dateHelpers";
import {
  showFailureMessage,
  ActionTypes,
  ActionObjs,
  showSuccessMessage
} from "@app/utils/FeedbackMessage";
import { ParkingService } from "../Parking/Service/ParkingService";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { WorkSpace } from "@shared/components/Workspace/Workspace/WorkSpace";
import { OfficePicker } from "@shared/components/Workspace/OfficePicker/OfficePicker";
import { InviteesPicker } from "./Pages/InviteesPicker/InviteesPicker";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import {
  GroupReservationApiModel,
  InviteeReservationApiModel
} from "@app/apimodels/GroupReservationApiModel";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { UserApp } from "@app/UserApp";
import { WorkspacePicker } from "@shared/components/Workspace/WorkspacePicker/WorkspacePicker";
import { WorkSpaceSummary } from "@shared/components/Workspace/WorkSpaceSummary/WorkSpaceSummary";
import { CheckInServiceSingleton } from "@app/services/CheckInService";
import { WorkspaceLocationVM } from "@shared/viewmodels/WorkspaceLocationVM";
import { GroupWorkspaceReservationServiceSingleton } from "@app/services/GroupWorkspaceReservationService";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { availabilityLocationData } from "@shared/viewmodels/AvailabilityLocationData";
import { Server400MultiErrorModel } from "@shared/viewmodels/Server400ErrorModel";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { InviteeVM } from "@app/viewmodels/InviteeVM";
import {
  getLastUsedLocationIdFromStorage,
  getValueFromLocalStorage,
  saveWorkspaceToLocalStorage
} from "@shared/utils/OfficeDay/storageHelpers";
import { WorkdayReservationServiceSingleton } from "@app/services/WorkdayReservationService";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { getOfficeId } from "@shared/utils/OfficeDay/helpers";
import { getGroupDeskIds } from "@app/utils/getGroupDeskIds";
import { statusIsConfirmedOrPending } from "@app/utils/statusIsConfirmedOrPending";
import { BreakpointVM } from "@app/viewmodels/ResponsiveDesign/BreakpointVM";

const Views = {
  WorkSpaceSummary: "WorkSpaceSummary",
  WorkSpace: "WorkSpace",
  OfficePicker: "OfficePicker",
  InviteesPicker: "InviteesPicker",
  WorkSpacePicker: "WorkSpacePicker",
  ActiveFilter: "ActiveFilter"
};

const eq = x => y => x === y;

export const WorkSpaceWizardVM = ({
  date,
  selectedWorkdayOfficeId = null,
  workspace = null,
  onBookingMade = () => {},
  preferredLocationId,
  user,
  mediator = Mediator()
}) => {
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const workspaceService = WorkspaceServiceSingleton();
  const workdayService = WorkdayReservationServiceSingleton();
  const workday = ko.pureComputed(
    () => WorkdayReservationsStoreSingleton().getForDate(date)[0]
  );
  const locationStore = LocationStoreSingleton();
  const lastUsedLocationId = getLastUsedLocationIdFromStorage(
    getValueFromLocalStorage
  );
  const userSelectedDifferentOffice = ko.observable(false);
  const userSelectedDifferentNode = ko.observable(false);
  const deskSelectionMode = ko.observable(false);
  const areaViewMode = ko.observable(false);
  const selectedFloor = ko.observable(null);
  const activeFilter = ko.observable(null);
  const deepestWorkspaceNodeId =
    workspace?.workAreaId ?? workspace?.floorId ?? workspace?.buildingId;

  const initialSelectedInvitees = ko.observableArray([]);
  const selectedInvitees = ko.observableArray([]);
  const groupValidationErrors = ko.observableArray([]);

  const checkedInDifferentOfficeId = hasWorkDayFeature
    ? null
    : CheckInServiceSingleton().checkedInDifferentOfficeId(
        date,
        workspace?.buildingId
      );

  const isEdit = ko.unwrap(workspace) !== null;

  const selectedOfficeId = ko.observable(null);
  const initialOfficeIdComputation = ko
    .computed(() => {
      const officeId = getOfficeId({
        ids: [
          selectedWorkdayOfficeId,
          checkedInDifferentOfficeId,
          deepestWorkspaceNodeId,
          lastUsedLocationId,
          preferredLocationId(),
          selectedInvitees
        ],
        selectedWorkdayOfficeId,
        lastUsedLocationId,
        deepestWorkspaceNodeId,
        checkedInDifferentOfficeId,
        date,
        locationStore,
        isEdit
      });
      selectedOfficeId(officeId);
    })
    .extend({ deferred: true });

  const summaryLocationId = ko.observable(null);

  // For dto-s
  const selectedDeskIds = ko.observableArray([]);
  const selectedLocationId = ko.observable(null);
  const selectedOfficeListener = selectedOfficeId.subscribe(() =>
    selectedFloor(null)
  );
  const deskSection = ko.observable(null);
  const parkingService = ParkingService(date, user);

  const selectedLocationIdSub = selectedLocationId.subscribe(() => {
    selectedDeskIds([]);
  });

  const activeView = ko.observable(Views.WorkSpace);

  const lastUsedLocationPickerType = ko.observable(null);

  const onClose = () => mediator.publish(Channels.CloseWorkspaceWizard);

  const loading = ko.observable(false);

  const getInvitees = () => {
    if (!(workspace?.members.length > 1)) return;

    const invitees = workspace.members
      ?.filter(
        ({ isOwner, status, userId }) =>
          !isOwner && userId !== user.id && statusIsConfirmedOrPending(status)
      )
      .map(({ user }) => InviteeVM(user));
    selectedInvitees(invitees);
    initialSelectedInvitees(invitees);
  };

  if (getFeatures().has(FeatureNames.GROUP_RESERVATIONS)) {
    getInvitees();
  }

  if (isEdit) {
    if (workspace.deskId) selectedDeskIds([workspace.deskId]);

    if (getFeatures().has(FeatureNames.GROUP_DESK_RESERVATIONS)) {
      selectedDeskIds(getGroupDeskIds(workspace)());
    }
  }

  const handleSuccess = () => {
    onBookingMade();
    if (parkingService.userCanSeeSuggestionPopUp()) {
      parkingService.openParkingConfirmationWindow();
      return;
    }
    if (isEdit) {
      showSuccessMessage(ActionTypes.Updated, ActionObjs.Workspace);
    } else {
      showSuccessMessage(ActionTypes.Booked, ActionObjs.Workspace);
    }
  };

  const isGroupBooking = ko.pureComputed(
    () =>
      getFeatures().has(FeatureNames.GROUP_RESERVATIONS) &&
      (selectedInvitees().length > 0 || workspace?.groupReservationId)
  );

  const handleInviteesServerError = (error, actionType) => {
    if (error.status === 400) {
      try {
        const { errorDescriptions } = Server400MultiErrorModel(error);
        groupValidationErrors(errorDescriptions);
        return;
      } catch (innerError) {
        console.error(innerError);
      }
    }

    showFailureMessage(actionType, ActionObjs.Workspace, error, date);
  };

  const bookGroupReservation = nodeIdFallback => {
    groupValidationErrors([]);

    const [firstDeskId, ...deskIds] = ko.unwrap(selectedDeskIds);

    const currentUser = UserApp.resolveUser();
    const groupReservation = new GroupReservationApiModel({
      startDate: getDateKey(date),
      endDate: getDateKey(date),
      owner: new InviteeReservationApiModel({
        isOwner: true,
        userId: currentUser.id,
        nodeId: firstDeskId || nodeIdFallback,
        status: ReservationStatus.Confirmed
      }),
      invitees: selectedInvitees().map(
        (inviteeSearchObj, index) =>
          new InviteeReservationApiModel({
            userId: inviteeSearchObj.id,
            nodeId: deskIds[index] || nodeIdFallback,
            status: ReservationStatus.InvitationPending
          })
      )
    });

    const gwrService = GroupWorkspaceReservationServiceSingleton();
    return gwrService
      .addGroupWorkspaceReservation(groupReservation, workspace)
      .then(() => {
        handleSuccess();
        onClose();
      })
      .catch(error => handleInviteesServerError(error, ActionTypes.Booked))
      .finally(() => loading(false));
  };

  const handleWorkspaceUpdate = nodeId => {
    const currentUser = UserApp.resolveUser();
    const [firstDeskId, ...deskIds] = ko.unwrap(selectedDeskIds);

    const members = [
      new InviteeReservationApiModel({
        userId: currentUser.id,
        nodeId: firstDeskId || nodeId,
        isOwner: true
      }),
      ...selectedInvitees().map(
        (inviteeSearchObj, index) =>
          new InviteeReservationApiModel({
            userId: inviteeSearchObj.id,
            nodeId: deskIds[index] || nodeId,
            isOwner: false
          })
      )
    ];

    return workspaceService
      .updateWorkspace(workspace.id, members, date)
      .then(async newWorkspace => {
        saveWorkspaceToLocalStorage(newWorkspace.nodeId, newWorkspace.deskId);

        if (hasWorkDayFeature) return;
        await parkingService.getParkingData(newWorkspace);
      })
      .then(async _data => {
        await workdayService.getWorkdayForSingleDay(date);
      })
      .then(() => handleSuccess())
      .then(() => {
        if (hasWorkDayFeature) return;
        // In case the user edits shift and the new building don't have a corresponding parking lot from the parking reservation
        // we will delete the user's parking reservation.
        parkingService.removeParkingReservationDifferentBuilding(date);
      })
      .then(() => onClose())
      .catch(error => {
        if (isGroupBooking()) {
          handleInviteesServerError(error, ActionTypes.Updated);
        } else {
          showFailureMessage(
            ActionTypes.Updated,
            ActionObjs.Workspace,
            error,
            date
          );
          onClose();
        }
      })
      .finally(() => {
        loading(false);
      });
  };

  const onConfirm = () => {
    loading(true);

    const lastUsedDeskId = getValueFromLocalStorage(BuildingNodeTypes.Desk);
    const locId = selectedLocationId() ?? selectedOfficeId();
    const deskId =
      selectedDeskIds()[0] ??
      (userSelectedDifferentOffice() ||
      userSelectedDifferentNode() ||
      (hasWorkDayFeature && workday()?.nodeId !== selectedWorkdayOfficeId)
        ? null
        : lastUsedDeskId);

    const nodeId = deskId || locId;
    const invitees = selectedInvitees().map(inviteeSearchObj => ({
      userId: inviteeSearchObj.id,
      nodeId,
      isOwner: false
    }));

    if (!isEdit && isGroupBooking()) {
      bookGroupReservation(nodeId);
    } else if (isEdit) {
      if (hasWorkDayFeature && selectedWorkdayOfficeId)
        showReplaceWorkspacePopup({
          buildingName: workspace.officeName,
          onClose,
          onPrimaryButtonClick: () => handleWorkspaceUpdate(nodeId)
        });
      else {
        handleWorkspaceUpdate(nodeId);
      }
    } else {
      workspaceService
        .postWorkspace(
          {
            date: getDateKey(date),
            nodeId
          },
          date,
          invitees
        )
        .then(async newWorkspace => {
          saveWorkspaceToLocalStorage(newWorkspace.nodeId, newWorkspace.deskId);
          await parkingService.getParkingData(newWorkspace);
        })
        .then(async _data => {
          if (workday()) return Promise.resolve();

          await workdayService.getWorkdayForSingleDay(date);
        })
        .then(() => handleSuccess())
        .catch(error =>
          showFailureMessage(
            ActionTypes.Booked,
            ActionObjs.Workspace,
            error,
            date
          )
        )
        .finally(() => {
          loading(false);
          onClose();
        });
    }
  };

  const activeViewData = ko.observable({});

  // Explicit methods for picking locations so we can track it and provide back functionality
  const goHome = () => {
    lastUsedLocationPickerType(null);
    activeView(Views.WorkSpace);
  };

  const openSummary = locationId => {
    summaryLocationId(locationId);
    lastUsedLocationPickerType(Views.WorkSpacePicker);
    activeView(Views.WorkSpaceSummary);
  };

  const openOfficePicker = () => {
    if (loading()) return;
    lastUsedLocationPickerType(Views.WorkSpace);
    activeView(Views.OfficePicker);
  };

  const openInviteesPicker = () => {
    if (loading()) return;
    lastUsedLocationPickerType(Views.WorkSpace);
    activeView(Views.InviteesPicker);
  };

  const openActiveFilterView = filter => {
    lastUsedLocationPickerType(Views.WorkSpacePicker);
    activeViewData({ options: filter.uniqueOptions });
    activeView(Views.ActiveFilter);
    activeFilter(filter);
  };
  const selectInviteesObservable = ko.observable(null);
  const isDirtyObservable = ko.observable(null);

  const selectInvitees = () =>
    selectInviteesObservable(!selectInviteesObservable());

  const { isBreakpointMatched } = BreakpointVM("(max-width: 500px)");

  const openWorkspacePicker = () => {
    lastUsedLocationPickerType(Views.WorkSpace);
    activeView(Views.WorkSpacePicker);
  };

  const workspaceSummaryVisible = activeView.map(eq(Views.WorkSpaceSummary));
  const workspaceVisible = activeView.map(eq(Views.WorkSpace));

  const goBack = () => {
    activeView(lastUsedLocationPickerType());
  };

  const inviteesPickerVisible = activeView.map(eq(Views.InviteesPicker));

  const hasPopupConfirmButton = ko.pureComputed(
    () => isBreakpointMatched() && inviteesPickerVisible()
  );

  const lastSelectedId = ko.pureComputed(() => {
    const location = locationStore.get(lastUsedLocationId);
    if (!lastUsedLocationId || !location || checkedInDifferentOfficeId)
      return false;
    const { disabled } = WorkspaceLocationVM(
      date,
      lastUsedLocationId,
      null,
      isEdit
    );
    if (!disabled()) return lastUsedLocationId;
  });
  const userChooseLastSelectedWorkdayId = ko.pureComputed(() => {
    if (!selectedWorkdayOfficeId || !lastSelectedId()) return false;
    const lastSelectedOffice = locationStore.getBuildingForId(lastSelectedId());

    return lastSelectedOffice?.id === selectedWorkdayOfficeId;
  });

  const selectedInviteesIsDirty = ko.pureComputed(() => {
    const initialIds = initialSelectedInvitees().map(({ id }) => id);
    const selectedIds = selectedInvitees().map(({ id }) => id);

    return (
      getFeatures().has(FeatureNames.GROUP_RESERVATIONS) &&
      (initialIds.length !== selectedIds.length ||
        selectedIds.some(id => initialIds.indexOf(id) === -1))
    );
  });

  const increasedNumberOfInvitees = ko.pureComputed(
    () => selectedInvitees().length > initialSelectedInvitees().length
  );

  const unselectLocation = () => {
    if (!hasWorkDayFeature) {
      selectedOfficeId(null);
      userSelectedDifferentOffice(true);
    }
    selectedLocationId(null);
    selectedDeskIds([]);
  };

  const selectedInviteesSub = selectedInvitees.subscribe(() => {
    if (increasedNumberOfInvitees()) {
      unselectLocation();
    }

    groupValidationErrors([]);
  });

  const forceNewDeskSuggestions = ko.pureComputed(() => {
    return (
      selectedInviteesIsDirty() ||
      userSelectedDifferentNode() ||
      userSelectedDifferentOffice()
    );
  });

  const groupSize = ko.pureComputed(
    () => (ko.unwrap(selectedInvitees)?.length || 0) + 1
  );

  return {
    nav: {
      goHome,
      goBack,
      activeViewData
    },

    // Overview
    workspaceSummaryParams: {
      date,
      deskSection,
      summaryLocationId,
      selectedDeskIds,
      areaViewMode,
      usePendingOrConfirmedMemberCount: isEdit,
      selectedInvitees,
      isGroupBooking,
      workspace
    },

    officePickerParams: {
      date,
      selectedOfficeId,
      initialSelection: selectedOfficeId,
      userSelectedDifferentOffice,
      selectedLocationId,
      selectedDeskIds,
      goHome,
      selectedInvitees,
      usePendingOrConfirmedMemberCount: isEdit,
      workspace,
      forceNewDeskSuggestions
    },

    inviteePickerParams: {
      date,
      selectedInvitees,
      goHome,
      selectInviteesObservable,
      isDirtyObservable
    },

    workSpaceParams: {
      selectedOfficeId,
      userSelectedDifferentOffice,
      selectedLocationId,
      openOfficePicker,
      openInviteesPicker,
      deskSection,
      selectedInvitees,
      initialSelectedInvitees,
      openWorkspacePicker,
      workspace,
      userSelectedDifferentNode,
      checkedInDifferentOfficeId,
      lastSelectedId,
      date,
      groupValidationErrors,
      loading,
      selectedWorkdayOfficeId,
      userChooseLastSelectedWorkdayId,
      deskSelectionMode,
      areaViewMode,
      selectedInviteesIsDirty,
      usePendingOrConfirmedMemberCount: isEdit,
      selectedDeskIds,
      forceNewDeskSuggestions
    },

    workspacePickerParams: {
      selectedOfficeId,
      date,
      selectedFloor,
      selectedLocationId,
      deepestWorkspaceNodeId,
      userSelectedDifferentNode,
      openActiveFilterView,
      selectedDeskIds,
      deskSection,
      openSummary,
      goHome,
      goBack,
      lastUsedLocationId,
      selectedInvitees,
      increasedNumberOfInvitees,
      userChooseLastSelectedWorkdayId,
      usePendingOrConfirmedMemberCount: isEdit
    },
    areaViewMode,
    activeFilter,
    activeViewData,
    deskSection,
    workspaceMapFooterVisible: ko.pureComputed(
      () => workspaceVisible() && deskSelectionMode()
    ),
    workspaceFooterVisible: ko.pureComputed(
      () => workspaceVisible() && !deskSelectionMode() && !areaViewMode()
    ),
    bookButtonText: ko.pureComputed(() =>
      selectedInvitees()?.length ? "Book desks" : "Book desk"
    ),
    disableBookButton: ko.pureComputed(() => {
      const bookableOnRootLevel = selectedOfficeId()
        ? !locationStore.getBuildingForId(selectedOfficeId())?.floors.length
        : false;

      const selectedId = selectedDeskIds()[0] ?? selectedLocationId();

      const locId = selectedLocationId() ?? selectedOfficeId();
      if (selectedInvitees().length > 0 && locId) {
        const {
          unavailableForGroupBookingDueToAvailability,
          unavailableForGroupBookingDueToDeskBookingOnly
        } = availabilityLocationData(
          date,
          locationStore.get(locId),
          selectedInvitees().length + 1,
          isEdit
        );
        if (
          unavailableForGroupBookingDueToAvailability() ||
          unavailableForGroupBookingDueToDeskBookingOnly()
        )
          return true;
      }

      const workspaceDeskIds = getGroupDeskIds(workspace);

      // If the user chooses a different desk then from his current reservation enable button
      // spread is necesarry, otherwise the value is not updated.
      const selectedDeskIsDifferentFromWorkspaceDesk =
        new Set([...workspaceDeskIds(), ...selectedDeskIds()]).size !==
        workspaceDeskIds().length;

      if (hasWorkDayFeature) {
        // If the user enters edit mode and chooses the last selected building which has desk booking mandatory we will enable the button if workspace doesn't exist or if chosen node is different from lastUsedLocationId(local storage)
        const lastSelectedOfficeIdIsDifferentFromWorkspaceNodeId =
          userChooseLastSelectedWorkdayId() &&
          !selectedInviteesIsDirty() &&
          workspace?.buildingId !== selectedWorkdayOfficeId &&
          deepestWorkspaceNodeId !== lastUsedLocationId;

        // If the user has workspace and enters edit mode if the number of invitees are lower or it is the same but with different people, we want to show location info and enable the book button
        const numberOfInviteesIsLowerOrSameButDifferentInvitees =
          workspace &&
          selectedInviteesIsDirty() &&
          !increasedNumberOfInvitees();

        // If a chosen building has mandatory desk booking and it is bookable on the root level(no nodes) enable the button
        const selectedOfficeIsBookableOnRootLevel =
          selectedWorkdayOfficeId &&
          workspace?.buildingId !== selectedWorkdayOfficeId &&
          bookableOnRootLevel;

        // If a user chooses a node that is different from the deepest node in the workspace(area/floor) enable the button
        const selectedLocationIdIsDifferentFromDeepestWorkspaceNodeId =
          selectedLocationId() &&
          selectedLocationId() !== deepestWorkspaceNodeId;

        const selectedLocationExistWithChangedInvitees =
          selectedLocationId() && selectedInviteesIsDirty();

        if (
          lastSelectedOfficeIdIsDifferentFromWorkspaceNodeId ||
          selectedOfficeIsBookableOnRootLevel ||
          numberOfInviteesIsLowerOrSameButDifferentInvitees ||
          selectedLocationIdIsDifferentFromDeepestWorkspaceNodeId ||
          selectedLocationExistWithChangedInvitees ||
          selectedDeskIsDifferentFromWorkspaceDesk
        ) {
          return false;
        }

        return true;
      }

      if (!workspace) {
        // Two possible ways that user will do something he change office or not
        if (!userSelectedDifferentOffice()) {
          // If user choose office and node enable button
          // If last locationId exist check if it is same with selectedOfficeId, because last location can be closed(opening days/closed/full) on different days then enable button
          // If default building is bookable on root level because enebale button
          if (
            selectedOfficeId() &&
            (selectedId ||
              (lastSelectedId() && !checkedInDifferentOfficeId) ||
              bookableOnRootLevel)
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          // If the user change office and chosen one is bookable on the root level we will enable the button
          if (bookableOnRootLevel) return false;
          // If the user change office and choose one is not bookable on the root level(floors exist) and the user didn't choose a node for booking
          if (!bookableOnRootLevel && !selectedId) return true;
        }
      }

      // If the user has workspace we need to check if did user chose a different office or chose some other floor/area in the same building
      // If user is checkedin different office button will be disabled until user changes different office or until node is chosen
      // If user changed building and is not bookable on root level(no floors inside building) and didn't chose node button will be disabled
      // If a user didn't change building but chose a desk depending if it is the same desk button will be disabled
      // If a user didn't change building but chose node which is the same as from workspace button will be disabled
      if (
        userSelectedDifferentOffice() &&
        !bookableOnRootLevel &&
        !selectedId
      ) {
        return true;
      }

      if (
        checkedInDifferentOfficeId &&
        locationStore.get(checkedInDifferentOfficeId) &&
        !userSelectedDifferentOffice() &&
        !userSelectedDifferentNode()
      ) {
        return !bookableOnRootLevel;
      }

      if (selectedDeskIds().length) {
        return !selectedDeskIsDifferentFromWorkspaceDesk;
      }

      return (
        deepestWorkspaceNodeId ===
          (selectedLocationId() || selectedOfficeId()) &&
        !selectedInviteesIsDirty()
      );
    }),
    onDone: () => {
      userSelectedDifferentNode(true);
      deskSelectionMode(false);
      goHome();
      deskSection()?.applyChanges();
    },
    disableDoneButton: ko.pureComputed(() => {
      if (isGroupBooking()) {
        return (
          selectedDeskIds().length !== groupSize() ||
          !deskSection()?.selectedDeskIsDirty()
        );
      }

      return !deskSection()?.selectedDeskIsDirty();
    }),
    selectedLocationId,
    selectedOfficeId,
    userSelectedDifferentOffice,
    lastUsedLocationId,

    activeView: ko.pureComputed(activeView),
    deskSelectionMode,
    workspaceSummaryVisible,
    workspaceVisible,
    officePickerVisible: activeView.map(eq(Views.OfficePicker)),
    inviteesPickerVisible,
    workspacePickerVisible: activeView.map(eq(Views.WorkSpacePicker)),
    activeFilterViewVisible: activeView.map(eq(Views.ActiveFilter)),

    date,

    canSelectInvitees: isDirtyObservable,
    selectInvitees,

    hasPopupConfirmButton,

    onClose,
    loading: ko.pureComputed(() => {
      const selfLoading = loading();
      if (selfLoading) return true;

      const desksLoading = deskSection()?.loading() ?? false;
      return desksLoading;
    }),

    onConfirm,

    dispose: () => {
      selectedInviteesSub.dispose();
      selectedLocationIdSub.dispose();
      selectedOfficeListener.dispose();
      initialOfficeIdComputation.dispose();
    }
  };
};

// Extend VM with helpers for popup manager
const {
  getTitle,
  showFooter,
  hasBackButton,
  onBack,
  hasCloseButton,
  disableClosing,
  hasConfirmButton,
  disableConfirmButton,
  onConfirm
} = PopupManagerVM.Keys;

Object.assign(WorkSpaceWizardVM, {
  [getTitle]: vm => {
    switch (vm.activeView()) {
      case Views.WorkSpaceSummary:
        return "Desk";
      case Views.WorkSpace:
        return "Book a desk";
      case Views.OfficePicker:
        return "Select an office";
      case Views.InviteesPicker:
        return "Select invitees";
      case Views.WorkSpacePicker:
        return "Select a desk";
      case Views.ActiveFilter:
        return "Filter floor";
      default:
        return format(vm.date, "EEEE, d MMMM");
    }
  },
  [showFooter]: vm => {
    switch (vm.activeView()) {
      case Views.WorkSpace:
        return vm.workspaceFooterVisible() || vm.workspaceMapFooterVisible();
      case Views.InviteesPicker:
        return vm.inviteesPickerVisible();
      case Views.ActiveFilter:
        return vm.activeFilterViewVisible();
      default:
        return false;
    }
  },
  [hasBackButton]: vm => {
    if (vm.workspaceVisible() && !vm.areaViewMode() && !vm.deskSelectionMode())
      return false;

    return true;
  },
  [hasCloseButton]: vm => !vm.hasPopupConfirmButton(),
  [hasConfirmButton]: vm => vm.hasPopupConfirmButton(),

  [onConfirm]: vm => {
    vm.selectInvitees();
  },
  [onBack]: vm => () => {
    switch (vm.activeView()) {
      case Views.WorkSpaceSummary:
        if (vm.areaViewMode()) vm.areaViewMode(false);
        else vm.nav.goBack();
        break;
      case Views.WorkSpace:
        if (vm.deskSelectionMode()) {
          vm.deskSelectionMode(false);
          vm.deskSection()?.resetChanges();
        }
        if (vm.areaViewMode()) vm.areaViewMode(false);

        break;
      case Views.OfficePicker:
      case Views.ActiveFilter:
        vm.nav.goBack();
        break;
      case Views.WorkSpacePicker:
      default:
        vm.nav.goHome();
    }
  },
  [disableClosing]: vm => vm.loading(),
  [disableConfirmButton]: vm => !vm.canSelectInvitees()
});

export const WorkSpaceWizard = {
  register: ko => {
    WorkSpace.register(ko);
    OfficePicker.register(ko);
    InviteesPicker.register(ko);

    WorkspacePicker.register(ko);
    WorkSpaceSummary.register(ko);
    ko.components.register("workspace-wizard-body", {
      template: bodyTemplate
    });

    ko.components.register("workspace-wizard-footer", {
      template: footerTemplate
    });
  }
};

const showReplaceWorkspacePopup = ({
  buildingName,
  onClose,
  onPrimaryButtonClick
}) => {
  Mediator().publish(Channels.ToggleConfirmationWindow, {
    options: {
      title: "Replace previous bookings?",
      subtitle: `Your previous bookings at ${buildingName} will be removed and replaced by your new booking.`,
      primaryButtonText: "Replace",
      secondaryButtonText: "Cancel",
      primaryButtonClass: "MpqButton--black",
      secondaryButtonClass: "MpqButton--outlineBlack",
      smallerSubText: true,
      buttonInColumnOrder: true,
      onPrimaryButtonClick,
      onSecondaryButtonClick: () => onClose()
    }
  });
};
