import { formatDateToNoUTC } from "@app/utils/formatDateToNoUTC";
import parseISO from "date-fns/parseISO";
import { MeetingAttendeeApiModel } from "./MeetingAttendeeApiModel";
import { MeetingRoomApiModel } from "./MeetingRoomApiModel";

export class MeetingApiModel {
  constructor(obj) {
    this.id = obj.id || null;
    this.title = obj.title;
    this.startDate = parseISO(
      formatDateToNoUTC(obj.startDateTime, obj.isAllDay)
    );
    this.endDate = parseISO(formatDateToNoUTC(obj.endDateTime, obj.isAllDay));
    this.isAllDay = obj.isAllDay;
    this.isCanceled = obj.isCanceled;
    this.meetingRooms = obj.meetingRooms
      .filter(r => r.id !== "00000000-0000-0000-0000-000000000000")
      .map(MeetingRoomApiModel.fromApiObj);

    this.attendees = obj.attendees.map(MeetingAttendeeApiModel.fromApiObj);
    this.onlineMeetingUrl = obj.onlineMeetingUrl;
    this.description = obj.message;
    this.preview = obj.messagePreview;
    this.organizerTimezone = obj.organizerTimezone || null;
  }

  static fromApiObj(obj) {
    return new MeetingApiModel(obj);
  }

  static toUpdateApiObj(apiModel, currentUserTimezone) {
    return {
      startDateTime: apiModel.startDate,
      endDateTime: apiModel.endDate,
      title: apiModel.title,
      message: apiModel.description,
      attendees: apiModel.attendees.map(a => a.email),
      isOnlineMeeting: !!apiModel.onlineMeetingUrl,
      isAllDay: apiModel.isAllDay,
      organizerTimezone: apiModel.organizerTimezone ?? currentUserTimezone,
      isCanceled: apiModel.isCanceled,
      id: apiModel.id
    };
  }
}
