export class UserSearchApiModel {
  constructor(apiObj) {
    const cleanedName = (apiObj.name || "").trim();

    this.id = apiObj.id;
    this.name = cleanedName || null;
    this.email = apiObj.email;
    this.isNew = apiObj.isNew;
    this.initials = apiObj.initials;
    this.imageHash = apiObj.imageHash;
  }
}
