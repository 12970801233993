import "./ProfilePage.scss";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { Channels } from "@shared/Channels";
import template from "./ProfilePage.template.html";
import ko from "@shared/knockout/extended";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { getAppFeatures } from "@app/services/AppFeatures.js";
import { MeetSection } from "./MeetSection.js";
import { WorkingHours } from "./WorkingHours/WorkingHours";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { NotificationPreferencesServiceSingleton } from "@app/services/NotificationPreferencesService";
import {
  notificationPreferencesCategories,
  notificationPreferencesTypes
} from "@app/apimodels/NotificationPreferencesApiModel";
import { ExportHistoricOverview } from "./components/ExportBookingOverview/ExportBookingOverview";
import { ExtrasTypes } from "@admin/components/SettingsViewer/views/ExtrasView/helpers/ExtrasTypes";

export const ProfilePageVM = ({
  user,
  workingHours,
  mediator,
  api,
  preferredLocationId,
  profilePicture,
  settings,
  imageHash,
  notificationPreferences
}) => {
  const NotificationPreferencesService = NotificationPreferencesServiceSingleton();

  const emailAddress = ko.pureComputed(
    () => settings() && settings().contactEmailAddress
  );

  const companyWebsite = ko.pureComputed(
    () => settings() && settings().companyWebsite
  );

  const formattedCompanyWebsite = ko.pureComputed(() =>
    companyWebsite() ? formatUrl(settings().companyWebsite) : null
  );

  const formatUrl = url => url.trim().replace(/https?:\/\//, "");

  const privacyPolicyUrl = ko.pureComputed(() => {
    return settings()?.effectivePrivacyPolicy;
  });

  const buildings = ko.pureComputed(LocationStoreSingleton().getBuildings);

  const clientPreferredBuildingId = ko.observable(preferredLocationId());

  const activeBuilding = ko.pureComputed(() =>
    buildings().find(b => b.id === preferredLocationId())
  );

  const saving = ko.observable(false);

  const toggleEmailUpdatePreferences = () => {
    // if value is not set in the BE treat it as disabled by default. (first click will enable)
    const next = !notificationPreferences()?.updates?.userEmail ?? true;

    NotificationPreferencesService.updatePreference({
      category: notificationPreferencesCategories.updates,
      type: notificationPreferencesTypes.userEmail,
      enabled: next
    }).then(({ category, type, enabled }) => {
      notificationPreferences()[category][type] = enabled;
      notificationPreferences.valueHasMutated();
    });
  };

  const save = () => {
    if (preferredLocationId() !== clientPreferredBuildingId()) {
      saving(true);
      api
        .sendLocationPreference(clientPreferredBuildingId())

        .then(() => {
          // Share with app
          preferredLocationId(clientPreferredBuildingId());
          publishAIEvent(
            EventNames.UpdatePreferredBuilding,
            null,
            ResultTypes.Success
          );
        })
        .catch(e => {
          mediator.publish(Channels.OpenStatusMessage, {
            type: "failure",
            reason:
              "We were unable to save your changes. Please try again later."
          });
          publishAIEvent(
            EventNames.UpdatePreferredBuilding,
            null,
            ResultTypes.Fail
          );
        })
        .finally(() => {
          saving(false);
          // Store locally
          localStorage.setItem(
            BuildingNodeTypes.LastChosenOffice,
            clientPreferredBuildingId()
          );
        });
    }
  };

  const openPictureUpload = () => {
    mediator.publish(Channels.OpenProfilePictureUploadWindow, user);
  };

  const featureEnabled = getAppFeatures().get(
    FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW
  )?.enabledForUsers;

  const meetSection = featureEnabled ? MeetSection(user) : null;

  // Settings panel
  const Views = {
    DefaultBuilding: "DefaultBuilding"
  };
  const activeView = ko.observable({});
  const settingsIsOpened = ko.observable(false);

  const openDefaultBuildingView = () => {
    settingsIsOpened(true);
    activeView(Views.DefaultBuilding);
  };
  const changeDefaultBuilding = building => {
    if (saving()) return;
    clientPreferredBuildingId(building.id);
    save();
  };
  const defaultBuldingViewVisible = activeView.map(eq(Views.DefaultBuilding));
  return {
    user,
    email: user.email,
    initials: user.initials,
    imageHash,
    emailAddress,
    formattedCompanyWebsite,
    companyWebsite,
    buildings,
    clientPreferredBuildingId,
    openPictureUpload,
    profilePicture,
    notificationPreferences,
    toggleEmailUpdatePreferences,

    hasWorkingHoursFeature: getFeatures().has(FeatureNames.MEETING_HOURS),
    workingHours,

    allowUpload: getFeatures().has(FeatureNames.PROFILE_PICTURES),
    privacyPolicyUrl,
    saving,
    save,
    settings,
    extrasType: ExtrasTypes.USER_PROFILE,
    meetSection,
    // settings view
    settingsIsOpened,
    activeBuilding,
    defaultBuldingViewVisible,
    openDefaultBuildingView,
    goBack: () => {
      activeView(null);
      settingsIsOpened(!settingsIsOpened());
    },
    changeDefaultBuilding,

    hasHistoricBookingOverviewFeature: getFeatures().has(
      FeatureNames.HISTORIC_BOOKING_OVERVIEW
    )
  };
};
export const ProfilePage = {
  register: ko => {
    WorkingHours.register(ko);
    ExportHistoricOverview.register(ko);

    ko.components.register("profile-body", {
      template: template
    });
  },

  bodyComponentName: "profile-body"
};

const eq = x => y => x === y;
