import { byAttendeeAvailabilityStatus } from "@app/apimodels/AvailabilityStatus";
import { LocationType } from "@app/apimodels/LocationType";
import { groupBy } from "@shared/utils/groupBy";
import { byPropAsc, combineSorters } from "@shared/utils/sort";
import { stringCompareAsc } from "@shared/utils/sortHelper";

export const createInviteesGroups = (attendees, userOfficeDay) => {
  const byBuilding = groupBy(
    a => a.shiftBuildingName,
    attendees.filter(a => a.locationType === LocationType.Local) || []
  );

  const byUserShiftFirst = (g1, g2) => {
    const userLocation = userOfficeDay.buildingName;
    if (g1.groupKey === userLocation) return -1;
    if (g2.groupKey === userLocation) return 1;
    return stringCompareAsc(g1.groupKey, g2.groupKey);
  };
  const byDisplayName = (a1, a2) => {
    if (a1.displayName === "You") return -1;
    if (a2.displayName === "You") return 1;
    return stringCompareAsc(a1.displayName, a2.displayName);
  };

  const attendeeGroups = [
    ...Object.entries(byBuilding)
      .map(e => ({
        groupKey: e[0],
        attendees: e[1]
      }))
      .sort(byUserShiftFirst),
    {
      groupKey: "Remote",
      attendees:
        attendees.filter(a => a.locationType === LocationType.Remote) || []
    },
    {
      groupKey: "Unknown",
      attendees:
        attendees.filter(a => a.locationType === LocationType.Unknown) || []
    }
  ].map(g => ({
    groupKey: g.groupKey,
    attendees: g.attendees.sort(
      combineSorters(
        byPropAsc("availability", byAttendeeAvailabilityStatus),
        byDisplayName
      )
    )
  }));

  return attendeeGroups;
};
