export const initialsForName = name => {
  const upperCaseWords = name.split(" ").filter(startsWithUpperCase);
  if (upperCaseWords.length < 2) {
    return returnTwoInitials(name.split(" "));
  }
  return returnTwoInitials(upperCaseWords);
};

export const stringToColor = string => {
  const nameToNumber = string
    .split("")
    .map(c => c.charCodeAt(0))
    .reduce((a, b) => a + b, 0);

  const hexCodes = Object.values(Colors);

  return hexCodes[nameToNumber % hexCodes.length];
};

const startsWithUpperCase = word => {
  const initial = word.charAt(0);

  return initial === initial.toUpperCase();
};

const returnTwoInitials = wordsArray => {
  return wordsArray
    .slice(0, 2)
    .map(w => w.charAt(0))
    .join("")
    .toUpperCase();
};

const Colors = {
  Yellow: "#FFEA9F",
  Orange: "#FFD3A0",
  Pink: "#FFBEC2",
  Green: "#B5E5C9",
  LightBlue: "#DCECFF",
  LightPurple: "#CED3F2",
  Purple: "#D0B4DA"
};
