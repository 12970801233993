import WorkAreaApiModel from "./WorkAreaApiModel";
import { byPropAsc } from "../utils/sort";
class FloorApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.number = obj.number;
    this.capacity = obj.capacity;
    this.workAreas = (obj.areas || [])
      .sort(byPropAsc("name"))
      .map(wa => new WorkAreaApiModel(wa));
    this.areas = this.workAreas;

    this.type = FloorApiModel;

    this.hasMap =
      !!obj.hasMap ||
      obj.id === "878412fb-5c5b-43b3-91a6-dfb49cb5d09f" || // Floor 0 in Building with a long name on dev
      obj.id === "a2a23ef2-8184-4a5d-9b04-82b3156c3c97"; // Floor 1 in Amsterdam on dev
  }
}

export default FloorApiModel;
