import "./BuildingPicker.scss";
import template from "./BuildingPicker.template.html";

import ko from "@shared/knockout/extended";
import { sort, byPropAsc } from "@shared/utils/sort";
import { KOComponent } from "@shared/knockout/KOComponent";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { MeetApi } from "@shared/services/MeetApi";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { getArrayOfWorkingDays } from "../popups/WorkingHours/helpers";
import { AppApi } from "@app/services/AppApi";

const BuildingPickerVM = ({
  preferredLocationId,
  workingHours,
  isNew,
  api = AppApi
}) => {
  const locationStore = LocationStoreSingleton();
  const daysWithWorkingHours = getArrayOfWorkingDays();
  const chosenBuildingId = ko.observable(preferredLocationId());
  const loading = ko.observable(false);

  const buildings = ko
    .pureComputed(locationStore.getBuildings)
    .map(sort(byPropAsc("name")));

  const openCalendar = () => {
    loading(true);

    // Store locally
    localStorage.setItem(
      BuildingNodeTypes.LastChosenOffice,
      chosenBuildingId()
    );

    // Store the location preference on the server
    const preferredBuildingPromise = api
      .sendLocationPreference(chosenBuildingId())
      .catch(e => {
        // Not super critical because we fall back to local storage
      })
      .then(() => {
        // Write to ui observable so other components can use it
        preferredLocationId(chosenBuildingId());
      });

    let workingHoursPromise;

    if (isNew) {
      workingHoursPromise = MeetApi.setUserPreference({
        workingHours: {
          ianaLocalTimezone: locationStore.getBuildingForId(chosenBuildingId())
            .ianaTimezone,
          days: daysWithWorkingHours
        }
      })
        //In case the user has already enabled the calendar by skipping the onboarding flow,
        //we want them to see the working hours before refreshing the page
        .then(updatedPreferences =>
          workingHours(updatedPreferences.workingHours)
        );
    } else {
      workingHoursPromise = Promise.resolve();
    }

    Promise.all([preferredBuildingPromise, workingHoursPromise]).finally(() =>
      loading(false)
    );
  };

  return { buildings, loading, openCalendar, chosenBuildingId };
};

export const BuildingPicker = KOComponent(
  "building-picker",
  BuildingPickerVM,
  template
);
