import ko from "@shared/knockout/extended";
import { indexBy } from "@shared/utils/indexBy.js";
import { LayerType, LayerState } from "@app/utils/svg-helpers.js";
import { ConnectedColleaguesStoreSingleton } from "@app/services/ConnectedColleaguesStore.js";
import { AdminApi } from "@shared/services/AdminApi.js";
import { Suggestion } from "@shared/apimodels/Suggestion.js";
import { Api } from "@shared/services/Api.js";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import { isSameDay, startOfDay } from "date-fns";
import { getDateKey } from "@shared/utils/dateHelpers";
import { getWorkspaceIcon } from "@app/utils/workspaceHelpers";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import {
  getLastBookedNodeIdFromSessionStorage,
  getValueFromLocalStorage
} from "@shared/utils/OfficeDay/storageHelpers";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import { ConnectedShiftsHelper } from "@shared/utils/ConnectedShiftsHelper";
import { statusIsConfirmedOrPending } from "@app/utils/statusIsConfirmedOrPending";
import { getFirstElement } from "@shared/utils/arrayHelpers";
import { getGroupDeskIds } from "@app/utils/getGroupDeskIds";

export const DeskSection = ({
  locationId,
  date,
  workspace = null,
  selectedUserId = null,
  selectedDeskIds,
  disableDeskChoosing = false,
  showMapIcon = true,
  showLastSelectedDesk = false,

  //group desk reservations
  // selectedGroupDeskIds = ko.observableArray([]),
  isGroupBooking = ko.observable(false),
  groupSize = ko.observable(null),
  forceNewDeskSuggestions = ko.observable(false)
}) => {
  //selectedUserId -> passed from admin app -> read from session storage (because that's where we store data for the admin app)
  const deepestWorkspaceNodeId =
    workspace?.workAreaId ?? workspace?.floorId ?? workspace?.buildingId;

  const _initialSelectedDeskIds = ko.observableArray(
    getGroupDeskIds(workspace)()
  );

  const _groupSize = ko.observable(ko.unwrap(groupSize));
  if (isGroupBooking() && workspace && !groupSize()) {
    _groupSize(
      workspace.members?.reduce(
        (acc, curr) => acc + Number(statusIsConfirmedOrPending(curr.status)),
        0
      )
    );
  }

  const lastSelectedDeskId = selectedUserId
    ? getLastBookedNodeIdFromSessionStorage(
        BuildingNodeTypes.Desk,
        selectedUserId
      )
    : getValueFromLocalStorage(BuildingNodeTypes.Desk);
  const locationStore = LocationStoreSingleton();
  const location = locationStore.get(ko.unwrap(locationId));
  const deskBookingRequired =
    (!disableDeskChoosing && location && location.hasBookableDesks) ?? false;
  const building = location
    ? locationStore.getBuildingForId(location.id)
    : null;
  const area = location ? locationStore.getWorkAreaForId(location.id) : null;
  const floor = location ? locationStore.getFloorForId(location.id) : null;
  const hasMap = floor && floor.hasMap;

  const conStore = ConnectedColleaguesStoreSingleton();
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const consInArea = area
    ? conStore
        .allWithOnlyShiftsOnDateInArea(date, area.id)
        .filter(({ workspace }) => workspace !== null)
        .map(c =>
          Object.assign({}, c, {
            checkInStatus: ko.pureComputed(() =>
              isSameDay(date, startOfDay(new Date()))
                ? checkInStatusStore.getById(c.id)
                : null
            )
          })
        )
    : [];

  // TODO: All desks booked
  const suggestions = ko.observable([]);
  const loading = ko.observable(deskBookingRequired);

  const selectedSuggestions = ko.computed({
    read: () => {
      const _suggestions = suggestions()?.filter(s =>
        ko.unwrap(selectedDeskIds).includes(s.deskId)
      );

      if (!_suggestions?.length) return [];
      return _suggestions;
    },
    write: deskSuggestions => {
      if (ko.isWritableObservable(selectedDeskIds)) {
        const deskIds = deskSuggestions?.map(({ deskId }) => deskId) ?? [];
        _initialSelectedDeskIds(deskIds);
        selectedDeskIds(deskIds);
      }
    }
  });

  const allDesks = ko.observable(null);
  const occupiedDeskIds = ko.observableArray([]);

  if (disableDeskChoosing && location?.hasBookableDesks) {
    Api.getDeskBookingStatus(location.id, date).then(r => {
      occupiedDeskIds(
        Object.entries(r).reduce((acc, [key, value]) => {
          if (value) {
            acc.push(key);
          }

          return acc;
        }, [])
      );
    });
  }

  if (deskBookingRequired) {
    // If a user id is passed, we request the suggestions for a specific user

    const suggestionCall = selectedUserId
      ? AdminApi.getDeskSuggestions({
          areaId: location.id,
          userId: selectedUserId,
          date: getDateKey(date)
        })
      : Api.getDeskSuggestions(location.id, date, _groupSize(), workspace?.id);

    Promise.all([Api.getDesks(location.id), suggestionCall])
      .then(([desks, suggestionSet]) => {
        const desksById = indexBy(o => o.id, desks);
        const visitsInExactLocation = ConnectedShiftsHelper.visitsInExactLocation(
          date,
          location.id
        );

        const consByDeskId = indexBy(
          c => c.location.deskId,
          visitsInExactLocation
        );

        // Expose desks for disabled ones
        allDesks(desks);

        suggestions(
          suggestionSet.desks
            .map(s =>
              Suggestion(
                suggestionSet.id,
                s,
                desksById[s.id],
                floor,
                area,
                consByDeskId[s.id]
              )
            )
            .sort((s1, s2) => s2.score - s1.score)
        );

        occupiedDeskIds(
          suggestionSet.desks.reduce((acc, { id, booked }) => {
            if (booked && !_initialSelectedDeskIds().includes(id)) {
              acc.push(id);
            }

            return acc;
          }, [])
        );

        const getBestSuggestion = () => suggestions().find(s => !s.booked);
        const bestSuggestion =
          showLastSelectedDesk && !workspace
            ? suggestions().find(
                s => !s.booked && s.isEnabled && s.deskId === lastSelectedDeskId
              ) ?? getBestSuggestion()
            : suggestions().find(s => s.deskId == workspace?.deskId) ??
              getBestSuggestion();

        const availableDeskSuggestions = suggestions().filter(
          ({ booked }) => !booked
        );

        if (!workspace || (workspace && ko.unwrap(forceNewDeskSuggestions))) {
          if (isGroupBooking()) {
            selectedSuggestions(
              availableDeskSuggestions.slice(0, _groupSize())
            );
          } else if (bestSuggestion) {
            selectedSuggestions([bestSuggestion]);
          }
        }
      })
      .finally(() => {
        loading(false);
      });
  }

  const selectedConnection = ko.observable(null);
  const toggleConnection = c => {
    if (selectedConnection() === c) {
      selectedConnection(null);
    } else {
      selectedConnection(c);
    }
  };

  const disabledDeskIds = allDesks.maybeMap(
    ds => ds.filter(d => !d.isEnabled).map(d => d.id),
    []
  );

  const selectDeskIdCallback = deskId => {
    const deskIdsSet = new Set([...selectedDeskIds()]);
    if (isGroupBooking()) {
      const remainingDeskSelectionCount = _groupSize() - deskIdsSet.size;

      if (remainingDeskSelectionCount >= 0) {
        if (deskIdsSet.has(deskId) || remainingDeskSelectionCount === 0) {
          deskIdsSet.delete(deskId);
        } else {
          deskIdsSet.add(deskId);
        }
      }
      // Next steps will be developed in different US
    } else {
      deskIdsSet.clear();
      deskIdsSet.add(deskId);
    }

    selectedDeskIds(Array.from(deskIdsSet.values()));
  };

  const generateDeskLabel = () => {
    if (isGroupBooking()) {
      return location && !location.hasBookableDesks
        ? `Any ${_groupSize()} available desks`
        : `${_groupSize()} desks`;
    }

    return selectedSuggestions
      .map(getFirstElement)
      .maybeMap(
        d => d.deskName,
        !location
          ? workspace?.deskName ?? "Any available desk"
          : location?.hasBookableDesks
          ? workspace?.deskName ?? "A desk will be assigned"
          : "Any available desk"
      );
  };

  const selectedDeskIsDirty = ko.pureComputed(() => {
    return (
      new Set([...selectedDeskIds(), ..._initialSelectedDeskIds()]).size !==
      selectedDeskIds().length
    );
  });

  const resetChanges = () => {
    selectedDeskIds(ko.unwrap(_initialSelectedDeskIds));
  };

  const applyChanges = () => {
    _initialSelectedDeskIds(ko.unwrap(selectedDeskIds));
  };

  return {
    deskId: ko.pureComputed(() => {
      return !isGroupBooking() ? selectedSuggestions()[0]?.deskId : null;
    }),
    resetChanges,
    applyChanges,
    groupSize: _groupSize,
    selectedDeskIds,
    isGroupBooking,
    _initialSelectedDeskIds,
    selectedDeskIsDirty,
    // Only for book entry screen
    labelExplainer: ko.pureComputed(() => {
      if (
        !selectedSuggestions().length ||
        selectedDeskIsDirty() ||
        // remove after workday release
        deepestWorkspaceNodeId === locationId() ||
        !location
      )
        return "";

      if (selectedSuggestions().includes(lastSelectedDeskId))
        return "Last selected";

      return isGroupBooking()
        ? "We picked these desks for you"
        : "We picked this desk for you";
    }),
    loading,
    deskExplainer: selectedSuggestions.maybeMap(
      s => s.explanation ?? "We picked a free desk for you",
      deskBookingRequired ? `Searching for a available desk` : ""
    ),
    changeable: hasMap && deskBookingRequired,
    viewable: hasMap,
    iconName: showMapIcon
      ? location
        ? getWorkspaceIcon(location)
        : workspace.deskName
        ? "workspaceDesk"
        : workspace.workAreaName
        ? "workspaceArea"
        : workspace.floorName
        ? "workspaceFloor"
        : workspace.buildingName
        ? "workspaceOffice"
        : null
      : null,
    deskName: generateDeskLabel(),
    areaName: area?.name,
    floorName: floor?.name,
    dispose: () => {
      selectedSuggestions.dispose();
    },
    suggestions,
    selectedSuggestions,
    occupiedDeskIds,

    connections: consInArea,
    selectedConnection,
    toggleConnection,

    svgMapParams: {
      floor,
      building,

      selectedDeskIds,
      occupiedDeskIds,
      disabledDeskIds,
      connections: consInArea,
      selectedConnection,

      typeDefaults: {
        [LayerType.Floor]: LayerState.Dimmed,
        [LayerType.Area]: LayerState.Dimmed,
        [LayerType.Desk]: LayerState.Default
      },
      selectDeskIdCallback,
      nodeOverrides: ko.pureComputed(() => {
        const bookedDesks = occupiedDeskIds();
        const disabledDesks = disabledDeskIds();

        return Object.fromEntries([
          ...bookedDesks.map(deskId => [deskId, LayerState.Booked]),
          ...disabledDesks.map(deskId => [deskId, LayerState.Disabled]),
          ...(area ? [[area.id, LayerState.Highlighted]] : []),
          ...(ko
            .unwrap(selectedDeskIds)
            ?.map(id => [id, LayerState.Selected]) || [])
        ]);
      })
    },

    hasMap,
    thumbnailParams: ko.pureComputed(() => {
      return {
        floor,
        building,
        typeDefaults: {
          [LayerType.Floor]: LayerState.Dimmed,
          [LayerType.Area]: LayerState.Dimmed
        },
        nodeOverrides: area
          ? {
              [area.id]: LayerState.Highlighted
            }
          : {}
      };
    })
  };
};
