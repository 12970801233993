const getElementCenterPoint = (element, viewport, panZoom) => {
  const p = panZoom.getPan();
  const vpBbox = viewport.getBoundingClientRect();
  const childBbox = element.getBoundingClientRect();
  const dx = childBbox.x - vpBbox.x;
  const dy = childBbox.y - vpBbox.y;
  return {
    x: -dx - childBbox.width / 2 + vpBbox.width / 2 + p.x,
    y: -dy - childBbox.height / 2 + vpBbox.height / 2 + p.y
  };
};

const getInitialZoomLevel = (desiredZoomRatio, element, viewport, panZoom) => {
  const currentZoomRatio = panZoom.getZoom();
  const vpBbox = viewport.getBoundingClientRect();
  const elementBbox = element.getBoundingClientRect();
  const actualRatio = Math.max(
    elementBbox.width / vpBbox.width,
    elementBbox.height / vpBbox.height
  );
  return (currentZoomRatio * desiredZoomRatio) / actualRatio;
};

const removeViewportTransition = viewport => {
  viewport.style.transition = "";
};

const setViewportTransition = viewport => {
  viewport.style.transition = "transform .5s ease-in-out";
};

export const zoomToElement = (
  panzoom,
  viewport,
  element,
  desiredZoomRatio = 0.8
) => {
  const onTransitionEnd = () => {
    removeViewportTransition(viewport);
    viewport.removeEventListener("transitionend", onTransitionEnd);
  };

  if (element) {
    const elementCenter = getElementCenterPoint(element, viewport, panzoom);
    const zoomLevel = getInitialZoomLevel(
      desiredZoomRatio,
      element,
      viewport,
      panzoom
    );

    setViewportTransition(viewport);
    viewport.addEventListener("transitionend", onTransitionEnd);

    panzoom.pan(elementCenter);
    panzoom.zoom(zoomLevel);
  }
};

const getCenter = el => {
  const bb = el.getBoundingClientRect();
  return { x: bb.left + bb.width / 2, y: bb.top + bb.height / 2 };
};
const subtractP = (p1, p2) => ({ x: p1.x - p2.x, y: p1.y - p2.y });

// All coordinates are pixel space in browser
export const panToElement = (panzoom, viewport, element, onDone) => {
  const onTransitionEnd = () => {
    removeViewportTransition(viewport);
    viewport.removeEventListener("transitionend", onTransitionEnd);

    if (onDone) onDone();
  };

  setViewportTransition(viewport);
  viewport.addEventListener("transitionend", onTransitionEnd);

  const svgCenter = getCenter(viewport.parentElement);
  const childCenter = getCenter(element);

  panzoom.panBy(subtractP(svgCenter, childCenter));
};

window.panToElement = panToElement;
