import { UserApp } from "@app/UserApp";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation.js";
import { format } from "date-fns";
import { ResponseStatus } from "@app/apimodels/ResponseStatus.js";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import {
  isLessThanMinDuration,
  isOverMaxDuration
} from "@app/components/MeetingCreation/utils";
import { pl } from "@shared/utils/pluralize";
import { OfficeDayVM } from "./OfficeDayVM";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { getDateKey } from "@shared/utils/dateHelpers";

export const MeetingVM = (
  dateOnWhichItAppearsInCalendar,
  showOngoingLabel = false,
  goBackToOverview = true,
  mediator = Mediator()
) => apiModel => {
  const urlNavigation = UrlNavigationSingleton();
  const userOfficeDay = OfficeDayVM(dateOnWhichItAppearsInCalendar);

  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const formattedDate = getDateKey(dateOnWhichItAppearsInCalendar);
  const timeLabel = apiModel.isAllDay
    ? `All day`
    : `${format(apiModel.startDate, "HH:mm")} - ${format(
        apiModel.endDate,
        "HH:mm"
      )} ${showOngoingLabel ? "(ongoing)" : ""}`;

  const userId = UserApp.resolveUser().directoryObjectId;
  const user = apiModel.attendees.find(a => a.id === userId);
  const isPending =
    !apiModel.isCanceled &&
    (user?.responseStatus === ResponseStatus.Pending ||
      user?.responseStatus === ResponseStatus.Maybe);

  const remoteAttendeesAmount = ko.pureComputed(() => {
    const count = apiModel.attendees.filter(
      a =>
        a.locationType === "Remote" &&
        [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
          a.responseStatus
        )
    ).length;
    //remove the user from the count if they book a shift (workday in the building) during the session
    if (
      user?.locationType === "Remote" &&
      [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
        user?.responseStatus
      ) &&
      userOfficeDay.isOfficeDay
    )
      return count - 1;
    //add the user to the count if they delete a shift (changed the workday status to not be in the building, i.e. 'Working remotely' or 'Not working') during the session
    if (
      user?.locationType === "Local" &&
      [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
        user?.responseStatus
      ) &&
      userOfficeDay.isRemote
    )
      return count + 1;
    return count;
  });
  const localAttendeesAmount = ko.pureComputed(() => {
    const count = apiModel.attendees.filter(
      a =>
        a.locationType === "Local" &&
        [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
          a.responseStatus
        )
    ).length;
    //remove the user from the count if they delete a shift (changed the workday status to not be in the building, i.e. 'Working remotely' or 'Not working') during the session
    if (
      user?.locationType === "Local" &&
      [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
        user?.responseStatus
      ) &&
      userOfficeDay.isRemote
    )
      return count - 1;
    //add the user to the count if they book a shift (workday in the building) during the session
    if (
      user?.locationType === "Remote" &&
      [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
        user?.responseStatus
      ) &&
      userOfficeDay.isOfficeDay
    )
      return count + 1;
    return count;
  });
  const unknownAttendeesAmount = apiModel.attendees.filter(
    a =>
      a.locationType === "Unknown" &&
      [ResponseStatus.Accepted, ResponseStatus.Organizer].includes(
        a.responseStatus
      )
  ).length;

  const onClick = () => {
    const urlNavigation = UrlNavigationSingleton();
    urlNavigation.navigate(null, "overview", [formattedDate, apiModel.id]);
  };

  const totalRoomsCount = apiModel.meetingRooms.length;
  const roomsInBookedBuilding = apiModel.meetingRooms.filter(
    r => r.buildingId === userOfficeDay.buildingId
  );
  const isThereMeetingRoomInBookedBuilding = roomsInBookedBuilding.length > 0;

  let meetingRoomLabel = null;
  if (totalRoomsCount > 0) {
    if (isThereMeetingRoomInBookedBuilding) {
      if (roomsInBookedBuilding.length === 1) {
        meetingRoomLabel = roomsInBookedBuilding[0].displayName;
      } else {
        meetingRoomLabel = `${roomsInBookedBuilding.length} rooms`;
      }
    } else {
      meetingRoomLabel = `${totalRoomsCount} ${pl("room", totalRoomsCount)}`;
    }
  }

  const locationStore = LocationStoreSingleton();
  const buildings = locationStore.getBuildings();

  const addRoomPageOpenedOrigin = "DayOverview";
  const canAddRoom =
    user?.responseStatus === ResponseStatus.Organizer &&
    !isOverMaxDuration(apiModel.endDate, apiModel.startDate) &&
    !isLessThanMinDuration(apiModel.endDate, apiModel.startDate) &&
    getFeatures().has(FeatureNames.ADD_ROOM_TO_MEETING);
  const roomPickerParams = {
    building:
      userOfficeDay && buildings.some(b => b.id === userOfficeDay.buildingId)
        ? userOfficeDay.buildingId
        : locationStore.getPreferredBuilding()?.id ?? buildings[0].id,
    startTime: apiModel.startDate,
    endTime: apiModel.endDate,
    selectedRooms: ko.observableArray([]),
    buildings,
    hasManualRoomSelection: getFeatures().has(
      FeatureNames.MANUAL_ROOM_SELECTION
    ),
    selectedMeetingId: apiModel.id,
    backFunction: () => {
      if (!goBackToOverview) {
        urlNavigation.navigate(null, null);
        return;
      }

      urlNavigation.navigate(null, hasWorkdayFeature ? null : "overview", [
        formattedDate
      ]);
    },
    addRoomPageOpenedOrigin
  };
  const onAddRoomClick = () => {
    publishAIEvent(
      EventNames.AddRoomPageOpened,
      { origin: addRoomPageOpenedOrigin },
      ResultTypes.Undefined
    );
    mediator.publish(Channels.OpenRoomPicker, roomPickerParams);
  };

  return Object.assign({}, apiModel, {
    timeLabel,
    isPending,
    onClick,
    remoteAttendeesAmount,
    localAttendeesAmount,
    unknownAttendeesAmount,
    isThereMeetingRoomInBookedBuilding,
    meetingRoomLabel,
    userOfficeDay,
    canAddRoom,
    onAddRoomClick,
    showOngoingLabel
  });
};
