import { AppApi } from "@app/services/AppApi";
import { Channels } from "@shared/Channels";
import ko from "@shared/knockout/extended";
import { Mediator } from "@shared/mediator";
import { stringToColor } from "@shared/utils/avatar-helpers";
import { pl } from "@shared/utils/pluralize";
import {
  RoomScheduleColor,
  RoomScheduleLabel,
  RoomScheduleSubtitleDetailsPage,
  RoomScheduleSubtitleNowPage,
  RoomSensorColor,
  RoomSensorLabel,
  RoomSensorState,
  RoomSensorSubtitle
} from "@app/utils/RoomStates";
import { differenceInMinutes } from "date-fns";

export const MeetingRoomVM = (
  hasManualRoomSelection,
  selectedRooms = ko.observableArray([]),
  afterSelection = () => true,
  isAddRoomFlow = false,
  currentSelection = null,
  hasDoubleAction = true,
  availabilityStartTime = null,
  availabilityEndTime = null
) => apiModel => {
  const isSelected = ko.pureComputed(() =>
    selectedRooms()?.some(r => r.id === apiModel.id)
  );
  const isCurrentSelection = ko.pureComputed(
    () => ko.unwrap(currentSelection) && currentSelection().id === apiModel.id
  );
  const label = `${apiModel.capacity} ${pl("seat", apiModel.capacity)}, ${
    apiModel.floorName
  }`;
  const labelWithBuilding = `${label}, ${apiModel.buildingName}`;
  const iconColor = stringToColor(apiModel.email || apiModel.id);

  const select = (self, e) => {
    e.stopPropagation();
    if (isSelected()) {
      selectedRooms.remove(self);
      if (isAddRoomFlow) {
        currentSelection(null);
      }
    } else {
      //old design carousel implementation only supports adding one room to a meeting
      if (!hasManualRoomSelection) selectedRooms([]);
      //add room flow only supports picking one room each time in the roompicker component
      if (isAddRoomFlow) {
        selectedRooms.remove(currentSelection());
        currentSelection(self);
      }
      selectedRooms.push(self);
      afterSelection();
    }
  };

  const deleteSelf = (self, e) => {
    selectedRooms.remove(self);
  };

  const openBookRoom = (self, e) => {
    Mediator().publish(Channels.OpenBookRoom, {
      room: self,
      date: null,
      startTime: availabilityStartTime,
      endTime: availabilityEndTime
    });
  };

  const openRoomDetails = (self, e) => {
    Mediator().publish(Channels.ToggleRoomDetails, {
      room: self,
      date: null
    });
  };

  const onTileClick = (self, e) =>
    hasDoubleAction ? select(self, e) : openBookRoom(self, e);

  const hasPicture = ko.observable(!!apiModel.imageHash);
  const picture = ko.observable(null);
  const loadingPicture = ko.observable(false);

  if (hasPicture()) {
    loadingPicture(true);
    AppApi.getMeetingRoomPicture(
      apiModel.buildingId,
      apiModel.imageHash,
      null,
      66
    )
      .then(blob => {
        picture(URL.createObjectURL(blob));
      })
      .catch(e => {
        hasPicture(false);
      })
      .finally(() => {
        loadingPicture(false);
      });
  }

  const roomScheduleLabel = RoomScheduleLabel(
    apiModel.state,
    apiModel.scheduleInformation,
    availabilityStartTime
  );
  const roomScheduleSubtitleDetailsPage = RoomScheduleSubtitleDetailsPage(
    apiModel.state,
    apiModel.scheduleInformation,
    availabilityStartTime,
    differenceInMinutes(availabilityEndTime, availabilityStartTime)
  );
  const roomScheduleSubtitleNowPage = RoomScheduleSubtitleNowPage(
    apiModel.state,
    apiModel.scheduleInformation,
    availabilityStartTime,
    differenceInMinutes(availabilityEndTime, availabilityStartTime)
  );
  const roomSensorLabel = RoomSensorLabel(apiModel.state);
  const roomSensorSubtitle = ko.pureComputed(() =>
    RoomSensorSubtitle(apiModel.state, apiModel.sensorStateTimestamp)
  );

  const roomScheduleColor = RoomScheduleColor(apiModel.state);
  const roomSensorColor = RoomSensorColor(apiModel.state);

  const showSensorLabelInList =
    apiModel.sensorAvailability === RoomSensorState.Occupied;

  const listSubLabel = showSensorLabelInList
    ? roomSensorLabel
    : roomScheduleSubtitleNowPage ?? null;

  return {
    ...apiModel,
    onTileClick,
    openRoomDetails,
    isSelected,
    label,
    iconColor,
    hasPicture,
    picture,
    loadingPicture,
    deleteSelf,
    labelWithBuilding,
    isCurrentSelection,
    hasDoubleAction,
    roomScheduleColor,
    roomSensorColor,
    roomSensorLabel,
    roomSensorSubtitle,
    roomScheduleLabel,
    listSubLabel,
    roomScheduleSubtitleDetailsPage
  };
};
