import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { differenceInDays, differenceInMinutes } from "date-fns";

export const MAX_DURATION = 14; //days
const MIN_DURATION = 5; //minutes

export const isOverMaxDuration = (end, start) =>
  differenceInDays(end, start) >= MAX_DURATION;

export const isLessThanMinDuration = (end, start) =>
  differenceInMinutes(end, start) < MIN_DURATION;

const discardChangesConfirmationOptions = {
  title: "Discard event?",
  subtitle: "Your changes will not be saved",
  primaryButtonText: "Discard"
};

export const openConfirmationWindow = (action, customTitle = null) => {
  const title = customTitle ?? discardChangesConfirmationOptions.title;
  Mediator().publish(Channels.ToggleConfirmationWindow, {
    options: {
      title,
      subtitle: discardChangesConfirmationOptions.subtitle,
      primaryButtonText: discardChangesConfirmationOptions.primaryButtonText,
      onPrimaryButtonClick: () => {
        action();
        //So the component can be reused when the action is an API call
        return Promise.resolve();
      }
    }
  });
};
