import ko from "@shared/knockout/extended";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { AvailabilityStoreSingleton } from "@shared/services/AvailabilityStore";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { format, isToday } from "date-fns";

const THRESHOLD_SINGLE = 10;
const THRESHOLD_ALL = 20;

/**
 * Used for rendering the occupancy details of a given day.
 * Has methods to provide availability of the user's preferred office and a total count for all offices
 * @param {LocationStre} locationStore
 * @param {AvailabilityStore} availabilityStore
 * @param {Date} day
 */
export const DayAvailabilityVM = day => {
  const locationStore = LocationStoreSingleton();
  const availabilityStore = AvailabilityStoreSingleton();
  // Single building environments get different labels (e.g. they do not have the concept of a preferred building)
  const singleBuilding = ko
    .pureComputed(locationStore.getBuildings)
    .map(bs => bs.length === 1);

  // When there is no date entry in the availability store for this day,
  // it's likely that the availability store is still loading
  const showAvailabilityLabel = ko.pureComputed(() =>
    availabilityStore.hasDate(day)
  );

  const inUseInPreferredBuilding = ko.pureComputed(() =>
    availabilityStore.getPreferredBuilding(day)
  );
  const inUseInAllBuildings = ko.pureComputed(() => {
    const totalShiftsBooked = availabilityStore.getAllBuildings(day);
    //if it's not today, it's impossible to have shifts in closed buildings.
    if (!isToday(day)) return totalShiftsBooked;
    //if it's today, we need to exclude shifts in closed buildings, since those buildings are not counted in the total available
    const buildingsClosedToday = locationStore
      .getBuildings()
      .filter(
        loc => loc.openingDays && !loc.openingDays.includes(format(day, "eeee"))
      )
      .map(loc => loc.id);
    const shiftsInBuildingsClosedToday = buildingsClosedToday
      .map(b => availabilityStore.get(day, b))
      .reduce(sum, 0);
    return totalShiftsBooked - shiftsInBuildingsClosedToday;
  });

  // We need to compare the usage stats to the capacity to determine our labels
  const preferredBuilding = ko.pureComputed(locationStore.getPreferredBuilding);
  const preferredCapacity = preferredBuilding.maybeMap(b => b.capacity, null);

  const availableCapacity = ko.pureComputed(() =>
    locationStore
      .allAvailable(day)
      .map(b => b.capacity)
      .reduce(sum, 0)
  );

  const allClosed = ko.pureComputed(
    () => locationStore.getOpenBuildings(day).length === 0
  );

  // A single string summary of the day's availability
  const label = ko.pureComputed(() => {
    if (allClosed())
      return getFeatures().has(FeatureNames.WORK_DAY)
        ? singleBuilding()
          ? "Office is closed"
          : "All offices are closed"
        : "Closed";
    if (!showAvailabilityLabel()) return "";

    if (inUseInAllBuildings() === null) {
      // No data
      return "";
    }

    const totalAvailable = availableCapacity() - inUseInAllBuildings();

    // For one building environments
    if (singleBuilding()) {
      if (totalAvailable <= 0) {
        return "Full";
      }

      if (totalAvailable <= THRESHOLD_SINGLE) {
        return `${totalAvailable} ${plural("spot", totalAvailable)} left`;
      }
    }

    // For multi-building environments
    if (totalAvailable <= 0) {
      return "All offices are full";
    }

    const pCap = preferredCapacity();
    const pUse = inUseInPreferredBuilding();
    const preferredAvailable = pCap - pUse;
    if (pCap !== null && pUse !== null) {
      if (preferredAvailable <= 0) {
        return "Your office is full";
      }

      if (preferredAvailable <= THRESHOLD_SINGLE) {
        return `${preferredAvailable} spot${
          preferredAvailable === 1 ? "" : "s"
        } left in your office`;
      }
    }

    if (totalAvailable <= THRESHOLD_ALL) {
      return `${totalAvailable} ${plural("spot", totalAvailable)} left`;
    }

    return "";
  });

  return {
    singleBuilding,
    allFull: ko.pureComputed(
      () => availableCapacity() <= inUseInAllBuildings()
    ),
    allClosed,
    label
  };
};

const sum = (a, b) => a + b;
const plural = (string, count) => string + (count === 1 ? "" : "s");
