import template from "./WorkingHours.template.html";
import row from "./WorkingHoursRow.template.html";
import "./WorkingHours.scss";
import { KOComponent } from "@shared/knockout/KOComponent";
import ko from "@shared/knockout/extended";
import {
  WorkingHoursPopup,
  WorkingHoursPopupVM
} from "@app/components/popups/WorkingHours/WorkingHoursPopup";
import { WorkingHoursPopupActiveView } from "@app/components/popups/WorkingHours/components/WorkingHoursPopupActiveView";
import { PopupManagerVM } from "@app/components/PopupManager/PopupManagerVM";
import { PopupConfig } from "@app/components/PopupManager/PopupConfig";
import { TimezoneStoreSingleton } from "@admin/components/BuildingEditor/services/TimezoneStore";
import { Mediator } from "@shared/mediator";
import { Channels } from "@shared/Channels";
import { AppApi } from "@app/services/AppApi";

const WorkingHoursVM = ({ workingHours, defaultBuilding }) => {
  const popupManager = PopupManagerVM();
  const timeZoneStore = TimezoneStoreSingleton();
  const timeZones = timeZoneStore.all;
  const loadingTimezones = ko.observable(false);
  const initialTimeZone = ko.observable(null);
  const initialStartWorkingTime = ko.observable(null);
  const initialEndWorkingTime = ko.observable(null);

  const setInitialState = () => {
    initialTimeZone(
      timeZones().find(
        tz => tz.timeZoneId === workingHours()?.ianaLocalTimezone
      )?.timeZoneIdWithUtcOffset
    );
    initialStartWorkingTime(
      workingHours()?.days[0].times[0].startTimeLocal.slice(0, 5)
    );
    initialEndWorkingTime(
      workingHours()?.days[0].times[0].endTimeLocal.slice(0, 5)
    );
  };

  const getTimezones = () => {
    if (!timeZoneStore.hydrated()) {
      loadingTimezones(true);
      AppApi.getTimezones()
        .then(timeZoneStore.update)
        .catch(() => {})
        .finally(() => {
          loadingTimezones(false);
          setInitialState();
        });
    } else setInitialState();
  };

  getTimezones();

  const openWorkingHoursPopup = (preferredBuildingTimezone = null) =>
    popupManager.showPopup(
      PopupConfig({
        headerType: PopupConfig.headerType.WhiteCenterAligned,
        ContentVM: WorkingHoursPopupVM,
        contentParams: {
          initialTimeZone,
          preferredBuildingTimezone,
          initialStartWorkingTime,
          initialEndWorkingTime,
          closePopup: popupManager.closePopup,
          setWorkingHours: apiObj => {
            workingHours(apiObj.workingHours);
            setInitialState();
          }
        },
        bodyComponentName: "working-hours-popup-body",
        footerComponentName: "working-hours-popup-footer"
      })
    );

  const defaultBuildingListener = defaultBuilding.subscribe(
    newDefaultBuilding => {
      const preferredBuildingTimezone = timeZones().find(
        tz => tz.timeZoneId === newDefaultBuilding.ianaTimezone
      )?.timeZoneIdWithUtcOffset;

      if (preferredBuildingTimezone !== initialTimeZone())
        Mediator().publish(Channels.ToggleConfirmationWindow, {
          options: {
            title: "Change time zone?",
            subtitle:
              "You can change your time zone to match your new default office",
            primaryButtonText: "Change",
            secondaryButtonText: "Cancel",
            primaryButtonClass: "MpqButton--black MpqButton--secondaryGray",
            secondaryButtonClass: "MpqButton--outlineBlack",

            onPrimaryButtonClick: () => {
              openWorkingHoursPopup(preferredBuildingTimezone);
              return Promise.resolve();
            }
          }
        });
    }
  );

  return {
    popupManager,
    loadingTimezones,
    workingHoursRange: ko.pureComputed(() =>
      initialStartWorkingTime() && initialEndWorkingTime()
        ? `${initialStartWorkingTime()} - ${initialEndWorkingTime()}`
        : ""
    ),
    openWorkingHoursPopup,

    dispose: () => defaultBuildingListener.dispose()
  };
};

const WorkingHoursComponent = KOComponent(
  "working-hours",
  WorkingHoursVM,
  template
);

export const WorkingHours = Object.assign({}, WorkingHoursComponent, {
  register: ko => {
    WorkingHoursComponent.register(ko);
    WorkingHoursPopupActiveView.register(ko);
    WorkingHoursPopup.register(ko);
    ko.components.register("working-hours-row", {
      template: row
    });
  }
});
