import {
  FeatureNames,
  getFeatures,
  overrideFlagsViaUrl,
  Status
} from "@shared/services/Features.js";
import { MeetApi } from "@shared/services/MeetApi.js";
/*
  App features are built on top of "regular" features (see Features.js). They
  are used whenever we need more than just a boolean on/off switch.

  This interface ensures:
   - Features are only loaded _once_ and can only be retrieved synchronously
     (like getFeatures and loadFeatures in Features.js)
   - App specific feature data is only loaded for features that are actually
     available in our regular features.

  Example:
   - getFeatures from Features.js returns whether a subscription has
     access to HybridMeeting
   - getAppFeatures in this file returns whether HybridMeeting is:
     - Enabled by an admin
     - Enabled by the current user
*/

let appFeatures = [];
let status = Status.NotInitialized;

// This mapping holds a single async function for every feature that
// requires app-specific information to be loaded.
// Features without a custom mapping will return a simple object with
// just their feature key.
const FeatureMapping = {
  // The HybridMeetings feature needs to be enabled by an admin.
  // Here, we check whether that has been done.
  [FeatureNames.HYBRID_MEETINGS_CALENDAR_VIEW]: async key => {
    let enabledForUsers = false;
    let isGoogleIntegration = false;
    let userHasOptedIn = false;
    let tokenAvailable = false;
    let hideConsentCTA = false;
    let hideConsentErrorCTA = false;
    let hideHereNowAnnouncement = true;
    let workingHours = null;
    let maxDaysPerRequest = 7;

    try {
      const {
        enabledOffice365,
        enabledGoogleWorkspace
      } = await MeetApi.getMeetingSettings();

      enabledForUsers = enabledOffice365 || enabledGoogleWorkspace;
      isGoogleIntegration = enabledGoogleWorkspace;
    } catch (e) {
      // Do nothing
    }

    if (enabledForUsers) {
      try {
        const [userPreferences, { pageTimespanInDays }] = await Promise.all([
          MeetApi.getUserPreference(),
          MeetApi.getGlobalMeetingSettings()
        ]);

        workingHours = userPreferences.workingHours;
        userHasOptedIn = userPreferences.enableCalendar;
        maxDaysPerRequest = pageTimespanInDays;
        hideConsentCTA = userPreferences.userClosedConsentTile;
        hideConsentErrorCTA = userPreferences.userClosedConsentErrorTile;
        hideHereNowAnnouncement = userPreferences.userClosedHereNowAnnouncement;

        if (userPreferences.enableCalendar) {
          const { isValidRefreshToken } = await MeetApi.getConsentStatus();
          tokenAvailable = isValidRefreshToken;
        }
        //to hide consentCTA in session during which it's been hidden by user
        sessionStorage.setItem(
          "hideConsentCTA",
          hideConsentCTA ? "true" : "false"
        );
      } catch (e) {
        // Do nothing
      }
    }
    return {
      key,
      enabledForUsers,
      userHasOptedIn,
      workingHours,
      tokenAvailable,
      maxDaysPerRequest,
      hideConsentCTA,
      hideConsentErrorCTA,
      maxDaysPerRequest,
      isGoogleIntegration,
      hideHereNowAnnouncement
    };
  },
  [FeatureNames.OCCUPANCY_SENSOR]: async key => {
    let hideHereNowAnnouncement = true;
    try {
      const userPreferences = await MeetApi.getUserPreference();

      hideHereNowAnnouncement = userPreferences.userClosedHereNowAnnouncement;
    } catch (e) {
      // Do nothing
    }
    return {
      key,
      hideHereNowAnnouncement
    };
  }
};
const DefaultGetter = key => Promise.resolve({ key });

const getFeatureMapping = key => {
  const getter = FeatureMapping[key] || DefaultGetter;
  return getter(key);
};

/**
 * Returns feature information enriched for the user app.
 * To check if a feature is enabled on subscription level, you can
 * run .has() on the feature map. To get the actual meta data, use
 * .get()
 */
export const getAppFeatures = () => {
  switch (status) {
    case Status.NotInitialized:
      throw "App features not initialized";
    case Status.Loading:
      throw "App features loading";
    case Status.Failed:
    case Status.Loaded:
      return new Map(appFeatures.map(f => [f.key, f]));
    default:
      throw "Unknown status";
  }
};

/**
 * Loads the feature specific data for each enabled feature.
 * loadFeatures need to be called and resolved before loadAppFeatures.
 * This function will throw otherwise.
 */
export const loadAppFeatures = () => {
  const subscriptionFeatures = Array.from(getFeatures());

  switch (status) {
    case Status.Loaded:
    case Status.Failed:
    case Status.Loading:
      throw "You can only load app features once per session";

    case Status.NotInitialized:
      status = Status.Loading;

      return Promise.all(subscriptionFeatures.map(getFeatureMapping))
        .then(features => {
          appFeatures = overrideFlagsViaUrl(features, document.location);

          status = Status.Loaded;
          return getAppFeatures();
        })
        .catch(e => {
          status = Status.Failed;
          throw e;
        });

    default:
      throw "Unknown status";
  }
};
