import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import template from "./GroupInvitations.template.html";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";
import { GroupInvitationVM } from "./GroupInvitation";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";

const GroupInvitationsVM = ({
  user,
  day,
  currentWorkspace,
  hasInvites = ko.observable(false),
  booked = ko.observable(false)
}) => {
  const loading = ko.observable(false);
  const workspaceService = WorkspaceServiceSingleton();

  const hasReservation = ko.pureComputed(
    () => booked() || currentWorkspace.map(Boolean)
  );
  const groupInvitations = ko.pureComputed(() => {
    const invites = workspaceService
      .getForDate(day)
      .filter(r =>
        r.members.some(
          i =>
            i.userId === user.id &&
            i.status === ReservationStatus.InvitationPending
        )
      )
      .map(WorkspaceVM)
      .map(r => {
        return GroupInvitationVM({
          groupWorkspace: r,
          currentUser: user,
          currentWorkspace,
          date: day
        });
      })
      .filter(i => i.statusObs() === ReservationStatus.InvitationPending);

    hasInvites(!!invites.length);
    return invites;
  });

  return {
    groupInvitations,
    loading,
    hasReservation
  };
};

export const GroupInvitations = KOComponent(
  "group-invitations",
  GroupInvitationsVM,
  template
);
