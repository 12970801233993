import { Singleton } from "@shared/utils/Singleton";
import ko from "@shared/knockout/extended";
import { groupByC } from "@shared/utils/groupBy";

const ExtrasStore = () => {
  const cache = ko.observable({});
  const hydrated = ko.observable(false);
  const all = cache.map(Object.values);
  const byType = all.map(groupByC(extra => extra.type));

  const update = extras => {
    hydrated(true);
    cache(Object.fromEntries(extras.map(extra => [extra.id, extra])));
  };

  const getByType = type => byType()[type] || [];

  const add = extra => {
    const _cache = cache();
    _cache[extra.id] = extra;
    cache.valueHasMutated();
  };

  const remove = id => {
    const _cache = cache();
    delete _cache[id];
    cache.valueHasMutated();
  };

  return {
    hydrated,
    getByType,
    add,
    update,
    remove
  };
};

export const ExtrasStoreSingleton = Singleton(ExtrasStore);
