export class MeetingRoomApiModel {
  constructor(obj) {
    this.name = obj.name;
    this.email = obj.email;
  }

  static fromApiObj(obj) {
    return new MeetingRoomApiModel(obj);
  }
}
