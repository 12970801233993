export const insert = (str, index, value) =>
  str.slice(0, index) + value + str.slice(index);

export const capitalizeSentence = (string, capitalize = true) => {
  const firstCharacter = string.charAt(0);
  const restOfSentence = string.slice(1);

  if (capitalize) return firstCharacter.toUpperCase() + restOfSentence;
  else return firstCharacter.toLowerCase() + restOfSentence;
};

export const caseInsensitiveComparison = (str1, str2) =>
  str1?.trim()?.localeCompare(str2?.trim(), undefined, { sensitivity: "base" });

export const injectLineBreaksAtSlash = str =>
  insert(str, str.indexOf("/") + 1, "\u200b");
