import {
  notificationPreferencesCategoryKeys,
  notificationPreferencesTypeKeys
} from "@app/apimodels/NotificationPreferencesApiModel";

export const NotificationPreferencesVM = ({ preferences }) => {
  // Create object with all the settings instead of array.
  return preferences.reduce((acc, { category, type, enabled }) => {
    const categoryKey = notificationPreferencesCategoryKeys[category];
    const typeKey = notificationPreferencesTypeKeys[type];

    acc[categoryKey] = {
      ...acc[categoryKey],
      [typeKey]: enabled
    };

    return acc;
  }, {});
};
