export const IconColors = {
  mpqBlue: "mpqBlue",
  lightBlue: "lightBlue",
  backgroundBlue: "backgroundBlue",
  lightGrey: "lightGrey",
  mediumGrey: "mediumGrey",
  darkGrey: "darkGrey",
  red: "red",
  orange: "orange",
  green: "green",
  darkerGrey: "darkerGrey",
  black: "black",
  white: "white",
  darkBlue: "darkBlue",
  newDarkGrey: "newDarkGrey",
  greyColor: "greyColor"
};
