/**
 * Pluralize a string
 * @param {string} str The word to pluralize
 * @param {number} count The count
 */
export const pl = (str, count, replaceY = false) =>
  count === 1
    ? str
    : str.slice(-1) === "y" && replaceY
    ? str.slice(0, -1).concat("ies")
    : `${str}s`;
