export const Singleton = Ctor => {
  let instance = null;

  return (...args) => {
    if (!instance) {
      instance = Ctor(...args);
      instance[Symbol.toStringTag] = Ctor.name;
    }

    return instance;
  };
};
