import { LocationType } from "@app/apimodels/LocationType.js";

// All supported groups + headers for the AttendeeDetails component
export const Group = {
  Local: "Local",
  Remote: "Remote",
  Unknown: "Unknown location"
};

// Icon names corresponding to keys in IconIndex.js
export const getGroupIcon = group => {
  switch (group) {
    case Group.Remote:
      return "remote";
    case Group.Unknown:
      return "unknownLocation";
    default:
      // Unknown groups are building names
      return "building";
  }
};

// Determine what group a MeetingAttendeeVM instance belongs to
export const getGroup = attendee => {
  switch (attendee.locationType) {
    case LocationType.Local:
      return Group.Local;
    case LocationType.Remote:
      return Group.Remote;
    case LocationType.Unknown:
      return Group.Unknown;
  }

  throw "Unimplemented LocationType encountered";
};
