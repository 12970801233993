import { IconColors } from "@shared/components/MpqIcon/ColorIndex";

export const PopupConfig = ({
  title = "Empty popup",
  subHeading = null,
  subHeadingIcon = null,

  ContentVM = () => {},
  contentParams = {},
  bodyComponentName = null,
  footerComponentName = null,
  hasCloseButton = true,
  hasBackButton = false,
  hasConfirmButton = false,
  disableConfirmButton = false,
  disableClosing = false,
  fixedHeight = false,
  wider = false,
  headerType = PopupConfig.headerType.BlueLeftAligned
}) => {
  return {
    title,
    hasCloseButton,
    hasBackButton,
    hasConfirmButton,
    subHeading,
    subHeadingIcon,
    disableClosing,
    disableConfirmButton,

    headerClass: headerType,
    headerIconColor:
      headerType === PopupConfig.headerType.WhiteCenterAligned
        ? IconColors.mpqBlue
        : IconColors.darkGrey,

    ContentVM,
    contentParams,

    bodyComponentName,
    footerComponentName,
    fixedHeight,
    wider,
    showFooter: !!footerComponentName
  };
};

// Note: these values correspond to css classes. Don't change them without
//       updating the css
PopupConfig.headerType = {
  BlueLeftAligned: "blueLeftAligned",
  WhiteCenterAligned: "whiteCentered",
  WhiteCenterAlignedWithBigFont: "whiteCenterAlignedWithBigFont"
};
