import { EventNames, ResultTypes } from "@app/tracking/EventNames";
import { publishAIEvent } from "@app/tracking/publishAIEvent";
import {
  ActionObjs,
  ActionTypes,
  showFailureMessage,
  showSuccessMessage
} from "@app/utils/FeedbackMessage";
import { WorkspaceApiModel } from "@shared/apimodels/WorkspaceApiModel";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { WorkspaceStoreSingleton } from "@shared/services/WorkspaceStore";
import { Singleton } from "@shared/utils/Singleton";
import { startOfWeek, format } from "date-fns";
import { AppApi } from "./AppApi";
import { ParkingReservationStoreSingleton } from "./ParkingReservationStore";
import { WorkdayReservationsStoreSingleton } from "@shared/services/Workday/WorkdayReservationsStore";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { ReservationStatus } from "@shared/apimodels/ReservationStatus";

const WorkspaceService = () => {
  const workspaceStore = WorkspaceStoreSingleton();
  const workdayReservationStore = WorkdayReservationsStoreSingleton();
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const today = new Date();
  const mediator = Mediator();
  const startOfCalendar = format(
    startOfWeek(today, { weekStartsOn: 1 }),
    "yyyy-MM-dd"
  );

  const removeWorkdayReservation = date => {
    const workdayReservation =
      workdayReservationStore.getForDate(date)[0] ?? null;
    if (!workdayReservation || !hasWorkDayFeature) return;

    workdayReservationStore.remove(workdayReservation.id);
  };

  const handleWorkspaceUpdate = (workspacePromise, date, eventName) =>
    workspacePromise
      .then(apiObj => new WorkspaceApiModel(apiObj))
      .then(newWorkspace => {
        workspaceStore.add(newWorkspace);
        removeWorkdayReservation(date);

        mediator.publish(Channels.UpdateAvailability);
        publishAIEvent(eventName, ResultTypes.Success);
        return newWorkspace;
      })
      .catch(e => {
        publishAIEvent(eventName, ResultTypes.Fail);

        throw e;
      });

  const postWorkspace = (workspace, date) =>
    handleWorkspaceUpdate(
      AppApi.postWorkspace(WorkspaceApiModel.toApiModel(workspace)),
      date,
      EventNames.BookShift
    );

  const getWorkspaces = () =>
    AppApi.getWorkspaces(startOfCalendar).then(workspaceStore.update);

  const getWorkspaceById = (id, forceRefresh = false) => {
    const fromCache = workspaceStore.getById(id);

    if (forceRefresh || !workspaceStore.all()?.length || !fromCache) {
      return AppApi.getWorkspaceReservation(id)
        .then(workspace => {
          workspaceStore.add(workspace);
          return workspace;
        })
        .catch(error => error);
    }

    return new Promise(res => res(fromCache));
  };

  const getForDate = date => {
    return workspaceStore.getForDate(date);
  };

  const getConfirmedForDate = date => {
    return workspaceStore
      .getForDate(date)
      .filter(r => r.status === ReservationStatus.Confirmed);
  };

  const getConfirmedForWeekNr = weekNumber => {
    return workspaceStore
      .getForWeekNr(weekNumber)
      .filter(r => r.status === ReservationStatus.Confirmed);
  };

  const updateWorkspace = (workspaceId, members, date) =>
    handleWorkspaceUpdate(
      AppApi.editWorkspaceReservation(workspaceId, members),
      date,
      EventNames.EditShiftLocation
    );

  const deleteWorkspace = (
    workspace,
    date = new Date(),
    showSuccesMessage = true
  ) =>
    AppApi.deleteWorkspaceReservation(workspace.id)
      .then(() => {
        workspaceStore.remove(workspace.id);
        const location = LocationStoreSingleton().get(workspace.buildingId);
        if (hasWorkDayFeature && !location?.allowWorkdayWithoutWorkspace) {
          removeWorkdayReservation(date);
        }

        mediator.publish(Channels.UpdateAvailability);
        publishAIEvent(EventNames.DeleteShift, null, ResultTypes.Success);

        const hasParkingReservation = deleteParkingReservation(date, location);

        if (showSuccesMessage) {
          showSuccessfullyDeletedMessage(hasParkingReservation);
        }
      })
      .catch(error => {
        publishAIEvent(EventNames.DeleteShift, null, ResultTypes.Fail);
        showFailureMessage(
          ActionTypes.Deleted,
          ActionObjs.Workspace,
          error,
          date
        );
      });

  return {
    //Expose all methods from the store, so we don't have to instantiate both (the store and the service) everywhere they are used, just the service
    ...workspaceStore,
    //CRUD
    getWorkspaces,
    getWorkspaceById,
    getForDate,
    getConfirmedForDate,
    getConfirmedForWeekNr,
    postWorkspace,
    updateWorkspace,
    deleteWorkspace
  };
};

export const WorkspaceServiceSingleton = Singleton(WorkspaceService);

const deleteParkingReservation = (date, location) => {
  const hasWorkdayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const parkingReservationStore = ParkingReservationStoreSingleton();
  const parkingReservation = parkingReservationStore.parkingReservationForDate(
    date
  );
  if (
    parkingReservation &&
    (!hasWorkdayFeature ||
      (hasWorkdayFeature && !location.allowWorkdayWithoutWorkspace))
  )
    parkingReservationStore.remove(parkingReservation.id);

  return Boolean(parkingReservation);
};

const showSuccessfullyDeletedMessage = hasParkingReservation => {
  const actionType = ActionTypes.Deleted;

  if (hasParkingReservation)
    showSuccessMessage(actionType, ActionObjs.Bookings);
  else showSuccessMessage(actionType, ActionObjs.Workspace);
};
