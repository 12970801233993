import ko from "@shared/knockout/extended";
import { Singleton } from "@shared/utils/Singleton.js";
import { CheckInStatus } from "@shared/services/CheckInStatus";
import { indexBy } from "@shared/utils/indexBy";

const CheckInStatusStore = () => {
  const hydrated = ko.observable(false);

  const cache = ko.observable({});
  const all = cache.map(Object.values);

  const update = checkInStatus => {
    hydrated(true);
    cache(indexBy(c => c.userId, checkInStatus));
    return checkInStatus;
  };

  return {
    hydrated,
    update,
    all,
    cache,
    getById: id => {
      const userCheckInData = cache()[id];
      return getCheckInData(userCheckInData);
    },
    getByDirectoryObjectId: id => {
      const userCheckInData = Object.values(cache()).find(
        ({ directoryObjectId }) => directoryObjectId === id
      );
      return getCheckInData(userCheckInData);
    }
  };
};

export const CheckInStatusStoreSingleton = Singleton(CheckInStatusStore);

const getCheckInData = userCheckInData =>
  userCheckInData && userCheckInData.eventType === CheckInStatus.AtTheOffice
    ? userCheckInData.eventType
    : null;
