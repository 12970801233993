class ParkingLotZoneApiModel {
  constructor(obj) {
    this.id = obj.id;
    this.capacity = obj.capacity;
    this.name = obj.name || "Unnamed parking zone";
    this.parentId = obj.parentId;

    this.type = ParkingLotZoneApiModel;
  }
}

export default ParkingLotZoneApiModel;
