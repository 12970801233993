import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { BuildingNodeTypes } from "@app/utils/BuildingNodesLS";
import {
  getLastBookedNodeIdFromSessionStorage,
  getValueFromLocalStorage
} from "@shared/utils/OfficeDay/storageHelpers";

const sortBuildings = (a, b, buildingId) => {
  if (a.id === buildingId) return -1;
  else if (b.id === buildingId) return 1;
  return 0;
};

export const sortByDefaultOffice = (a, b) => {
  const defaultOffice =
    [a, b].find(({ isUserDefaultOffice }) => isUserDefaultOffice) ??
    LocationStoreSingleton().getPreferredBuilding();

  return sortBuildings(a, b, defaultOffice?.id);
};

export const sortByLastSelectedOffice = (a, b, userId = null) => {
  const storageFn = userId
    ? getLastBookedNodeIdFromSessionStorage
    : getValueFromLocalStorage;

  const lasteSelectedOfficeId = storageFn(BuildingNodeTypes.Building, userId);
  return sortBuildings(a, b, lasteSelectedOfficeId);
};
