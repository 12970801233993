import ko from "@shared/knockout/extended";
import { ParkingLotStoreSingleton } from "./ParkingLotStore";
import { notNil } from "@shared/utils/typeHelper";
import { sum } from "@shared/utils/sum";
import { getDateKey } from "@shared/utils/dateHelpers";
import { Singleton } from "@shared/utils/Singleton";

export const AvailabilityParkingStore = () => {
  const cache = ko.observable({});
  const update = newCache => cache(newCache);

  const get = (date, parkingLotId) => {
    const dateKey = getDateKey(date);
    const _cache = cache();

    // Check if this level appears in cache first
    const inCache = _cache[dateKey] && _cache[dateKey][parkingLotId];
    if (typeof inCache !== "undefined") return inCache;

    // Fall back to using location tree to get all zones
    // and count up their availabilities
    const parking = ParkingLotStoreSingleton().get(parkingLotId);

    if (!parking) {
      return null;
    }

    const children = parking.parkingZones;
    if (!children) return null;
    if (children) {
      const childAvailabilities = children.map(child => get(date, child.id));

      if (childAvailabilities.length === 0) return null;

      const nextLevel = childAvailabilities.filter(notNil);
      return nextLevel.length > 0 ? nextLevel.reduce(sum, 0) : null;
    }
  };

  return {
    update,
    get
  };
};

export const AvailabilityParkingStoreSingleton = Singleton(
  AvailabilityParkingStore
);
