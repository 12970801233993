import { LocationStoreSingleton } from "@shared/services/LocationStore";

export const locationLabels = (locationId, deskName = null) => {
  const locationStore = LocationStoreSingleton();
  const buildingName = locationStore.getBuildingForId(locationId)?.name ?? null;
  const floorName = locationStore.getFloorForId(locationId)?.name ?? null;
  const areaName = locationStore.getWorkAreaForId(locationId)?.name ?? null;
  const areaOrFloorName = areaName ?? floorName;

  return [
    buildingName,
    deskName && areaName ? `${areaName}, ${deskName}` : areaOrFloorName,
    //for workspace details (in side panel) and desk info screen (in booking flow)
    areaOrFloorName ?? buildingName,
    areaName ? `${buildingName}, ${floorName}` : floorName ? buildingName : null
  ];
};
