import template from "./PendingWorkspaceSummary.template.html";
import "./PendingWorkspaceSummary.scss";
import { KOComponent } from "@shared/knockout/KOComponent.js";
import { isSameDay, startOfDay } from "date-fns";
import ko from "@shared/knockout/extended";
import { ConnectionsStoreSingleton } from "@app/services/ConnectionStore.js";
import { ConnectedShiftsHelper } from "@shared/utils/ConnectedShiftsHelper.js";
import { DeskSection } from "@shared/viewmodels/DeskSection.js";
import { CheckInStatusStoreSingleton } from "@app/services/CheckInStatusStore";
import {
  getWorkspaceIcon,
  getWorkspaceIconFromWorkspace
} from "@app/utils/workspaceHelpers";
import { UrlNavigationSingleton } from "@app/utils/URLNavigation";
import { FeatureNames, getFeatures } from "@shared/services/Features";
import { LocationStoreSingleton } from "@shared/services/LocationStore";
import { GroupInvitationVM } from "@app/components/WorkDayTile/GroupInvitation/GroupInvitation";
import { WorkspaceServiceSingleton } from "@app/services/WorkspaceService";
import { WorkspaceVM } from "@app/viewmodels/WorkspaceVM";
import { getGroupDeskIds } from "@app/utils/getGroupDeskIds";
import { getDateKey } from "@shared/utils/dateHelpers";

export const PendingWorkspaceSummaryVM = ({
  groupWorkspaceId,
  date,
  currentWorkspace,
  selectedLocationId,
  user,
  conStore = ConnectionsStoreSingleton(),
  ConHelper = ConnectedShiftsHelper
}) => {
  const checkInStatusStore = CheckInStatusStoreSingleton();
  const urlNavigation = UrlNavigationSingleton();
  const locationStore = LocationStoreSingleton();
  const loading = ko.observable(false);
  const hasWorkDayFeature = getFeatures().has(FeatureNames.WORK_DAY);
  const workspaceService = WorkspaceServiceSingleton();

  const workspace = ko.observable(null);
  const GroupInvite = ko.observable(null);

  const loadingData = ko.observable(true);
  const formattedDate = getDateKey(date);

  const backToDayOverview = () => {
    urlNavigation.navigate("", hasWorkDayFeature ? null : "overview", [
      formattedDate
    ]);
  };

  const loadData = async () => {
    if (!groupWorkspaceId) {
      backToDayOverview();
      return;
    }

    loadingData(true);

    await workspaceService
      .getWorkspaceById(groupWorkspaceId)
      .then(async _groupWorkspace => {
        const currentUserReservationId = _groupWorkspace.members.find(
          ({ userId }) => user.id === userId
        )?.reservationId;

        await workspaceService
          .getWorkspaceById(currentUserReservationId)
          .then(WorkspaceVM)
          .then(async _workspace => {
            workspace(_workspace);

            GroupInvite(
              GroupInvitationVM({
                groupWorkspace: _groupWorkspace,
                currentUser: user,
                currentWorkspace,
                date,
                saveCallback: backToDayOverview
              })
            );
          });
      })
      .catch(e => {
        console.log(e);
        backToDayOverview();
      })
      .finally(() => {
        loadingData(false);
      });
  };

  loadData();

  const locationId = ko.pureComputed(
    () =>
      workspace()?.workAreaId || workspace()?.floorId || workspace()?.buildingId
  );

  const location = ko.pureComputed(() => locationStore.get(locationId()));
  const building = ko.pureComputed(() =>
    locationStore.getBuildingForId(locationId())
  );

  // Social features
  const conHelper = ConHelper();
  const visitsOnDay = conHelper.visitsOnDate(date).map(workspace =>
    Object.assign({}, workspace, {
      person: {
        ...workspace.person,
        checkInStatus: ko.pureComputed(() =>
          isSameDay(date, startOfDay(new Date()))
            ? checkInStatusStore.getById(workspace.person.id)
            : null
        )
      }
    })
  );

  const deskSection = ko.pureComputed(() => {
    if (ko.unwrap(workspace)) {
      const d = DeskSection({
        locationId,
        date,
        workspace: ko.unwrap(workspace),
        selectedDeskIds: getGroupDeskIds(workspace),
        isGroupBooking: ko.observable(true)
      });

      return d;
    }
  });

  // For the editor view
  const visitsInBuilding = ko.pureComputed(() =>
    ConHelper.inBuilding(
      building() ? building().id : workspace()?.buildingId,
      visitsOnDay
    ).filter(loc => loc.location.groupId === locationId)
  );

  const viewInMapMode = ko.observable(false);

  const onBack = () => {
    if (viewInMapMode()) {
      viewInMapMode(false);
      return;
    }

    urlNavigation.navigate(
      "",
      "overview",
      hasWorkDayFeature ? ["workspace"] : [formattedDate]
    );
  };

  //is this used?
  const goHome = () => urlNavigation.navigate("", null, [formattedDate]);

  return {
    loading,
    onBack,
    goHome,
    // General info
    iconName: ko.pureComputed(() =>
      location
        ? getWorkspaceIcon(location)
        : getWorkspaceIconFromWorkspace(workspace())
    ),
    deskName: ko.pureComputed(
      () => workspace()?.deskName ?? "You can use any available desk"
    ),
    hasMap: ko.pureComputed(
      () => locationStore.get(workspace()?.floorId)?.hasMap ?? false
    ),
    viewInMapMode,

    date,
    currentWorkspace,
    workspace,
    selectedLocationId,
    selectedBuildingId: ko.pureComputed(() => workspace()?.buildingId),
    visitsInBuilding,
    user,

    loadingData,

    viewInMap: () => {
      // Make sure we always open fresh without a selected connection
      // from a previous viewing
      deskSection().selectedConnection(null);
      viewInMapMode(true);
    },
    // Desk visualization
    deskSection,

    // Social info
    hasConnections: conStore.all().length,

    backButtonIsVisible: ko.pureComputed(
      () => !hasWorkDayFeature || (hasWorkDayFeature && viewInMapMode())
    ),
    workspaceNodeSummaryParams: {
      date,
      locationId,
      workspace: ko.unwrap(workspace)
    },

    saving: ko.pureComputed(() => GroupInvite()?.saving() ?? false),
    accept: (...props) => GroupInvite()?.accept(...props),
    decline: (...props) => GroupInvite()?.decline(...props)
  };
};

export const PendingWorkspaceSummary = KOComponent(
  "pending-workspace-summary",
  PendingWorkspaceSummaryVM,
  template
);
