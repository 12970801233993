import "./ConnectionAddWindow.scss";
import template from "./ConnectionAddWindow.template.html";

import ko from "@shared/knockout/extended";
import { KOComponent } from "@shared/knockout/KOComponent";
import { Channels } from "@shared/Channels";
import { Mediator } from "@shared/mediator";
import { ConnectionsService } from "@app/services/ConnectionService";

const ConnectionAddWindowVM = ({ name, userId, mediator = Mediator() }) => {
  const question = `Connect with ${name ? name : "the selected colleague"}?`;
  const loading = ko.observable(false);
  const cancel = () => {
    mediator.publish(Channels.CloseConnectionAddWindow);
  };

  return {
    question,
    cancel,
    confirm: () => ConnectionsService().createConnection(userId),
    loading
  };
};

export const ConnectionAddWindow = KOComponent(
  "connection-add",
  ConnectionAddWindowVM,
  template
);
