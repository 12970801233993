import ko from "@shared/knockout/extended";
import template from "./WorkDayInviteTile.template.html";
import { KOComponent } from "@shared/knockout/KOComponent";
import "./WorkDayInviteTile.scss";
import { FeatureNames, getFeatures } from "@shared/services/Features";

const WorkDayInviteTileVM = ({ user, workspace, day, booked }) => {
  const hasGroupInvitationsFeature = getFeatures().has(
    FeatureNames.GROUP_RESERVATIONS
  );

  const hasInvites = ko.observable(false);

  const groupInvitationsParams = {
    user,
    day,
    currentWorkspace: workspace,
    hasInvites,
    booked
  };

  return {
    hasInvites,
    groupInvitationsParams,
    hasGroupInvitationsFeature
  };
};

export const WorkDayInviteTile = KOComponent(
  "workday-invite-tile",
  WorkDayInviteTileVM,
  template
);
